import React from 'react';
import { useMeetingState } from 'hooks';

import DesktopMeetingStateInformation from './DesktopMeetingStateInformation';
import DesktopVotingResults from './DesktopVotingResults';
import DesktopVote from './DesktopVote';

const DesktopVotingMenu: React.FC = () => {
  const {
    isMeetingPlanned,
    hasMeetingEnded,
    isUserVoting,
    isMeetingInProgress,
    isVotingState,
    isResumptionVotingState,
    isResolutionVotingState,
    isMeetingPaused,
    hasUserVotedWithAllShares,
    isBreakTime,
    goalDate,
  } = useMeetingState();

  const isVoteMenuShown = isUserVoting && isResolutionVotingState && !hasUserVotedWithAllShares;

  const isVoteMenuEnabledShown = isVoteMenuShown && isMeetingInProgress;
  const isVoteMenuDisabledShown = isVoteMenuShown && isMeetingPaused;
  const isResumptionVoteMenuShown =
    isUserVoting && isResumptionVotingState && !hasUserVotedWithAllShares && isMeetingInProgress;
  if (isVoteMenuEnabledShown || isVoteMenuDisabledShown || isResumptionVoteMenuShown) {
    return <DesktopVote isMeetingPaused={isMeetingPaused} goalDate={goalDate} />;
  }

  const areAllVotesSentBase = isUserVoting && isVotingState && hasUserVotedWithAllShares;
  const areAllVotesSent =
    (areAllVotesSentBase && isMeetingInProgress) || (areAllVotesSentBase && isMeetingPaused);
  // user voted with all shares
  if (areAllVotesSent) {
    return (
      <DesktopMeetingStateInformation
        secondText="pages.meeting.all_votes_sent"
        secondTextOptions={{ color: 'success', icon: 'success' }}
        counterOptions={{ goalDate, isPaused: isMeetingPaused }}
      />
    );
  }

  // Voting results case
  if (isBreakTime) {
    return <DesktopVotingResults goalDate={goalDate} />;
  }

  const isNotVotingResumptionMenuShown =
    (!isUserVoting && isResumptionVotingState && isMeetingInProgress) ||
    (!isUserVoting && isResumptionVotingState && isMeetingPaused);
  // User not voting on resumption case
  if (isNotVotingResumptionMenuShown) {
    return (
      <DesktopMeetingStateInformation
        secondText="pages.meeting.not_voting_on_resumption"
        counterOptions={{ goalDate, isPaused: isMeetingPaused }}
      />
    );
  }

  const isNotVotingMenuShown =
    (!isUserVoting && isMeetingInProgress) || (!isUserVoting && isMeetingPaused);
  // User not voting case
  if (isNotVotingMenuShown) {
    return (
      <DesktopMeetingStateInformation
        secondText="pages.meeting.not_voting"
        counterOptions={{ goalDate, isPaused: isMeetingPaused }}
      />
    );
  }

  // Meeting ended case
  if (hasMeetingEnded) {
    return <DesktopMeetingStateInformation secondText="pages.meeting.meeting_ended" />;
  }

  // Meeting not started yet case
  if (isMeetingPlanned) {
    return (
      <DesktopMeetingStateInformation
        firstText="pages.meeting.meeting_didnt_started"
        secondText="pages.meeting.there_you_will_vote"
      />
    );
  }

  return null;
};

export default DesktopVotingMenu;
