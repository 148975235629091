import React from 'react';
import { AddShareholdingGroupReq } from 'app-types';
import { Text } from 'components/common';
import { GroupForm } from 'components/forms';
import { Flex } from 'components/layout';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { fetchShareholdingData, successNotification } from 'slices';

const AddGroup: React.FC = () => {
  const { hideModal, data: companyId } = useModal<undefined, string>();
  const dispatch = useAppDispatch();
  const api = useApi();

  const onSubmit = async (reqData: AddShareholdingGroupReq) =>
    api.post(`/company/${companyId}/shareholding/group`, reqData, () => {
      dispatch(fetchShareholdingData(companyId));
      dispatch(successNotification('notifications.added_shareholding_group'));
      hideModal();
    });

  return (
    <Flex direction="col" gap={2}>
      <Text text="modals.add_group.title" size={4} weight="bold" />
      <GroupForm variant="add_group" onSubmit={onSubmit} onCancel={hideModal} />
    </Flex>
  );
};

export default AddGroup;
