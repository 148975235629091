export interface BaseInvoice {
  General: GeneralInvoiceData;
  ParentDetails?: ParentDetailsInvoiceData;
  BuyerDetails: BuyerDetailsInvoiceData;
  Items: ItemsInvoiceData[];
  CurrencyDetails: CurrencyDetailsInvoiceData;
  PaymentDetails: PaymentDetailsInvoiceData;
  RecieverDetails?: RecieverDetailsInvoiceData;
  SplitVatDetails?: SplitVatDetailsInvoiceData;
  AppearanceDetails: AppearanceDetailsInvoiceData;
  InternalDetails: InternalDetailsInvoiceData;
  AllocationDetails?: AllocationDetailsInvoiceData;
}

export interface CreateInvoiceReq extends BaseInvoice {
  General: GeneralInvoiceData;
  PrintSettings?: PrintSettingsInvoiceData;
}

export interface GeneralInvoiceData {
  SeriesCode?: string;
  Type: InvoiceType;
  Number?: string; // example: RA 0001/09/2022
  NumberPostscript?: string;
  MadeOutDate: Date;
  SellingDate: Date;
  ItemsAccountCalculateType: InvoiceItemsAccountCalculateType;
  Notes?: string;
  MadeOutBy: string;
  ReceivedBy?: string;
  AutoCreateInvoiceToPaidProforma?: boolean;
  OrderNumber?: string;
  ContractNumber?: string;
  VATProcedures?: string[];
}

export enum InvoiceType {
  INVOICE = 'Invoice',
  INVOICE_EU = 'InvoiceEU',
  MARGIN = 'Margin',
  BILL = 'Bill',
  REVERSE_CHARGE = 'ReverseCharge',
  PROFORM = 'Proform',
  PROFORM_EU = 'ProformEU',
  CORRECTION = 'Correction',
  CORRECTION_EU = 'CorrectionEU',
  ADVANCE = 'Advance',
  REVERSE_CHARGE_CORRECTION = 'ReverseChargeCorrection',
  BILL_CORRECTION = 'BillCorrection',
  MARGIN_INVOICE_CORRECTION = 'MarginInvoiceCorrection',
  ACCOUNTING_TYPE = 'AccountingNote',
  MARGIN_INVOICE_PROFORMA = 'MarginInvoiceProforma',
}

export enum InvoiceItemsAccountCalculateType {
  FROM_NET = 'FromNet',
  FROM_GROSS = 'FromGross',
}

export interface ParentDetailsInvoiceData {
  IDParentInvoice: number;
  ParentDocumentNumber: string;
  ParentMadeOutDate: Date;
}

export interface BuyerDetailsInvoiceData {
  BuyerName: string;
  BuyerId?: number;
  BuyerIdentifierType: InvoiceBuyerIdentifierType;
  BuyerIdentifier: string;
  BuyerAddressStreet: string;
  BuyerAddressCity: string;
  BuyerAddressCountry: string;
  GlobalLocationNumber?: string;
  BuyerEmail: string;
  Tags?: string[];
}

export enum InvoiceBuyerIdentifierType {
  TAX_ID = 'TaxID',
  PERSONAL_ID = 'PersonalID',
}

export interface ItemsInvoiceData {
  ProductName: string;
  ProductId?: number;
  Amount: number;
  Unit: string;
  NetPrice: number;
  GrossPrice: number;
  TaxRate: number;
  Discount?: number;
  ProductVATCode?: GroupOfGoodsAndServicesCode;
  LineDescription?: string;
  Tags?: string[];
  UpdateProductData: boolean;
}

export enum GroupOfGoodsAndServicesCode {
  GTU_01 = 'GTU_01',
  GTU_02 = 'GTU_02',
  GTU_03 = 'GTU_03',
  GTU_04 = 'GTU_04',
  GTU_05 = 'GTU_05',
  GTU_06 = 'GTU_06',
  GTU_07 = 'GTU_07',
  GTU_08 = 'GTU_08',
  GTU_09 = 'GTU_09',
  GTU_10 = 'GTU_10',
  GTU_11 = 'GTU_11',
  GTU_12 = 'GTU_12',
  GTU_13 = 'GTU_13',
}

export interface CurrencyDetailsInvoiceData {
  Currency: string;
  ConversionRate?: number;
  ConversionRateData?: string;
  ConversionRateSource?: string;
}

export enum Currency {
  PLN = 'PLN',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  CHF = 'CHF',
  AUD = 'AUD',
  CAD = 'CAD',
  JPY = 'JPY',
  CNH = 'CNH',
}

export interface PaymentDetailsInvoiceData {
  PaymentForm: InvoicePaymentFormType;
  BankAccount: BankAccount;
  PaymentDeadline: Date;
  PaymentEventType?: InvoicePaymentEventType;
  PaymentEventDays?: number;
  PaymentEventDate?: Date;
  PaidAmount: number;
  PaidAmountForNow: number;
}

interface BankAccount {
  BankAccountNumber: string;
  BankName: string;
  SWIFT: string;
}

export enum InvoicePaymentFormType {
  CASH = 'Cash',
  BANK_TRANSFER = 'BankTransfer',
  CREDIT_CARD = 'CreditCard',
  PAYMENT_CARD = 'PaymentCard',
  CUSTOM = 'Custom',
  CASH_PAID = 'CashPaid',
  BANK_TRANSFER_PAID = 'BankTransferPaid',
  COMPENSATION = 'Compensation',
  PAID_BY_ELECTRONIC_PAYMENT = 'PaidByElectronicPayment',
  BARTER = 'Barter',
  PAID_BY_PAYPAL = 'PaidByPayPal',
  BANK_TRANSFER_OR_ELECTRONIC_PAYMENT = 'BankTransferOrElectronicPayment',
  ELECTRONIC_PAYMENT = 'ElectronicPayment',
  CASH_ON_DELIVERY = 'CashOnDelivery',
  PAID_BY_STRIPE = 'PaidByStripe',
  PAID_BY_AMAZON = 'PaidByAmazon',
  PAID_BY_ALLEGRO = 'PaidByAllegro',
  PAID_BY_ETSY = 'PaidByEtsy',
  PAID_BY_ARENA = 'PaidByArena',
  TURIST_VOUCHER = 'TuristVoucher',
  PAID_BY_TPAY = 'PaidByTPay',
  PAID_BY_PAYU = 'PaidByPayU',
  PAID_BY_PRZELEWY24 = 'PaidByPrzelewy24',
}

enum InvoicePaymentEventType {
  DRAWN_UP_DATE = 'DrawnUpDate',
  DELIVERY_DATE = 'DeliveryDate',
  COLLECTION_DATE = 'CollectionDate',
  COLLECTION_OF_DOCUMENT_DATE = 'CollectionOfDocumentDate',
}

export interface RecieverDetailsInvoiceData {
  ReceiverName: string;
  ReceiverId?: number;
  ReceiverAddressStreet: string;
  ReceiverAddressCity: string;
  ReceiverAddressCountry: string;
  ReceiverExternalID?: string;
}

export interface SplitVatDetailsInvoiceData {
  VATBankAccount: {
    BankAccountNumber: string;
    BankName: string;
    SWIFT: string;
  };
  PaymentForTwoCurrencyType: InvoicePaymentForTwoCurrencyType;
  PaymentConversionRate: number;
  PaymentConversionRateDate: Date;
}

enum InvoicePaymentForTwoCurrencyType {
  NETTO = 'Netto',
  GROSS_IN_CURRENCY = 'GrossInCurrency',
  GROSS_IN_BASE_CURRENCY = 'GrossInBaseCurrency',
  GROSS_IN_CURRENCY_OR_BASE_CURRENCY = 'GrossInCurrencyOrBaseCurrency',
}

export interface AppearanceDetailsInvoiceData {
  Language: InvoiceLanguageType;
  SecondLanguage?: InvoiceLanguageType;
}

export enum InvoiceLanguageType {
  PL = 'PL',
  EN = 'EN',
  DE = 'DE',
  RU = 'RU',
  CS = 'CS',
  FR = 'FR',
  IT = 'IT',
  ES = 'ES',
  UK = 'UK',
  SV = 'SV',
  DA = 'DA',
  FI = 'FI',
  NB = 'NB',
}

export interface InternalDetailsInvoiceData {
  InternalNotes?: string;
  Tags?: string[];
  Flag: InvoiceFlagType;
}

export enum InvoiceFlagType {
  NO_FLAG = 'NoFlag',
  RED = 'Red',
  ORANGE = 'Orange',
  YELLOW = 'Yellow',
  GREEN = 'Green',
  VIOLET = 'Violet',
  BLUE = 'Blue',
  PINK = 'Pink',
}

interface PrintSettingsInvoiceData {
  PrintAccountNumber?: boolean;
  PrintBankName?: boolean;
  PrintEmail?: boolean;
  PrintMobilePhoneNo?: boolean;
  PrintPhoneNo?: boolean;
  PrintSwift?: boolean;
  PrintWebsite?: boolean;
  PrintCurrencyConversionRate?: boolean;
  PrintMadeOutSignature?: boolean;
  PrintReceivedBySignature?: boolean;
  PrintDiscountIfItIsZero?: boolean;
  PrintBaseCurrencySummaryForCurrencyDocument?: boolean;
  PrintVATID?: boolean;
  PrintOnlyChangedCorrectedItems?: boolean;
  PrintPackingDataBaseNo?: boolean;
  PrintProductVATCode?: boolean;
  PrintItemsLineDescription?: boolean;
  PrintOrderNumber?: boolean;
  PrintContractNumber?: boolean;
  PrintVATProcedures?: boolean;
}

export interface AllocationDetailsInvoiceData {
  Rows: RowsItem[];
}

type RowsItem = {
  Tags: string[];
  Description: string;
  Amount: number;
  Percent: number;
};
