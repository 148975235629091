import React from 'react';
import { useParams } from 'react-router';
import { Flex } from 'components/layout';
import { InlineButton } from 'components/common';
import { useAppDispatch, useAppSelector } from 'hooks';
import { prepareModal } from 'slices';
import { ModalType, MeetingParams } from 'types';
import {
  isResolutionVotingState,
  isResumptionVotingState,
  isVotingState,
  usersNotVotedChange,
} from 'utils';

interface Props {
  areButtonsDisabled: boolean;
}

const VoteActionButtons: React.FC<Props> = ({ areButtonsDisabled }) => {
  const dispatch = useAppDispatch();
  const { meetingId } = useParams<MeetingParams>();
  const { data, state } = useAppSelector(({ meeting }) => meeting);

  const onSplitVotes = () => {
    if (!data || !isVotingState(state)) return;

    if (isResolutionVotingState(state)) {
      const modalData = {
        meetingId: data.id,
        votingId: state.resolution.voting.id,
        shareholder: state.shareholders[0],
      };

      dispatch(prepareModal({ type: ModalType.SplitVotes, data: modalData, isVisible: true }));
      return;
    }

    const modalData = {
      meetingId: data.id,
      resumptionId: state.resumption.id,
      shareholder: state.shareholders[0],
    };

    dispatch(prepareModal({ type: ModalType.SplitVotes, data: modalData, isVisible: true }));
  };

  const onChangeReport = () => {
    if (!data || !isResolutionVotingState(state)) return;

    dispatch(
      prepareModal({
        type: ModalType.ReportChange,
        data: {
          votingId: state.resolution.voting.id,
          shareholders: data.shareholders,
          meetingId,
          changesReports: state.changesReports,
        },
        isVisible: true,
      }),
    );
  };

  const hadAllShareholdersReportedChange = Boolean(
    state &&
      'changesReports' in state &&
      usersNotVotedChange(data?.shareholders, state.changesReports).length === 0,
  );

  const isReportChangeButtonDisabled =
    areButtonsDisabled || isResumptionVotingState(state) || hadAllShareholdersReportedChange;

  return (
    <Flex justify="space-between" gap={1}>
      <InlineButton
        text="pages.meeting.split_votes"
        underline
        type="button"
        onClick={onSplitVotes}
        disabled={areButtonsDisabled}
      />
      <InlineButton
        text="pages.meeting.report_change"
        underline
        type="button"
        onClick={onChangeReport}
        disabled={isReportChangeButtonDisabled}
      />
    </Flex>
  );
};

export default VoteActionButtons;
