import React, { useId } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors } from 'react-hook-form';
import clsx from 'clsx';
import { Icon } from 'components/common';
import { FieldErrorMessage, Label } from 'components/forms';
import { __ } from 'services';

import styles from './Input.module.scss';
import { isValid } from './isValid';

interface Props {
  name: string;
  value: string;
  onChange: (v: string) => void;
  errors?: FieldErrors;
  label?: string;
  required?: boolean;
  disabled?: boolean;
}

const Input: React.FC<Props> = ({ name, value, onChange, errors, label, required, disabled }) => {
  const id = useId();
  let lastVal = '';

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val === value || !isValid(val)) return;

    if (val.length === 2 && lastVal.length !== 3 && val.indexOf(':') === -1) {
      val += ':';
    }

    if (val.length === 2 && lastVal.length === 3) {
      val = val.slice(0, 1);
    }

    if (val.length > 5) return;

    lastVal = val;

    onChange(val);
  };
  return (
    <div className={styles.container}>
      {label && (
        <Label disabled={disabled} label={label} htmlFor={`${id}-${name}`} required={required} />
      )}
      <div className={clsx(styles?.['input-container'], { [styles.disabled]: disabled })}>
        <Icon className={styles.icon} name="clock-fill" />
        <input
          className={clsx(styles.input)}
          onChange={onChangeHandler}
          value={value}
          placeholder={__('placeholders.time')}
          id={`${id}-${name}`}
          disabled={disabled}
        />
        {errors && (
          <ErrorMessage
            errors={errors}
            name={name ?? ''}
            render={({ message }) => (
              <FieldErrorMessage message={message} className={styles.nowrap} />
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
