import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useApi, useAppDispatch } from 'hooks';
import { CompanyType, MeetingFormData, MeetingFormDataRes, MeetingType } from 'app-types';
import { ProtocolFormData, ProtocolType } from 'types';
import { AllRoutes, createPath } from 'config';
import { getProtocolData } from 'utils';
import { successNotification } from 'slices';

interface Props {
  meetingId: string;
}

interface Protocol {
  meeting: MeetingFormData | undefined;
  protocolType: ProtocolType;
  submitProtocol: (data: ProtocolFormData) => Promise<void>;
}

export const useProtocol = ({ meetingId }: Props): Protocol => {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [meeting, setMeeting] = useState<MeetingFormData | undefined>();

  useEffect(() => {
    (async function fetchMeeting() {
      await api.get<MeetingFormDataRes>(`/meeting/${meetingId}/form-data`, (data) => {
        setMeeting(data.meeting);
      });
    })();
  }, [meetingId, api]);

  const protocolType = useMemo(() => {
    if (
      meeting?.type !== MeetingType.NWZ &&
      meeting?.type !== MeetingType.NZW &&
      meeting?.type !== MeetingType.ZWZ &&
      meeting?.type !== MeetingType.ZZW
    ) {
      return ProtocolType.AUTHORITY_MEETING;
    }
    if (meeting?.company.type === CompanyType.JOINT_STOCK_COMPANY) {
      return ProtocolType.JOINT_STOCK_COMPANY;
    }
    if (meeting?.company.type === CompanyType.SIMPLE_JOINT_STOCK_COMPANY) {
      return ProtocolType.SIMPLE_JOINT_STOCK_COMPANY;
    }
    if (meeting?.company.type === CompanyType.LIMITED_LIABILITY_COMPANY) {
      return ProtocolType.LIMITED_LIABILITY_COMPANY;
    }

    return null as never;
  }, [meeting?.company.type, meeting?.type]);

  const submitProtocol = async (data: ProtocolFormData) => {
    if (!meeting) {
      return;
    }

    const formData = getProtocolData(protocolType, data);

    await api.post(`/protocol/${meetingId}`, formData, () => {
      dispatch(successNotification('notifications.generated_protocol'));
      navigate(
        createPath({
          path: AllRoutes.AdminCompanyArchiveMeetingDetails,
          params: {
            meetingId,
            companyId: meeting.company?.id,
          },
        }),
      );
    });
  };

  return { meeting, protocolType, submitProtocol };
};
