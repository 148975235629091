import React, { useMemo } from 'react';
import { Text } from 'components/common';
import { DeleteModalContentContainer } from 'components/containers';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { successNotification, fetchMeetingData } from 'slices';
import { ReportData, ReportFormData } from 'types';

import ReportModalContent from './ReportModalContent';

interface Props {
  variant: 'change' | 'objection';
}

const Report: React.FC<Props> = ({ variant }) => {
  const {
    hideModal,
    data: { votingId, shareholders, objections, meetingId, changesReports },
  } = useModal<undefined, ReportData>();
  const api = useApi();
  const dispatch = useAppDispatch();

  const onSubmit = async ({ reportingId }: ReportFormData) => {
    await api.patch(
      `/voting/${votingId}/shareholder/${reportingId}/${variant}s-reports/${meetingId}`,
      undefined,
      () => {
        dispatch(successNotification(`notifications.${variant}_reported`));
        dispatch(fetchMeetingData({ meetingId }));
        hideModal();
      },
    );
  };

  const filteredShareholders = useMemo(() => {
    if (variant === 'change') {
      return shareholders.filter((shareholder) => {
        if ('userVoterId' in shareholder) {
          return !changesReports?.some(
            (changeReport) => changeReport.userVoter?.id === shareholder.userVoterId,
          );
        }
        if ('companyVoterId' in shareholder) {
          return !changesReports?.some(
            (changeReport) => changeReport.companyVoter?.id === shareholder.companyVoterId,
          );
        }
        return false;
      });
    }
    if (variant === 'objection') {
      return shareholders.filter((shareholder) => {
        if ('userVoterId' in shareholder) {
          return !objections?.some(
            (objection) => objection.userVoter?.id === shareholder.userVoterId,
          );
        }
        if ('companyVoterId' in shareholder) {
          return !objections?.some(
            (objection) => objection.companyVoter?.id === shareholder.companyVoterId,
          );
        }
        return false;
      });
    }

    return [];
  }, [objections, shareholders, variant, changesReports]);

  if (filteredShareholders.length === 0) return null;

  return (
    <DeleteModalContentContainer>
      <Text text={`modals.report.${variant}_title`} size={4} uppercase weight="bold" />
      <ReportModalContent
        shareholders={filteredShareholders}
        totalShareholders={shareholders.length}
        onSubmit={onSubmit}
        onCancel={hideModal}
        variant={variant}
      />
    </DeleteModalContentContainer>
  );
};

export default Report;
