import React from 'react';
import { Input } from 'components/forms';
import { Flex } from 'components/layout';

import styles from './NameInputs.module.scss';

interface Props {
  name: string;
}
const NameInputs: React.FC<Props> = ({ name }) => (
  <Flex justify="space-between" grow={1} gap={2}>
    <Input
      name={`${name}.names.0`}
      label="protocol.name"
      placeholder="placeholders.provide_data"
      required
      size="responsive"
      className={styles.input}
    />
    <Input
      name={`${name}.names.1`}
      label="protocol.second_name"
      placeholder="placeholders.provide_data"
      className={styles.input}
      size="responsive"
    />
  </Flex>
);

export default NameInputs;
