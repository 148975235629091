import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiErrorCode, GetMeetingCurrentStateRes } from 'app-types';
import { callApi } from 'services';
import { EmptyObject, MeetingState } from 'types';
import { isApiErrorMessage } from 'utils';

const initialState: MeetingState = {};

interface FetchMeetingDataProps {
  meetingId: string;
  onForbiddenResource?: () => void;
}

export const fetchMeetingData = createAsyncThunk<
  GetMeetingCurrentStateRes | EmptyObject,
  FetchMeetingDataProps
>('meeting/fetchMeetingData', async ({ meetingId, onForbiddenResource }, { dispatch }) =>
  callApi<GetMeetingCurrentStateRes>(
    dispatch,
    'GET',
    `/meeting/${meetingId}/current-state`,
    undefined,
    undefined,
    (error) =>
      isApiErrorMessage(error) &&
      error.error_code === ApiErrorCode.ForbiddenResource &&
      onForbiddenResource?.(),
    false,
  ),
);

const excludeShareholders = (meetingData: GetMeetingCurrentStateRes | EmptyObject) => {
  if (!('data' in meetingData)) return meetingData;
  const { data, state } = meetingData;

  const shareholders = data.shareholders.filter(
    ({ id }) => !state?.excludedVotersIds?.includes(id),
  );

  return { data: { ...data, shareholders }, state };
};

export const meetingSlice = createSlice({
  name: 'meeting',
  initialState,
  reducers: {
    clearMeetingData: () => initialState,
  },
  extraReducers: (builder) =>
    builder.addCase(fetchMeetingData.fulfilled, (state, { payload }) =>
      excludeShareholders(payload),
    ),
});

export const { clearMeetingData } = meetingSlice.actions;
