import React from 'react';
import clsx from 'clsx';
import { __ } from 'services';

import styles from './HorizontalLine.module.scss';

interface Props {
  or?: boolean;
  mrT?: number;
  mrB?: number;
}

const HorizontalLine: React.FC<Props> = ({ or, mrT, mrB }) => (
  <div
    className={clsx(styles.wrapper, {
      [styles?.[`margin-top-${mrT}`]]: mrT,
      [styles?.[`margin-bottom-${mrB}`]]: mrB,
    })}
  >
    {or && <span className={styles.text}>{__('application.or')}</span>}
  </div>
);

export default HorizontalLine;
