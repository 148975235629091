import React from 'react';
import { Text } from 'components/common';
import { MobileSharesMenuContentContainer } from 'components/containers';
import { Flex } from 'components/layout';
import { SharesList } from 'components/meeting';
import { useAppSelector } from 'hooks';
import { getName, isVotingState } from 'utils';

import MobileSharesMenuProgressbar from '../MobileSharesMenuProgressbar';

const MobileSharesMenuContent: React.FC = () => {
  const { state } = useAppSelector(({ meeting }) => meeting);

  const shareholder = isVotingState(state) && state?.shareholders?.[0];
  if (!shareholder) return null;

  return (
    <MobileSharesMenuContentContainer>
      <Flex direction="col" gap={2}>
        <MobileSharesMenuProgressbar />
        <Text text={getName(shareholder)} shouldTranslate={false} size={5} weight="bold" />
        <Text size={1} uppercase color="sixth" weight="bold" text="pages.meeting.shares_data" />
        <SharesList shares={shareholder.shares} />
      </Flex>
    </MobileSharesMenuContentContainer>
  );
};

export default MobileSharesMenuContent;
