import React from 'react';
import { useNavigate } from 'react-router';
import { AllRoutes, createPath } from 'config';
import { Flex } from 'components/layout';
import { useModal } from 'hooks';
import { Text, Button } from 'components/common';
import { MeetingClosedData } from 'types';
import { ModalActionButtonsContainer } from 'components/containers';

const MeetingClosed: React.FC = () => {
  const navigate = useNavigate();
  const {
    hideModal,
    data: { meetingId },
  } = useModal<undefined, MeetingClosedData>();

  const clickHandler = () => {
    navigate(createPath({ path: AllRoutes.AdminProtocol, params: { meetingId } }));
    hideModal();
  };

  return (
    <Flex direction="col" align="center" gap={2}>
      <Text text="modals.meeting_closed.title" size={4} weight="bold" uppercase />
      <ModalActionButtonsContainer>
        <Button text="application.generate_protocol" variant="secondary" onClick={clickHandler} />
      </ModalActionButtonsContainer>
    </Flex>
  );
};

export default MeetingClosed;
