import React from 'react';
import { Icon, Text } from 'components/common';
import { DesktopMeetingStateInformationContainer } from 'components/containers';
import { Flex } from 'components/layout';
import { IconName } from 'types';

import DesktopCounter from '../DesktopCounter';
import styles from './DesktopMeetingStateInformation.module.scss';

interface SecondTextOptions {
  color: 'primary' | 'success' | 'error';
  icon?: IconName;
}

interface CounterOptions {
  goalDate: string | undefined | Date;
  isPaused: boolean;
}

interface Props {
  secondText: string;
  secondTextOptions?: SecondTextOptions;
  firstText?: string;
  counterOptions?: CounterOptions;
}

const DesktopMeetingStateInformation: React.FC<Props> = ({
  firstText,
  secondText,
  counterOptions,
  secondTextOptions = { color: 'primary', icon: undefined },
}) => (
  <DesktopMeetingStateInformationContainer withCounter={!!counterOptions}>
    {!!counterOptions && (
      <DesktopCounter goalDate={counterOptions.goalDate} isPaused={counterOptions.isPaused} />
    )}
    <Flex direction="col" gap={2}>
      {firstText && <Text text={firstText} size={3} weight="bold" align="center" />}
      <Flex
        className={styles?.[`color-${secondTextOptions?.color}`]}
        align="center"
        justify="center"
        xs={counterOptions ? 8 : undefined}
        gap={1}
      >
        {secondTextOptions?.icon && <Icon name={secondTextOptions?.icon} />}
        <Text text={secondText} size={2} color={secondTextOptions?.color} align="center" />
      </Flex>
    </Flex>
  </DesktopMeetingStateInformationContainer>
);

export default DesktopMeetingStateInformation;
