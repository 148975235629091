import React from 'react';
import { AgendaItemListContainer } from 'components/containers';
import { useAppSelector } from 'hooks';

import AgendaItem from '../AgendaItem';

const AgendaItemList: React.FC = () => {
  const { data, state } = useAppSelector(({ meeting }) => meeting);

  const { agendaItems } = data!;

  return (
    <AgendaItemListContainer>
      {agendaItems.map(({ id: agendaItemId, resolution }, i) => (
        <AgendaItem
          isActive={agendaItemId === state?.id}
          key={agendaItemId}
          resolutionData={resolution}
          ordinalNumber={i + 1}
          identifiers={{ meetingId: data!.id, agendaItemId }}
          votersLeft={state?.votersLeft}
        />
      ))}
    </AgendaItemListContainer>
  );
};

export default AgendaItemList;
