import React from 'react';
import { Flex } from 'components/layout';
import { ProtocolFormData, ProtocolType } from 'types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PageSubheader } from 'components/common';

import AgendaItem from './AgendaItem';

interface Props {
  protocolType: ProtocolType;
}

const Agenda: React.FC<Props> = ({ protocolType }) => {
  const { control } = useFormContext<ProtocolFormData>();
  const { fields } = useFieldArray({
    control,
    name: 'agendaContentList',
  });

  return (
    <Flex direction="col" gap={6}>
      <PageSubheader text="protocol.agenda_title" />
      <Flex direction="col" gap={3}>
        {fields.map((item, index) => (
          <AgendaItem protocolType={protocolType} item={item} index={index} key={item.id} />
        ))}
      </Flex>
    </Flex>
  );
};
export default Agenda;
