import React from 'react';
import { GroupMemberModalVariant } from 'types';
import AddMember from './AddMember';
import AddSubject from './AddSubject';
import EditMember from './EditMember';
import EditSubject from './EditSubject';
import DeleteMember from './DeleteMember';

interface Props {
  variant: GroupMemberModalVariant;
}

const GroupMember: React.FC<Props> = ({ variant }) => {
  switch (variant) {
    case 'add_member':
      return <AddMember />;
    case 'add_subject':
      return <AddSubject />;
    case 'edit_member':
      return <EditMember />;
    case 'edit_subject':
      return <EditSubject />;
    case 'delete_member':
      return <DeleteMember />;
    default:
      return null;
  }
};

export default GroupMember;
