import React from 'react';
import clsx from 'clsx';

import styles from './Spinner.module.scss';

interface Props {
  overlay?: boolean;
  small?: boolean;
  transparent?: boolean;
  halfTransparent?: boolean;
  dark?: boolean;
  fading?: boolean;
}

const Spinner = ({ overlay, transparent, halfTransparent, dark, small, fading }: Props) => {
  const classNames = clsx(styles.container, {
    [styles.overlay]: overlay,
    [styles.small]: small,
    [styles.transparent]: transparent,
    [styles?.['half-transparent']]: halfTransparent,
    [styles.dark]: dark,
    [styles.fading]: fading,
  });

  return (
    <div className={classNames}>
      <div className={styles.spinner} />
    </div>
  );
};

export default Spinner;
