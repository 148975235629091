import React, { useEffect } from 'react';
import { DefaultValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface Props<T extends object> {
  children: React.ReactNode;
  submitHandler: SubmitHandler<T>;
  validationSchema?: Yup.AnyObjectSchema;
  defaultValues?: DefaultValues<T>;
  resetOnSuccessSubmit?: boolean;
  autoComplete?: 'off' | 'new-password';
}

const Form = <T extends object>({
  children,
  submitHandler,
  validationSchema,
  defaultValues,
  resetOnSuccessSubmit,
  autoComplete,
}: Props<T>) => {
  const methods = useForm({
    resolver: validationSchema && yupResolver(validationSchema),
    defaultValues,
  });

  const { reset, formState } = methods;

  useEffect(() => {
    if (!defaultValues) return;

    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!formState.isSubmitSuccessful || !resetOnSuccessSubmit) return;

    reset(defaultValues);
  }, [formState, defaultValues, reset, resetOnSuccessSubmit]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete={autoComplete}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
