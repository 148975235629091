import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'components/common';
import { Checkbox, Input } from 'components/forms';
import { Row } from 'components/layout';
import { GroupMemberModalVariant } from 'types';
import { CompanyType } from 'app-types';

import InputsList from './InputsList';

interface Props {
  variant: GroupMemberModalVariant;
  companyType: CompanyType;
}

const getVariantData = (variant?: GroupMemberModalVariant) => {
  switch (variant) {
    case 'add_subject':
    case 'edit_subject':
      return { areTreasurySharesVisible: true };
    default:
      return { areTreasurySharesVisible: false };
  }
};

const SharesGroup: React.FC<Props> = ({ variant, companyType }) => {
  const isLimitedLiabilityCompany = companyType === CompanyType.LIMITED_LIABILITY_COMPANY;
  const {
    watch,
    trigger,
    formState: { isSubmitted },
  } = useFormContext();

  const isVoting = watch('isVoting');
  const { areTreasurySharesVisible } = getVariantData(variant);
  const basicSharesTranslationPath = isLimitedLiabilityCompany ? 'shares_stock' : 'shares';

  useEffect(() => {
    const subscription = watch((data, info) => {
      if (data?.shares?.normal?.value === undefined) return;
      if (info.type === 'change' && info.name?.includes('shares') && isSubmitted) {
        trigger('shares');
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, trigger, isSubmitted]);

  return (
    <>
      <Checkbox name="isVoting" label="shares.is_voting" />
      {!!isVoting && (
        <>
          <Text
            text={isLimitedLiabilityCompany ? 'application.shares_stock' : 'application.shares'}
            size={3}
            weight="bold"
          />
          <InputsList isLimitedLiabilityCompany={isLimitedLiabilityCompany} />

          {!isLimitedLiabilityCompany && areTreasurySharesVisible && (
            <>
              <Text text={`${basicSharesTranslationPath}.treasury`} size={3} weight="bold" />
              <Row divide={2} alignFe>
                <Input
                  minNumber={0}
                  name="shares.treasury.value"
                  label={`${basicSharesTranslationPath}.value`}
                  size="responsive"
                  type="number"
                />
                <Checkbox
                  name="shares.treasury.isQuorumIncluded"
                  label="shares.is_quorum_included"
                />
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SharesGroup;
