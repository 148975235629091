import React from 'react';
import { __ } from 'services';

import styles from './DataWillBeRemoved.module.scss';

const DataWillBeRemoved: React.FC = () => (
  <span className={styles.text}>
    {__('modals.upload_csv.title_1')}
    <b>{__('modals.upload_csv.title_2')}</b>
  </span>
);

export default DataWillBeRemoved;
