import React from 'react';
import { __ } from 'services';

import styles from './AppOffline.module.scss';

const AppOffline: React.FC = () => (
  <div className={styles?.['app-status']}>
    <span className={styles?.['app-status-title']}>Ivoting Business</span>
    <strong>{__('offline_status.you_re_offline')}</strong>
    <svg
      className={styles?.['nowifi-icon']}
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m256 0c-67.468 0-131.5 26.88-180.31 75.688s-75.688 112.84-75.688 180.31c0 139.48 116.13 256 256 256 139.48 0 256-116.13 256-256 0-139.48-116.13-256-256-256zm-159.1 415.1c-85.646-85.645-89.056-217.87-10.262-307.25l26.815 26.815c-10.11 6.838-19.785 14.425-28.931 22.75-6.126 5.576-6.573 15.063-0.998 21.19 5.575 6.126 15.062 6.573 21.19 0.998 9.529-8.672 19.707-16.438 30.401-23.275l21.723 21.723c-12.761 7.556-24.738 16.608-35.68 27.084-5.984 5.729-6.19 15.225-0.461 21.208 5.729 5.984 15.225 6.19 21.208 0.46 11.172-10.696 23.616-19.643 36.97-26.716l22.538 22.538c-16.302 7.228-31.205 17.655-43.631 30.838-5.683 6.028-5.402 15.521 0.626 21.204s15.522 5.402 21.204-0.626c12.613-13.381 28.006-22.984 44.938-28.279l25.482 25.482c-22.939 1.796-43.455 14.16-56.006 32.543-4.671 6.842-2.912 16.175 3.93 20.846 6.847 4.674 16.178 2.907 20.846-3.93 7.022-10.286 18.045-17.272 30.243-19.169 24.864-3.87 41.106 13.323 46.499 15.221l108.6 108.6c-89.375 78.793-221.6 75.383-307.25-10.262zm328.46-10.95-162.95-162.95c26.722 1.591 51.372 13.106 69.977 32.844 5.687 6.034 15.181 6.304 21.204 0.626 6.028-5.682 6.309-15.175 0.626-21.204-25.437-26.986-61.236-42.464-98.218-42.464-7.39 0-14.732 0.628-21.954 1.833l-25.048-25.048c15.087-4.468 30.887-6.785 47.002-6.785 42.726 0 83.245 16.267 114.09 45.804 5.984 5.73 15.479 5.522 21.208-0.46 5.729-5.984 5.523-15.479-0.461-21.208-36.459-34.91-84.347-54.136-134.84-54.136-24.488 0-48.358 4.535-70.613 13.174l-22.815-22.815c29.069-13.313 60.857-20.359 93.428-20.359 56.032 0 109.76 20.813 151.28 58.604 6.127 5.576 15.613 5.129 21.19-0.998 5.576-6.127 5.129-15.614-0.998-21.19-47.061-42.829-107.96-66.416-171.48-66.416-40.686 0-80.291 9.693-115.88 27.907l-32.268-32.268c42.825-37.755 95.482-56.649 148.15-56.649 57.246 0 114.49 22.304 159.1 66.911 85.646 85.646 89.055 217.87 10.262 307.25z" />
      <path d="m256 331c-24.813 0-45 20.187-45 45s20.187 45 45 45 45-20.187 45-45-20.187-45-45-45zm0 60c-8.271 0-15-6.729-15-15s6.729-15 15-15 15 6.729 15 15-6.729 15-15 15z" />
    </svg>
    <p>{__('offline_status.become_online')}</p>
  </div>
);

export default AppOffline;
