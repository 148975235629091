import React, { useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { __ } from 'services';

import FieldErrorMessage from '../FieldErrorMessage';
import Label from '../Label';
import styles from './TextArea.module.scss';

interface Props {
  name: string;
  label?: string;
  labelTranslationArgs?: Record<string, string>;
  placeholder?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  cols?: number;
  rows?: number;
  size?: 'normal' | 'responsive';
  disableResize?: boolean;
}

const TextArea: React.FC<Props> = ({
  name,
  label,
  labelTranslationArgs,
  className,
  required,
  cols,
  rows,
  disabled = false,
  placeholder = 'placeholders.provide_data',
  size = 'normal',
  disableResize,
}) => {
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);
  const id = useId();

  return (
    <div className={clsx(className, styles.field)}>
      {label && (
        <Label
          htmlFor={`${id}-${name}`}
          required={required}
          label={label}
          translationArgs={labelTranslationArgs}
        />
      )}
      <textarea
        {...(register && register(name))}
        className={clsx(
          styles?.['text-area'],
          { [styles.error]: error },
          styles?.[size],
          disableResize && styles['disable-resize'],
        )}
        id={`${id}-${name}`}
        name={name}
        placeholder={placeholder ? __(placeholder) : ''}
        aria-invalid={!!error}
        disabled={disabled}
        cols={cols}
        rows={rows}
      />
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <FieldErrorMessage message={message} />}
        />
      )}
    </div>
  );
};

export default TextArea;
