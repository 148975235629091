import {
  AddCompanyShareholderReq,
  AddUserShareholderReq,
  BaseSharesWithIds,
  SetShareholderSharesReq,
  ShareholderCompany,
  ShareholderUser,
  SharesType,
  UpdateCompanyShareholderReq,
  UpdateUserShareholderReq,
  ShareholdingGroup,
} from 'app-types';
import {
  GroupMemberFormData,
  GroupSubjectFormData,
  AddSubjectOrMemberShares,
  ParticipantsGroup,
} from 'types';

const mapSharesType = (key: string) => {
  switch (key) {
    case 'nonVoting':
      return SharesType.NON_VOTING;
    case 'preferential':
      return SharesType.PREFERENTIAL;
    case 'treasury':
      return SharesType.TREASURY;
    default:
      return null;
  }
};

export const mapSharesTypeToString = (type: SharesType | null, multiplier?: number) => {
  switch (type) {
    case SharesType.NON_VOTING:
      return 'nonVoting';
    case SharesType.PREFERENTIAL:
      return `preferentialM${multiplier}`;
    case SharesType.TREASURY:
      return 'treasury';
    default:
      return 'normal';
  }
};

const transformSharesObjectToArray = (
  shares: AddSubjectOrMemberShares,
): SetShareholderSharesReq[] =>
  Object.entries(shares).reduce<SetShareholderSharesReq[]>((acc, [key, obj]) => {
    if (!obj.value || Number(obj.value) === 0) return acc;
    const [firstKey, multiplier] = key.split('M');

    return [
      ...acc,
      { ...obj, type: mapSharesType(firstKey), multiplier: multiplier ?? obj.multiplier },
    ];
  }, []);

const transformSharesArrToObject = (shares: BaseSharesWithIds[]) =>
  shares.reduce<AddSubjectOrMemberShares>(
    (acc, { type, value, isQuorumIncluded, multiplier }) => {
      const key = mapSharesTypeToString(type, multiplier);

      return { ...acc, [key]: { value, isQuorumIncluded } };
    },
    {
      normal: { value: '' },
      nonVoting: { value: '' },
      preferentialM1: { value: '', multiplier: '' },
      preferentialM2: { value: '', multiplier: '' },
      preferentialM3: { value: '', multiplier: '' },
      treasury: { value: '', isQuorumIncluded: false },
    },
  );

export const mapToAddUserShareholderReq = ({
  isVoting,
  shares,
  representatives,
  email,
}: GroupMemberFormData): AddUserShareholderReq => {
  if (!shares || !isVoting) return { email, representative: representatives[0] };
  const sharesArr = transformSharesObjectToArray(shares);

  return { email, shares: sharesArr, representative: representatives[0] };
};

export const mapToUpdateUserShareholderReq = ({
  shares,
  representatives,
  isVoting,
}: GroupMemberFormData): UpdateUserShareholderReq => {
  if (!isVoting || !shares) return { representative: representatives[0] };
  const sharesArr = transformSharesObjectToArray(shares);

  return { shares: sharesArr, representative: representatives[0] };
};

export const mapToAddCompanyShareholderReq = ({
  isVoting,
  shares,
  ...rest
}: GroupSubjectFormData): AddCompanyShareholderReq => {
  if (!shares || !isVoting) return rest;
  const sharesArr = transformSharesObjectToArray(shares);

  return { ...rest, shares: sharesArr };
};

export const mapToUpdateCompanyShareholderReq = ({
  shares,
  isVoting,
  representatives,
}: GroupSubjectFormData): UpdateCompanyShareholderReq => {
  if (!isVoting || !shares) return { representatives };
  const sharesArr = transformSharesObjectToArray(shares);

  return { shares: sharesArr, representatives };
};

export const mapToGroupMemberFormData = ({
  representative,
  shares,
  email,
}: ShareholderUser): GroupMemberFormData => {
  const representatives = representative ? [{ email: representative.email }] : [];

  let formattedShares;
  let isVoting = false;

  if (shares && shares.length) {
    isVoting = true;
    formattedShares = transformSharesArrToObject(shares);
  }

  return { email, representatives, shares: formattedShares, isVoting };
};

export const mapToGroupSubjectFormData = ({
  shares,
  representatives,
  ...rest
}: ShareholderCompany): GroupSubjectFormData => {
  const representativesArr = representatives.map(({ email }) => ({ email }));
  let formattedShares;
  let isVoting = false;

  if (shares && shares.length) {
    isVoting = true;
    formattedShares = transformSharesArrToObject(shares);
  }

  return { ...rest, representatives: representativesArr, shares: formattedShares, isVoting };
};

export const isVotersInShareholding = (
  shareholdingGroup: ShareholdingGroup[],
  participants: ParticipantsGroup[],
) => {
  let isVoters = false;

  shareholdingGroup.every((group) => {
    const isGroupChecked = participants.find((participant) => participant.id === group.id);
    if (!isGroupChecked) {
      return true;
    }

    if (group.people.length + group.companies.length > 0) {
      isVoters = true;
      return false;
    }

    return true;
  });

  return isVoters;
};
