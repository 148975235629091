import React, { useMemo } from 'react';
import { DesktopSideColumnContainer } from 'components/containers';
import { AdminActionButtons } from 'components/meeting';
import { CONFIG } from 'config';
import { useWindowResize } from 'hooks';

import ShareholdersList from './ShareholdersList';

const DesktopShareholderColumn: React.FC = () => {
  const [width] = useWindowResize();
  const isDesktop = useMemo(() => width >= CONFIG.BREAKPOINTS.xl, [width]);

  if (!isDesktop) return null;

  return (
    <DesktopSideColumnContainer>
      <AdminActionButtons variant="desktop" />
      <ShareholdersList />
    </DesktopSideColumnContainer>
  );
};

export default DesktopShareholderColumn;
