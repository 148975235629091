import React from 'react';
import { Form } from 'components/forms';
import { Main, Flex } from 'components/layout';
import { ProtocolSchemaFileName } from 'app-types';
import { ButtonsContainer } from 'components/containers';
import { jointStockCompanyProtocolValidationSchema } from 'config';
import { PageHeader, HorizontalLine, Button } from 'components/common';
import { AgendaContentList, ProtocolFormData, ProtocolType } from 'types';

import Agenda from '../Agenda';
import Chairman from '../Chairman';
import Preview from '../Preview';
import Recorder from '../Recorder';
import Conclusions from '../Conclusions';

interface Props {
  agendaContentList: AgendaContentList[];
  onSubmit: (data: ProtocolFormData) => Promise<void>;
}

const JointStockCompanyProtocolForm: React.FC<Props> = ({ agendaContentList, onSubmit }) => (
  <Main size="medium">
    <Flex gap={4}>
      <PageHeader text="protocol.title.joint_stock" uppercase />
      <Flex gap={10}>
        <Preview fileName={ProtocolSchemaFileName.JointStock} />
        <Form
          submitHandler={onSubmit}
          defaultValues={{
            agendaContentList,
          }}
          validationSchema={jointStockCompanyProtocolValidationSchema}
        >
          <Flex direction="col" gap={6}>
            <Agenda protocolType={ProtocolType.SIMPLE_JOINT_STOCK_COMPANY} />
            <HorizontalLine />
            <Chairman />
            <HorizontalLine />
            <Recorder />
            <HorizontalLine />
            <Conclusions />
            <ButtonsContainer>
              <Button text="protocol.generate_protocol" variant="secondary" size="normal" />
            </ButtonsContainer>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  </Main>
);

export default JointStockCompanyProtocolForm;
