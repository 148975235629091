import React from 'react';
import clsx from 'clsx';

import styles from './Flex.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  gap?: number;
  gapCol?: number;
  grow?: number;
  shrink?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  justify?: 'start' | 'center' | 'end' | 'space-between';
  align?: 'start' | 'center' | 'end' | 'stretch';
  direction?: 'row' | 'col';
  onClick?: () => void;
  role?: 'radio' | 'button';
}

const Flex: React.FC<Props> = ({
  children,
  className,
  gap,
  gapCol,
  grow,
  shrink,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  justify = 'start',
  align = 'stretch',
  direction = 'row',
  role,
  onClick,
}) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div
    role={role}
    onClick={onClick}
    className={clsx(styles.flex, className, {
      [styles?.[`gap-${gap}`]]: gap,
      [styles?.[`gap-col-${gapCol}`]]: gapCol,
      [styles?.[`grow-${grow}`]]: grow,
      [styles?.[`shrink-${shrink}`]]: shrink,
      [styles?.[`xs-${xs}`]]: xs,
      [styles?.[`sm-${sm}`]]: sm,
      [styles?.[`md-${md}`]]: md,
      [styles?.[`lg-${lg}`]]: lg,
      [styles?.[`xl-${xl}`]]: xl,
      [styles?.[`xxl-${xxl}`]]: xxl,
      [styles?.[`justify-${justify}`]]: justify,
      [styles?.[`align-${align}`]]: align,
      [styles?.[`direction-${direction}`]]: direction,
    })}
  >
    {children}
  </div>
);

export default Flex;
