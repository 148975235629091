import React from 'react';
import { Majority } from 'app-types';
import { useFormContext, useWatch } from 'react-hook-form';

import { Input } from 'components/forms';

const ThresholdInput: React.FC = () => {
  const { control } = useFormContext();

  const typeValue = useWatch({
    control,
    name: 'majority',
  });

  const isThreshold = typeValue === Majority.QUALIFIED;

  if (isThreshold)
    return <Input name="threshold" size="small" placeholder="placeholders.fractional" />;

  return null;
};

export default ThresholdInput;
