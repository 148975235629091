import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ProtocolFormData } from 'types';
import { Flex, Card } from 'components/layout';
import { Input, TextArea } from 'components/forms';
import { ButtonsContainer } from 'components/containers';
import { PageSubheader, Button, Text, IconButton } from 'components/common';

const Disscussion = () => {
  const { control, getValues, setValue } = useFormContext<ProtocolFormData>();
  const { fields, append, remove } = useFieldArray({ control, name: 'discussions' });

  const addDisccussion = () => {
    const tempTitle = getValues('tempTitle');
    const tempDescription = getValues('tempDescription');

    append({ title: String(tempTitle), description: String(tempDescription) });
    setValue('tempTitle', '');
    setValue('tempDescription', '');
  };

  return (
    <Flex align="start" justify="space-between">
      <Flex xs={7}>
        <PageSubheader text="protocol.discussion" />
      </Flex>
      <Flex xs={12} gap={2} justify="start" direction="col">
        <Input size="protocol" name="tempTitle" label="protocol.discussion_title" />
        <TextArea
          size="responsive"
          name="tempDescription"
          label="protocol.contents"
          cols={60}
          rows={5}
        />
        <ButtonsContainer>
          <Button
            size="normal"
            text="protocol.add_discussion"
            variant="tertiary"
            type="button"
            onClick={() => addDisccussion()}
          />
        </ButtonsContainer>
        {fields.map((item, i) => (
          <Card direction="col" gap={2} key={item.id}>
            <Flex align="center" justify="space-between">
              <Text text={item.title} weight="bold" shouldTranslate={false} />
              <IconButton iconName="trash-can" onClick={() => remove(i)} />
            </Flex>
            <Text text={item.description} />
          </Card>
        ))}
      </Flex>
    </Flex>
  );
};

export default Disscussion;
