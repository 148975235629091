import React, { useState, useEffect } from 'react';
import { AppOffline, OnlineContent, OfflineContent, UpdatePopup, Spinner } from 'components/common';
import { BaseRoutes } from 'components/routes';
import { NotificationContainer } from 'components/notification';
import { CONFIG } from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { i18n } from 'services';
import { changeAppStatus, refreshToken } from 'slices';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { isLoading: isCallApiLoading } = useAppSelector(({ dashboard }) => dashboard);
  const user = useAppSelector((state) => state.user);

  const checkI18nLoaded = () => {
    if (i18n.loaded) {
      setIsLoading(false);
    } else {
      setTimeout(checkI18nLoaded, 100);
    }
  };

  const handleOnline = () => dispatch(changeAppStatus(true));
  const handleOffline = () => dispatch(changeAppStatus(false));

  useEffect(() => {
    (async function loadTranslations() {
      await i18n.load(CONFIG.DEFAULT_LANG);
      checkI18nLoaded();
    })();
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined;

    if (user.accessExpireTime) {
      intervalId = setInterval(async () => {
        dispatch(refreshToken(1));
      }, CONFIG.REFRESH_TOKEN_INTERVAL);
    }
    return () => clearInterval(intervalId);

    // eslint-disable-next-line
  }, []);

  if (isLoading) return <Spinner overlay />;

  return (
    <>
      <OnlineContent>
        {isCallApiLoading && <Spinner overlay halfTransparent />}
        <UpdatePopup />
        <NotificationContainer />
        <BaseRoutes />
      </OnlineContent>
      <OfflineContent>
        <AppOffline />
      </OfflineContent>
    </>
  );
};

export default App;
