export const omit = <T extends object, K extends keyof T>(obj: T, key: K | K[]): Omit<T, K> => {
  if (Array.isArray(key)) {
    const result = { ...obj };
    key.forEach((v) => {
      delete result[v];
    });
    return result;
  }

  // eslint-disable-next-line
  const { [key]: deletedKey, ...result } = obj;
  return result;
};
