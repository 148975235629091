import React, { useMemo } from 'react';
import { VotingResult } from 'app-types';
import { Message } from 'components/common';
import { VotingResultsVariant } from 'types';

interface Props {
  status: VotingResult;
  variant: VotingResultsVariant;
}

interface VariantData {
  type: 'success' | 'error' | 'loading';
  text: string;
}

const getVariantData = (
  status: VotingResult | null,
  variant: VotingResultsVariant,
): VariantData => {
  switch (status) {
    case VotingResult.ACCEPTED:
      return { type: 'success', text: `pages.meeting.${variant}_status.accepted` };
    case VotingResult.REJECTED:
      return { type: 'error', text: `pages.meeting.${variant}_status.rejected` };
    case VotingResult.INTERRUPTED:
      return { type: 'error', text: `pages.meeting.${variant}_status.quorum_not_reached` };
    default:
      return { type: 'loading', text: `pages.meeting.${variant}_status.loading` };
  }
};

const VotingStatus: React.FC<Props> = ({ status, variant }) => {
  const { type, text } = useMemo(() => getVariantData(status, variant), [status, variant]);

  return <Message type={type} text={text} />;
};

export default VotingStatus;
