import React from 'react';
import * as Yup from 'yup';
import { VotingType, Majority } from 'app-types';

import { Flex } from 'components/layout';
import { AddEditResolutionFormData } from 'types';
import { Button, Text } from 'components/common';
import { Form, Input, RadioGroup, TimeInput, FileInput, TextArea } from 'components/forms';
import { ModalActionButtonsContainer } from 'components/containers';

import ThresholdInput from './Threshold';
import styles from './ResolutionForm.module.scss';

const votingTypes = [
  { label: 'resolution.voting_type.secret', value: VotingType.SECRET },
  { label: 'resolution.voting_type.open', value: VotingType.OPEN },
];

const majorityTypes = [
  { label: 'resolution.majority.simple', value: Majority.SIMPLE },
  { label: 'resolution.majority.absolute', value: Majority.ABSOLUTE },
  { label: 'resolution.majority.qualified', value: Majority.QUALIFIED },
];

type Variant = 'add-resolution' | 'edit-resolution';

interface Props {
  onSubmit: (formData: AddEditResolutionFormData) => void;
  onCancel: () => void;
  validationSchema: Yup.AnyObjectSchema;
  variant: Variant;
  defaultValues?: AddEditResolutionFormData;
  isVotingDurationInputDisabled?: boolean;
}

const getSubmitText = (formVariant: Variant) => {
  switch (formVariant) {
    case 'add-resolution':
      return 'modals.add_resolution.title';
    case 'edit-resolution':
      return 'application.save_changes';
    default:
      return '';
  }
};

const ResolutionForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  defaultValues,
  validationSchema,
  variant,
  isVotingDurationInputDisabled,
}) => (
  <Form submitHandler={onSubmit} defaultValues={defaultValues} validationSchema={validationSchema}>
    <Flex direction="col" gap={4}>
      <Input name="title" label="resolution.title" size="responsive" required />
      <TextArea name="description" label="resolution.description" />
      <Flex>
        <FileInput name="pdf" label="resolution.send_file" />
      </Flex>
      <Text text="resolution.voting_type.title" size={4} weight="bold" />
      <RadioGroup name="type" items={votingTypes} direction="row" gap={4} />
      <Text text="resolution.duration.time_title" size={4} weight="bold" />
      <TimeInput
        name="duration"
        label="resolution.duration.time"
        disabled={isVotingDurationInputDisabled}
      />
      <Text text="resolution.quorum" size={4} weight="bold" />
      <Flex align="end" gap={1}>
        <Input
          minNumber={0}
          name="quorum"
          type="number"
          size="small"
          placeholder="placeholders.number"
          label="resolution.quorum"
          errorNowrap
        />
        <Text className={styles.quorum} text="%" shouldTranslate={false} size={4} />
      </Flex>
      <Text text="resolution.majority.title" size={4} weight="bold" />
      <RadioGroup name="majority" items={majorityTypes} direction="row" gap={5} />
      <ThresholdInput />
      <ModalActionButtonsContainer>
        <Button text={getSubmitText(variant)} variant="secondary" />
        <Button text="application.cancel" type="button" onClick={onCancel} variant="tertiary" />
      </ModalActionButtonsContainer>
    </Flex>
  </Form>
);

export default ResolutionForm;
