import React from 'react';
import { PropsWithChildren } from 'types';
import { Main } from 'components/layout';

import styles from './HelpdeskContainer.module.scss';

const HelpdeskContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Main className={styles.container} size="medium">
    {children}
  </Main>
);

export default HelpdeskContainer;
