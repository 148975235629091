import React from 'react';
import { Button, Text } from 'components/common';
import { DeleteModalContentContainer, ModalActionButtonsContainer } from 'components/containers';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { EndVotingData } from 'types';
import { fetchMeetingData } from 'slices';

const EndVoting: React.FC = () => {
  const {
    hideModal,
    data: { resolutionTitle, meetingId },
  } = useModal<undefined, EndVotingData>();
  const api = useApi();
  const dispatch = useAppDispatch();

  const onAccept = async () =>
    api.post(`/voting/${meetingId}/end`, undefined, () => {
      dispatch(fetchMeetingData({ meetingId }));
      hideModal();
    });

  return (
    <DeleteModalContentContainer>
      <Text
        text="modals.end_voting.title"
        translationArgs={{ resolutionTitle }}
        size={4}
        weight="bold"
      />
      <ModalActionButtonsContainer>
        <Button text="application.yes" variant="secondary" onClick={onAccept} />
        <Button text="application.no" variant="tertiary" onClick={hideModal} />
      </ModalActionButtonsContainer>
    </DeleteModalContentContainer>
  );
};

export default EndVoting;
