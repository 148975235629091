import { useCallback, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { callApi } from 'services';
import { ManagementTableApiData, ManagementTableApiResponse, ManagementTableParams } from 'types';
import { getUrlWithQueryParams } from 'utils';

import { getManagementTableItemsData } from './utils';

interface ManagementData {
  data?: ManagementTableApiData;
  getManagementTableData: (params: ManagementTableParams) => void;
}

export const useManagementData = (): ManagementData => {
  const [data, setData] = useState<ManagementTableApiData>();
  const dispatch = useAppDispatch();

  const getManagementTableData = useCallback(
    async (params: ManagementTableParams) => {
      await callApi<ManagementTableApiResponse, undefined>(
        dispatch,
        'GET',
        getUrlWithQueryParams('/panel/companies', params),
        undefined,
        ({ items: rawItems, countTotal }) => {
          const items = getManagementTableItemsData(rawItems);
          setData({ items, countTotal });
        },
      );
    },
    [dispatch],
  );

  return { data, getManagementTableData };
};
