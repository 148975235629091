import { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';
import { MeetingResultsRes } from 'app-types';
import { useApi } from 'hooks';
import { MeetingParams } from 'types';

interface MeetingResultsData {
  meetingResultsData: MeetingResultsRes | undefined;
  getMeetingResultsData: () => Promise<void>;
}

export const useMeetingResultsData = (): MeetingResultsData => {
  const api = useApi();
  const { meetingId } = useParams<MeetingParams>();
  const [data, setData] = useState<MeetingResultsRes>();

  const getMeetingResultsData = useCallback(async () => {
    await api.get<MeetingResultsRes>(`/meeting/${meetingId}/results`, (responseData) => {
      setData(responseData);
    });
  }, [api, meetingId]);

  useEffect(() => {
    (async () => {
      await getMeetingResultsData();
    })();
  }, [getMeetingResultsData]);

  return useMemo(
    () => ({ meetingResultsData: data, getMeetingResultsData }),
    [data, getMeetingResultsData],
  );
};
