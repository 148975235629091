import React, { useId } from 'react';
import { ApiErrorCode } from 'app-types';
import { Icon, Text } from 'components/common';
import { useAppDispatch } from 'hooks';
import { errorNotification } from 'slices';

import styles from './CSVFileInput.module.scss';

interface Props {
  text: string;
  name: string;
  onSelect: (file: File) => void;
}

const CSVFileInput: React.FC<Props> = ({ text, name, onSelect }) => {
  const dispatch = useAppDispatch();
  const id = useId();

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return;
    const fileName = e.target.files[0].name;
    const lastDot = fileName.lastIndexOf('.');
    const ext = fileName.slice(lastDot + 1);

    if (ext !== 'csv') {
      dispatch(errorNotification(ApiErrorCode.InvalidFileType));
      return;
    }

    onSelect(e.target.files[0]);
  };

  return (
    <div className={styles.container}>
      <input id={`${id}-${name}`} className={styles.input} type="file" onChange={onFileChange} />
      <label htmlFor={`${id}-${name}`} className={styles.label}>
        <Icon name="import" />
        <Text className={styles.text} text={text} weight="bold" color="secondary" />
      </label>
    </div>
  );
};

export default CSVFileInput;
