import React from 'react';
import { Navigate } from 'react-router';
import { AllRoutes } from 'config';
import { useAppSelector, useRedirectedFrom } from 'hooks';

interface Props {
  children: React.ReactNode;
  redirectTo?: string;
}

const RequireAuth: React.FC<Props> = ({ children, redirectTo = AllRoutes.Home }) => {
  const { id } = useAppSelector(({ user }) => user);
  const isAuthenticated = id !== undefined;

  useRedirectedFrom(!isAuthenticated);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{isAuthenticated ? children : <Navigate to={redirectTo} replace />}</>;
};

export default RequireAuth;
