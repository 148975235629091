import React, { useMemo } from 'react';
import { CastVoterVotesReq } from 'app-types';
import { Text } from 'components/common';
import { SplitVotesForm } from 'components/forms';
import { Flex } from 'components/layout';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { SplitVotesData, SplitVotesFormData } from 'types';
import { fetchMeetingData, successNotification } from 'slices';
import { getVotingShares, validateAndShapeSplitVotes } from 'utils';

const SplitVotes: React.FC = () => {
  const { hideModal, data } = useModal<undefined, SplitVotesData>();
  const dispatch = useAppDispatch();
  const api = useApi();

  const shares = useMemo(
    () => getVotingShares(data.shareholder.shares!),
    [data.shareholder.shares],
  );

  const onSubmit = async (formValues: SplitVotesFormData) => {
    const reqData = validateAndShapeSplitVotes(formValues, shares);
    const isResolutionVoting = 'votingId' in data;

    if (isResolutionVoting) {
      await api.post<undefined, CastVoterVotesReq>(
        `/voting/${data.votingId}/shareholder/${data.shareholder.id}/votes/${data.meetingId}`,
        reqData,
        async () => {
          await dispatch(fetchMeetingData({ meetingId: data.meetingId }));
          await dispatch(successNotification('notifications.vote_sent'));
          hideModal();
        },
      );
      return;
    }

    await api.post<undefined, CastVoterVotesReq>(
      `/meeting/${data.meetingId}/resumption/${data.resumptionId}/shareholder/${data.shareholder.id}/votes`,
      reqData,
      async () => {
        await dispatch(fetchMeetingData({ meetingId: data.meetingId }));
        await dispatch(successNotification('notifications.vote_sent'));
        hideModal();
      },
    );
  };

  return (
    <Flex direction="col" gap={4}>
      <Text text="modals.split_votes.title" size={4} weight="bold" uppercase />
      <SplitVotesForm onSubmit={onSubmit} onCancel={hideModal} shares={shares} />
    </Flex>
  );
};

export default SplitVotes;
