import React from 'react';
import { getFullName } from 'utils';
import { Text } from 'components/common';
import { Flex } from 'components/layout';
import { Checkbox } from 'components/forms';
import { ExcludedGroupsVotersCompanies, ExcludedGroupsVotersPeople } from 'types';

interface Props {
  data: ExcludedGroupsVotersCompanies | ExcludedGroupsVotersPeople;
}

const CompanyVoter: React.FC<Props> = ({ data }) => {
  if ('fullCompany' in data) {
    const { name, representatives } = data.fullCompany;

    const representative = representatives.find(
      (representativeItem) => representativeItem?.id === data.voter.id,
    );

    if (representative) {
      return (
        <Flex align="center" gap={1}>
          <Checkbox name={`excludedCompaniesVoters.${data.id}`} color="sixth" />
          <Text text={getFullName(representative)} shouldTranslate={false} />
          -
          <Text text={name} shouldTranslate={false} />
        </Flex>
      );
    }
  }

  return null;
};

export default CompanyVoter;
