import React from 'react';
import clsx from 'clsx';
import { __ } from 'services';

import styles from './Label.module.scss';

interface Props {
  label: string;
  translationArgs?: Record<string, string>;
  htmlFor?: string;
  required?: boolean;
  disabled?: boolean;
}

const Label: React.FC<Props> = ({ label, translationArgs, htmlFor, required, disabled }) => (
  <label htmlFor={htmlFor} className={clsx(styles.label, { [styles.disabled]: disabled })}>
    {__(label, translationArgs)} {required && <span className={styles.required}>*</span>}
  </label>
);

export default Label;
