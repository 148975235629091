import { BaseCompany, InitialCapital } from 'app-types';
import { PreparedCompanyData } from 'types';
import { numberWithSpaces } from './number-with-spaces';

type Company = BaseCompany & { initialCapital: InitialCapital };

export const prepareCompanyData = (data: Company): PreparedCompanyData => {
  const subjectData = [
    { text: 'company.name', value: data.name },
    { text: 'company.type', value: data.type },
  ];

  const identifiers = [
    { text: 'company.krs_number', value: data.krs },
    { text: 'company.nip_number', value: data.nip },
    { text: 'company.regon_number', value: data.regon },
  ];

  const hqAddress = [
    { text: 'company.country', value: data.country },
    { text: 'company.voivodeship', value: data.voivodeship },
    { text: 'company.district', value: data.district },
    { text: 'company.commune', value: data.commune },
    { text: 'company.zip_code', value: data.zipCode },
    { text: 'company.city', value: data.city },
    { text: 'company.street', value: data.streetName },
    { text: 'company.building_number', value: data.streetNumber },
  ];

  if (data?.apartmentNumber) {
    hqAddress.push({ text: 'company.apartment_number', value: data.apartmentNumber });
  }

  const capital = [
    {
      text: 'company.capital_value',
      value: numberWithSpaces(data.initialCapital.value),
    },
    { text: 'company.currency', value: data.initialCapital.currency },
  ];

  return { subjectData, identifiers, hqAddress, capital };
};
