import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/common';

import styles from './PaginationButton.module.scss';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconName?: 'arrow-left' | 'arrow-right';
  isActive?: boolean;
}

const PaginationButton: React.FC<Props> = ({
  iconName,
  isActive,
  className,
  children,
  ...buttonProps
}) => (
  <button
    {...buttonProps}
    className={clsx(className, styles.button, { [styles.active]: isActive })}
  >
    {iconName && <Icon name={iconName} />}
    {children}
  </button>
);

export default PaginationButton;
