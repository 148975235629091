import React from 'react';
import { createRoot } from 'react-dom/client';
import { WorkBoxProvider } from 'react-workbox';
import { Provider } from 'react-redux';
import { store } from 'config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import './index.scss';

Sentry.init({
  dsn: 'https://aabee282ff45426e8c28f035c25309d3@sentry.etd24.pl/92',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

const rootElement = createRoot(document.getElementById('root')!);

rootElement.render(
  <Provider store={store}>
    <WorkBoxProvider>
      <App />
    </WorkBoxProvider>
  </Provider>,
);
