import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const useScrollLock = (elementId: string) => {
  useEffect(() => {
    const element = document.getElementById(elementId)!;
    disableBodyScroll(element);

    return () => {
      enableBodyScroll(element);
    };
  }, [elementId]);
};
