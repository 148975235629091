import React from 'react';
import { Flex } from 'components/layout';
import { PropsWithChildren } from 'types';

import styles from './ArchiveMeetingDetailsProtocolsButtonsContainer.module.scss';

const ArchiveMeetingDetailsProtocolsButtonsContainer: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Flex className={styles.container} gap={2}>
    {children}
  </Flex>
);
export default ArchiveMeetingDetailsProtocolsButtonsContainer;
