export const getUrlWithQueryParams = (url: string, params: object): string => {
  const trimmedParams = Object.entries(params).reduce<object>(
    (acc, [key, value]) => (value !== undefined && value !== null ? { ...acc, [key]: value } : acc),
    {},
  );

  return Object.entries(trimmedParams).reduce<string>(
    (acc, [key, value], i) => (i === 0 ? `${acc}?${key}=${value}` : `${acc}&${key}=${value}`),
    url,
  );
};
