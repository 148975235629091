import React, { useMemo } from 'react';
import { DesktopSideColumnContainer } from 'components/containers';
import { CONFIG } from 'config';
import { useWindowResize } from 'hooks';

import DesktopVotingMenu from './DesktopVotingMenu';

const DesktopVotingColumn: React.FC = () => {
  const [width] = useWindowResize();
  const isDesktop = useMemo(() => width >= CONFIG.BREAKPOINTS.xl, [width]);

  if (!isDesktop) return null;

  return (
    <DesktopSideColumnContainer>
      <DesktopVotingMenu />
    </DesktopSideColumnContainer>
  );
};

export default DesktopVotingColumn;
