import React from 'react';

import { NotificationType } from 'types';
import { Icon } from 'components/common';

interface AlertIconProps {
  type: NotificationType;
}

const AlertIcon: React.FC<AlertIconProps> = ({ type }) => {
  switch (type) {
    case NotificationType.Success:
      return <Icon name="success" />;
    case NotificationType.Info:
      return <Icon name="information" />;
    case NotificationType.Error:
      return <Icon name="error" />;
    default:
      return null;
  }
};

export default AlertIcon;
