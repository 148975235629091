import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/layout';

import styles from './MobileBottomBarContainer.module.scss';

interface Props {
  children: React.ReactNode;
  id?: string;
}

const MobileBottomBarContainer: React.FC<Props> = ({ children, id }) => (
  <aside className={clsx(styles.container, { [styles?.['padding-top']]: id })} id={id}>
    <Flex className={styles.rel} direction="col" align="center" gap={2}>
      {children}
    </Flex>
  </aside>
);

export default MobileBottomBarContainer;
