/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useAppDispatch, useAppSelector, useEscapeClick } from 'hooks';
import { hideModal } from 'slices';

import ModalSelector from './ModalSelector';
import styles from './Modal.module.scss';

const Modal: React.FC = () => {
  const ref = useRef<null | HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { isPrepared, isVisible, type } = useAppSelector(({ modal }) => modal);
  useEscapeClick(() => dispatch(hideModal()));

  if (!isPrepared || !isVisible) {
    return null;
  }

  return createPortal(
    <div
      className={styles.overlay}
      onClick={() => dispatch(hideModal())}
      role="button"
      tabIndex={0}
      aria-label="modal-selector-container"
    >
      <div
        ref={ref}
        id="modal-content"
        className={styles.content}
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
        aria-label="modal-selector"
      >
        <ModalSelector content={type} />
      </div>
    </div>,
    document.body!,
  );
};

export default Modal;
