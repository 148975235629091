import React from 'react';
import { Button } from 'components/common';
import { ModalActionButtonsContainer } from 'components/containers';
import { Form, Input } from 'components/forms';
import { Flex } from 'components/layout';
import { groupSubjectValidationSchema } from 'config';
import { GroupMemberModalVariant, GroupSubjectFormData } from 'types';
import { CompanyType } from 'app-types';

import SharesGroup from '../SharesGroup';
import RepresentativesGroup from '../RepresentativesGroup';

interface Props {
  variant: GroupMemberModalVariant;
  onSubmit: (formData: GroupSubjectFormData) => void;
  onCancel: () => void;
  companyType: CompanyType;
  defaultValues?: GroupSubjectFormData;
}

const SubjectForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  variant,
  companyType,
  defaultValues,
}) => (
  <Form
    defaultValues={defaultValues}
    submitHandler={onSubmit}
    validationSchema={groupSubjectValidationSchema}
  >
    <Flex direction="col" gap={4}>
      <Input
        name="krs"
        label="company.krs_number"
        size="responsive"
        required
        disabled={variant === 'edit_subject'}
      />
      <SharesGroup variant={variant} companyType={companyType} />
      <RepresentativesGroup variant={variant} />
      <ModalActionButtonsContainer>
        <Button text={`modals.${variant}.button`} variant="secondary" />
        <Button text="application.cancel" type="button" onClick={onCancel} variant="tertiary" />
      </ModalActionButtonsContainer>
    </Flex>
  </Form>
);

export default SubjectForm;
