import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { DropdownItem } from 'types';

import DropdownInput from './DropdownInput';

interface Props {
  name: string;
  options: DropdownItem[];
  size?: 'small' | 'normal' | 'big' | 'responsive' | 'protocol';
  label?: string;
  shouldTranslateValues?: boolean;
  required?: boolean;
}

const Dropdown: React.FC<Props> = ({
  name,
  options,
  size,
  label,
  shouldTranslateValues,
  required,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <DropdownInput
          label={label}
          size={size}
          value={value}
          onChange={onChange}
          errors={errors}
          options={options}
          name={name}
          shouldTranslateValues={shouldTranslateValues}
          required={required}
        />
      )}
    />
  );
};

export default Dropdown;
