import React, { useMemo, useState } from 'react';
import { CONFIG } from 'config';
import { useWindowResize } from 'hooks';
import { MobileBottomBarState } from 'types';

import MobileVotingMenu from './MobileVotingMenu';
import MobileSharesMenu from './MobileSharesMenu';

const MobileBottomBar: React.FC = () => {
  const [width] = useWindowResize();
  const isMobile = useMemo(() => width < CONFIG.BREAKPOINTS.xl, [width]);

  const [bottomBarState, setBottomBarState] = useState(MobileBottomBarState.VotingMenu);

  if (!isMobile) return null;

  switch (bottomBarState) {
    case MobileBottomBarState.ShareholdingMenu:
      return <MobileSharesMenu goBack={() => setBottomBarState(MobileBottomBarState.VotingMenu)} />;
    default:
      return (
        <MobileVotingMenu
          changeMenu={() => setBottomBarState(MobileBottomBarState.ShareholdingMenu)}
        />
      );
  }
};

export default MobileBottomBar;
