import { Majority, ResolutionEntity } from '../resolution';
import { ObjectionEntity } from '../objection';
import { VoteEntity } from '../vote';
import { UserVoterEntity } from '../user-voter';
import { CompanyVoterEntity } from '../company-voter';
import { ChangeReportEntity } from '../change-report';

export interface BaseVoting {
  startDate?: Date | string;
  endDate?: Date | string;
  type: VotingType;
  duration: string;
  majority: Majority;
  threshold?: string | null;
}

export interface VotingEntity extends BaseVoting {
  id: string;
  aggregatorId?: string;
  status: VotingStatus;
  result: VotingResult;
  resolution?: ResolutionEntity;
  votes?: VoteEntity[];
  objections?: ObjectionEntity[];
  changesReports?: ChangeReportEntity[];
  excludedUsersVoters?: UserVoterEntity[];
  excludedCompaniesVoters?: CompanyVoterEntity[];
}

export enum VotingStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  DURING_BREAK = 'DURING_BREAK',
  DURING_RESUMPTION = 'DURING_RESUMPTION',
  DURING_RESUMPTION_BREAK = 'DURING_RESUMPTION_BREAK',
  INTERRUPTED = 'INTERRUPTED',
  ENDED = 'ENDED',
}

export enum VotingResult {
  STARTED = 'STARTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  INTERRUPTED = 'INTERRUPTED',
}

export enum VotingType {
  OPEN = 'OPEN',
  SECRET = 'SECRET',
}
