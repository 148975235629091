import {
  ApiErrorCode,
  ApiErrorMessage,
  ApiErrorResponse,
  ApiOkMessage,
  ApiResult,
  MeetingCurrentState,
  MeetingStateDuringResolutionVoting,
  MeetingStateDuringResults,
  MeetingStateDuringResumptionVoting,
  MeetingStateDuringVoting,
  NativeNestError,
  OpenVotingResultRes,
  SecretVotingResultRes,
} from 'app-types';
import { BlockchainErrorCode, BlockchainFailureMessage, BlockchainSuccessMessage } from 'types';

export const isVotingState = (
  state: MeetingCurrentState | undefined,
): state is MeetingStateDuringVoting =>
  (state as MeetingStateDuringVoting)?.shareholders !== undefined;

export const areVotingStateEqual = (
  firstState: MeetingCurrentState | undefined,
  secondState: MeetingCurrentState | undefined,
): boolean => isVotingState(firstState) === isVotingState(secondState);

export const isResolutionVotingState = (
  state: MeetingCurrentState | undefined,
): state is MeetingStateDuringResolutionVoting =>
  (state as MeetingStateDuringResolutionVoting)?.ordinalNumber !== undefined &&
  state?.isResumptionTime === false;

export const isResumptionVotingState = (
  state: MeetingCurrentState | undefined,
): state is MeetingStateDuringResumptionVoting =>
  (state as MeetingStateDuringResumptionVoting)?.resumption !== undefined &&
  state?.isResumptionTime === true;

export const isVotingResultState = (
  state: MeetingCurrentState | undefined,
): state is MeetingStateDuringResults => (state as MeetingStateDuringResults)?.result !== undefined;

export const isResolutionVotingResultState = (
  state: MeetingCurrentState | undefined,
): state is SecretVotingResultRes | OpenVotingResultRes =>
  (state as SecretVotingResultRes | OpenVotingResultRes)?.inFavour !== undefined &&
  state?.isResumptionTime === false;

export const isOpenVotingState = (
  state: MeetingCurrentState | undefined,
): state is OpenVotingResultRes =>
  (state as MeetingStateDuringResults)?.inFavour !== null &&
  (state as MeetingStateDuringResults)?.inFavour !== undefined &&
  typeof (state as MeetingStateDuringResults).inFavour !== 'number';

export const isResumptionVotingResultState = (
  state: MeetingCurrentState | undefined,
): state is MeetingStateDuringResults =>
  (state as MeetingStateDuringResults)?.resumptionId !== undefined &&
  state?.isResumptionTime === true;
export const isBlockchainErrorCode = (
  error: ApiErrorCode | BlockchainErrorCode,
): error is BlockchainErrorCode => error in BlockchainErrorCode;

export const isBlockchainSuccessMessage = <Data>(
  data: BlockchainSuccessMessage<Data> | BlockchainFailureMessage,
): data is BlockchainSuccessMessage<Data> =>
  (data as BlockchainSuccessMessage<Data>)?.result !== undefined;

export const isBlockchainFailureMessage = <Data>(
  data: BlockchainSuccessMessage<Data> | BlockchainFailureMessage,
): data is BlockchainFailureMessage => (data as BlockchainFailureMessage)?.error !== undefined;

export const isApiErrorResponse = <T>(
  data: ApiErrorResponse | ApiOkMessage<T>,
): data is ApiErrorResponse =>
  (data as NativeNestError)?.statusCode !== undefined ||
  (data as ApiErrorMessage)?.result === ApiResult.error;

export const isApiErrorMessage = (error: ApiErrorResponse): error is ApiErrorMessage =>
  (error as ApiErrorMessage)?.result === ApiResult.error;

export const isNativeNestError = (error: ApiErrorResponse): error is NativeNestError =>
  (error as NativeNestError)?.message !== undefined;

export const isApiOkMessage = <T>(
  data: ApiErrorResponse | ApiOkMessage<T>,
): data is ApiOkMessage<T> => (data as ApiOkMessage<T>)?.result === ApiResult.ok;
