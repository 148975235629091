import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import clsx from 'clsx';
import { CircleContainer, Icon } from 'components/common';
import { useAppDispatch, useAppSelector, useCountdown, useMeetingOnlineStatus } from 'hooks';
import { GetMeetingCurrentStateRes, VotingResult, MeetingStatus } from 'app-types';
import { fetchMeetingData } from 'slices';
import { MeetingParams } from 'types';
import { areVotingStateEqual } from 'utils';

import styles from './MobileCounter.module.scss';

interface Props {
  goalDate: string | undefined | Date;
  isPaused: boolean;
  isFadedOut: boolean;
}

const MobileCounter: React.FC<Props> = ({ goalDate, isPaused, isFadedOut }) => {
  const { sendOnlineStatus } = useMeetingOnlineStatus();
  const dispatch = useAppDispatch();
  const { meetingId } = useParams<MeetingParams>();
  const meeting = useAppSelector((state) => state.meeting);

  const onCountdownEnd = useCallback(async () => {
    for (let i = 0; i < 10; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const data = await dispatch(fetchMeetingData({ meetingId }));
      const payload = data.payload as GetMeetingCurrentStateRes;

      const isMeetingEnded = payload.data && payload.data.status === MeetingStatus.ENDED;
      const areStatesEqual = areVotingStateEqual(meeting.state, payload.state);
      const isResultInterrupted =
        payload.state &&
        'result' in payload.state &&
        payload.state.result === VotingResult.INTERRUPTED;

      if (isMeetingEnded || !areStatesEqual || (areStatesEqual && isResultInterrupted)) {
        break;
      }

      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
    await sendOnlineStatus(meetingId);
    // eslint-disable-next-line
  }, [dispatch, sendOnlineStatus, meetingId]);

  const { text } = useCountdown(onCountdownEnd, goalDate, isPaused);

  return (
    <CircleContainer
      className={clsx(styles.container, {
        [styles?.['faded-out']]: isFadedOut,
        [styles.paused]: isPaused,
      })}
    >
      {isPaused ? <Icon name="pause-fill" /> : text}
    </CircleContainer>
  );
};

export default MobileCounter;
