import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { ApiErrorCode } from 'app-types';
import {
  BlockchainErrorCode,
  ErrorNotification,
  NotificationsState,
  NotificationType,
  OtherNotification,
} from 'types';
import { isBlockchainErrorCode } from 'utils';

const initialState: NotificationsState = [];

const getApiError = (error: ApiErrorCode) => {
  switch (error) {
    case ApiErrorCode.OtherError:
      return 'errors.other_error';
    case ApiErrorCode.BadCredentials:
      return 'errors.bad_credentials';
    case ApiErrorCode.InsufficientPrivileges:
      return 'errors.insufficient_privileges';
    case ApiErrorCode.NoSuchUser:
      return 'errors.no_such_user';
    case ApiErrorCode.NoSuchRole:
      return 'errors.no_such_role';
    case ApiErrorCode.InvalidLanguage:
      return 'errors.invalid_language';
    case ApiErrorCode.InvalidToken:
      return 'errors.invalid_token';
    case ApiErrorCode.TokenOutdated:
      return 'errors.token_outdated';
    case ApiErrorCode.PasswordAlreadySet:
      return 'errors.password_already_set';
    case ApiErrorCode.InvalidPassword:
      return 'errors.invalid_password';
    case ApiErrorCode.CountryNotSupported:
      return 'errors.country_not_supported';
    case ApiErrorCode.CountryNotParsed:
      return 'errors.country_not_supported';
    case ApiErrorCode.SMSCouldNotBeSent:
      return 'errors.sms_could_not_be_sent';
    case ApiErrorCode.NotAllowedSortingCase:
      return 'errors.not_allowed_sorting_case';
    case ApiErrorCode.InvalidCurrentPage:
      return 'errors.invalid_current_page';
    case ApiErrorCode.ValidationError:
      return 'errors.validation_error';
    case ApiErrorCode.CompanyAlreadyExists:
      return 'errors.company_already_exists';
    case ApiErrorCode.UnsupportedCompanyType:
      return 'errors.unsupported_company_type';
    case ApiErrorCode.NoSuchCompany:
      return 'errors.no_such_company';
    case ApiErrorCode.UserAlreadyRegistered:
      return 'errors.user_already_registered';
    case ApiErrorCode.NoSuchVoting:
      return 'errors.no_such_voting';
    case ApiErrorCode.UserCannotVote:
      return 'errors.user_cannot_vote';
    case ApiErrorCode.NoSuchMeeting:
      return 'errors.no_such_meeting';
    case ApiErrorCode.InvalidFileType:
      return 'errors.invalid_file_type';
    case ApiErrorCode.VotingIsClosed:
      return 'errors.voting_is_closed';
    case ApiErrorCode.UnknownMajority:
      return 'errors.unknown_majority';
    case ApiErrorCode.ThresholdNotReceived:
      return 'errors.threshold_not_received';
    case ApiErrorCode.MultiplierNotAllowed:
      return 'errors.multiplier_not_allowed';
    case ApiErrorCode.SharesNotAllowedToVote:
      return 'errors.shares_not_allowed_to_vote';
    case ApiErrorCode.NotEnoughShares:
      return 'errors.not_enough_shares';
    case ApiErrorCode.QuorumIncludedPropertyNotDefined:
      return 'errors.quorum_included_property_not_defined';
    case ApiErrorCode.AuthorityNotIncluded:
      return 'errors.authority_not_included';
    case ApiErrorCode.UnrecognizedCompany:
      return 'errors.unrecognized_company';
    case ApiErrorCode.ShareholderAlreadyAdded:
      return 'errors.shareholder_already_added';
    case ApiErrorCode.NoSuchCompanyInKrsApi:
      return 'errors.no_such_company_in_krs_api';
    case ApiErrorCode.CompanyDoesntIncludeSelectedShareholdingGroup:
      return 'errors.company_doesnt_include_selected_shareholding_group';
    case ApiErrorCode.NotAllowedToGetDataAboutSelectedCompany:
      return 'errors.not_allowed_to_get_data_about_selected_company';
    case ApiErrorCode.RequestingUserIsNotCompanyOwner:
      return 'errors.requesting_user_is_not_company_owner';
    case ApiErrorCode.ShareholdingGroupAlreadyExists:
      return 'errors.shareholding_group_already_exists';
    case ApiErrorCode.ShareholderIsNotPartOfSelectedShareholdingGroup:
      return 'errors.shareholder_is_not_part_of_selected_shareholding_group';
    case ApiErrorCode.NoSuchAdmin:
      return 'errors.no_such_admin';
    case ApiErrorCode.NoSuchGroup:
      return 'errors.no_such_group';
    case ApiErrorCode.NotCompanyRepresentative:
      return 'errors.not_company_representative';
    case ApiErrorCode.UserCannotBeItsRepresentative:
      return 'errors.user_cannot_be_its_representative';
    case ApiErrorCode.OnlyOneCompanyVoterCanBeChosen:
      return 'errors.only_one_company_voter_can_be_chosen';
    case ApiErrorCode.SelectedUserVoterIsNotUserRepresentative:
      return 'errors.selected_user_voter_is_not_user_representative';
    case ApiErrorCode.ThresholdMustBeFraction:
      return 'errors.threshold_must_be_fraction';
    case ApiErrorCode.FractionMustBeLowerThanOne:
      return 'errors.fraction_must_be_lower_than_one';
    case ApiErrorCode.OrdinalNumberAlreadyTaken:
      return 'errors.ordinal_number_already_taken';
    case ApiErrorCode.OrdinalNumberCannotBeLowerThanOne:
      return 'errors.ordinal_number_cannot_be_lower_than_one';
    case ApiErrorCode.NotAllowedSequence:
      return 'errors.not_allowed_sequence';
    case ApiErrorCode.MeetingIsNotDraft:
      return 'errors.meeting_is_not_draft';
    case ApiErrorCode.ResolutionIsDuplicated:
      return 'errors.resolution_is_duplicated';
    case ApiErrorCode.NoSuchAgendaItem:
      return 'errors.no_such_agenda_item';
    case ApiErrorCode.ResolutionNotAssignedToSelectedAgendaItem:
      return 'errors.resolution_not_assigned_to_selected_agenda_item';
    case ApiErrorCode.NotAuthorized:
      return 'errors.not_authorized';
    case ApiErrorCode.UnsuccessfulInvoiceCreation:
      return 'errors.unsuccessful_invoice_creation';
    case ApiErrorCode.InvoiceEmailCouldNotBeSent:
      return 'errors.invoice_email_could_not_be_sent';
    case ApiErrorCode.UserNotAssignedToSelectedVoting:
      return 'errors.user_not_assigned_to_selected_voting';
    case ApiErrorCode.DuplicatedVotesTypeFound:
      return 'errors.duplicated_votes_type_found';
    case ApiErrorCode.MissingTimeSeparator:
      return 'errors.missing_time_separator';
    case ApiErrorCode.InvalidMinutesFormat:
      return 'errors.invalid_minutes_format';
    case ApiErrorCode.InvalidSecondsFormat:
      return 'errors.invalid_seconds_format';
    case ApiErrorCode.ReceivedValuesMustBeNumbers:
      return 'errors.received_values_must_be_numbers';
    case ApiErrorCode.InvalidTimeFormat:
      return 'errors.invalid_time_format';
    case ApiErrorCode.NoSuchResolution:
      return 'errors.no_such_resolution';
    case ApiErrorCode.NoSuchResumption:
      return 'errors.no_such_resumption';
    case ApiErrorCode.ResumptionNotAccepted:
      return 'errors.resumption_not_accepted';
    case ApiErrorCode.NoVotesCastedYet:
      return 'errors.no_votes_casted_yet';
    case ApiErrorCode.NewVotingAlreadySet:
      return 'errors.new_voting_already_set';
    case ApiErrorCode.VoterAlreadyVoted:
      return 'errors.voter_already_voted';
    case ApiErrorCode.VoterAlreadyCastedObjection:
      return 'errors.voter_already_casted_objection';
    case ApiErrorCode.CommentAlreadyPostedByVoter:
      return 'errors.comment_already_posted_by_voter';
    case ApiErrorCode.SubscriptionAllowsCreatingOnlyOneMeeting:
      return 'errors.subscription_allows_creating_only_one_meeting';
    case ApiErrorCode.TooManyVoters:
      return 'errors.too_many_voters';
    case ApiErrorCode.TooManyResolutions:
      return 'errors.too_many_resolutions';
    case ApiErrorCode.SubscriptionAlreadyAssigned:
      return 'errors.subscription_already_assigned';
    case ApiErrorCode.SubscriptionNotAssigned:
      return 'errors.subscription_not_assigned';
    case ApiErrorCode.NoSuchFile:
      return 'errors.no_such_file';
    case ApiErrorCode.ChangingCompanyNipNotAllowed:
      return 'errors.changing_company_nip_not_allowed';
    case ApiErrorCode.NoVotersGroupSelected:
      return 'errors.no_voters_group_selected';
    case ApiErrorCode.NotaryEmailAlreadyInUseInTheMeeting:
      return 'errors.notary_email_already_in_use_in_the_meeting';
    case ApiErrorCode.CompanyNotCreated:
      return 'errors.company_not_created';
    case ApiErrorCode.NoSuchSubscription:
      return 'errors.no_such_subscription';
    case ApiErrorCode.CancelingSubscriptionAllowedForMonthlyPaymentsOnly:
      return 'errors.canceling_subscription_allowed_for_monthly_payments_only';
    case ApiErrorCode.FileNameMustBeUnique:
      return 'errors.file_name_must_be_unique';
    case ApiErrorCode.MeetingAlreadyStarted:
      return 'errors.meeting_already_started';
    case ApiErrorCode.VotesCastedInForbiddenTimeRange:
      return 'errors.votes_casted_in_forbidden_time_range';
    case ApiErrorCode.TimeIsTooLong:
      return 'errors.time_is_too_long';
    case ApiErrorCode.NoSuchVoterObject:
      return 'errors.no_such_voter_object';
    case ApiErrorCode.VoterExcludedFromVoting:
      return 'errors.voter_excluded_from_voting';
    case ApiErrorCode.UnableToResumeMeetingThatIsNotPaused:
      return 'errors.unable_to_resume_meeting_that_is_not_paused';
    case ApiErrorCode.MeetingCanBePausedOnlyDuringVotingTime:
      return 'errors.meeting_can_be_paused_only_during_voting_time';
    case ApiErrorCode.NoActiveVotingFound:
      return 'errors.no_active_voting_found';
    case ApiErrorCode.VotingDoesntBelongToSelectedMeeting:
      return 'errors.voting_doesnt_belong_to_selected_meeting';
    case ApiErrorCode.VoterAlreadyReportedChange:
      return 'errors.voter_already_reported_change';
    case ApiErrorCode.InvalidVotingDuration:
      return 'errors.invalid_voting_duration';
    case ApiErrorCode.VotingDurationTimeHigherThanExpected:
      return 'errors.voting_duration_time_higher_than_expected';
    case ApiErrorCode.YouCannotUpdateSelectedAgendaItem:
      return 'errors.you_cannot_update_selected_agenda_item';
    case ApiErrorCode.MeetingMustBePaused:
      return 'errors.meeting_must_be_paused';
    case ApiErrorCode.ResumptionAlreadyCreated:
      return 'errors.resumption_already_created';
    case ApiErrorCode.ResumptionCanBeCreatedOnlyDuringBreakTime:
      return 'errors.resumption_can_be_created_only_during_break_time';
    case ApiErrorCode.MeetingIsInProgress:
      return 'errors.meeting_is_in_progress';
    case ApiErrorCode.UnknownSortByType:
      return 'errors.unknown_sort_by_type';
    case ApiErrorCode.UnknownSortDirType:
      return 'errors.unknown_sort_dir_type';
    case ApiErrorCode.QuorumNotReached:
      return 'errors.quorum_not_reached';
    case ApiErrorCode.CouldNotDownloadFile:
      return 'errors.could_not_download_file';
    case ApiErrorCode.ForbiddenResource:
      return 'errors.forbidden_resource';
    case ApiErrorCode.MaxNumberOfFilesExceeded:
      return 'errors.max_number_of_files_exceeded';
    case ApiErrorCode.AggregatorApiError:
    case ApiErrorCode.CyberIdApiError:
    case ApiErrorCode.KrsApiError:
    case ApiErrorCode.TaxxoApiError:
      return 'errors.cant_do_request_to_external_api';
    case ApiErrorCode.ExceptionDuringTransaction:
      return 'errors.exception_during_transaction';
    case ApiErrorCode.UserIsDeleted:
      return 'errors.user_is_deleted';
    case ApiErrorCode.UserIsDisabled:
      return 'errors.user_is_disabled';
    case ApiErrorCode.ExceededNumberOfShareholders:
      return 'errors.exceeded_number_of_shareholders';
    case ApiErrorCode.UserAlreadyActivated:
      return 'errors.user_already_activated';
    case ApiErrorCode.ActivationCodeExpired:
      return 'errors.activation_code_expired';
    case ApiErrorCode.InvalidActivationCode:
      return 'errors.invalid_activation_code';
    case ApiErrorCode.PhoneNumberAlreadyExists:
      return 'errors.phone_number_already_exists';
    default:
      return 'errors.error_occurred';
  }
};

const getBlockchainError = (error: BlockchainErrorCode): string => {
  const map: Record<BlockchainErrorCode, string> = {
    [BlockchainErrorCode.InternalError]: 'blockchain_errors.internal_error',
    [BlockchainErrorCode.InvalidParams]: 'blockchain_errors.invalid_params',
    [BlockchainErrorCode.InvalidRequest]: 'blockchain_errors.invalid_request',
    [BlockchainErrorCode.MethodNotFound]: 'blockchain_errors.method_not_found',
    [BlockchainErrorCode.ParseError]: 'blockchain_errors.parse_error',
    [BlockchainErrorCode.ErrorOccurred]: 'blockchain_errors.error_occurred',
    [BlockchainErrorCode.RunOutOfRetries]: 'blockchain_errors.run_out_of_retries',
  };

  return map[error] ?? 'blockchain_errors.error_occurred';
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    hideNotification: (state, { payload }: OtherNotification) =>
      state.filter(({ id }) => id !== payload),
    errorNotification: (state, { payload }: ErrorNotification) => {
      const text = isBlockchainErrorCode(payload)
        ? getBlockchainError(payload)
        : getApiError(payload);

      state.push({
        type: NotificationType.Error,
        id: uuid(),
        text,
      });
    },
    infoNotification: (state, { payload }: OtherNotification) => {
      state.push({
        type: NotificationType.Info,
        id: uuid(),
        text: payload,
      });
    },
    successNotification: (state, { payload }: OtherNotification) => {
      state.push({
        type: NotificationType.Success,
        id: uuid(),
        text: payload,
      });
    },
  },
});

export const { hideNotification, errorNotification, infoNotification, successNotification } =
  notificationsSlice.actions;
