import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/layout';
import { Text } from 'components/common';
import { NotificationType } from 'types';
import AlertIcon from './AlertIcon';

import styles from './Alert.module.scss';

interface Props {
  type: NotificationType;
  text: string;
}

const headerText: { [key in NotificationType]: string } = {
  SUCCESS: 'notifications.success',
  ERROR: 'notifications.error',
  INFO: 'notifications.info',
};

const Alert: React.FC<Props> = ({ type, text }) => (
  <div className={clsx(styles.alert, styles?.[type])}>
    <AlertIcon type={type} />
    <Flex direction="col">
      <Text className={styles.text} size={2} weight="semi-bold" text={headerText[type]} />
      <Text className={styles.text} size={2} color="sixth" text={text} />
    </Flex>
  </div>
);

export default Alert;
