import React from 'react';
import { Text } from 'components/common';
import { Flex } from 'components/layout';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { fetchShareholdingData, successNotification } from 'slices';
import { AddMemberOrSubjectData, GroupSubjectFormData } from 'types';
import { mapToAddCompanyShareholderReq } from 'utils';

import SubjectForm from '../SubjectForm';

const defaultValues = {
  representatives: [{}],
} as GroupSubjectFormData;

const AddSubject: React.FC = () => {
  const dispatch = useAppDispatch();
  const api = useApi();
  const { hideModal, data } = useModal<undefined, AddMemberOrSubjectData>();

  const onSubmit = async (formData: GroupSubjectFormData) => {
    const reqData = mapToAddCompanyShareholderReq(formData);
    const { companyId, group } = data;

    await api.post(
      `/company/${companyId}/shareholding/groups/${group.id}/shareholders/company`,
      reqData,
      () => {
        dispatch(successNotification('notifications.added_subject_to_group'));
        hideModal();
      },
    );
    dispatch(fetchShareholdingData(companyId));
  };

  return (
    <Flex direction="col" gap={4}>
      <Text text="modals.add_subject.title" size={4} weight="bold" />
      <SubjectForm
        variant="add_subject"
        onSubmit={onSubmit}
        onCancel={hideModal}
        companyType={data.companyType}
        defaultValues={defaultValues}
      />
    </Flex>
  );
};

export default AddSubject;
