import React from 'react';
import { getFullName } from 'utils';
import { Text } from 'components/common';
import { Flex } from 'components/layout';
import { Checkbox } from 'components/forms';
import { ExcludedGroupsVotersCompanies, ExcludedGroupsVotersPeople } from 'types';

interface Props {
  data: ExcludedGroupsVotersCompanies | ExcludedGroupsVotersPeople;
}

const UserVoter: React.FC<Props> = ({ data }) => {
  if ('fullUser' in data) {
    const { names, surname, representative, email } = data.fullUser;
    return (
      <Flex gap={1} align="center">
        {representative?.id === data.voter.id ? (
          <>
            <Checkbox name={`excludedUsersVoters.${data.id}`} color="sixth" />
            <Text
              text={getFullName({
                names: representative.names,
                surname: representative.surname,
                placeholder: representative.email,
              })}
              shouldTranslate={false}
            />
            -
            <Text
              text={getFullName({ names, surname, placeholder: email })}
              shouldTranslate={false}
            />
          </>
        ) : (
          <>
            <Checkbox name={`excludedUsersVoters.${data.id}`} color="sixth" />
            <Text
              text={getFullName({ names, surname, placeholder: email })}
              shouldTranslate={false}
            />
          </>
        )}
      </Flex>
    );
  }

  return null;
};

export default UserVoter;
