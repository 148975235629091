import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/common';
import { __ } from 'services';
import { IconName } from 'types';

import styles from './InputWithIcon.module.scss';

interface Props
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  iconName: IconName;
  placeholder?: string;
}

const InputWithIcon: React.FC<Props> = ({ iconName, placeholder, className, ...inputProps }) => (
  <div className={styles.container}>
    <Icon name={iconName} />
    <input
      {...inputProps}
      className={clsx(styles.input, className)}
      placeholder={placeholder && __(placeholder)}
      type="text"
    />
  </div>
);

export default InputWithIcon;
