import { SaveVotingResultsData } from './save-voting-results';

export enum BlockchainMethods {
  Ping = 'ping',
  AddTransactionToMempool = 'add_tx_to_mempool',
  SaveVotingResults = 'add_another_voting_protocol',
}

export enum BlockchainStatus {
  Done = 'done',
}

export interface JsonRpcBody {
  jsonrpc: '2.0';
  id: string;
  method: BlockchainMethods;
  params: unknown;
}

// According to https://www.jsonrpc.org/specification
export enum BlockchainErrorCode {
  RunOutOfRetries = -50000,
  ErrorOccurred = -40000,
  ParseError = -32700,
  InternalError = -32603,
  InvalidParams = -32602,
  MethodNotFound = -32601,
  InvalidRequest = -32600,
}

interface JsonRpcError {
  code: BlockchainErrorCode;
  message: string;
}

interface BlockchainMessage {
  id: string;
}

export interface BlockchainResult<Data> {
  data: Data;
  status: BlockchainStatus;
}

export interface BlockchainSuccessMessage<Data> extends BlockchainMessage {
  result: BlockchainResult<Data>;
}

export interface BlockchainFailureMessage extends BlockchainMessage {
  error: JsonRpcError;
}

export type BlockchainResults =
  | BlockchainSuccessMessage<SaveVotingResultsData>
  | BlockchainSuccessMessage<SaveVotingResultsData>[];
