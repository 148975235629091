import React from 'react';
import { Main } from 'components/layout';
import { PropsWithChildren } from 'types';

import styles from './MeetingContainer.module.scss';

const MeetingContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Main className={styles.main} bodyBackgroundColor="gray">
    {children}
  </Main>
);

export default MeetingContainer;
