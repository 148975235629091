import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState: true,
  reducers: {
    changeAppStatus: (state, { payload }: PayloadAction<boolean>) => payload,
  },
});

export const { changeAppStatus } = appStatusSlice.actions;
