import React from 'react';
import { Input } from 'components/forms';
import { Flex } from 'components/layout';

import styles from './SurnameInput.module.scss';

interface Props {
  name: string;
}

const SurnameInputs: React.FC<Props> = ({ name }) => (
  <Flex justify="space-between" grow={1} gap={2}>
    <Input
      name={`${name}.surname.0`}
      label="protocol.surname"
      placeholder="placeholders.provide_data"
      required
      className={styles.input}
      size="responsive"
    />
    <Input
      name={`${name}.surname.1`}
      label="protocol.second_surname"
      placeholder="placeholders.provide_data"
      className={styles.input}
      size="responsive"
    />
  </Flex>
);

export default SurnameInputs;
