import React from 'react';
import { useParams, useNavigate } from 'react-router';
import clsx from 'clsx';
import { Button } from 'components/common';
import { Flex } from 'components/layout';
import { SendVotingResultsToBlockchain } from 'components/meeting';
import { useApi, useAppDispatch, useAppSelector, useMeetingState } from 'hooks';
import { fetchMeetingData, prepareModal } from 'slices';
import { MeetingParams, ModalType } from 'types';
import { isResolutionVotingResultState } from 'utils';
import { AllRoutes, createPath } from 'config';
import { VotingResult } from 'app-types';

import styles from './AdminActionButtons.module.scss';

interface Props {
  variant: 'mobile' | 'desktop';
}

const AdminActionButtons: React.FC<Props> = ({ variant }) => {
  const {
    isUserCompanyOwner,
    isMeetingPlanned,
    isMeetingInProgress,
    hasMeetingEnded,
    isVotingState,
    isMeetingPaused,
    isResolutionVotingState,
    wasResolutionRecapitulated,
  } = useMeetingState();
  const { meetingId } = useParams<MeetingParams>();
  const { state } = useAppSelector(({ meeting }) => meeting);
  const dispatch = useAppDispatch();
  const api = useApi();
  const navigate = useNavigate();

  if (hasMeetingEnded && isUserCompanyOwner) {
    const redirectToProtocolForm = () => {
      navigate(createPath({ path: AllRoutes.AdminProtocol, params: { meetingId } }));
    };

    return (
      <>
        <SendVotingResultsToBlockchain className={styles?.[variant]} />
        <Button
          text="application.generate_protocol"
          variant="secondary"
          onClick={redirectToProtocolForm}
          // disabled={!areVotesAlreadySent(data)}
          className={styles?.[variant]}
        />
      </>
    );
  }

  const isStartMeetingButtonVisible = isUserCompanyOwner && isMeetingPlanned;
  if (isStartMeetingButtonVisible) {
    const onMeetingStart = async () => {
      await api.patch(`/meeting/${meetingId}/status`);
      await dispatch(fetchMeetingData({ meetingId }));
    };

    return (
      <Button
        className={styles?.[variant]}
        text="pages.meeting.start_meeting"
        variant="secondary"
        onClick={onMeetingStart}
      />
    );
  }

  const isManageResumptionButtonVisible =
    isUserCompanyOwner &&
    !wasResolutionRecapitulated &&
    isResolutionVotingResultState(state) &&
    state.result !== VotingResult.INTERRUPTED;
  if (isManageResumptionButtonVisible) {
    const onManageResumption = () => {
      dispatch(
        prepareModal({
          type: ModalType.ManageResumption,
          data: { resolutionId: state.resolutionId, meetingId },
          isVisible: true,
        }),
      );
    };

    return (
      <Button
        className={styles?.[variant]}
        onClick={onManageResumption}
        text="pages.meeting.manage_resumption"
      />
    );
  }

  const isPauseMeetingButtonVisible =
    isUserCompanyOwner && isResolutionVotingState && isMeetingInProgress;
  if (isPauseMeetingButtonVisible) {
    const onMeetingPause = async () => {
      await api.post(`/meeting/${meetingId}/pause`);
      await dispatch(fetchMeetingData({ meetingId }));
    };

    return (
      <Button
        className={styles?.[variant]}
        text="pages.meeting.pause_voting"
        onClick={onMeetingPause}
      />
    );
  }

  const areMeetingPausedButtonsVisible = isUserCompanyOwner && isVotingState && isMeetingPaused;
  if (areMeetingPausedButtonsVisible) {
    const onMeetingResume = async () => {
      await api.post(`/meeting/${meetingId}/resume`);
      await dispatch(fetchMeetingData({ meetingId }));
    };

    const onAddResolution = () =>
      dispatch(
        prepareModal({
          type: ModalType.AddResolution,
          data: { meetingId, isMeetingInProgress: true },
          isVisible: true,
        }),
      );

    return (
      <Flex
        direction="col"
        gap={1}
        className={clsx(styles?.[variant], styles?.[`${variant}-flex`])}
      >
        <Button
          className={styles?.[variant]}
          text="pages.meeting.resume_meeting"
          variant="secondary"
          onClick={onMeetingResume}
        />
        <Button
          className={styles?.[variant]}
          onClick={onAddResolution}
          text="modals.add_resolution.title"
        />
      </Flex>
    );
  }

  return null;
};

export default AdminActionButtons;
