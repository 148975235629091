import React from 'react';
import { Flex } from 'components/layout';
import { successNotification } from 'slices';
import { Form, Input } from 'components/forms';
import { Text, Button } from 'components/common';
import { useModal, useAppDispatch, useApi } from 'hooks';
import { shareYoutubeVideoValidationSchema } from 'config';
import { ModalActionButtonsContainer } from 'components/containers';
import { ShareYoutubeVideoData, ShareYoutubeVideoFormData } from 'types';
import { ShareVideoReq } from 'app-types';

const ShareYoutubeVideo: React.FC = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const {
    hideModal,
    data: { link },
  } = useModal<undefined, ShareYoutubeVideoData>();

  const onSubmit = async (data: ShareYoutubeVideoFormData) => {
    const formData = {
      email: data.email,
      youtubeUrl: link,
    };

    await api.post<undefined, ShareVideoReq>('/helpdesk/share-video', formData, () => {
      dispatch(successNotification('notifications.sent_youtube_link'));
      hideModal();
    });
  };

  const onCancel = () => hideModal();

  return (
    <Flex direction="col" gap={3}>
      <Text text="modals.share_youtube_link.title" size={4} weight="bold" />
      <Form submitHandler={onSubmit} validationSchema={shareYoutubeVideoValidationSchema}>
        <Flex direction="col" gap={5}>
          <Input name="email" label="modals.share_youtube_link.email" size="responsive" />
          <ModalActionButtonsContainer>
            <Button text="application.send_invite" variant="secondary" />
            <Button text="application.cancel" type="button" onClick={onCancel} variant="tertiary" />
          </ModalActionButtonsContainer>
        </Flex>
      </Form>
    </Flex>
  );
};

export default ShareYoutubeVideo;
