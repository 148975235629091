import * as Yup from 'yup';
import {
  MeetingType,
  VotingType,
  Majority,
  HelpdeskIssueCategory,
  TechnicalProblemSubcategory,
  Voivodeship,
  NotifiedBy,
} from 'app-types';
import { startOfDay } from 'date-fns';
import { SharesWithId } from 'types';
import { CONFIG } from './app';

export const requiredStringSchema = Yup.string().required('validation.field_required');

export const requiredDurationHoursAndMinutesSchema = requiredStringSchema.matches(
  /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g,
  'validation.must_be_time_format_hours_and_minutes',
);

export const requiredDurationMinutesAndSecondsSchema = requiredStringSchema
  .matches(
    /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/g,
    'validation.must_be_time_format_minutes_and_seconds',
  )
  .test('Is between 00:01 and 05:00', 'validation.must_be_between_1s_and_5m', (v) => {
    const [minStr, secStr] = v.split(':');
    if (!minStr || !secStr) return false;
    const secTotal = Number(minStr) * 60 + Number(secStr);

    return secTotal <= 300 && secTotal > 0;
  });

export const optionalStringSchema = Yup.string().optional().nullable();

export const krsNumberSchema = Yup.string()
  .required('validation.field_required')
  .test('is-number', 'validation.must_be_number', (v) => (!v ? false : /^\d+$/.test(v)))
  .test('is-exact-10-chars', 'validation.exactly_10_characters', (v) =>
    !v ? false : v.length === 10,
  );

export const nipNumberSchema = Yup.string()
  .required('validation.field_required')
  .test('is-number', 'validation.must_be_number', (v) => (!v ? false : /^\d+$/.test(v)))
  .test('is-exact-10-chars', 'validation.exactly_10_characters', (v) =>
    !v ? false : v.length === 10,
  );

export const regonNumberSchema = Yup.string()
  .required('validation.field_required')
  .test('is-number', 'validation.must_be_number', (v) => (!v ? false : /^\d+$/.test(v)))
  .test('is-exact-9-or-14-chars', 'validation.exactly_9_or_14_characters', (v) =>
    !v ? false : v.length === 9 || v.length === 14,
  );

export const emailSchema = Yup.string()
  .email('validation.incorrect_email')
  .required('validation.field_required');

export const phoneNumberSchema = Yup.string().matches(
  /^\+48\d{9}$/,
  'validation.incorrect_phone_number',
);

export const passwordSchema = Yup.string().matches(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,32}$/,
  'validation.password',
);
export const codeSchema = requiredStringSchema
  .test('is-number', 'validation.must_be_number', (v) => (!v ? false : /^\d+$/.test(v)))
  .test('is-exact-6-chars', 'validation.exactly_6_characters', (v) =>
    !v ? false : v.length === 6,
  );

export const basicUserSchema = Yup.object({
  email: emailSchema,
});

export const quorumSchemaRequired = Yup.number()
  .typeError('validation.field_required')
  .min(0, 'validation.equal_or_greater_than_zero')
  .max(100, 'validation.less_than_or_equal_to_houndred');

export const requiredNumberGreaterThanZeroSchema = Yup.number()
  .typeError('validation.field_required')
  .min(0, 'validation.equal_or_greater_than_zero');

type FileWithDelete = FileList & {
  isDeleted?: boolean;
};

interface FileListWithDelete {
  [Symbol.iterator](): IterableIterator<FileWithDelete>;
}

export const validateFilesCount = (count: number) =>
  Yup.mixed().test('maxFile', `validation.max_${count}_files`, (fileList: FileListWithDelete) => {
    if (fileList && Array.from(fileList).length > count) {
      const fileLength = Array.from(fileList).reduce((acc: number, file: FileWithDelete) => {
        if (file && file.isDeleted === true) {
          return acc;
        }
        return acc + 1;
      }, 0);

      if (fileLength > count) {
        return false;
      }
    }

    return true;
  });

export const validateFileTypes = (type: string, errorMessage: string) =>
  Yup.mixed().test('type', errorMessage, (fileList: FileList) => {
    let isValid = true;

    if (fileList && fileList.length > 0) {
      Array.from(fileList).forEach((file: File) => {
        if (!(file instanceof File)) return;
        if (file.type !== type) isValid = false;
      });
    }

    return isValid;
  });

const shareSchema = Yup.object({
  value: optionalStringSchema
    .test({
      name: 'atLeastOneOf',
      message: 'validation.at_least_one_of_should_be_filled',
      test: (ownValue, { from }) => {
        if (ownValue) return true;
        const obj = from?.[1].value;
        if (!obj) return true;
        const keys = Object.keys(obj) as (keyof typeof obj)[];

        return keys.some((k) => obj[k].value);
      },
    })
    .test({
      name: 'atLeastOneOfGreaterThanZero',
      message: 'validation.at_least_one_of_should_be_greater_than_zero',
      test: (ownValue, { from }) => {
        if (ownValue && Number(ownValue) > 0) return true;

        const obj = from?.[1].value;
        if (!obj) return true;
        const keys = Object.keys(obj) as (keyof typeof obj)[];

        return keys.some((k) => obj[k].value > 0);
      },
    }),
});

export const sharesSchema = Yup.object({}).when('isVoting', ([val], schema) =>
  val
    ? schema.shape({
        normal: shareSchema,
        nonVoting: shareSchema,
        preferentialM1: shareSchema,
        preferentialM2: shareSchema,
        preferentialM3: shareSchema,
        // eslint-disable-next-line react/forbid-prop-types
        treasury: Yup.object({
          value: optionalStringSchema.test({
            name: 'atLeastOneOf',
            message: 'validation.at_least_one_of_should_be_filled',
            test: (ownValue, { from }) => {
              if (ownValue) return true;

              const obj = from?.[1].value;
              if (!obj) return false;
              const keys = Object.keys(obj) as (keyof typeof obj)[];

              return keys.some((k) => obj[k].value);
            },
          }),
          quorumIncluded: Yup.boolean(),
        }),
      })
    : schema,
);

const voteSchema = Yup.number()
  .transform((parsedValue, originalValue) => (originalValue === '' ? null : parsedValue))
  .nullable()
  .min(0, 'validation.greater_than_zero');

const requiredFirstElementInArray = Yup.tuple([requiredStringSchema, optionalStringSchema]);

const requiredNameAndSurnameSchema = Yup.object().shape({
  names: requiredFirstElementInArray,
  surname: requiredFirstElementInArray,
});

const requiredRepresentativeDateSchema = Yup.array().of(
  Yup.object().shape({
    date: Yup.date()
      .typeError('validation.field_required')
      .max(startOfDay(new Date()), 'validation.date_in_the_future'),
  }),
);

const requiredNotificationSchema = Yup.mixed<NotifiedBy>()
  .oneOf(Object.values(NotifiedBy))
  .required('validation.field_required');

const requiredRepresentativeRoleSchema = Yup.array().of(
  Yup.object().shape({
    role: requiredStringSchema,
  }),
);

const requiredProposingPersonSchema = Yup.array().of(
  Yup.object().shape({
    proposingPerson: requiredNameAndSurnameSchema,
  }),
);

/* VALIDATION SCHEMAS */

export const addOrEditGroupValidationSchema = Yup.object({
  name: requiredStringSchema,
});

export const krsNumberValidationSchema = Yup.object({
  krsNumber: krsNumberSchema,
});

export const addOrEditCompanyValidationSchema = Yup.object({
  name: requiredStringSchema,
  type: requiredStringSchema,
  krs: krsNumberSchema,
  nip: nipNumberSchema,
  regon: regonNumberSchema,
  country: requiredStringSchema,
  voivodeship: Yup.string().oneOf(Object.values(Voivodeship)).required('validation.field_required'),
  district: requiredStringSchema,
  commune: requiredStringSchema,
  zipCode: requiredStringSchema,
  city: requiredStringSchema,
  streetName: requiredStringSchema,
  streetNumber: requiredStringSchema,
  apartmentNumber: optionalStringSchema,
  initialCapital: Yup.object({
    value: requiredStringSchema,
    currency: requiredStringSchema,
  }),
});

export const groupSubjectValidationSchema = Yup.object({
  krs: krsNumberSchema,
  isVoting: Yup.boolean(),
  shares: sharesSchema,
  representatives: Yup.array().of(basicUserSchema),
});

export const groupMemberValidationSchema = Yup.object({
  email: emailSchema,
  isVoting: Yup.boolean(),
  shares: sharesSchema,
  representatives: Yup.array().of(basicUserSchema),
});

export const addMeetingValidationSchema = Yup.object({
  type: Yup.string().required('validation.field_required'),
  customMeetingType: Yup.string().when('type', {
    is: (val: string) => val === MeetingType.Authority,
    then: (schema) => schema.required('validation.field_required'),
  }),
  formality: Yup.string().oneOf(['FORMAL', 'INFORMAL']).required('validation.field_required'),
  notary: Yup.string().email('validation.incorrect_email').nullable(),
  date: Yup.date()
    .required('validation.field_required')
    .min(startOfDay(new Date()), 'validation.date_in_the_past'),
  time: requiredDurationHoursAndMinutesSchema,
  participants: Yup.array()
    .of(
      Yup.object().shape({
        isChecked: Yup.boolean(),
      }),
    )
    .compact((v) => !v.isChecked)
    .min(1, 'validation.at_least_one_participant'),
  quorum: quorumSchemaRequired,
});

export const resolutionFormValidationSchema = Yup.object({
  title: Yup.string()
    .required('validation.field_required')
    .max(400, 'validation.resolution_title_max_characters'),
  description: Yup.string().max(65535, 'validation.description_max_characters'),
  pdf: validateFilesCount(CONFIG.MAX_RESOLUTION_FILES_COUNT).concat(
    validateFileTypes('application/pdf', 'validation.only_pdf'),
  ),
  type: Yup.mixed<VotingType>()
    .oneOf(Object.values(VotingType))
    .required('validation.field_required'),
  duration: requiredDurationMinutesAndSecondsSchema,
  quorum: quorumSchemaRequired,
  majority: Yup.mixed<Majority>()
    .oneOf(Object.values(Majority))
    .required('validation.field_required'),
  threshold: Yup.string()
    .nullable()
    .when('majority', {
      is: (val: string) => val === Majority.QUALIFIED,
      then: (schema) =>
        schema.test('isFractional', 'validation.must_be_fractional_format', (value) => {
          const splitFraction = value?.split('/');
          const isFraction = splitFraction && splitFraction.length === 2;
          if (!isFraction) return false;

          const [dividend, divider] = splitFraction;
          const isDividendGreaterThanZero = +dividend > 0;
          const isDividerGreaterThanZero = +divider > 0;
          const isDividerGreaterThanDividend = +divider > +dividend;

          return (
            isDividendGreaterThanZero && isDividerGreaterThanZero && isDividerGreaterThanDividend
          );
        }),
    }),
});
export const votersFormValidationSchema = Yup.object({
  pdf: validateFileTypes('application/pdf', 'validation.only_pdf'),
});

export const voteValidationSchema = Yup.object({
  voteOption: requiredStringSchema,
});

export const reportValidationSchema = Yup.object({
  reportingId: requiredStringSchema,
});

export const manageResumptionValidationSchema = Yup.object({
  duration: requiredDurationMinutesAndSecondsSchema,
});

export const helpdeskReportIssueValidationSchema = Yup.object({
  category: requiredStringSchema.oneOf(Object.values(HelpdeskIssueCategory)),
  subcategory: Yup.string()
    .nullable()
    .when('category', {
      is: (v: HelpdeskIssueCategory) => v === HelpdeskIssueCategory.TechnicalProblem,
      then: (schema) =>
        schema
          .required('validation.field_required')
          .oneOf(Object.values(TechnicalProblemSubcategory)),
    }),
  description: requiredStringSchema,
  senderFullName: requiredStringSchema,
  senderEmail: emailSchema,
});

export const createSplitVotesValidationSchema = (shares: SharesWithId[]) => {
  const schema = shares.reduce<Record<string, Yup.AnyObjectSchema>>(
    (acc, { id, value }) => ({
      ...acc,
      [id]: Yup.object({
        inFavour: voteSchema,
        against: voteSchema,
        abstain: voteSchema,
      })
        .test('isVotesSumSmallerThanTotalValue', 'validation.not_enough_shares', (v) => {
          const votes = Object.values(v).map((vote) =>
            typeof vote === 'number' && !Number.isNaN(vote) ? vote : 0,
          );

          const sum = votes.reduce((innerAcc, vote) => innerAcc + vote, 0);

          return sum <= value;
        })
        .test('isAnyValueSpecified', 'validation.no_value_specified', (v) => {
          const valuesArr = Object.values(v);
          const valuesSelected = valuesArr.filter((val) => Boolean(val)).length;

          return Boolean(valuesSelected);
        }),
    }),
    {},
  );

  return Yup.object(schema);
};

export const limitedLiabilityCompanyProtocolValidationSchema = Yup.object({
  greeter: requiredNameAndSurnameSchema,
  notification: requiredNotificationSchema,
  representativeDateList: requiredRepresentativeDateSchema,
  representativeRoleInCompanyList: requiredRepresentativeRoleSchema,
  copies: requiredNumberGreaterThanZeroSchema,
  chairman: requiredNameAndSurnameSchema,
  agendaRecorder: requiredNameAndSurnameSchema,
});

export const simpleJointStockCompanyProtocolValidationSchema = Yup.object({
  greeter: requiredNameAndSurnameSchema,
  notification: requiredNotificationSchema,
  representativeDateList: requiredRepresentativeDateSchema,
  representativeRoleInCompanyList: requiredRepresentativeRoleSchema,
  copies: requiredNumberGreaterThanZeroSchema,
  chairman: requiredNameAndSurnameSchema,
  agendaRecorder: requiredNameAndSurnameSchema,
});

export const jointStockCompanyProtocolValidationSchema = Yup.object({
  chairman: requiredNameAndSurnameSchema,
  agendaRecorder: requiredNameAndSurnameSchema,
});

export const authorityMeetingProtocolValidationSchema = Yup.object({
  protocolNumber: requiredStringSchema,
  notification: requiredNotificationSchema,
  greeter: requiredNameAndSurnameSchema,
  closer: requiredNameAndSurnameSchema,
  agendaContentList: requiredProposingPersonSchema,
});

export const shareYoutubeVideoValidationSchema = Yup.object({
  email: emailSchema,
});

export const loginValidationSchema = Yup.object({
  email: emailSchema,
  password: requiredStringSchema,
  isRegulationsAndPrivacyPolicyChecked: Yup.boolean().oneOf([true]),
});

export const registerValidationSchema = Yup.object({
  name: requiredStringSchema,
  secondName: optionalStringSchema,
  lastName: requiredStringSchema,
  secondLastName: optionalStringSchema,
  phone: phoneNumberSchema,
  email: emailSchema,
  password: passwordSchema,
  repeatPassword: requiredStringSchema.oneOf([Yup.ref('password')], 'validation.repeat_password'),
  isRegulationsAndPrivacyPolicyChecked: Yup.boolean().oneOf([true]),
});

export const completeRegistrationValidationSchema = Yup.object({
  name: requiredStringSchema,
  secondName: optionalStringSchema,
  lastName: requiredStringSchema,
  secondLastName: optionalStringSchema,
  phone: phoneNumberSchema,
  password: passwordSchema,
  repeatPassword: requiredStringSchema.oneOf([Yup.ref('password')], 'validation.repeat_password'),
  isRegulationsAndPrivacyPolicyChecked: Yup.boolean().oneOf([true]),
});

export const confirmationCodeValidationSchema = Yup.object({
  emailCode: codeSchema,
  smsCode: codeSchema,
});

export const confirmationSmsCodeValidationSchema = Yup.object({
  smsCode: codeSchema,
});

export const requestResetPassowrdValidationSchema = Yup.object({
  email: emailSchema,
});
export const resetPassowrdValidationSchema = Yup.object({
  password: passwordSchema,
  repeatPassword: requiredStringSchema.oneOf([Yup.ref('password')], 'validation.repeat_password'),
});
