import FileSaver from 'file-saver';
import { Dispatch } from '@reduxjs/toolkit';
import { ApiErrorCode } from 'app-types';
import { CONFIG } from 'config';
import { errorNotification, setLoading } from 'slices';

const is400 = (response: Response) => response.status === 400;

export const downloadFile = async (
  dispatch: Dispatch,
  url: string,
  fileName: string,
): Promise<void> => {
  const requestInit: RequestInit = {
    method: 'GET',
    credentials: 'include',
  };

  try {
    dispatch(setLoading(true));
    const response = await fetch(`${CONFIG.BASE_URL}${url}`, requestInit);

    if (!response.ok || is400(response)) {
      dispatch(errorNotification(ApiErrorCode.CouldNotDownloadFile));
      return;
    }

    const blob = await response.blob();
    FileSaver.saveAs(blob, fileName);

    dispatch(setLoading(false));
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(errorNotification(ApiErrorCode.CouldNotDownloadFile));
  }
};
