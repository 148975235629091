import React from 'react';
import { Flex } from 'components/layout';
import { ProtocolFormData } from 'types';
import { PageSubheader, HorizontalLine } from 'components/common';
import { useFieldArray, useFormContext } from 'react-hook-form';

import ListItem from './ListItem';

const PowerOfAttorney: React.FC = () => {
  const { control } = useFormContext<ProtocolFormData>();
  const { fields } = useFieldArray({
    control,
    name: 'representativeDateList',
  });

  if (!fields.length) return null;

  return (
    <>
      <Flex align="start" justify="space-between">
        <Flex xs={7}>
          <PageSubheader text="protocol.power_of_attorney" />
        </Flex>
        <Flex xs={12} gap={2} justify="start">
          {fields.map((listItem, index) => (
            <ListItem
              key={listItem.id}
              user={listItem.user}
              voter={listItem.voter}
              ordinalNumber={index + 1}
            />
          ))}
        </Flex>
      </Flex>
      <HorizontalLine />
    </>
  );
};

export default PowerOfAttorney;
