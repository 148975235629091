import React from 'react';
import { BaseVoting, Majority, VotingType } from 'app-types';
import { Text } from 'components/common';
import { Flex } from 'components/layout';

type VotingData = BaseVoting & { quorum: number };

interface Props {
  data: VotingData;
}

const getTranslation = (val: VotingType | Majority, translationPath: string) => {
  switch (val) {
    case Majority.ABSOLUTE:
    case Majority.QUALIFIED:
    case Majority.SIMPLE:
    case VotingType.OPEN:
    case VotingType.SECRET:
      return `${translationPath}.${val}`;
    default:
      return `${translationPath}.unknown`;
  }
};

const transformData = ({ type, duration, threshold, majority, quorum }: VotingData) => {
  const result = [
    {
      label: 'agenda_item.voting_type',
      text: getTranslation(type, 'voting.type'),
      shouldTranslate: true,
    },
    {
      label: 'agenda_item.duration',
      text: duration,
      shouldTranslate: false,
    },
    {
      label: 'agenda_item.quorum',
      text: quorum,
      shouldTranslate: false,
    },
    {
      label: 'agenda_item.majority',
      text: getTranslation(majority, 'voting.majority'),
      shouldTranslate: true,
    },
  ];
  if (threshold) {
    result.push({
      label: 'agenda_item.required_threshold',
      text: threshold,
      shouldTranslate: false,
    });
  }

  return result;
};

const VotingInfo: React.FC<Props> = ({ data }) => {
  const dataArr = transformData(data);

  return (
    <Flex direction="col">
      {dataArr.map(({ label, text, shouldTranslate }) => (
        <Flex gap={2} key={label}>
          <Text text={label} size={2} color="sixth" />
          <Text text={`${text}`} shouldTranslate={shouldTranslate} size={2} />
        </Flex>
      ))}
    </Flex>
  );
};

export default VotingInfo;
