import React from 'react';
import { useAppSelector } from 'hooks';
import { Text } from 'components/common';
import { isVotingState, getName } from 'utils';
import { SharesList } from 'components/meeting';
import { DesktopShareholderCardContainer } from 'components/containers';

import ShareholderCard from '../ShareholderCard';

const ShareholdersList: React.FC = () => {
  const { data, state } = useAppSelector(({ meeting }) => ({
    data: meeting.data,
    state: isVotingState(meeting.state) ? meeting.state : undefined,
  }));

  if (!data?.shareholders || data?.shareholders.length === 0) return null;

  if (data.shareholders.length > 1) {
    return (
      <>
        {data.shareholders.map((shareholder) => (
          <ShareholderCard
            key={shareholder.id}
            shareholder={shareholder}
            isActive={shareholder.id === state?.shareholders[0]?.id}
          />
        ))}
      </>
    );
  }

  return (
    <DesktopShareholderCardContainer>
      <Text text={getName(data.shareholders[0])} shouldTranslate={false} size={4} weight="bold" />
      <Text size={1} uppercase color="sixth" weight="bold" text="pages.meeting.shares_data" />
      <SharesList shares={data.shareholders[0].shares} />
    </DesktopShareholderCardContainer>
  );
};

export default ShareholdersList;
