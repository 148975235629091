import React from 'react';
import { ExcludedGroupsVotersCompanies, ExcludedGroupsVotersPeople } from 'types';

import UserVoter from '../UserVoter';
import CompanyVoter from '../CompanyVoter';

interface Props {
  data: ExcludedGroupsVotersCompanies | ExcludedGroupsVotersPeople;
}

const Member: React.FC<Props> = ({ data }) => (
  <>
    <UserVoter data={data} />
    <CompanyVoter data={data} />
  </>
);
export default Member;
