export enum ApiErrorCode {
  OtherError,
  BadCredentials,
  InsufficientPrivileges,
  NoSuchUser,
  NoSuchRole,
  InvalidLanguage,
  InvalidToken,
  TokenOutdated,
  PasswordAlreadySet,
  InvalidPassword,
  CountryNotSupported,
  SMSCouldNotBeSent,
  NotAllowedSortingCase,
  InvalidCurrentPage,
  ValidationError,
  CompanyAlreadyExists,
  UnsupportedCompanyType,
  NoSuchCompany,
  UserAlreadyRegistered,
  NoSuchVoting,
  UserCannotVote,
  NoSuchMeeting,
  InvalidFileType,
  VotingIsClosed,
  UnknownMajority,
  ThresholdNotReceived,
  MultiplierNotAllowed,
  SharesNotAllowedToVote,
  NotEnoughShares,
  QuorumIncludedPropertyNotDefined,
  AuthorityNotIncluded,
  UnrecognizedCompany,
  ShareholderAlreadyAdded,
  NoSuchCompanyInKrsApi,
  CompanyDoesntIncludeSelectedShareholdingGroup,
  NotAllowedToGetDataAboutSelectedCompany,
  RequestingUserIsNotCompanyOwner,
  ShareholdingGroupAlreadyExists,
  ShareholderIsNotPartOfSelectedShareholdingGroup,
  NoSuchAdmin,
  NoSuchGroup,
  NotCompanyRepresentative,
  UserCannotBeItsRepresentative,
  OnlyOneCompanyVoterCanBeChosen,
  SelectedUserVoterIsNotUserRepresentative,
  ThresholdMustBeFraction,
  FractionMustBeLowerThanOne,
  OrdinalNumberAlreadyTaken,
  OrdinalNumberCannotBeLowerThanOne,
  NotAllowedSequence,
  MeetingIsNotDraft,
  ResolutionIsDuplicated,
  NoSuchAgendaItem,
  ResolutionNotAssignedToSelectedAgendaItem,
  NotAuthorized,
  UnsuccessfulInvoiceCreation,
  InvoiceEmailCouldNotBeSent,
  UserNotAssignedToSelectedVoting,
  DuplicatedVotesTypeFound,
  MissingTimeSeparator,
  InvalidMinutesFormat,
  InvalidSecondsFormat,
  ReceivedValuesMustBeNumbers,
  InvalidTimeFormat,
  NoSuchResolution,
  NoSuchResumption,
  ResumptionNotAccepted,
  NoVotesCastedYet,
  NewVotingAlreadySet,
  VoterAlreadyVoted,
  VoterAlreadyCastedObjection,
  CommentAlreadyPostedByVoter,
  SubscriptionAllowsCreatingOnlyOneMeeting,
  TooManyVoters,
  TooManyResolutions,
  SubscriptionAlreadyAssigned,
  SubscriptionNotAssigned,
  NoSuchFile,
  ChangingCompanyNipNotAllowed,
  NoVotersGroupSelected,
  NotaryEmailAlreadyInUseInTheMeeting,
  CompanyNotCreated,
  NoSuchSubscription,
  CancelingSubscriptionAllowedForMonthlyPaymentsOnly,
  FileNameMustBeUnique,
  MeetingAlreadyStarted,
  VotesCastedInForbiddenTimeRange,
  TimeIsTooLong,
  NoSuchVoterObject,
  VoterExcludedFromVoting,
  UnableToResumeMeetingThatIsNotPaused,
  MeetingCanBePausedOnlyDuringVotingTime,
  MeetingCanBeResumeOnlyDuringVotingTime,
  NoActiveVotingFound,
  VotingDoesntBelongToSelectedMeeting,
  VoterAlreadyReportedChange,
  InvalidVotingDuration,
  VotingDurationTimeHigherThanExpected,
  YouCannotUpdateSelectedAgendaItem,
  MeetingMustBePaused,
  ResumptionAlreadyCreated,
  ResumptionCanBeCreatedOnlyDuringBreakTime,
  MeetingIsInProgress,
  UnknownSortByType,
  UnknownSortDirType,
  QuorumNotReached,
  CouldNotDownloadFile,
  ForbiddenResource,
  MeetingAlreadyFinished,
  MaxNumberOfFilesExceeded,
  AggregatorApiError,
  CyberIdApiError,
  KrsApiError,
  TaxxoApiError,
  MissingVotingOrResumptionId,
  ExceptionDuringTransaction,
  UserIsDeleted,
  UserIsDisabled,
  ExceededNumberOfShareholders,
  UserAlreadyActivated,
  ActivationCodeExpired,
  InvalidActivationCode,
  CountryNotParsed,
  PhoneNumberAlreadyExists,
}
