import { format, formatDuration, intervalToDuration, isBefore } from 'date-fns';

export const formatDate = (date: Date, includeTime?: boolean) => {
  if (includeTime) {
    return format(date, 'dd.MM.yyyy HH:mm');
  }

  return format(date, 'dd.MM.yyyy');
};

export const formatTime = (date: Date) => format(date, 'HH:mm');

export const formatTimeDistance = (start: Date, end: Date): string => {
  if (!isBefore(start, end)) return '00:00';

  const duration = intervalToDuration({ start, end });

  const zeroPad = (num: number) => String(num).padStart(2, '0');

  return formatDuration(duration, {
    format: ['minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  });
};
