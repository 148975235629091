import React from 'react';
import clsx from 'clsx';

import styles from './Row.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  width?: number;
  alignC?: boolean;
  alignFe?: boolean;
  center?: boolean;
  justifyFe?: boolean;
  spaceBetween?: boolean;
  col?: boolean;
  gap?: number;
  divide?: number;
  noWrap?: boolean;
}

const Row = ({
  children,
  className,
  width,
  alignC,
  alignFe,
  center,
  col,
  divide,
  gap,
  noWrap,
  justifyFe,
  spaceBetween,
}: Props) => (
  <div
    className={clsx(className, styles.row, {
      [styles?.[`width-${width}`]]: width,
      [styles.center]: center,
      [styles?.['justify-flex-end']]: justifyFe,
      [styles?.['space-between']]: spaceBetween,
      [styles?.['align-center']]: alignC,
      [styles?.['align-flex-end']]: alignFe,
      [styles.col]: col,
      [styles?.[`divide-${divide}`]]: divide,
      [styles?.['no-wrap']]: noWrap,
      [styles?.[`gap-${gap}`]]: gap,
    })}
  >
    {children}
  </div>
);

export default Row;
