import React from 'react';
import clsx from 'clsx';

import Flex from '../Flex';
import styles from './TextCol.module.scss';

interface Props {
  children: React.ReactNode;
  grow?: number;
  basis?: number;
}

const TextCol: React.FC<Props> = ({ children, grow, basis }) => (
  <Flex className={clsx(styles.col)} justify="space-between" align="center" grow={grow} xs={basis}>
    {children}
  </Flex>
);

export default TextCol;
