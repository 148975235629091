import React from 'react';
import { SubscribeReq } from 'app-types';
import { CONFIG } from 'config';
import { formatPrice } from 'utils';
import { Flex, Row } from 'components/layout';
import { Text, Button } from 'components/common';
import { Form, Input, Checkbox } from 'components/forms';

import SummaryCard from './SummaryCard';
import styles from './CalculatorForm.module.scss';

interface Props {
  onSubmit: (data: SubscribeReq) => void;
}

const CalculatorForm: React.FC<Props> = ({ onSubmit }) => (
  <Form submitHandler={onSubmit}>
    <Flex justify="space-between" gap={6}>
      <Flex direction="col" justify="space-between" gap={4}>
        <Flex align="start" gapCol={19}>
          <Input
            name="votersCount"
            label="calculator.number_of_voters"
            type="number"
            size="small"
            placeholder="placeholders.number"
            minNumber={1}
          />
          <Input
            name="resolutionsCount"
            label="calculator.number_of_resolutions"
            type="number"
            size="small"
            placeholder="placeholders.number"
            minNumber={1}
          />
        </Flex>
        <Flex direction="col" gap={2}>
          <Text text="calculator.is_assistance_needed_label" weight="bold" />
          <Row alignC gap={1} className={styles.assistance}>
            <Checkbox name="isAssistanceNeeded" label="calculator.is_assistance_needed" />
            <Text
              text="calculator.is_assistance_needed_price"
              translationArgs={{
                currency: 'zł',
                price: formatPrice(CONFIG.SUBSCRIPTION.ASSISTANCE_PRICE),
              }}
            />
          </Row>
        </Flex>
      </Flex>
      <SummaryCard />
    </Flex>
    <Flex>
      <Button className={styles.button} text="calculator.buy_plan" />
    </Flex>
  </Form>
);

export default CalculatorForm;
