import React from 'react';
import { LineProgressbar } from 'components/common';
import { useAppSelector, useMeetingState } from 'hooks';

const MobileSharesMenuProgressbar: React.FC = () => {
  const { isMeetingInProgress, progressbarValue } = useMeetingState();
  const { data } = useAppSelector(({ meeting }) => meeting);

  const allShareholders = data?.shareholders;
  const isComponentVisible = isMeetingInProgress && allShareholders && allShareholders.length > 1;
  if (!isComponentVisible) return null;

  const votedState = `${progressbarValue}/${allShareholders.length}`;

  return (
    <LineProgressbar
      label="pages.meeting.voted_progress"
      translationArgs={{ votedState }}
      value={progressbarValue}
      goal={allShareholders.length}
    />
  );
};

export default MobileSharesMenuProgressbar;
