import React from 'react';
import { Row } from 'components/layout';
import { Input } from 'components/forms';

const inputs = [
  { label: 'shares.normal', name: 'shares.normal.value' },
  { label: 'shares.non_voting', name: 'shares.nonVoting.value' },
  {
    label: 'shares.preferential',
    name: 'shares.preferentialM1.value',
    translationArgs: { multiplier: '1' },
  },
  {
    label: 'shares.preferential',
    name: 'shares.preferentialM2.value',
    translationArgs: { multiplier: '2' },
  },
  {
    label: 'shares.preferential',
    name: 'shares.preferentialM3.value',
    translationArgs: { multiplier: '3' },
  },
];

const limitedLiabilityCompanyInputs = [
  { label: 'shares_stock.normal', name: 'shares.normal.value' },
  {
    label: 'shares_stock.preferential',
    name: 'shares.preferentialM1.value',
    translationArgs: { multiplier: '1' },
  },
  {
    label: 'shares_stock.preferential',
    name: 'shares.preferentialM2.value',
    translationArgs: { multiplier: '2' },
  },
  {
    label: 'shares_stock.preferential',
    name: 'shares.preferentialM3.value',
    translationArgs: { multiplier: '3' },
  },
];

interface Props {
  isLimitedLiabilityCompany: boolean;
}

const InputsList: React.FC<Props> = ({ isLimitedLiabilityCompany }) => (
  <Row divide={2}>
    {isLimitedLiabilityCompany ? (
      <>
        {limitedLiabilityCompanyInputs.map(({ label, name, translationArgs }) => (
          <Input
            minNumber={0}
            maxNumber={4294967295}
            name={name}
            label={label}
            labelTranslationArgs={translationArgs}
            size="responsive"
            type="number"
            key={name}
          />
        ))}
      </>
    ) : (
      <>
        {inputs.map(({ label, name, translationArgs }) => (
          <Input
            minNumber={0}
            maxNumber={4294967295}
            name={name}
            label={label}
            labelTranslationArgs={translationArgs}
            size="responsive"
            type="number"
            key={name}
          />
        ))}
      </>
    )}
  </Row>
);

export default InputsList;
