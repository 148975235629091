import {
  AgendaItemFormData,
  ProtocolDataFromUser,
  UserVoterFormData,
  CompanyVoterFormData,
  UserDataTypeForProtocol,
} from 'app-types';

export interface RepresentativeDateList extends UserVoterFormData {
  date: string;
}

export interface AgendaContentList extends AgendaItemFormData {
  content: string;
  proposingPerson: UserDataTypeForProtocol;
}

export interface RepresentativeRoleInCompanyList extends CompanyVoterFormData {
  role: string;
}

export interface ProtocolFormData
  extends Omit<
    ProtocolDataFromUser,
    'representativeDateList' | 'agendaContentList' | 'representativeRoleInCompanyList'
  > {
  representativeDateList: RepresentativeDateList[];
  agendaContentList: AgendaContentList[];
  representativeRoleInCompanyList: RepresentativeRoleInCompanyList[];
  tempTitle?: string;
  tempDescription?: string;
  tempAbsentPerson?: UserDataTypeForProtocol;
}

export enum ProtocolType {
  LIMITED_LIABILITY_COMPANY,
  JOINT_STOCK_COMPANY,
  SIMPLE_JOINT_STOCK_COMPANY,
  AUTHORITY_MEETING,
}
