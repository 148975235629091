import {
  CompaniesDiffs,
  CompanyShareholderData,
  ResolutionData,
  ResolutionRes,
  ShareholderCompany,
  ShareholderUser,
  UserShareholderData,
  VotingShareholder,
  CompanyType,
  ObjectionEntity,
  ChangeReportEntity,
} from 'app-types';
import { VotingResultCardVariants } from './ui';

export interface ModalState {
  isVisible: boolean;
  isClosable: boolean;
  isPrepared: boolean;
  isLoading: boolean;
  type: ModalType | null;
  data: unknown;
  outputData: unknown;
}

export enum ModalType {
  AddGroup = 'add-group',
  AddMember = 'add-member',
  AddSubject = 'add-subject',
  AddResolution = 'add-resolution',
  DataChanged = 'data-changed',
  EditGroup = 'edit-group',
  EditGroupSubject = 'edit-group-subject',
  EditGroupMember = 'edit-group-member',
  EditResolution = 'edit-resolution',
  DeleteFromGroup = 'delete-from-group',
  DeleteResolution = 'delete-resolution',
  EndVoting = 'end-voting',
  SplitVotes = 'split-votes',
  ShowVotes = 'show-votes',
  ReportChange = 'report-change',
  ReportObjection = 'report-objection',
  ManageResumption = 'manage-resumption',
  UploadCSV = 'upload-csv',
  MeetingClosed = 'meeting-closed',
  ShareYoutubeVideo = 'share-youtube-video',
  ManageExcludedVoters = 'manage-excluded-voters',
  EnterSmsCode = 'enter-sms-code',
}

export type UnPrepareModalActionPayload = UnPrepareModalOptions | undefined;

export interface UnPrepareModalOptions {
  isVisible: boolean;
}

export type PrepareModalActionPayloads =
  | PrepareDataChangedModal
  | PrepareAddGroupModal
  | PrepareEditGroupModal
  | PrepareAddMemberOrSubjectModal
  | PrepareEditGroupMemberModal
  | PrepareEditGroupSubjectModal
  | PrepareDeleteFromGroupModal
  | PrepareAddResolutionModal
  | PrepareEditResolutionModal
  | PrepareDeleteResolutionModal
  | PrepareEndVotingModal
  | PrepareSplitVotesModal
  | PrepareShowVotesModal
  | PrepareReportModal
  | PrepareMangeResumptionModal
  | PrepareUploadCSVModal
  | PrepareMeetingClosedModal
  | PrepareShareYoutubeVideoModal
  | PrepareManageExcludedVotersModal
  | PrepareEnterSmsCodeModal;

export interface PrepareModal<Type extends ModalType, Data extends unknown> {
  type: Type;
  data: Data;
  isVisible: boolean;
}

type PrepareAddGroupModal = PrepareModal<ModalType.AddGroup, string>;

export interface MeetingClosedData {
  meetingId: string;
}

type PrepareMeetingClosedModal = PrepareModal<ModalType.MeetingClosed, MeetingClosedData>;

export interface GroupData {
  id: string;
  name: string;
}

export interface AddMemberOrSubjectData {
  companyId: string;
  group: GroupData;
  companyType: CompanyType;
}

type PrepareAddMemberOrSubjectModal = PrepareModal<
  ModalType.AddSubject | ModalType.AddMember,
  AddMemberOrSubjectData
>;

export interface EditGroupData {
  companyId: string;
  group: GroupData;
}

type PrepareEditGroupModal = PrepareModal<ModalType.EditGroup, EditGroupData>;

type PrepareDataChangedModal = PrepareModal<ModalType.DataChanged, CompaniesDiffs[]>;

export interface EditGroupMemberData extends AddMemberOrSubjectData {
  member: ShareholderUser;
}

type PrepareEditGroupMemberModal = PrepareModal<ModalType.EditGroupMember, EditGroupMemberData>;

export interface EditGroupSubjectData extends AddMemberOrSubjectData {
  member: ShareholderCompany;
}

type PrepareEditGroupSubjectModal = PrepareModal<ModalType.EditGroupSubject, EditGroupSubjectData>;

interface DeleteFromGroupData extends AddMemberOrSubjectData {
  member: ShareholderUser | ShareholderCompany;
}

type PrepareDeleteFromGroupModal = PrepareModal<ModalType.DeleteFromGroup, DeleteFromGroupData>;

export interface AddResolutionData {
  meetingId: string;
  isMeetingInProgress?: boolean;
}

type PrepareAddResolutionModal = PrepareModal<ModalType.AddResolution, AddResolutionData>;

export interface DeleteResolutionData {
  agendaItemId: string;
  meetingId: string;
  title: string;
  isMeetingInProgress?: boolean;
}

type PrepareDeleteResolutionModal = PrepareModal<ModalType.DeleteResolution, DeleteResolutionData>;

export interface EditResolutionData {
  agendaItemId: string;
  meetingId: string;
  resolution: ResolutionRes | ResolutionData;
  isMeetingInProgress?: boolean;
  isCurrentlyVotingOnThatResolution?: boolean;
}

type PrepareEditResolutionModal = PrepareModal<ModalType.EditResolution, EditResolutionData>;

export interface EndVotingData {
  meetingId: string;
  resolutionTitle: string;
  votingId: string;
}

type PrepareEndVotingModal = PrepareModal<ModalType.EndVoting, EndVotingData>;

export interface SplitVotesBaseData {
  meetingId: string;
  shareholder: Pick<ShareholderUser, 'id' | 'shares'>;
}

export interface SplitVotesResolutionData extends SplitVotesBaseData {
  votingId: string;
}

export interface SplitVotesResumptionData extends SplitVotesBaseData {
  resumptionId: string;
}

export type SplitVotesData = SplitVotesResolutionData | SplitVotesResumptionData;

export type PrepareSplitVotesModal = PrepareModal<ModalType.SplitVotes, SplitVotesData>;

export interface ReportData {
  votingId: string;
  shareholders: (UserShareholderData | CompanyShareholderData)[];
  meetingId: string;
  objections?: ObjectionEntity[];
  changesReports?: ChangeReportEntity[];
}

export type PrepareReportModal = PrepareModal<
  ModalType.ReportChange | ModalType.ReportObjection,
  ReportData
>;

export interface ShowVotesData {
  variant: VotingResultCardVariants;
  voters: VotingShareholder[];
}

export type PrepareShowVotesModal = PrepareModal<ModalType.ShowVotes, ShowVotesData>;

export interface ManageResumptionData {
  meetingId: string;
  resolutionId: string | undefined;
}

export type PrepareMangeResumptionModal = PrepareModal<
  ModalType.ManageResumption,
  ManageResumptionData
>;

export interface UploadCSVData {
  companyId: string;
}

type PrepareUploadCSVModal = PrepareModal<ModalType.UploadCSV, UploadCSVData>;

export interface ShareYoutubeVideoData {
  link: string;
}

type PrepareShareYoutubeVideoModal = PrepareModal<
  ModalType.ShareYoutubeVideo,
  ShareYoutubeVideoData
>;

export interface ManageExcludedVotersData {
  meetingId: string;
  companyId: string;
  votingId: string;
}

type PrepareManageExcludedVotersModal = PrepareModal<
  ModalType.ManageExcludedVoters,
  ManageExcludedVotersData
>;
type PrepareEnterSmsCodeModal = PrepareModal<
  ModalType.EnterSmsCode,
  { id: string; isLoggedIn?: boolean }
>;
