import React from 'react';
import SVG from 'react-inlinesvg';

interface Props {
  className?: string;
  center?: boolean;
}

const Logo = ({ className, center }: Props) => (
  <div className={className} style={{ marginInline: center ? 'auto' : '0px' }}>
    <SVG src="/assets/images/logo.svg" />
  </div>
);

export default Logo;
