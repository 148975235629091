import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Icon } from 'components/common';
import { __ } from 'services';
import { IconName } from 'types';

import styles from './Link.module.scss';

interface CommonProps {
  text: string;
  className?: string;
  textAlign?: 'left' | 'center' | 'right';
  bold?: boolean;
  underline?: boolean;
  customIcon?: IconName;
  leftArrow?: boolean;
  rightArrow?: boolean;
  fontSize?: number;
  uppercase?: boolean;
  variant?: 'primary' | 'secondary';
}

interface LinkProps extends CommonProps {
  to: string;
}

interface ButtonProps extends CommonProps {
  onClick: () => void;
}

const Link: React.FC<LinkProps | ButtonProps> = ({
  text,
  className,
  textAlign,
  bold,
  underline,
  customIcon,
  leftArrow,
  rightArrow,
  fontSize,
  uppercase,
  variant = 'primary',
  ...rest
}) => {
  const classNames = clsx(className, styles.link, {
    [styles?.[variant]]: variant,
    [styles?.[`ta-${textAlign}`]]: textAlign,
    [styles?.['with-arrow']]: leftArrow || customIcon,
    [styles?.[`size-${fontSize}`]]: fontSize,
    [styles.bold]: bold,
    [styles.underline]: underline,
    [styles.uppercase]: uppercase,
  });

  if ('onClick' in rest) {
    return (
      <button className={classNames} onClick={rest.onClick} type="button">
        {leftArrow && <Icon name="arrow-left" />}
        {customIcon && <Icon name={customIcon} />}
        {__(text)}
      </button>
    );
  }

  return (
    <RouterLink className={classNames} to={rest.to}>
      {leftArrow && <Icon name="arrow-left" />}
      {customIcon && <Icon name={customIcon} />}
      {__(text)}
    </RouterLink>
  );
};

export default Link;
