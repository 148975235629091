import { SubscriptionType } from 'app-types';
import { FaqItemData, YoutubeVideoData } from 'types';

export const CONFIG = {
  LANGUAGES: ['pl'],
  DEFAULT_LANG: 'pl',
  BASE_URL: process.env.REACT_APP_API_URL || 'http://localhost:3001',
  BC_URL: process.env.REACT_APP_BC_URL || 'http://4dc.ivoting.pl:22084',
  /**
   * !IMPORTANT!
   * AUTH HAS TO BE ENCODED VIA BASE64 (btoa method)
   * GENERAL RECIPE IS USER:KEY
   * FOR EXAMPLE ivbiznes:MY_SUPER_KEY
   */
  BC_AUTH:
    process.env.REACT_APP_BC_AUTH || 'aXZiaXpuZXM6b2hwaGlDNmVpdGhvN2NhaHRoZWhlZWQ0bG9vcGVpcDg=',
  BC_RETRIES: 5,
  BC_DELAY_IN_MS: 5000,
  SUBSCRIPTION: {
    RESOLUTION_PRICE: 92.25,
    VOTER_PRICE: 61.5,
    ASSISTANCE_PRICE: 553.5,
    MONTHLY_PRICE: 369,
    ANNUALLY_PRICE: 3542.4,
  },
  DEFAULT_HELPDESK_TAB: 'faq' as const,
  SUPPORT_EMAIL: 'bok@ivoting.pl',
  BREAKPOINTS: {
    xs: 320,
    sm: 768,
    md: 920,
    lg: 1100,
    xl: 1368,
    xxl: 1680,
  },
  REGISTER_URL: 'https://stg.cyberid.app/register',
  MAX_RESOLUTION_FILES_COUNT: 3,
  REFRESH_TOKEN_INTERVAL: 1000 * 270, // 4 min 30 sec
};

export const PLANS = [
  {
    subscriptionType: SubscriptionType.PER_MEETING,
    heading: {
      title: 'pages.select_plan.card_1.title',
      subtitle: 'pages.select_plan.card_1.title_2',
      cost: 'pages.select_plan.card_1.cost',
      timePeriod: 'pages.select_plan.card_1.time_period',
      monthlyCost: 'pages.select_plan.card_1.monthly_cost',
    },
    text1: 'pages.select_plan.card_1.text_1',
    text2: 'pages.select_plan.card_1.text_2',
    text3: 'pages.select_plan.card_1.text_3',
    features: [
      'pages.select_plan.card_1.feature_1',
      'pages.select_plan.card_1.feature_2',
      'pages.select_plan.card_1.feature_3',
    ],
    feature1: 'pages.select_plan.card_1.feature_1',
    feature2: 'pages.select_plan.card_1.feature_2',
    feature3: 'pages.select_plan.card_1.feature_3',
  },
  {
    subscriptionType: SubscriptionType.ANNUALLY_PAYMENT,
    heading: {
      title: 'pages.select_plan.card_2.title',
      subtitle: '',
      cost: 'pages.select_plan.card_2.cost',
      totalCost: CONFIG.SUBSCRIPTION.ANNUALLY_PRICE,
      timePeriod: 'pages.select_plan.card_2.time_period',
      monthlyCost: 'pages.select_plan.card_2.monthly_cost',
      totalMonthlyCost: CONFIG.SUBSCRIPTION.ANNUALLY_PRICE / 12,
    },
    text1: 'pages.select_plan.card_2.text_1',
    text2: 'pages.select_plan.card_2.text_2',
    text3: 'pages.select_plan.card_2.text_3',
    features: [
      'pages.select_plan.card_2.feature_1',
      'pages.select_plan.card_2.feature_2',
      'pages.select_plan.card_2.feature_3',
    ],
    feature1: 'pages.select_plan.card_2.feature_1',
    feature2: 'pages.select_plan.card_2.feature_2',
    feature3: 'pages.select_plan.card_2.feature_3',
  },
  {
    subscriptionType: SubscriptionType.MONTHLY_PAYMENT,
    heading: {
      title: 'pages.select_plan.card_3.title',
      subtitle: '',
      cost: 'pages.select_plan.card_3.cost',
      totalCost: CONFIG.SUBSCRIPTION.MONTHLY_PRICE * 12,
      timePeriod: 'pages.select_plan.card_3.time_period',
      monthlyCost: 'pages.select_plan.card_3.monthly_cost',
      totalMonthlyCost: CONFIG.SUBSCRIPTION.MONTHLY_PRICE,
    },
    text1: 'pages.select_plan.card_3.text_1',
    text2: 'pages.select_plan.card_3.text_2',
    text3: 'pages.select_plan.card_3.text_3',
    features: [
      'pages.select_plan.card_3.feature_1',
      'pages.select_plan.card_3.feature_2',
      'pages.select_plan.card_3.feature_3',
    ],
    feature1: 'pages.select_plan.card_3.feature_1',
    feature2: 'pages.select_plan.card_3.feature_2',
    feature3: 'pages.select_plan.card_4.feature_3',
  },
];

export const FAQ_ITEMS: FaqItemData[] = [
  {
    title: 'faq.0.question',
    description: 'faq.0.answer',
  },
  {
    title: 'faq.1.question',
    description: 'faq.1.answer',
    link: '[TODO]', // TODO
  },
  {
    title: 'faq.2.question',
    description: 'faq.2.answer',
  },
  {
    title: 'faq.3.question',
    description: 'faq.3.answer',
    email: CONFIG.SUPPORT_EMAIL,
  },
  {
    title: 'faq.4.question',
    description: 'faq.4.answer',
  },
  {
    title: 'faq.5.question',
    description: 'faq.5.answer',
  },
  {
    title: 'faq.6.question',
    description: 'faq.6.answer',
  },
];

export const TUTORIALS_VIDEO_DATA: YoutubeVideoData[] = [
  {
    id: 'T4t29_ODDY4',
    title: 'iVoting Free - Rejestracja',
  },
  {
    id: 'ccSyUZ9yWFM',
    title: 'iVoting Free - opis interfejsu',
  },
];
