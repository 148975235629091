import React from 'react';
import clsx from 'clsx';

import styles from './CircleContainer.module.scss';

const CircleContainer: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...divProps
}) => (
  <div {...divProps} className={clsx(className, styles.container)}>
    {children}
  </div>
);

export default CircleContainer;
