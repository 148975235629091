import React from 'react';
import { Input } from 'components/forms';
import { Flex, Row } from 'components/layout';
import { Text } from 'components/common';
import { CompanyShareholderFormData, UserShareholderFormData } from 'app-types';
import { getFullName } from 'utils';

import styles from './ListItem.module.scss';

interface Props {
  ordinalNumber: number;
  company: CompanyShareholderFormData;
  voter: UserShareholderFormData;
}

const ListItem: React.FC<Props> = ({ ordinalNumber, company, voter }) => (
  <Flex align="center" gap={2} justify="start" xs={12}>
    <Flex className={styles.container} gap={1}>
      <Text
        weight="bold"
        text="protocol.ordinal_number"
        translationArgs={{ number: String(ordinalNumber) }}
      />
      <Flex gap={1}>
        <Row noWrap>
          <Text
            text="protocol.company_name"
            translationArgs={{ company: company.name }}
            uppercase
            weight="bold"
          />
        </Row>
        <Row noWrap gap={1}>
          <Text text="protocol.company_representative" color="sixth" />
          <Text text={getFullName(voter)} shouldTranslate={false} />
        </Row>
      </Flex>
    </Flex>
    <Flex className={styles.container}>
      <Input
        className={styles.input}
        size="responsive"
        name={`representativeRoleInCompanyList.${ordinalNumber - 1}.role`}
        label="protocol.representative_role"
        required
      />
    </Flex>
  </Flex>
);

export default ListItem;
