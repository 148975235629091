import { Files } from 'types';
import { RemoveFileReq } from 'app-types';

export const getFormDataFromObj = <O extends object = object>(obj: O) => {
  const formData = new FormData();
  Object.entries(obj).forEach(
    ([key, val]) => (val || val === 0 || val === '') && formData.append(key, val.toString()),
  );
  return formData;
};

export const getFormDataFromArr = (arr: unknown[], name: string): FormData => {
  const formData = new FormData();
  formData.append(name, JSON.stringify(arr));
  return formData;
};

export const appendFiles = (filesArr: (File | Files)[], formData: FormData, name = 'file') => {
  filesArr.forEach((file) => {
    if (file instanceof File) formData.append(name, file, file.name);
  });
};

export const getRemovedFiles = (filesArr: (File | Files)[]) => {
  const removedFiles: RemoveFileReq[] = [];
  filesArr.forEach((item) => {
    if (!(item instanceof File) && item && item?.isDeleted) {
      removedFiles.push({ id: item.id, hash: item.hash, awsPath: item.awsPath });
    }
  });

  return removedFiles;
};

export const checkIfAllFilesArePdf = (fileList: File[]) =>
  fileList.filter((fileEl) => !!fileEl.type).every((file) => file.type === 'application/pdf');
