import React from 'react';
import { DateInput } from 'components/forms';
import { Flex, Row } from 'components/layout';
import { Text } from 'components/common';
import { UserShareholderFormData } from 'app-types';
import { getFullName } from 'utils';

import styles from './ListItem.module.scss';

interface Props {
  ordinalNumber: number;
  user: UserShareholderFormData;
  voter: UserShareholderFormData;
}

const ListItem: React.FC<Props> = ({ ordinalNumber, user, voter }) => (
  <Flex align="center" gap={2} justify="start" xs={12}>
    <Flex className={styles.container} gap={1}>
      <Text
        weight="bold"
        text="protocol.ordinal_number"
        translationArgs={{ number: String(ordinalNumber) }}
      />
      <Flex gap={1}>
        <Row noWrap>
          <Text
            text={getFullName({ names: user.names, surname: user.surname })}
            shouldTranslate={false}
            uppercase
            weight="bold"
          />
        </Row>
        <Row noWrap gap={1}>
          <Text text="protocol.attorney" color="sixth" />
          <Text
            text={getFullName({ names: voter.names, surname: voter.surname })}
            shouldTranslate={false}
          />
        </Row>
      </Flex>
    </Flex>
    <Flex className={styles.container} justify="space-between" gap={2}>
      <DateInput
        maxDate={new Date()}
        name={`representativeDateList.${ordinalNumber - 1}.date`}
        label="protocol.attorney_date"
        required
      />
    </Flex>
  </Flex>
);

export default ListItem;
