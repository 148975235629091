import { FileEntity } from '../file';
import { ResumptionEntity } from '../resumption';
import { VotingEntity } from '../voting';

export interface BaseUpdateResolution {
  title: string;
  description: string;
  quorum: number;
}

export interface BaseResolution extends BaseUpdateResolution {
  isQuorumReached?: boolean;
  files: FileEntity[];
  voting: VotingEntity;
}

export interface ResolutionEntity extends BaseResolution {
  id: string;
  resumption?: ResumptionEntity | null;
}

export enum Majority {
  SIMPLE = 'SIMPLE',
  ABSOLUTE = 'ABSOLUTE',
  QUALIFIED = 'QUALIFIED',
}
