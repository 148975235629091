import React from 'react';
import { PropsWithChildren } from 'types';

import styles from './ModalActionButtonsContainer.module.scss';

const ModalActionButtonsContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

export default ModalActionButtonsContainer;
