// export const helpdeskTabNames = ['faq', 'tutorials', 'report_issue'] as const;
export const helpdeskTabNames = ['faq', 'report_issue'] as const;

export type HelpdeskTabName = typeof helpdeskTabNames[number];

export interface FaqItemData {
  title: string;
  description: string;
  email?: string;
  link?: string;
}

export interface YoutubeVideoData {
  id: string;
  title: string;
}

export interface ShareYoutubeVideoFormData {
  email: string;
}
