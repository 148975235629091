import React from 'react';
import clsx from 'clsx';

import styles from './List.module.scss';

interface Props {
  children: React.ReactNode;
  listStyleType?: 'none' | 'disc';
  className?: string;
}

const List: React.FC<Props> = ({ children, className, listStyleType = 'disc' }) => (
  <ul className={clsx(className, styles.list, styles?.[`list-style-type-${listStyleType}`])}>
    {children}
  </ul>
);

export default List;
