import React from 'react';
import { Form } from 'components/forms';
import { Main, Flex } from 'components/layout';
import { ProtocolSchemaFileName } from 'app-types';
import { ButtonsContainer } from 'components/containers';
import { PageHeader, HorizontalLine, Button } from 'components/common';
import { limitedLiabilityCompanyProtocolValidationSchema } from 'config';
import {
  ProtocolFormData,
  AgendaContentList,
  RepresentativeDateList,
  RepresentativeRoleInCompanyList,
  ProtocolType,
} from 'types';

import Agenda from '../Agenda';
import Preview from '../Preview';
import Greeter from '../Greeter';
import Chairman from '../Chairman';
import Recorder from '../Recorder';
import Conclusions from '../Conclusions';
import AbsentPerson from '../AbsentPerson';
import Notifications from '../Notifications';
import PowerOfAttorney from '../PowerOfAttorney';
import NumberOfProtocols from '../NumberOfProtocols';
import CompanyRepresentative from '../CompanyRepresentative';

interface Props {
  representativeDateList: RepresentativeDateList[];
  agendaContentList: AgendaContentList[];
  representativeRoleInCompanyList: RepresentativeRoleInCompanyList[];
  onSubmit: (data: ProtocolFormData) => Promise<void>;
  meeting: {
    companyName: string;
    location: string;
    type: string;
  };
}

const LimitedLiabilityCompanyProtocolForm: React.FC<Props> = ({
  representativeDateList,
  agendaContentList,
  representativeRoleInCompanyList,
  onSubmit,
  meeting,
}) => (
  <Main size="medium">
    <Flex gap={4}>
      <PageHeader
        text="protocol.title.limited"
        uppercase
        args={{ name: meeting.companyName, location: meeting.location, type: meeting.type }}
      />
      <Flex gap={10}>
        <Preview fileName={ProtocolSchemaFileName.LimitedLiability} />
        <Form
          submitHandler={onSubmit}
          defaultValues={{
            representativeRoleInCompanyList,
            representativeDateList,
            agendaContentList,
          }}
          validationSchema={limitedLiabilityCompanyProtocolValidationSchema}
        >
          <Flex direction="col" gap={6}>
            <Greeter />
            <HorizontalLine />
            <Notifications />
            <HorizontalLine />
            <PowerOfAttorney />
            <Agenda protocolType={ProtocolType.LIMITED_LIABILITY_COMPANY} />
            <HorizontalLine />
            <CompanyRepresentative />
            <AbsentPerson />
            <HorizontalLine />
            <NumberOfProtocols />
            <HorizontalLine />
            <Chairman />
            <HorizontalLine />
            <Recorder />
            <HorizontalLine />
            <Conclusions />
            <ButtonsContainer>
              <Button text="protocol.generate_protocol" variant="secondary" />
            </ButtonsContainer>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  </Main>
);

export default LimitedLiabilityCompanyProtocolForm;
