import React from 'react';
import clsx from 'clsx';
import { __ } from 'services';

import styles from './FieldErrorMessage.module.scss';

interface Props {
  message: string;
  className?: string;
  errorNowrap?: boolean;
}

const FieldErrorMessage: React.FC<Props> = ({ message, className, errorNowrap }) => (
  <p
    data-ref="error-message"
    role="alert"
    className={clsx(styles.error, className, errorNowrap && styles.nowrap)}
  >
    {__(message)}
  </p>
);

export default FieldErrorMessage;
