import { useEffect, useState } from 'react';

interface OptionsObj {
  interval?: number;
  isStopped?: boolean;
}

type Options = undefined | OptionsObj;
type Callback = () => Promise<unknown> | unknown;

/**
 * @param {Callback} callback - must be memoized (useCallback)
 * @param {Options} options
 * @param options.interval - default 5000 (time in ms)
 * @param options.isStopped - if true stops polling
 */
export const usePolling = (
  callback: Callback,
  { interval, isStopped }: Options = { interval: 5000, isStopped: false },
) => {
  const [id, setId] = useState(0);

  useEffect(() => {
    (async function pollOnMount() {
      await callback();
    })();
  }, [callback]);

  useEffect(() => {
    const currentId = window.setTimeout(async () => {
      if (isStopped) {
        window.clearTimeout(currentId);
        return;
      }
      await callback();
      setId(currentId);
    }, interval);

    return () => {
      window.clearTimeout(currentId);
    };
  }, [id, callback, interval, isStopped]);
};
