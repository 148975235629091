import { useMemo, useState } from 'react';
import { debounce } from 'utils';

type DebouncedState<Value extends unknown> = [Value, (value: Value) => void];

export const useStateDebounced = <Value extends unknown>(
  defaultValue: Value,
  timeInMs: number = 300,
): DebouncedState<Value> => {
  const [state, setState] = useState(defaultValue);
  const setStateDebounced = useMemo(
    () => debounce((v: Value) => setState(v), timeInMs),
    [timeInMs],
  );

  return [state, setStateDebounced];
};
