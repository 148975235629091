import { ReactNode } from 'react';

export interface RadioItem {
  value: string | number;
  label: string;
  checked?: boolean;
}

export type SetPasswordVariant = 'set-new-password' | 'reset-password';

export interface PropsWithChildren {
  children: ReactNode;
}

export interface DropdownItem<Value extends string | number = string> {
  text: string;
  value: Value;
}

export enum MobileBottomBarState {
  VotingMenu = 'VOTING_MENU',
  ShareholdingMenu = 'SHAREHOLDING_MENU',
}

export type VotingResultCardVariants = 'yes' | 'no' | 'abstain';

export type ReportModalVariant = 'change' | 'objection';
