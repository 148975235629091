import React from 'react';
import { Message } from 'components/common';
import { Flex } from 'components/layout';

const AllVotesSend: React.FC = () => (
  <Flex xs={2}>
    <Message type="success" text="pages.meeting.all_votes_sent" />
  </Flex>
);

export default AllVotesSend;
