import { useCallback, useMemo } from 'react';
import { MeetingStatus } from 'app-types';
import { useAppSelector } from './redux';
import { useApi } from './useApi';

const isCorrectMeetingStatus = (status?: MeetingStatus) => {
  if (!status) return false;
  const approvedStatuses = [MeetingStatus.PLANNED, MeetingStatus.IN_PROGRESS, MeetingStatus.PAUSED];

  return approvedStatuses.includes(status);
};

export const useMeetingOnlineStatus = () => {
  const api = useApi(false);
  const { data } = useAppSelector(({ meeting }) => meeting);

  const shouldSendStatus = useMemo(() => isCorrectMeetingStatus(data?.status), [data?.status]);

  const sendOnlineStatus = useCallback(
    async (meetingId: string) => {
      if (!shouldSendStatus) return;

      await api.patch(`/meeting/${meetingId}/online-status`);
    },
    [api, shouldSendStatus],
  );

  return useMemo(
    () => ({
      shouldSendStatus,
      sendOnlineStatus,
    }),
    [shouldSendStatus, sendOnlineStatus],
  );
};
