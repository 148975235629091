import React from 'react';
import { Button } from 'components/common';
import { ModalActionButtonsContainer } from 'components/containers';
import { Form, Input } from 'components/forms';
import { Flex } from 'components/layout';
import { groupMemberValidationSchema } from 'config';
import { GroupMemberFormData, GroupMemberModalVariant } from 'types';
import { CompanyType } from 'app-types';

import SharesGroup from '../SharesGroup';
import RepresentativesGroup from '../RepresentativesGroup';

interface Props {
  variant: GroupMemberModalVariant;
  onSubmit: (formData: GroupMemberFormData) => void;
  onCancel: () => void;
  companyType: CompanyType;
  defaultValues?: GroupMemberFormData;
}

const MemberForm: React.FC<Props> = ({
  variant,
  onSubmit,
  onCancel,
  companyType,
  defaultValues,
}) => (
  <Form
    submitHandler={onSubmit}
    validationSchema={groupMemberValidationSchema}
    defaultValues={defaultValues}
  >
    <Flex direction="col" gap={4}>
      <Input
        name="email"
        label="user.email"
        required
        size="responsive"
        disabled={variant === 'edit_member'}
      />
      <SharesGroup variant={variant} companyType={companyType} />
      <RepresentativesGroup variant={variant} />
      <ModalActionButtonsContainer>
        <Button text={`modals.${variant}.button`} variant="secondary" />
        <Button text="application.cancel" type="button" onClick={onCancel} variant="tertiary" />
      </ModalActionButtonsContainer>
    </Flex>
  </Form>
);

export default MemberForm;
