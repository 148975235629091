import React from 'react';
import { Flex } from 'components/layout';
import { Text } from 'components/common';

interface Props {
  text: string;
}

const MeetingStateInformation: React.FC<Props> = ({ text }) => (
  <Flex direction="col" align="center" justify="center" xs={2}>
    <Text text={text} size={2} align="center" />
  </Flex>
);

export default MeetingStateInformation;
