import React from 'react';
import { FileRes } from 'app-types';
import { Flex } from 'components/layout';

import File from '../File';

interface Props {
  files: Pick<FileRes, 'id' | 'name'>[];
  isClickable?: boolean;
}

const FileList: React.FC<Props> = ({ files, isClickable }) => {
  if (!files.length) return null;

  return (
    <Flex direction="col" gap={1}>
      {files.map((file) => (
        <File key={file.id} data={file} isClickable={isClickable} />
      ))}
    </Flex>
  );
};

export default FileList;
