import { SortingRule } from 'react-table';
import { Row, SortDir, SubscriptionType } from 'app-types';
import { __ } from 'services';
import { ManagementTableItemsData } from 'types';

const getPage = (page: number): string => `${page + 1}`;

const getSortDir = <T extends object>(sortBy: SortingRule<T>[]): SortDir | undefined => {
  if (sortBy?.[0]?.desc === undefined) return undefined;

  return sortBy[0].desc ? 'DESC' : 'ASC';
};

const getSearchQuery = (term: string | undefined): string | undefined => term || undefined;

interface GetTableParamsProps<T extends object> {
  pageIndex: number;
  sortBy: SortingRule<T>[];
  searchTerm?: string;
}

export const getTableParams = <T extends object>({
  pageIndex,
  searchTerm,
  sortBy,
}: GetTableParamsProps<T>) => ({
  page: getPage(pageIndex),
  sortDir: getSortDir(sortBy),
  sortBy: sortBy?.[0]?.id,
  searchQuery: getSearchQuery(searchTerm),
});

export const getManagementTableItemsData = (items: Row[]): ManagementTableItemsData[] => {
  const BREAKABLE_SUBSCRIPTIONS = [SubscriptionType.ANNUALLY_PAYMENT];

  return items.map(({ subscription, ...rest }) => ({
    ...rest,
    subscription: __(`company.subscription.${subscription}`),
    add_permanent_subscription: subscription === SubscriptionType.PENDING,
    block: BREAKABLE_SUBSCRIPTIONS.includes(subscription),
    delete: true,
  }));
};
