import React from 'react';
import { Button } from 'components/common';
import { AllVotesSend, MeetingStateInformation, Vote, VotingResults } from 'components/meeting';
import { useMeetingState } from 'hooks';

interface Props {
  isOpen: boolean;
  openMenu: () => void;
}

const MobileVotingMenuContent: React.FC<Props> = ({ isOpen, openMenu }) => {
  const {
    isUserVoting,
    isMeetingPlanned,
    hasMeetingEnded,
    isMeetingInProgress,
    isMeetingPaused,
    isResolutionVotingState,
    isResumptionVotingState,
    isVotingState,
    hasUserVotedWithAllShares,
    isBreakTime,
  } = useMeetingState();

  // Menu open, user is voting and meeting is in progress and isVotingInProgress
  const isVoteMenuShown =
    isOpen && isUserVoting && isResolutionVotingState && !hasUserVotedWithAllShares;

  const isVoteMenuEnabledShown = isVoteMenuShown && isMeetingInProgress;
  const isVoteMenuDisabledShown = isVoteMenuShown && isMeetingPaused;
  const isResumptionVoteMenuShown =
    isOpen &&
    isUserVoting &&
    isResumptionVotingState &&
    !hasUserVotedWithAllShares &&
    isMeetingInProgress;

  if (isVoteMenuEnabledShown || isVoteMenuDisabledShown || isResumptionVoteMenuShown) {
    return <Vote isMeetingPaused={isMeetingPaused} />;
  }

  // Menu closed, user is voting and meeting is in progress
  const isShowVoteMenuButtonShown =
    !isOpen && isUserVoting && isVotingState && !hasUserVotedWithAllShares;
  const isShowVoteMenuButtonEnabledShown = isShowVoteMenuButtonShown && isMeetingInProgress;
  const isShowVoteMenuButtonDisabledShown = isShowVoteMenuButtonShown && isMeetingPaused;

  if (isShowVoteMenuButtonEnabledShown || isShowVoteMenuButtonDisabledShown) {
    return (
      <Button
        text="pages.meeting.vote"
        variant="secondary"
        onClick={openMenu}
        responsive
        disabled={isMeetingPaused}
      />
    );
  }

  // Menu open, User is voting, meeting and voting is in progress, and user voted with all shares
  const isAllVotesSent = isOpen && isUserVoting && hasUserVotedWithAllShares;
  const isAllVotesSentMenuShown =
    (isAllVotesSent && isMeetingInProgress) || (isAllVotesSent && isMeetingPaused);

  if (isAllVotesSentMenuShown) {
    return <AllVotesSend />;
  }

  // Menu open, meeting in progress, and it's time between voting
  const isResolutionVotingResultsMenuShown = isOpen && isBreakTime;
  if (isResolutionVotingResultsMenuShown) {
    return <VotingResults />;
  }

  // Menu closed, meeting in progress, and it's time between voting
  const isShowVotingResultsMenuButtonShown = !isOpen && isBreakTime;
  if (isShowVotingResultsMenuButtonShown) {
    return (
      <Button text="pages.meeting.show_results" variant="tertiary" onClick={openMenu} responsive />
    );
  }

  // Menu open, user isn't voting on resumption
  const isNotVotingOnResumptionMenuShown =
    (isOpen && !isUserVoting && isResumptionVotingState && isMeetingInProgress) ||
    (isOpen && !isUserVoting && isResumptionVotingState && isMeetingPaused);
  if (isNotVotingOnResumptionMenuShown) {
    return <MeetingStateInformation text="pages.meeting.not_voting_on_resumption" />;
  }

  // Menu open, user isn't voting
  const isNotVotingMenuShown =
    (isOpen && !isUserVoting && isMeetingInProgress) ||
    (isOpen && !isUserVoting && isMeetingPaused);
  if (isNotVotingMenuShown) {
    return <MeetingStateInformation text="pages.meeting.not_voting" />;
  }

  // Menu open, meeting ended
  const isMeetingEndedMenuShown = isOpen && hasMeetingEnded;
  if (isMeetingEndedMenuShown) {
    return <MeetingStateInformation text="pages.meeting.meeting_ended" />;
  }

  // Menu open, meeting not in progress
  const isMeetingDidntStartedMenuShown = isOpen && isMeetingPlanned;
  if (isMeetingDidntStartedMenuShown) {
    return <MeetingStateInformation text="pages.meeting.meeting_didnt_started" />;
  }

  return null;
};

export default MobileVotingMenuContent;
