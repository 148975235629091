import React, { useState } from 'react';
import { CompaniesDiffs, DiffsProperties, EditCompanyReq } from 'app-types';
import { Button, SectionHeader, Text } from 'components/common';
import { ModalActionButtonsContainer } from 'components/containers';
import { Flex } from 'components/layout';
import { CompareTable } from 'components/tables';
import { useAppDispatch, useAppSelector } from 'hooks';
import { hideModal, setOutputData } from 'slices';

// Sets default selected data to be data coming from our db
const getDefaultState = (data: CompaniesDiffs[]): Partial<EditCompanyReq> =>
  data.reduce((prev, curr) => ({ ...prev, [curr.property]: curr.dbCompany }), {});

/**
 * If there is nested key e.g. initialCapital.value it shapes object to be actually nested
 * For example { initialCapital: { value: 'some value' } }
 * instead of { 'initialCapital.value': 'some value' }
 */
const shapeNestedData = (data: Record<DiffsProperties, string>): Partial<EditCompanyReq> =>
  Object.entries(data).reduce<Partial<EditCompanyReq>>((prev, [key, value]) => {
    const [firstKey, nestedKey] = key.split('.', 2);

    if (nestedKey) {
      const nestedObj =
        typeof prev[firstKey as keyof EditCompanyReq] === 'object'
          ? prev[firstKey as keyof EditCompanyReq]
          : {};

      return {
        ...prev,
        [firstKey]: {
          ...nestedObj,
          [nestedKey]: value,
        },
      };
    }

    return { ...prev, [key]: value };
  }, {});

const DataChanged: React.FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(({ modal }) => modal.data as CompaniesDiffs[]);
  const [compareData, setCompareData] = useState(getDefaultState(data));

  const onAccept = () => {
    const nestedData = shapeNestedData(compareData);

    dispatch(setOutputData(nestedData));
    dispatch(hideModal());
  };

  return (
    <Flex direction="col" gap={2}>
      <SectionHeader text="modals.edit_company.title" />
      <Text size={3} text="modals.edit_company.description" />
      <CompareTable data={data} state={[compareData, setCompareData]} />
      <ModalActionButtonsContainer>
        <Button
          text="placeholders.provide_data"
          type="button"
          variant="secondary"
          onClick={onAccept}
        />
        <Button
          text="application.cancel"
          type="button"
          onClick={() => dispatch(hideModal())}
          variant="tertiary"
        />
      </ModalActionButtonsContainer>
    </Flex>
  );
};

export default DataChanged;
