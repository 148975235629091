import React from 'react';
import { Text } from 'components/common';
import { Flex, TextCol } from 'components/layout';
import { CompanyValue } from 'types';

interface Props {
  title: string;
  data: CompanyValue[];
}

const DashboardCardSection: React.FC<Props> = ({ title, data }) => (
  <Flex direction="col">
    <Text size={1} uppercase opacity={5} weight="bold" text={title} />
    {data.map(({ text, value }) => (
      <TextCol key={text}>
        <Text weight="bold" size={2} text={text} />
        <Text shouldTranslate={false} size={2} text={value} />
      </TextCol>
    ))}
  </Flex>
);

export default DashboardCardSection;
