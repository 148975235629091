import { PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorCode } from 'app-types';
import { BlockchainErrorCode } from './blockchain';

export enum NotificationType {
  Info = 'INFO',
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export interface SingleNotification {
  type: NotificationType;
  text: string;
  id: string;
}

export type NotificationsState = SingleNotification[];

export type ErrorNotification = PayloadAction<ApiErrorCode | BlockchainErrorCode>;

export type OtherNotification = PayloadAction<string>;
