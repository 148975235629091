import React, { useMemo } from 'react';
import clsx from 'clsx';
import { VotingShareholder } from 'app-types';
import { InlineButton, LineProgressbar, Text } from 'components/common';
import { Flex } from 'components/layout';
import { useAppDispatch } from 'hooks';
import { prepareModal } from 'slices';
import { ModalType, VotingResultCardVariants } from 'types';

import styles from './VotingResultCard.module.scss';

interface Props {
  variant: VotingResultCardVariants;
  votesFor: number;
  totalVotes: number;
  hasMajority: boolean;
  voters?: VotingShareholder[];
}

const progressbarColors = {
  yes: 'primary' as const,
  no: 'vote-no' as const,
  abstain: 'action' as const,
};

const getPercentage = (val: number, totalVal: number) => {
  if (Number.isNaN(val / totalVal)) return '0.0000%';
  return `${((val / totalVal) * 100).toFixed(4)}%`;
};

const VotingResultCard: React.FC<Props> = ({
  variant,
  votesFor,
  totalVotes,
  hasMajority,
  voters,
}) => {
  const dispatch = useAppDispatch();
  const percentageValue = useMemo(
    () => getPercentage(votesFor, totalVotes),
    [votesFor, totalVotes],
  );

  const openShowVotesModal = () => {
    dispatch(
      prepareModal({
        type: ModalType.ShowVotes,
        data: { variant, voters: voters! },
        isVisible: true,
      }),
    );
  };

  return (
    <div className={clsx(styles.container, { [styles?.[`majority-${variant}`]]: hasMajority })}>
      <Flex justify="space-between" align="center">
        <Text text={`application.${variant}`} weight="bold" size={3} />
        <Text text={percentageValue} shouldTranslate={false} weight="bold" size={3} />
      </Flex>
      <LineProgressbar value={votesFor} goal={totalVotes} color={progressbarColors[variant]} />
      <InlineButton
        text="pages.meeting.votes_for"
        translationArgs={{ votesFor }}
        onClick={voters ? openShowVotesModal : undefined}
        fontWeight="normal"
        variant="vote-result"
      />
    </div>
  );
};

export default VotingResultCard;
