import React, { useCallback } from 'react';
import PaginationButton from './PaginationButton';
import PaginationDots from './PaginationDots';
import { usePagination } from './usePagination';
import styles from './TablePagination.module.scss';

interface Props {
  pageCount: number;
  pageIndex: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
}

const TablePagination = ({ pageCount, pageIndex, gotoPage }: Props) => {
  const pageRange = usePagination({
    pageCount,
    currentPage: pageIndex,
  });

  const previousPage = useCallback(() => gotoPage(pageIndex - 1), [gotoPage, pageIndex]);

  const nextPage = useCallback(() => gotoPage(pageIndex + 1), [gotoPage, pageIndex]);

  return (
    <div className={styles.wrapper}>
      <PaginationButton iconName="arrow-left" disabled={pageIndex === 0} onClick={previousPage} />
      {pageRange.map((pageNumber, i) => {
        if (typeof pageNumber === 'string') {
          const key = pageNumber + i;

          return <PaginationDots key={key} />;
        }

        return (
          <PaginationButton
            isActive={pageNumber === pageIndex}
            key={pageNumber}
            onClick={() => gotoPage(pageNumber)}
          >
            {pageNumber + 1}
          </PaginationButton>
        );
      })}
      <PaginationButton
        iconName="arrow-right"
        disabled={pageIndex + 1 === pageCount}
        onClick={nextPage}
      />
    </div>
  );
};

export default TablePagination;
