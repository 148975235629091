import React, { useId, useMemo } from 'react';
import clsx from 'clsx';
import { Flex } from 'components/layout';
import { __ } from 'services';

import styles from './LineProgressbar.module.scss';

interface Props {
  value: number;
  goal: number;
  color?: 'success' | 'primary' | 'vote-no' | 'action';
  label?: string;
  translationArgs?: Record<string, string | number>;
}

const LineProgressbar: React.FC<Props> = ({
  label,
  translationArgs,
  value,
  goal,
  color = 'success',
}) => {
  const id = useId();

  const percentage = useMemo(() => {
    if (!goal || !value) return 0;
    if (value >= goal) return 100;
    return (value / goal) * 100;
  }, [value, goal]);

  return (
    <Flex direction="col" gap={1}>
      {label && (
        <label className={styles.label} htmlFor={`progressbar-${id}`}>
          {__(label, translationArgs)}
        </label>
      )}
      <div id={`progressbar-${id}`} className={styles.track}>
        <div
          className={clsx(styles.progress, styles?.[`color-${color}`])}
          style={{ transform: `scaleX(${percentage}%)` }}
        />
      </div>
    </Flex>
  );
};

export default LineProgressbar;
