import React from 'react';
import { ExcludedGroupsVotersForm } from 'types';
import { Text } from 'components/common';

interface Props {
  participantsGroup: ExcludedGroupsVotersForm;
}

const CustomCover: React.FC<Props> = ({ participantsGroup }) => (
  <Text text={participantsGroup.name} shouldTranslate={false} />
);

export default CustomCover;
