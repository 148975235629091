import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import {
  ApiErrorCode,
  CompanyDashboardRes,
  CompanyShareholdingRes,
  Formality,
  GetMeetingDetailsRes,
  OperationStatus,
  Status,
} from 'app-types';
import { callApi } from 'services';
import {
  AddMeetingMoveCard,
  AddMeetingStages,
  DashboardState,
  VotingParticipantsForm,
} from 'types';
import { isApiErrorMessage, isNativeNestError } from 'utils';

import {
  filterOnlyParticipatingGroups,
  mapToNewMeetingForm,
  addDefaultValuesToVotingParticipantsForm,
  mapToVotingParticipantsFormGroupsFromShareholdingGroupArr,
  mapToExcludedVotersForm,
  filterExcludedVoters,
  filterOutAuthorizationFiles,
} from './utils';

const initialAddMeetingState = { stage: AddMeetingStages.NewMeeting, agenda: [] };

const initialState: DashboardState = {
  addMeeting: initialAddMeetingState,
  isSubscriptionPaid: false,
  isLoading: false,
};

interface FetchDashboardDataProps {
  companyId: string;
  onForbiddenResource?: () => void;
  onBlockedCompany?: () => void;
}

export const fetchDashboardData = createAsyncThunk<
  CompanyDashboardRes | {},
  FetchDashboardDataProps
>(
  'dashboard/fetchCompanyData',
  async ({ companyId, onForbiddenResource, onBlockedCompany }, { dispatch }) =>
    callApi(
      dispatch,
      'GET',
      `/company/${companyId}/dashboard`,
      undefined,
      undefined,
      (error, res) => {
        if (isApiErrorMessage(error) && error.error_code === ApiErrorCode.ForbiddenResource) {
          onForbiddenResource?.();
          return;
        }

        if (
          isNativeNestError(error) &&
          error?.message === ApiErrorCode.NoSuchSubscription &&
          res?.status === 403
        ) {
          onBlockedCompany?.();
        }
      },
    ),
);

export const fetchShareholdingData = createAsyncThunk<CompanyShareholdingRes | {}, string>(
  'dashboard/fetchShareholdingData',
  async (companyId, { dispatch }) => callApi(dispatch, 'GET', `/company/${companyId}/shareholding`),
);

export const fetchAddMeetingData = createAsyncThunk<GetMeetingDetailsRes | {}, string>(
  'dashboard/fetchAddMeetingData',
  (meetingId, { dispatch }) =>
    callApi<GetMeetingDetailsRes>(dispatch, 'GET', `/meeting/${meetingId}`),
);

export const fetchPaymentStatus = createAsyncThunk<OperationStatus | {}>(
  'dashboard/fetchPaymentStatus',
  async (_, { dispatch }) => callApi(dispatch, 'GET', '/subscription/status'),
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setNewMeetingDefaults(state) {
      const participants =
        state.shareholding?.groups.map(({ id, name }) => ({
          id,
          name,
          isChecked: false,
        })) ?? [];

      state.addMeeting.newMeeting = {
        type: '',
        customMeetingType: '',
        formality: Formality.FORMAL,
        date: new Date().toString(),
        time: '',
        notary: '',
        quorum: null,
        participants,
      };
    },
    resetAddMeetingState(state) {
      state.addMeeting = initialAddMeetingState;
    },
    setAddMeetingStage(state, { payload }: PayloadAction<AddMeetingStages>) {
      state.addMeeting.stage = payload;
    },
    addMeetingMoveCard(
      state,
      { payload: { dragIndex, hoverIndex } }: PayloadAction<AddMeetingMoveCard>,
    ) {
      const { agenda } = state.addMeeting;

      state.addMeeting.agenda = update(agenda, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, agenda[dragIndex]],
        ],
      });
    },
    setAddMeetingFilteredExcludedVoters(state, { payload: searchTerm }: PayloadAction<string>) {
      if (!state.addMeeting.excludedGroupsVotersForm?.form) return;

      if (!searchTerm) {
        state.addMeeting.excludedGroupsVotersForm.view =
          state.addMeeting.excludedGroupsVotersForm.form;
        return;
      }

      state.addMeeting.excludedGroupsVotersForm.view = filterExcludedVoters(
        state.addMeeting.excludedGroupsVotersForm.form,
        searchTerm,
      );
    },
    setVotingId(state, { payload: votingId }: PayloadAction<string>) {
      if (!votingId || !state.addMeeting.excludedGroupsVotersForm) return;

      state.addMeeting.excludedGroupsVotersForm.votingId = votingId;
    },
    setLoading(state, { payload: status }: PayloadAction<boolean>) {
      state.isLoading = status;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchDashboardData.fulfilled, (state, { payload }) => {
        if (!('company' in payload) || !payload?.company) return;

        state.company = payload.company;
        state.draftMeetings = payload.draftMeetings;
        state.plannedMeetings = payload.plannedMeetings;
      })
      .addCase(fetchShareholdingData.fulfilled, (state, { payload }) => {
        if (!('shareholding' in payload)) return;

        state.shareholding = payload.shareholding;
      })
      .addCase(fetchAddMeetingData.fulfilled, (state, { payload }) => {
        if (!('id' in payload) || !state.shareholding?.groups) return;

        const newMeeting = mapToNewMeetingForm(payload, state.shareholding.groups);
        const voters = [...payload.usersVoters, ...payload.companiesVoters];
        const areVotersUnset = !voters.length;
        const votingParticipantsView = filterOnlyParticipatingGroups(
          state.shareholding.groups,
          newMeeting.participants,
        );

        const defaultParticipantsForm: VotingParticipantsForm = {
          groups: mapToVotingParticipantsFormGroupsFromShareholdingGroupArr(votingParticipantsView),
          pdf: filterOutAuthorizationFiles(payload.attachments),
        };

        const votingParticipantsForm = areVotersUnset
          ? defaultParticipantsForm
          : addDefaultValuesToVotingParticipantsForm(defaultParticipantsForm, voters);
        const shareHoldingGroup = [...state.shareholding.groups];

        const excludedVotersForm = mapToExcludedVotersForm(
          payload.participatingGroups,
          payload.companiesVoters,
          payload.usersVoters,
          shareHoldingGroup,
        );

        state.addMeeting.excludedGroupsVotersForm = {
          view: excludedVotersForm,
          form: excludedVotersForm,
        };

        if (!votingParticipantsView.length) {
          state.addMeeting.stage = AddMeetingStages.NewMeeting;
        } else if (areVotersUnset) {
          state.addMeeting.stage = AddMeetingStages.VotingParticipants;
        } else {
          state.addMeeting.stage = AddMeetingStages.Agenda;
        }

        state.addMeeting.newMeeting = newMeeting;
        state.addMeeting.votingParticipants = {
          view: votingParticipantsView,
          form: votingParticipantsForm,
        };
        state.addMeeting.agenda = payload.agenda.items;
        state.addMeeting.status = payload.status;
      })
      .addCase(fetchPaymentStatus.fulfilled, (state, { payload }) => {
        if (!('status' in payload)) return;

        if (payload.status === Status.SUCCESS) {
          state.isSubscriptionPaid = true;
        }
      }),
});

export const {
  addMeetingMoveCard,
  setNewMeetingDefaults,
  resetAddMeetingState,
  setAddMeetingStage,
  setAddMeetingFilteredExcludedVoters,
  setVotingId,
  setLoading,
} = dashboardSlice.actions;
