import React from 'react';
import { PropsWithChildren } from 'types';
import { Flex } from 'components/layout';

import styles from './VotingResultsContainer.module.scss';

const VotingResultsContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex className={styles.container} gap={3} direction="col">
    {children}
  </Flex>
);

export default VotingResultsContainer;
