import React, { useId } from 'react';
import clsx from 'clsx';
import { Icon, Text } from 'components/common';

import styles from './FileInput.module.scss';

interface CustomProps {
  name: string;
  label: string;
  onChange: (files: FileList | null) => void;
}

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & CustomProps;

const FileInput: React.FC<Props> = ({ name, className, label, onChange, ...inputProps }) => {
  const id = useId();

  return (
    <div className={styles.container}>
      <input
        {...inputProps}
        id={`${id}-${name}`}
        type="file"
        className={clsx(className, styles.input)}
        onChange={(e) => onChange(e.target.files)}
      />
      <label className={styles.label} htmlFor={`${id}-${name}`}>
        <Icon name="import" />
        <Text
          text={label}
          className={styles.text}
          size={2}
          color="action"
          weight="bold"
          align="center"
        />
      </label>
    </div>
  );
};

export default FileInput;
