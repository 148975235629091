import React from 'react';
import pl from 'date-fns/locale/pl';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CONFIG } from 'config';

import CustomInput from './CustomInput';

registerLocale('pl', pl);

interface Props {
  name: string;
  minDate?: Date;
  maxDate?: Date;
  required?: boolean;
  label?: string;
}

const DateInput: React.FC<Props> = ({ name, minDate, maxDate, required, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        const parsedValue = value ? new Date(value) : value;
        return (
          <div>
            <DatePicker
              dateFormat="dd.MM.yyyy"
              customInput={
                <CustomInput fName={name} label={label} required={required} errors={errors} />
              }
              selected={parsedValue}
              onChange={onChange}
              minDate={minDate}
              maxDate={maxDate}
              locale={CONFIG.DEFAULT_LANG}
            />
          </div>
        );
      }}
    />
  );
};

export default DateInput;
