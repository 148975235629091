import React from 'react';
import clsx from 'clsx';
import { PropsWithChildren } from 'types';

import styles from './DesktopShareholderCardContainer.module.scss';

interface Props {
  multiple?: boolean;
}

const DesktopShareholderCardContainer: React.FC<PropsWithChildren & Props> = ({
  children,
  multiple,
}) => <div className={clsx(styles.container, { [styles.multiple]: multiple })}>{children}</div>;

export default DesktopShareholderCardContainer;
