import React from 'react';
import { Button, PageSubheader } from 'components/common';
import { Input } from 'components/forms';
import { Row } from 'components/layout';
import { CompanyFormVariant } from 'types';

interface Props {
  variant: CompanyFormVariant;
  onGetKrsData?: () => void;
}

const Identifiers: React.FC<Props> = ({ variant = 'add-company', onGetKrsData }) => {
  if (variant === 'edit-company' && onGetKrsData) {
    return (
      <Row col gap={1}>
        <PageSubheader text="company.identifiers" />
        <Row gap={2} alignFe>
          <Input name="krs" label="company.krs_number" size="normal" required />
          <Input name="nip" label="company.nip_number" size="normal" required disabled />
          <Input name="regon" label="company.regon_number" size="normal" required />
          <Button
            text="pages.edit_company.update_krs_data"
            type="button"
            variant="secondary"
            onClick={onGetKrsData}
          />
        </Row>
      </Row>
    );
  }

  return (
    <Row col gap={1}>
      <PageSubheader text="company.identifiers" />
      <Row gap={2}>
        <Input name="krs" label="company.krs_number" size="normal" required />
        <Input name="nip" label="company.nip_number" size="normal" required />
        <Input name="regon" label="company.regon_number" size="normal" required />
      </Row>
    </Row>
  );
};

export default Identifiers;
