import React from 'react';
import { SetResumptionDurationReq } from 'app-types';
import { Text } from 'components/common';
import { DeleteModalContentContainer } from 'components/containers';
import { ManageResumptionForm } from 'components/forms';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { fetchMeetingData, successNotification } from 'slices';
import { ManageResumptionData, ManageResumptionFormData } from 'types';

const ManageResumption: React.FC = () => {
  const {
    hideModal,
    data: { resolutionId, meetingId },
  } = useModal<undefined, ManageResumptionData>();
  const api = useApi();
  const dispatch = useAppDispatch();

  const onAccept = async (formData: ManageResumptionFormData) =>
    api.post<void, SetResumptionDurationReq>(
      `/meeting/${meetingId}/resolution/${resolutionId}/resumption`,
      formData,
      () => {
        dispatch(fetchMeetingData({ meetingId }));
        dispatch(successNotification('notifications.managed_resumption'));
        hideModal();
      },
    );

  return (
    <DeleteModalContentContainer>
      <Text text="modals.manage_resumption.title" size={4} weight="bold" />
      <ManageResumptionForm onSubmit={onAccept} onCancel={hideModal} />
    </DeleteModalContentContainer>
  );
};

export default ManageResumption;
