import React from 'react';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { EditGroupMemberData, EditGroupSubjectData } from 'types';
import { Button, Text } from 'components/common';
import { DeleteModalContentContainer, ModalActionButtonsContainer } from 'components/containers';
import { getFullName } from 'utils';
import { fetchShareholdingData, successNotification } from 'slices';

const DeleteMember: React.FC = () => {
  const { hideModal, data } = useModal<undefined, EditGroupSubjectData | EditGroupMemberData>();

  const dispatch = useAppDispatch();
  const api = useApi();

  const name =
    'names' in data.member
      ? getFullName({ ...data.member, placeholder: data.member.email })
      : data.member.name;

  const onAccept = async () => {
    if ('names' in data.member) {
      await api.delete(
        `/company/${data.companyId}/shareholding/groups/${data.group.id}/shareholders/users/${data.member.id}`,
        () => {
          dispatch(fetchShareholdingData(data.companyId));
          dispatch(successNotification('notifications.deleted_shareholder'));
          hideModal();
        },
      );
      return;
    }

    await api.delete(
      `/company/${data.companyId}/shareholding/groups/${data.group.id}/shareholders/companies/${data.member.id}`,
      () => {
        dispatch(fetchShareholdingData(data.companyId));
        dispatch(successNotification('notifications.deleted_shareholder'));
        hideModal();
      },
    );
  };

  return (
    <DeleteModalContentContainer>
      <Text text="modals.delete_member.title" translationArgs={{ name }} size={4} weight="bold" />
      <ModalActionButtonsContainer>
        <Button text="application.yes" variant="secondary" onClick={onAccept} />
        <Button text="application.no" variant="tertiary" onClick={hideModal} />
      </ModalActionButtonsContainer>
    </DeleteModalContentContainer>
  );
};

export default DeleteMember;
