import React from 'react';
import { Text } from 'components/common';
import { Flex } from 'components/layout';

interface ErrorType {
  email: string;
  additionalInfo: string[];
}

const Error: React.FC<ErrorType> = ({ email, additionalInfo }) => (
  <Flex direction="col" gap={1}>
    {email && (
      <Text
        text="modals.csv_error.email"
        translationArgs={{ email }}
        weight="bold"
        whiteSpace="break-spaces"
      />
    )}
    {React.Children.toArray(
      additionalInfo.map((item) => <Text text={`modals.csv_error.errors.${item}`} />),
    )}
  </Flex>
);

export default Error;
