import React from 'react';
import { InlineButton } from 'components/common';
import { ProtocolSchemaFileName } from 'app-types';
import { useFileDownload } from 'hooks';

interface Props {
  fileName: ProtocolSchemaFileName;
}

const Preview: React.FC<Props> = ({ fileName }) => {
  const { downloadProtocolPreview } = useFileDownload();

  const downloadFile = async () => downloadProtocolPreview({ fileName });

  return (
    <InlineButton
      text="protocol.preview"
      iconName="preview"
      onClick={downloadFile}
      variant="protocol-preview"
    />
  );
};

export default Preview;
