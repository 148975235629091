import { UserRole } from 'app-types';
import { AllRoutes, CONFIG, createPath } from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { useState } from 'react';
import { logoutUser } from 'slices';
import Button from '../Button';
import IconButton from '../IconButton';
import styles from './Menu.module.scss';

interface Props {}

type MenuElement = {
  id: number;
  text: string;
  route: AllRoutes;
  isCompany?: boolean;
};

const MENU_ELEMENTS: MenuElement[] = [
  {
    id: 0,
    text: 'menu.profile',
    route: AllRoutes.Profile,
  },
  {
    id: 1,
    text: 'menu.my-company',
    route: AllRoutes.AdminCompanyEditCompany,
    isCompany: true,
  },
  {
    id: 2,
    text: 'menu.create-meeting',
    route: AllRoutes.AdminCompanyAddMeeting,
    isCompany: true,
  },
  {
    id: 3,
    text: 'menu.planned-meetings',
    route: AllRoutes.AdminCompanyPlannedMeetings,
    isCompany: true,
  },
  {
    id: 4,
    text: 'menu.draft-meetings',
    route: AllRoutes.AdminCompanyDraftMeetings,
    isCompany: true,
  },
  {
    id: 5,
    text: 'menu.archive',
    route: AllRoutes.AdminCompanyArchive,
    isCompany: true,
  },
  {
    id: 6,
    text: 'menu.add-company',
    route: AllRoutes.AddCompany,
  },
];

const Menu: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { role, id } = useAppSelector(({ user }) => user);
  const isAuthenticated = id !== undefined;
  const isAdminAuthenticated = role?.includes(UserRole.Admin);
  const { companies } = useAppSelector(({ user }) => user);

  const onMenuElementClick = () => {
    setIsOpen(false);
  };

  const onLogout = () => {
    dispatch(logoutUser());
  };
  const toggleOpen = () => setIsOpen((open) => !open);

  if (!isAuthenticated) {
    return <div className={styles.spacing} />;
  }

  return (
    <>
      <IconButton iconName="menu" onClick={toggleOpen} className={styles.menu} />
      {isOpen && (
        <div className={styles.popup}>
          {MENU_ELEMENTS.map((element) =>
            !element.isCompany || (element.isCompany && isAdminAuthenticated) ? (
              <Button
                to={createPath({ path: element.route, params: { companyId: companies?.[0]?.id } })}
                key={element.id}
                text={element.text}
                onClick={onMenuElementClick}
              />
            ) : null,
          )}
          <Button
            text="menu.regulations"
            target="_blank"
            to={`${CONFIG.BASE_URL}/regulation/TERMS_OF_SERVICE`}
            variant="primary"
          />
          <Button text="application.logout" onClick={onLogout} variant="secondary" />
        </div>
      )}
    </>
  );
};

export default Menu;
