import React from 'react';
import { Flex } from 'components/layout';
import { Icon, Spinner, Text } from 'components/common';

import styles from './Message.module.scss';

interface Props {
  type: 'success' | 'information' | 'error' | 'loading';
  text: string;
  translationArgs?: Record<string, string | number>;
}

const textColors = {
  success: 'success' as const,
  information: 'action' as const,
  error: 'error' as const,
  loading: 'primary' as const,
};

const Message: React.FC<Props> = ({ type, text, translationArgs }) => (
  <Flex className={styles?.[`color-${type}`]} gap={1} align="center" justify="center">
    {type === 'loading' ? <Spinner transparent small /> : <Icon name={type} />}
    <Text
      text={text}
      translationArgs={translationArgs}
      size={2}
      align="center"
      color={textColors[type]}
    />
  </Flex>
);

export default Message;
