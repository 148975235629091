import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'components/common';
import { SharesWithId, VoteOption } from 'types';

interface Props {
  shares: SharesWithId;
}

type Colors = 'sixth' | 'error' | 'success';
type FormValues = Partial<Record<VoteOption, string>>;

const getColor = (v1: number, v2: number): Colors => {
  if (v1 === v2) {
    return 'success';
  }
  if (v1 > v2) {
    return 'error';
  }
  return 'sixth';
};

const sumArrSafe = (arr: unknown[]) =>
  arr.reduce<number>((acc, v) => {
    const numV = Number(v);
    return Number.isNaN(numV) ? acc : acc + numV;
  }, 0);

const VotesUsed: React.FC<Props> = ({ shares: { id, value } }) => {
  const { watch } = useFormContext();
  const formValues: FormValues = watch(id);

  const firstVal = useMemo(
    () => sumArrSafe([formValues?.inFavour, formValues?.against, formValues?.abstain]),
    [formValues?.inFavour, formValues?.abstain, formValues?.against],
  );

  return (
    <Text
      text="modals.split_votes.votes_used"
      translationArgs={{ votes: `${firstVal}/${value}` }}
      size={2}
      color={getColor(firstVal, value)}
      truncate
    />
  );
};

export default VotesUsed;
