import React, { useEffect } from 'react';
import { DropdownItem } from 'types';
import { HelpdeskIssueCategory, TechnicalProblemSubcategory } from 'app-types';
import { useFormContext } from 'react-hook-form';
import { Dropdown } from 'components/forms';

const dropdownItems: DropdownItem[] = [
  {
    text: 'helpdesk_issue.subcategories.cant_add_shareholder_to_voting',
    value: TechnicalProblemSubcategory.CantAddShareholderToVoting,
  },
  {
    text: 'helpdesk_issue.subcategories.krs_data_is_incorrect',
    value: TechnicalProblemSubcategory.KRSDataIsIncorrect,
  },
  {
    text: 'helpdesk_issue.subcategories.company_already_added',
    value: TechnicalProblemSubcategory.CompanyAlreadyAdded,
  },
];

const TechnicalProblemSubcategoryDropdown: React.FC = () => {
  const { watch, setValue } = useFormContext();

  const category: HelpdeskIssueCategory = watch('category');

  useEffect(() => {
    const isTechnicalProblemCategory = category === HelpdeskIssueCategory.TechnicalProblem;
    if (!isTechnicalProblemCategory) {
      setValue('subcategory', null);
    }
  }, [category, setValue]);

  if (category !== HelpdeskIssueCategory.TechnicalProblem) return null;

  return (
    <Dropdown
      name="subcategory"
      options={dropdownItems}
      label="helpdesk_issue.subcategory"
      size="big"
    />
  );
};

export default TechnicalProblemSubcategoryDropdown;
