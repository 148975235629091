import React from 'react';
import { AddShareholdingGroupReq } from 'app-types';
import { Button } from 'components/common';
import { ModalActionButtonsContainer } from 'components/containers';
import { Form, Input } from 'components/forms';
import { Flex } from 'components/layout';
import { addOrEditGroupValidationSchema } from 'config';
import { GroupModalVariant } from 'types';

interface Props {
  variant: GroupModalVariant;
  onSubmit: (reqData: AddShareholdingGroupReq) => void;
  onCancel: () => void;
  defaultValues?: AddShareholdingGroupReq;
}

const GroupForm: React.FC<Props> = ({ variant, onSubmit, onCancel, defaultValues }) => (
  <Form
    validationSchema={addOrEditGroupValidationSchema}
    submitHandler={onSubmit}
    defaultValues={defaultValues}
  >
    <Flex direction="col" gap={6}>
      <Input name="name" label="group.name" size="responsive" required />
      <ModalActionButtonsContainer>
        <Button text={`modals.${variant}.title`} variant="secondary" />
        <Button text="application.cancel" type="button" onClick={onCancel} variant="tertiary" />
      </ModalActionButtonsContainer>
    </Flex>
  </Form>
);

export default GroupForm;
