import React from 'react';
import { CellProps } from 'react-table';
import { useApi, useAppDispatch } from 'hooks';
import { __ } from 'services';
import { successNotification } from 'slices';
import { ManagementTableItemsData, ManagementTableParams } from 'types';

import { getTableParams } from '../utils';
import styles from './ActionCell.module.scss';

interface CustomProps {
  reloadData?: (params: ManagementTableParams) => void;
  searchTerm?: string;
}

type Props = CellProps<ManagementTableItemsData, boolean> & CustomProps;

const isDesiredVariant = (variant: string) => {
  const desiredVariants = ['block', 'delete', 'add_permanent_subscription'];

  return desiredVariants.includes(variant);
};

const ActionCell: React.FC<Props> = ({
  cell,
  column,
  row,
  reloadData,
  searchTerm,
  state: { pageIndex, sortBy },
}) => {
  const api = useApi();
  const dispatch = useAppDispatch();

  if (!cell.value || !isDesiredVariant(column.id)) return null;

  const onClick = async () => {
    const params: ManagementTableParams = getTableParams({ pageIndex, sortBy, searchTerm });

    if (column.id === 'delete') {
      await api.delete(`/panel/company/${row.original.id}`, async () => {
        await reloadData?.(params);
        dispatch(successNotification('notifications.deleted_company'));
      });
      return;
    }

    if (column.id === 'add_permanent_subscription') {
      await api.patch(
        `/panel/company/${row.original.id}/permanent-subscription`,
        undefined,
        async () => {
          await reloadData?.(params);
          dispatch(successNotification('notifications.added_permanent_subscription'));
        },
      );
      return;
    }

    await api.delete(`/panel/company/${row.original.id}/subscription`, async () => {
      await reloadData?.(params);
      dispatch(successNotification('notifications.canceled_company_subscription'));
    });
  };

  return (
    <button className={styles.button} onClick={onClick}>
      {__(`application.${column.id}`)}
    </button>
  );
};

export default ActionCell;
