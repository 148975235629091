import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Input from './Input';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  disabled?: boolean;
}

const TimeInput: React.FC<Props> = ({ name, required, label, disabled }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <Input
          name={name}
          label={label}
          required={required}
          // prevents undefined -> defined error
          value={value ?? ''}
          disabled={disabled}
          onChange={onChange}
          errors={errors}
        />
      )}
    />
  );
};

export default TimeInput;
