import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { UserRole } from 'app-types';
import { setRedirectFrom } from 'slices';
import { useAppDispatch } from './redux';

export const useRedirectedFrom = (isRedirectedFromSet: boolean, requiredRole?: UserRole) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!isRedirectedFromSet) return;
    dispatch(setRedirectFrom({ redirectedFrom: location.pathname, requiredRole }));
  }, [dispatch, location.pathname, isRedirectedFromSet, requiredRole]);
};
