import React, { useMemo } from 'react';
import { CastVoterVotesReq, MeetingCurrentState, OperationStatus } from 'app-types';
import { Text } from 'components/common';
import { VoteContainer } from 'components/containers';
import { VoteForm } from 'components/forms';
import { Flex } from 'components/layout';
import { useApi, useAppDispatch, useAppSelector } from 'hooks';
import { fetchMeetingData, successNotification } from 'slices';
import { VoteFormData } from 'types';
import { getName, isResolutionVotingState, isVotingState, mapToCastVoterVotesReq } from 'utils';

import VoteActionButtons from './VoteActionButtons';

interface Props {
  isMeetingPaused: boolean;
}

interface Title {
  titleText: string;
  shouldTranslateTitle: boolean;
  titleTranslationArgs?: Record<string, string>;
}

const getTitle = (state?: MeetingCurrentState): Title => {
  // never rendered case
  if (!isVotingState(state)) return { titleText: '', shouldTranslateTitle: false };

  // resolution case
  if (isResolutionVotingState(state))
    return { titleText: state.resolution.title, shouldTranslateTitle: false };

  // resumption case
  return {
    titleText: 'pages.meeting.resumption_title',
    shouldTranslateTitle: true,
    titleTranslationArgs: { title: state.resolution.title },
  };
};

const Vote: React.FC<Props> = ({ isMeetingPaused }) => {
  const dispatch = useAppDispatch();
  const { state, data: meetingData } = useAppSelector(({ meeting }) => meeting);
  const api = useApi();

  const { titleText, titleTranslationArgs, shouldTranslateTitle } = useMemo(
    () => getTitle(state),
    [state],
  );

  if (!isVotingState(state)) return null;

  const onVoteSubmit = async (formValues: VoteFormData) => {
    const [firstShareholder] = state.shareholders;
    if (!firstShareholder || !meetingData) return;

    const reqData = mapToCastVoterVotesReq(formValues, firstShareholder.shares);

    if (isResolutionVotingState(state)) {
      await api.post<OperationStatus, CastVoterVotesReq>(
        `/voting/${state.resolution.voting.id}/shareholder/${firstShareholder.id}/votes/${meetingData.id}`,
        reqData,
        async () => {
          await dispatch(fetchMeetingData({ meetingId: meetingData.id }));
          await dispatch(successNotification('notifications.vote_sent'));
        },
      );
      return;
    }

    await api.post<OperationStatus, CastVoterVotesReq>(
      `/meeting/${meetingData.id}/resumption/${state.resumption.id}/shareholder/${firstShareholder.id}/votes`,
      reqData,
      async () => {
        await dispatch(fetchMeetingData({ meetingId: meetingData.id }));
        await dispatch(successNotification('notifications.vote_sent'));
      },
    );
  };

  return (
    <VoteContainer>
      <Flex gap={1}>
        <Text text="pages.meeting.voting_for_person" size={2} />
        <Text
          text={getName(state.shareholders[0])}
          shouldTranslate={false}
          size={2}
          weight="bold"
        />
      </Flex>
      <Text
        text={titleText}
        shouldTranslate={shouldTranslateTitle}
        translationArgs={titleTranslationArgs}
        size={3}
        weight="bold"
        truncateVertical={5}
      />
      <VoteForm submitHandler={onVoteSubmit} isFormDisabled={isMeetingPaused} />
      <VoteActionButtons areButtonsDisabled={isMeetingPaused} />
    </VoteContainer>
  );
};

export default Vote;
