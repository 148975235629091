import React from 'react';
import { Flex } from 'components/layout';
import { Input } from 'components/forms';
import { PageSubheader } from 'components/common';

const ProtocolNumber = () => (
  <Flex align="start" justify="space-between">
    <Flex xs={7}>
      <PageSubheader text="protocol.protocol_number" />
    </Flex>
    <Flex xs={12}>
      <Input size="protocol" name="protocolNumber" label="protocol.protocol_number" required />
    </Flex>
  </Flex>
);
export default ProtocolNumber;
