import React from 'react';
import { Majority } from 'app-types';
import { TextArea } from 'components/forms';
import { Flex } from 'components/layout';
import { AgendaContentList, ProtocolType } from 'types';
import { FileList, VotingInfo } from 'components/meeting';
import { AgendaItemContentContainer } from 'components/containers';
import { FoldingContainer, Text, PageSubheader } from 'components/common';

import NameInputs from '../../NameInputs';
import SurnameInputs from '../../SurnameInputs';

interface Props {
  item: AgendaContentList;
  index: number;
  protocolType: ProtocolType;
}

const AgendaItem: React.FC<Props> = ({ item, index, protocolType }) => (
  <Flex key={item.id} direction="col" gap={4}>
    <FoldingContainer
      index={index + 1}
      text={item.resolution.title}
      iconName="pdf"
      iconCount={item.resolution.files.length}
    >
      <AgendaItemContentContainer>
        <Text text={item.resolution.description} shouldTranslate={false} size={3} />
        <FileList files={item.resolution.files} />
        <VotingInfo
          data={{
            ...item.resolution.voting,
            quorum: item.resolution.quorum,
            majority: item.resolution.voting.majority as Majority,
          }}
        />
      </AgendaItemContentContainer>
    </FoldingContainer>
    {protocolType === ProtocolType.AUTHORITY_MEETING && (
      <Flex align="start" justify="space-between">
        <Flex xs={7}>
          <PageSubheader text="protocol.person_who_propsed_resolution" />
        </Flex>
        <Flex xs={12} gap={3} justify="start">
          <NameInputs name={`agendaContentList.${index}.proposingPerson`} />
          <SurnameInputs name={`agendaContentList.${index}.proposingPerson`} />
        </Flex>
      </Flex>
    )}
    <TextArea
      size="responsive"
      name={`agendaContentList.${index}.content`}
      rows={5}
      required
      disableResize
    />
  </Flex>
);

export default AgendaItem;
