import React from 'react';
import { Link, Text } from 'components/common';
import { Flex } from 'components/layout';

interface Props {
  to: string;
}

const TutorialTooltip: React.FC<Props> = ({ to }) => (
  <Flex direction="col" gap={2}>
    <Text text="application.need_help" size={2} color="sixth" />
    <Link
      to={to}
      text="application.watch_tutorial"
      customIcon="youtube"
      underline
      bold
      fontSize={1}
    />
  </Flex>
);

export default TutorialTooltip;
