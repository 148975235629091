import React from 'react';
import clsx from 'clsx';
import { __ } from 'services';

import styles from './SectionHeader.module.scss';

interface Props {
  text: string;
  args?: Record<string, string>;
  className?: string;
  uppercase?: boolean;
}

const SectionHeader: React.FC<Props> = ({ text, args, className, uppercase }) => (
  <h2 className={clsx(styles.header, className, { [styles.uppercase]: uppercase })}>
    {__(text, args)}
  </h2>
);

export default SectionHeader;
