import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import clsx from 'clsx';

import styles from './ArchiveCardContainer.module.scss';

const ArchiveCardContainer: React.FC<LinkProps> = ({ className, ...linkProps }) => (
  <Link className={clsx(styles.link, className)} {...linkProps} />
);

export default ArchiveCardContainer;
