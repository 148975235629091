import clsx from 'clsx';
import React, { useId } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, useWatch } from 'react-hook-form';
import { Files } from 'types';
import { __ } from 'services';
import { v4 } from 'uuid';
import { Flex } from 'components/layout';
import { Button, Text } from 'components/common';
import FieldErrorMessage from '../FieldErrorMessage';
import styles from './Input.module.scss';

interface Props {
  name: string;
  label: string;
  title?: string;
  titleUppercase?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  size?: 'small' | 'semi-small' | 'normal' | 'semi-big' | 'big' | 'responsive';
}

const FileInput: React.FC<Props> = ({
  name,
  label,
  title = 'resolution.files',
  titleUppercase,
  className,
  disabled,
  size = 'big',
  placeholder = 'placeholders.provide_data',
}) => {
  const { getFieldState, formState, control, setValue } = useFormContext();
  const { error } = getFieldState('files', formState);
  const id = useId();
  const files = useWatch({
    control,
    name,
  });

  const onDelete = (index: number, isFile: boolean) => {
    if (isFile) {
      const filteredFiles = [...files].filter((item, fileIndex) => fileIndex !== index);
      setValue(name, filteredFiles);
    } else {
      const updatedFiles: FileList | Files[] = [...files];
      updatedFiles[index] = {
        ...updatedFiles[index],
        isDeleted: true,
      };
      setValue(name, updatedFiles);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target;
    if (!input.files?.length) {
      return;
    }

    const fileList = files || [];
    Array.from(input.files).forEach((file) => {
      fileList.push(file);
    });

    setValue(name, fileList);
  };

  return (
    <div
      className={clsx(className, styles.field, {
        [styles.error]: error,
        [styles?.[size]]: size,
      })}
    >
      <Flex gap={5}>
        <Text text={title} size={4} uppercase={titleUppercase} weight="bold" />
        <input
          className={styles.input}
          type="file"
          multiple
          id={id}
          name={name}
          placeholder={placeholder ? __(placeholder) : ''}
          aria-invalid={!!error}
          disabled={disabled}
          onChange={handleFileChange}
        />
        <label className={styles.label} htmlFor={id}>
          {__(label)}
        </label>
      </Flex>
      <Flex direction="col" gap={1}>
        {files &&
          [...files].map((file, index) => {
            if (file.isDeleted) return null;
            return (
              <Flex align="center" gap={5} key={v4()}>
                <span className={styles.file}>{file.name ?? file.hash}</span>
                <Button
                  text="application.delete"
                  variant="tertiary"
                  type="button"
                  onClick={() => onDelete(index, !file.id)}
                  size="small"
                />
              </Flex>
            );
          })}
      </Flex>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <FieldErrorMessage message={message} errorNowrap />}
        />
      )}
    </div>
  );
};

export default FileInput;
