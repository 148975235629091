import React from 'react';
import { PropsWithChildren } from 'types';

import styles from './MeetingHeaderContainer.module.scss';

const MeetingHeaderContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <section className={styles.container}>{children}</section>
);

export default MeetingHeaderContainer;
