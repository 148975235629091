import React from 'react';
import { CircularProgressbar as MyCircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Props {
  value: number;
  goal: number;
  text?: string;
}

const CircularProgressbar: React.FC<Props> = ({ value, goal, text }) => (
  <MyCircularProgressbar
    value={value}
    maxValue={goal}
    text={text}
    styles={{
      text: { fontSize: '30px', lineHeight: 1.25, fill: '#707070' },
      trail: { stroke: 'transparent' },
      path: { stroke: '#00a45a' },
    }}
  />
);

export default CircularProgressbar;
