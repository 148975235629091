import React from 'react';
import { Button } from 'components/common';
import { Form, Input } from 'components/forms';
import { Flex } from 'components/layout';
import { krsNumberValidationSchema } from 'config';

interface Props {
  onSubmit: (formData: Record<'krsNumber', string>) => Promise<void>;
  defaultValues?: Record<'krsNumber', string>;
}

const GetKrsData: React.FC<Props> = ({ onSubmit, defaultValues }) => (
  <Form
    submitHandler={onSubmit}
    validationSchema={krsNumberValidationSchema}
    defaultValues={defaultValues}
  >
    <Flex align="end" gap={5}>
      <Input name="krsNumber" label="company.krs_number" errorNowrap />
      <Button text="pages.new_company.button1" variant="secondary" />
    </Flex>
  </Form>
);

export default GetKrsData;
