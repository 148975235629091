import {
  CreateDraftMeetingReq,
  RemoveFileReq,
  Majority,
  VotingType,
  CreateAgendaItemReq,
} from 'app-types';

export enum AddMeetingStages {
  NewMeeting,
  VotingParticipants,
  Agenda,
  ExcludedVoters,
}

export interface ParticipantsGroup {
  isChecked: boolean;
  id: string;
  name: string;
}

export interface NewMeetingForm
  extends Omit<CreateDraftMeetingReq, 'participants' | 'date' | 'quorum'> {
  participants: ParticipantsGroup[];
  date: string;
  quorum: number | null;
  time: string;
  customMeetingType: string;
  notary?: string;
}

type GroupId = string;
type UserId = string;
type RepresentativeId = string;
type CompanyId = string;

export interface VotersData<Representative = RepresentativeId | false> {
  isVoting: boolean;
  representative: Representative;
}

export interface UserVoters {
  [key: UserId]: VotersData;
}

export interface CompaniesVoters {
  [key: CompanyId]: VotersData<RepresentativeId>;
}

export interface VotingParticipantsFormGroups {
  [key: GroupId]: {
    isVoting: boolean;
    usersVoters: UserVoters;
    companiesVoters: CompaniesVoters;
  };
}

export interface VotingParticipantsForm {
  pdf: (File | Files)[];
  groups: VotingParticipantsFormGroups;
}

export interface ExcludedVotersForm {
  excludedCompaniesVoters: {
    [key: string]: boolean;
  };
  excludedUsersVoters: {
    [key: string]: boolean;
  };
}

export interface Voting {
  type: VotingType;
  duration: string;
  majority: Majority;
  threshold: string;
}

export interface Files extends RemoveFileReq {
  isDeleted?: boolean;
}

export interface AddEditResolutionFormData extends CreateAgendaItemReq {
  pdf: (File | Files)[];
}
