import React from 'react';
import clsx from 'clsx';
import { Icon } from 'components/common';
import { __ } from 'services';
import { IconName } from 'types';

import styles from './InlineButton.module.scss';

interface Props {
  text: string;
  translationArgs?: Record<string, string | number>;
  shouldTranslate?: boolean;
  onClick?: () => void;
  type?: 'submit' | 'button';
  isActive?: boolean;
  variant?: 'first' | 'second' | 'vote-result' | 'protocol-preview';
  fontWeight?: 'thin' | 'normal' | 'bold';
  underline?: boolean;
  iconName?: IconName;
  disabled?: boolean;
  className?: string;
}

const InlineButton: React.FC<Props> = ({
  text,
  translationArgs,
  shouldTranslate = true,
  type = 'submit',
  onClick,
  isActive,
  variant = 'first',
  fontWeight = 'bold',
  underline,
  iconName,
  disabled,
  className,
}) => (
  <button
    className={clsx(styles.button, className, {
      [styles?.[variant]]: variant,
      [styles?.[fontWeight]]: fontWeight,
      [styles.active]: isActive,
      [styles.underline]: underline,
      [styles.clickable]: onClick,
    })}
    type={type}
    onClick={onClick}
    disabled={disabled}
  >
    {iconName && <Icon name={iconName} />}
    {shouldTranslate ? __(text, translationArgs) : text}
  </button>
);

export default InlineButton;
