import { Dispatch } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { CONFIG } from 'config';
import {
  BlockchainErrorCode,
  BlockchainFailureMessage,
  BlockchainMethods,
  BlockchainSuccessMessage,
  EmptyObject,
  JsonRpcBody,
  SuccessCb,
} from 'types';
import {
  isBlockchainFailureMessage,
  isBlockchainSuccessMessage,
  retryFetch,
  TimeoutError,
} from 'utils';
import { errorNotification, infoNotification } from 'slices';

export interface RetryOptions {
  retries: number;
  delayInMs: number;
}

export interface BaseBlockchainParams<ResponseData> {
  successCb?: SuccessCb<
    BlockchainSuccessMessage<ResponseData> | BlockchainSuccessMessage<ResponseData>[]
  >;
  failureCb?: (
    error: BlockchainFailureMessage | BlockchainFailureMessage[],
    results?: BlockchainSuccessMessage<ResponseData>[],
  ) => void;
  options?: RetryOptions;
  stringifyParams?: boolean;
}

interface BlockchainParams<Params extends unknown, ResponseData>
  extends BaseBlockchainParams<ResponseData> {
  dispatch: Dispatch;
  method: BlockchainMethods;
  params: Params;
}

const getStrParams = <Params extends Object | undefined>(params: Params) =>
  params &&
  Object.entries(params).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: JSON.stringify(value) }),
    {},
  );

export const callBlockchain = async <Params extends Object | undefined, ResponseData>({
  dispatch,
  method,
  params,
  successCb,
  failureCb,
  stringifyParams,
  options = {
    retries: CONFIG.BC_RETRIES,
    delayInMs: CONFIG.BC_DELAY_IN_MS,
  },
}: BlockchainParams<Params, ResponseData>): Promise<ResponseData | EmptyObject> => {
  // default values if one of options isn't provided and the another one is
  const { retries = CONFIG.BC_RETRIES, delayInMs = CONFIG.BC_DELAY_IN_MS } = options;

  const headers = {
    'Content-Type': 'application/json',
    /**
     * !IMPORTANT!
     * AUTH HAS TO BE ENCODED VIA BASE64 (btoa method)
     * GENERAL RECIPE IS USER:KEY
     * FOR EXAMPLE ivbiznes:MY_SUPER_KEY
     */
    Authorization: `Basic ${CONFIG.BC_AUTH}`,
  };

  let objBody: JsonRpcBody | JsonRpcBody[] = {
    jsonrpc: '2.0',
    id: uuid(),
    method,
    params: stringifyParams ? getStrParams(params) : params,
  };

  if (Array.isArray(params)) {
    objBody = params.map(({ id, ...paramsObj }) => ({
      jsonrpc: '2.0',
      id: id || uuid(),
      method,
      params: stringifyParams ? getStrParams(paramsObj) : paramsObj,
    }));
  }

  const onRetry = () => dispatch(infoNotification('blockchain_informations.retrying'));

  try {
    const data:
      | BlockchainSuccessMessage<ResponseData>
      | BlockchainFailureMessage
      | (BlockchainSuccessMessage<ResponseData> | BlockchainFailureMessage)[] = await retryFetch(
      CONFIG.BC_URL,
      {
        method: 'POST',
        headers,
        body: JSON.stringify(objBody),
        credentials: 'include',
        delayInMs,
        retries,
        onRetry,
      },
    );

    if (Array.isArray(data)) {
      const results = data.filter(isBlockchainSuccessMessage);
      const errors = data.filter(isBlockchainFailureMessage);

      if (!errors.length) {
        if (successCb) successCb(results);

        return results;
      }

      if (failureCb) failureCb(errors, results);
      return {};
    }

    if (isBlockchainSuccessMessage(data)) {
      if (successCb) successCb(data);
      return data;
    }

    if (isBlockchainFailureMessage(data)) {
      if (failureCb) failureCb(data);
      return data;
    }
  } catch (e) {
    if (e instanceof TimeoutError) {
      dispatch(errorNotification(BlockchainErrorCode.RunOutOfRetries));
      return {};
    }

    dispatch(errorNotification(BlockchainErrorCode.ErrorOccurred));
  }

  return {};
};
