import { useEffect } from 'react';

export const useEscapeClick = (callback: () => void) => {
  useEffect(() => {
    const handleClickEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') callback();
    };

    document.addEventListener('keydown', handleClickEsc);

    return () => {
      document.removeEventListener('keydown', handleClickEsc);
    };
  }, [callback]);
};
