import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { getFullName } from 'utils';
import { ProtocolFormData } from 'types';
import { Flex, Card } from 'components/layout';
import { ButtonsContainer } from 'components/containers';
import { PageSubheader, Button, Text, IconButton } from 'components/common';

import NameInputs from '../NameInputs';
import SurnameInputs from '../SurnameInputs';

const AbsentPerson = () => {
  const { control, getValues, setValue, setError, clearErrors, watch } =
    useFormContext<ProtocolFormData>();
  const { fields, append, remove } = useFieldArray({ control, name: 'absentPerson' });

  const addPerson = () => {
    clearErrors('tempAbsentPerson');
    const tempAbsentPerson = getValues('tempAbsentPerson');
    let isError = false;

    if (!tempAbsentPerson?.names[0] || tempAbsentPerson.names[0].length < 2) {
      setError('tempAbsentPerson.names.0', { message: 'validation.field_required' });
      isError = true;
    }

    if (!tempAbsentPerson?.surname[0] || tempAbsentPerson.names[0].length < 2) {
      setError('tempAbsentPerson.surname.0', { message: 'validation.field_required' });
      isError = true;
    }

    if (isError) return;

    if (tempAbsentPerson) {
      append(tempAbsentPerson);
      setValue('tempAbsentPerson', { names: ['', ''], surname: ['', ''] });
    }
  };

  useEffect(() => {
    const subscription = watch(({ tempAbsentPerson }) => {
      if (tempAbsentPerson?.names?.[0] && tempAbsentPerson.names[0].length > 1) {
        clearErrors('tempAbsentPerson.names.0');
      }

      if (tempAbsentPerson?.surname?.[0] && tempAbsentPerson.surname[0].length > 1) {
        clearErrors('tempAbsentPerson.surname.0');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, clearErrors]);

  return (
    <Flex align="start" justify="space-between">
      <Flex xs={7}>
        <PageSubheader text="protocol.absent_person" />
      </Flex>

      <Flex xs={12} gap={3} justify="start" direction="col">
        <NameInputs name="tempAbsentPerson" />
        <SurnameInputs name="tempAbsentPerson" />

        <ButtonsContainer>
          <Button
            size="normal"
            text="protocol.add_person"
            variant="tertiary"
            type="button"
            onClick={() => addPerson()}
          />
        </ButtonsContainer>

        {fields.map((absentPerson, i) => (
          <Card direction="col" gap={2} key={absentPerson.id}>
            <Flex align="center" justify="space-between">
              <Text
                text={getFullName({
                  names: [absentPerson.names[0], absentPerson.names[1]],
                  surname: [absentPerson.surname[0], absentPerson.surname[1]],
                })}
                shouldTranslate={false}
              />
              <IconButton iconName="trash-can" onClick={() => remove(i)} />
            </Flex>
          </Card>
        ))}
      </Flex>
    </Flex>
  );
};

export default AbsentPerson;
