import React from 'react';
import clsx from 'clsx';

import styles from './DesktopMeetingStateInformationContainer.module.scss';

interface Props {
  children: React.ReactNode;
  withCounter?: boolean;
}

const DesktopMeetingStateInformationContainer: React.FC<Props> = ({ children, withCounter }) => (
  <div className={clsx(styles.container, { [styles?.['without-counter']]: !withCounter })}>
    {children}
  </div>
);

export default DesktopMeetingStateInformationContainer;
