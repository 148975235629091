import React, { useId, useMemo, useRef, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { FieldErrors } from 'react-hook-form';
import clsx from 'clsx';
import { Icon, Text } from 'components/common';
import { useOutsideClick } from 'hooks';
import { DropdownItem } from 'types';

import FieldErrorMessage from '../../FieldErrorMessage';
import Label from '../../Label';
import styles from './DropdownInput.module.scss';

interface Props {
  value: string;
  onChange: (value: string) => void;
  errors: FieldErrors;
  options: DropdownItem[];
  name: string;
  label?: string;
  size?: 'small' | 'normal' | 'big' | 'responsive' | 'protocol';
  shouldTranslateValues?: boolean;
  required?: boolean;
}

const DropdownInput: React.FC<Props> = ({
  value,
  onChange,
  errors,
  options,
  name,
  label,
  shouldTranslateValues,
  size = 'normal',
  required,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const id = useId();
  const ref = useRef<null | HTMLDivElement>(null);
  useOutsideClick(ref, () => setIsOpen(false));
  const selected = useMemo(
    () => options.find((option) => option.value === value),
    [value, options],
  );

  return (
    <div className={clsx(styles.wrapper, { [styles?.[size]]: size })} ref={ref}>
      {label && <Label htmlFor={`${id}-${name}`} label={label} required={required} />}
      <button
        aria-label="open-dropdown"
        className={clsx(styles.button, {
          [styles?.[size]]: size,
          [styles.open]: isOpen,
          [styles.error]: errors?.[name],
          [styles.selected]: selected !== undefined,
        })}
        id={`${id}-${name}`}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text
          className={styles.text}
          size={3}
          text={selected ? selected.text : 'application.choose'}
          shouldTranslate={selected ? shouldTranslateValues : true}
        />
        <Icon name="arrow-down" className={styles.icon} />
      </button>
      {errors && name && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <FieldErrorMessage message={message} />}
        />
      )}
      {isOpen && (
        <div className={styles.popup}>
          {options.map((option) => (
            <button
              aria-label="dropdown"
              type="button"
              className={clsx(styles.button, styles?.['popup-item'])}
              key={option.value}
              onClick={() => {
                onChange(option.value);
                setIsOpen(false);
              }}
            >
              <Text
                size={3}
                weight={selected?.value === option.value ? 'bold' : 'normal'}
                text={option.text}
                shouldTranslate={shouldTranslateValues}
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownInput;
