import React from 'react';
import { Flex, Row } from 'components/layout';
import { Text, Button, Icon } from 'components/common';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Error from './Error';
import styles from './UploadError.module.scss';

type ErrorType = {
  email: string;
  additionalInfo: string[];
};

interface Props {
  errors: ErrorType[];
  hideModal: () => void;
}

const UploadError: React.FC<Props> = ({ errors, hideModal }) => {
  const onCancel = () => hideModal();

  return (
    <Flex direction="row" justify="space-between" gap={2}>
      <Flex direction="col" align="center" justify="center" xs={6} gap={6}>
        <Flex direction="col" align="center" gap={4}>
          <Icon name="error" className={styles.icon} />
          <Flex direction="col" align="center" gap={1}>
            <Row>
              <Text text="modals.csv_error.title_1" />
              <Text
                text="modals.csv_error.title_2"
                weight="semi-bold"
                translationArgs={{ errors: errors.length }}
                whiteSpace="break-spaces"
              />
              <Text text="modals.csv_error.title_3" />
            </Row>
            <Text text="modals.csv_error.subtitle" />
          </Flex>
        </Flex>
        <Button text="application.ok" variant="secondary" onClick={onCancel} />
      </Flex>
      <Flex direction="col" xs={6} className={styles['error-container']}>
        <Scrollbars>
          <Flex direction="col" xs={6} gap={2} className={styles['error-wrapper']}>
            {React.Children.toArray(
              errors.map((item) => (
                <Error email={item.email} additionalInfo={item.additionalInfo} />
              )),
            )}
          </Flex>
        </Scrollbars>
      </Flex>
    </Flex>
  );
};

export default UploadError;
