import React, { useMemo } from 'react';
import { CompanyShareholderData, UserShareholderData } from 'app-types';
import { Button, Text } from 'components/common';
import { ModalActionButtonsContainer } from 'components/containers';
import { Dropdown, Form } from 'components/forms';
import { reportValidationSchema } from 'config';
import { Flex } from 'components/layout';
import { DropdownItem, ReportFormData, ReportModalVariant } from 'types';
import { getName } from 'utils';

interface Props {
  shareholders: (CompanyShareholderData | UserShareholderData)[];
  onSubmit: (formValues: ReportFormData) => Promise<void>;
  onCancel: () => void;
  variant: ReportModalVariant;
  totalShareholders: number;
}

const ReportModalContent: React.FC<Props> = ({
  shareholders,
  onSubmit,
  onCancel,
  variant,
  totalShareholders,
}) => {
  const dropdownOptions = useMemo(
    () =>
      shareholders.reduce<DropdownItem[]>(
        (acc, curr) => [...acc, { text: getName(curr), value: curr.id }],
        [],
      ),
    [shareholders],
  );

  if (totalShareholders > 1) {
    if (shareholders.length > 1) {
      return (
        <Form submitHandler={onSubmit} validationSchema={reportValidationSchema}>
          <Flex direction="col" gap={4}>
            <Dropdown
              name="reportingId"
              label={`modals.report.choose_as_who_you_are_reporting_${variant}`}
              options={dropdownOptions}
              shouldTranslateValues={false}
              size="responsive"
            />
            <ModalActionButtonsContainer>
              <Button
                text={`modals.report.${variant}_button`}
                iconName="warning"
                variant="secondary"
              />
              <Button
                text="application.cancel"
                variant="tertiary"
                type="button"
                onClick={onCancel}
              />
            </ModalActionButtonsContainer>
          </Flex>
        </Form>
      );
    }
    return (
      <Flex direction="col" gap={4}>
        <Text
          text={`modals.report.reporting_for_${variant}`}
          translationArgs={{ name: dropdownOptions[0].text }}
        />
        <ModalActionButtonsContainer>
          <Button
            text={`modals.report.${variant}_button`}
            variant="secondary"
            onClick={() => onSubmit({ reportingId: shareholders[0].id })}
            iconName="warning"
          />
          <Button text="application.cancel" variant="tertiary" type="button" onClick={onCancel} />
        </ModalActionButtonsContainer>
      </Flex>
    );
  }

  return (
    <ModalActionButtonsContainer>
      <Button
        text={`modals.report.${variant}_button`}
        variant="secondary"
        onClick={() => onSubmit({ reportingId: shareholders[0].id })}
        iconName="warning"
      />
      <Button text="application.cancel" variant="tertiary" type="button" onClick={onCancel} />
    </ModalActionButtonsContainer>
  );
};

export default ReportModalContent;
