import React from 'react';
import { Flex } from 'components/layout';
import { Input } from 'components/forms';
import { PageSubheader } from 'components/common';

const NumberOfProtocols: React.FC = () => (
  <Flex align="start" justify="space-between">
    <Flex xs={7}>
      <PageSubheader text="protocol.number_of_protocols" />
    </Flex>
    <Flex xs={12} justify="start">
      <Input
        minNumber={1}
        name="copies"
        type="number"
        size="small"
        placeholder="placeholders.number"
        label="protocol.quantity"
        required
        errorNowrap
      />
    </Flex>
  </Flex>
);

export default NumberOfProtocols;
