import {
  BaseSharesWithId,
  CastVoterVotesReq,
  SharesType,
  UserShareholderData,
  CompanyShareholderData,
  ObjectionEntity,
  ChangeReportEntity,
} from 'app-types';
import { SharesWithId, SplitVotesFormData, VoteFormData } from 'types';

export const getVotingShares = <Shares extends Pick<BaseSharesWithId, 'type' | 'multiplier'>>(
  shares: Shares[],
): Shares[] => {
  const normalShares = shares.filter(({ type }) => type === null);

  const preferentialShares = shares
    .filter(({ type }) => type === SharesType.PREFERENTIAL)
    .sort((a, b) => {
      if (a.multiplier && b.multiplier) {
        return a.multiplier - b.multiplier;
      }

      return 0;
    });

  return [...normalShares, ...preferentialShares];
};

export const mapToCastVoterVotesReq = (
  { voteOption }: VoteFormData,
  shares: SharesWithId[],
): CastVoterVotesReq => {
  const result: CastVoterVotesReq = {
    inFavour: [],
    against: [],
    abstain: [],
  };
  const votingShares = getVotingShares(shares);

  result[voteOption] = votingShares.map(({ type, multiplier, value }) => ({
    type,
    value,
    multiplier,
  }));

  return result;
};

export const validateAndShapeSplitVotes = (
  formValues: SplitVotesFormData,
  shares: SharesWithId[],
): CastVoterVotesReq =>
  shares.reduce<CastVoterVotesReq>(
    (acc, { id, type, multiplier }) => {
      const values = formValues[id];
      if (!values) return acc;

      const result = { ...acc };

      Object.entries(values).forEach(([voteOption, v]) => {
        if (!v) return;
        result[voteOption as keyof typeof result].push({ multiplier, type, value: Number(v) });
      });

      return result;
    },
    {
      inFavour: [],
      against: [],
      abstain: [],
    },
  );

export const usersNotVotedObjection = (
  shareholders?: (UserShareholderData | CompanyShareholderData)[],
  objections?: ObjectionEntity[],
) => {
  if (!shareholders || !objections) return [];
  return shareholders.filter((shareholder) => {
    if ('userVoterId' in shareholder) {
      return !objections?.some((objection) => objection.userVoter?.id === shareholder.userVoterId);
    }
    if ('companyVoterId' in shareholder) {
      return !objections?.some(
        (objection) => objection.companyVoter?.id === shareholder.companyVoterId,
      );
    }
    return false;
  });
};

export const usersNotVotedChange = (
  shareholders?: (UserShareholderData | CompanyShareholderData)[],
  changesReports?: ChangeReportEntity[],
) => {
  if (!shareholders || !changesReports) return [];
  return shareholders.filter((shareholder) => {
    if ('userVoterId' in shareholder) {
      return !changesReports?.some(
        (changeReport) => changeReport.userVoter?.id === shareholder.userVoterId,
      );
    }
    if ('companyVoterId' in shareholder) {
      return !changesReports?.some(
        (changeReport) => changeReport.companyVoter?.id === shareholder.companyVoterId,
      );
    }
    return false;
  });
};
