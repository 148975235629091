import { ProtocolType, ProtocolFormData, AgendaContentList } from 'types';
import { ProtocolDataFromUser, AgendaContent } from 'app-types';

const getAgendaContentAndProposingPersonList = (
  agendaContentList: AgendaContentList[],
): AgendaContent[] =>
  agendaContentList.map((item) => ({
    agendaItemId: item.resolution.id,
    content: item.content,
    proposingPerson: item.proposingPerson,
  }));

const getAgendaContentList = (agendaContentList: AgendaContentList[]): AgendaContent[] =>
  agendaContentList.map((item) => ({
    agendaItemId: item.resolution.id,
    content: item.content,
  }));

const getJointStockCompanyData = (data: ProtocolFormData): ProtocolDataFromUser => ({
  agendaContentList: getAgendaContentList(data.agendaContentList),
  agendaRecorder: data.agendaRecorder,
  anyOtherBusiness: data.anyOtherBusiness,
  chairman: data.chairman,
});

const getSimpleJointStockCompanyData = (data: ProtocolFormData): ProtocolDataFromUser => ({
  greeter: data.greeter,
  notification: data.notification,
  representativeDateList: data.representativeDateList.map((item) => ({
    userId: item.user.id,
    representativeId: item.voter.id,
    date: item.date,
  })),
  agendaContentList: getAgendaContentList(data.agendaContentList),
  representativeRoleInCompanyList: data.representativeRoleInCompanyList.map((item) => ({
    userId: item.voter.id,
    companyId: item.company.id,
    role: item.role,
  })),
  copies: data.copies,
  chairman: data.chairman,
  agendaRecorder: data.agendaRecorder,
  anyOtherBusiness: data.anyOtherBusiness,
});

const getLimitedLiabilityCompanyData = (data: ProtocolFormData): ProtocolDataFromUser => ({
  greeter: data.greeter,
  notification: data.notification,
  representativeDateList: data.representativeDateList.map((item) => ({
    userId: item.user.id,
    representativeId: item.voter.id,
    date: String(new Date(item.date)),
  })),
  agendaContentList: getAgendaContentList(data.agendaContentList),
  representativeRoleInCompanyList: data.representativeRoleInCompanyList.map((item) => ({
    userId: item.voter.id,
    companyId: item.company.id,
    role: item.role,
  })),
  copies: data.copies,
  chairman: data.chairman,
  agendaRecorder: data.agendaRecorder,
  anyOtherBusiness: data.anyOtherBusiness,
  absentPerson: data.absentPerson,
});

const getAuthorityMeetingData = (data: ProtocolFormData): ProtocolDataFromUser => ({
  protocolNumber: data.protocolNumber,
  notification: data.notification,
  greeter: data.greeter,
  discussions: data.discussions,
  anyOtherBusiness: data.anyOtherBusiness,
  closer: data.closer,
  agendaContentList: getAgendaContentAndProposingPersonList(data.agendaContentList),
});

export const getProtocolData = (
  protocolType: ProtocolType,
  data: ProtocolFormData,
): ProtocolDataFromUser | {} => {
  switch (protocolType) {
    case ProtocolType.JOINT_STOCK_COMPANY:
      return getJointStockCompanyData(data);
    case ProtocolType.SIMPLE_JOINT_STOCK_COMPANY:
      return getSimpleJointStockCompanyData(data);
    case ProtocolType.LIMITED_LIABILITY_COMPANY:
      return getLimitedLiabilityCompanyData(data);
    case ProtocolType.AUTHORITY_MEETING:
      return getAuthorityMeetingData(data);
    default:
      return {} as never;
  }
};
