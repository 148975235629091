import React, { useMemo } from 'react';
import { Text } from 'components/common';
import { Flex } from 'components/layout';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { fetchShareholdingData, successNotification } from 'slices';
import { EditGroupMemberData, GroupMemberFormData } from 'types';
import { getFullName, mapToGroupMemberFormData, mapToUpdateUserShareholderReq } from 'utils';

import MemberForm from '../MemberForm';

const EditMember: React.FC = () => {
  const { hideModal, data } = useModal<undefined, EditGroupMemberData>();
  const dispatch = useAppDispatch();
  const api = useApi();

  const name = useMemo(
    () => getFullName({ ...data.member, placeholder: data.member.email }),
    [data.member],
  );
  const defaultValues = useMemo(() => mapToGroupMemberFormData(data.member), [data.member]);

  const onSubmit = async (formData: GroupMemberFormData) => {
    const { companyId, group, member } = data;
    const reqData = mapToUpdateUserShareholderReq(formData);

    await api.put(
      `/company/${companyId}/shareholding/groups/${group.id}/shareholders/users/${member.id}`,
      reqData,
      () => {
        dispatch(successNotification('notifications.edited_shareholder'));
        hideModal();
      },
    );
    dispatch(fetchShareholdingData(companyId));
  };

  return (
    <Flex direction="col" gap={2}>
      <Text text="modals.edit_member.title" translationArgs={{ name }} size={4} weight="bold" />
      <MemberForm
        variant="edit_member"
        onSubmit={onSubmit}
        onCancel={hideModal}
        companyType={data.companyType}
        defaultValues={defaultValues}
      />
    </Flex>
  );
};

export default EditMember;
