import { saveState, loadState } from 'utils';
import { configureStore } from '@reduxjs/toolkit';
import {
  appStatusSlice,
  dashboardSlice,
  userSlice,
  modalSlice,
  notificationsSlice,
  routerSlice,
  meetingSlice,
} from 'slices';

const preloadedState = loadState();

export const store = configureStore({
  reducer: {
    appStatus: appStatusSlice.reducer,
    dashboard: dashboardSlice.reducer,
    meeting: meetingSlice.reducer,
    modal: modalSlice.reducer,
    notifications: notificationsSlice.reducer,
    router: routerSlice.reducer,
    user: userSlice.reducer,
  },
  preloadedState,
});

store.subscribe(() => {
  const { user, router } = store.getState();

  if (router.redirectedFrom.includes('admin')) {
    return;
  }

  saveState({
    user,
    router,
  });
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
