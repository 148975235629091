import React from 'react';
import { Text } from 'components/common';
import { Flex } from 'components/layout';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { fetchShareholdingData, successNotification } from 'slices';
import { EditGroupSubjectData, GroupSubjectFormData } from 'types';
import { mapToGroupSubjectFormData, mapToUpdateCompanyShareholderReq } from 'utils';

import SubjectForm from '../SubjectForm';

const EditSubject: React.FC = () => {
  const { hideModal, data } = useModal<undefined, EditGroupSubjectData>();
  const dispatch = useAppDispatch();
  const api = useApi();

  const defaultValues = mapToGroupSubjectFormData(data.member);

  const onSubmit = async (formData: GroupSubjectFormData) => {
    const { companyId, member, group } = data;
    const reqData = mapToUpdateCompanyShareholderReq(formData);

    await api.put(
      `/company/${companyId}/shareholding/groups/${group.id}/shareholders/companies/${member.id}`,
      reqData,
      () => {
        dispatch(successNotification('notifications.edited_shareholder'));
        hideModal();
      },
    );
    dispatch(fetchShareholdingData(companyId));
  };

  return (
    <Flex direction="col" gap={2}>
      <Text
        text="modals.edit_subject.title"
        translationArgs={{ name: data.member.name }}
        size={4}
        weight="bold"
      />
      <SubjectForm
        variant="edit_subject"
        onSubmit={onSubmit}
        onCancel={hideModal}
        companyType={data.companyType}
        defaultValues={defaultValues}
      />
    </Flex>
  );
};

export default EditSubject;
