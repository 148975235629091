import React, { useState } from 'react';
import { UploadCSVData } from 'types';
import { OperationStatus, Status } from 'app-types';
import { useModal, useApi, useAppDispatch } from 'hooks';
import { fetchShareholdingData, successNotification } from 'slices';

import Upload from './Upload';
import UploadError from './UploadError';

enum UploadStage {
  UploadCSV,
  Error,
}

const UploadCSV: React.FC = () => {
  const [stage, setStage] = useState<UploadStage>(UploadStage.UploadCSV);
  const [csvErrors, setCsvErrors] = useState([]);
  const api = useApi();
  const dispatch = useAppDispatch();
  const {
    hideModal,
    data: { companyId },
  } = useModal<undefined, UploadCSVData>();

  const onCsvImport = async (file: File) => {
    const formData = new FormData();
    formData.append('csv', file, file.name);

    await api.put<OperationStatus, FormData>(
      `/company/${companyId}/shareholding-from-csv`,
      formData,
      async (data) => {
        switch (data.status) {
          case Status.SUCCESS:
            await dispatch(fetchShareholdingData(companyId));
            dispatch(successNotification('notifications.successful_csv_group_import'));
            hideModal();
            break;
          case Status.FAILURE:
            setCsvErrors(data.payload);
            setStage(UploadStage.Error);
            break;
          default:
        }
      },
    );
  };

  switch (stage) {
    case UploadStage.UploadCSV:
      return <Upload hideModal={hideModal} onCsvImport={onCsvImport} />;
    case UploadStage.Error:
      return <UploadError hideModal={hideModal} errors={csvErrors} />;
    default:
      return null;
  }
};

export default UploadCSV;
