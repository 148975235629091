import React from 'react';
import { Text } from 'components/common';

interface Props {
  size: number;
}

const FetchingError: React.FC<Props> = ({ size }) => (
  <Text text="errors.fetching_error" size={size} italic align="center" />
);

export default FetchingError;
