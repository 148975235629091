import {
  AttachmentFile,
  CompanyVoterRes,
  FileType,
  GetMeetingDetailsRes,
  MeetingParticipatingGroupsDetailsRes,
  MeetingType,
  ShareholderCompany,
  ShareholderUser,
  ShareholdingGroup,
  SharesType,
  UserVoterRes,
} from 'app-types';
import {
  NewMeetingForm,
  ParticipantsGroup,
  VotingParticipantsForm,
  ExcludedGroupsVotersForm,
  ExcludedGroupsVotersCompanies,
  ExcludedGroupsVotersPeople,
  CompaniesVoters,
  Files,
  UserVoters,
  VotingParticipantsFormGroups,
} from 'types';
import { formatTime, getFullName, getVotingShares } from 'utils';

export const filterOutAuthorizationFiles = (attachments: AttachmentFile[]) =>
  attachments.reduce<Files[]>(
    (acc, file) =>
      file.fileType === FileType.AUTHORIZATION ? [...acc, { ...file, isDeleted: false }] : acc,
    [],
  );

export const addDefaultValuesToVotingParticipantsForm = (
  formData: VotingParticipantsForm,
  voters: (UserVoterRes | CompanyVoterRes)[],
): VotingParticipantsForm => {
  voters.forEach((voter) => {
    const {
      group: { id: groupId },
      voter: { id: voterId },
    } = voter;

    if ('company' in voter) {
      const {
        company: { id: companyId },
      } = voter;
      // eslint-disable-next-line no-param-reassign
      formData.groups[groupId].companiesVoters[companyId].isVoting = true;
      // eslint-disable-next-line no-param-reassign
      formData.groups[groupId].companiesVoters[companyId].representative = voterId;
      return;
    }
    const {
      user: { id: userId },
    } = voter;
    // eslint-disable-next-line no-param-reassign
    formData.groups[groupId].usersVoters[userId].isVoting = true;
    // eslint-disable-next-line no-param-reassign
    formData.groups[groupId].usersVoters[userId].representative = userId !== voterId && voterId;
  });

  // Check group checkbox if every voter is voting
  Object.entries(formData.groups).forEach(([groupId, { companiesVoters, usersVoters }]) => {
    const allVoters = [...Object.values(companiesVoters), ...Object.values(usersVoters)];

    // eslint-disable-next-line no-param-reassign
    formData.groups[groupId].isVoting = allVoters.every(({ isVoting }) => isVoting);
  });

  return formData;
};

const filterVotingParticipants = <Participant extends { shares?: { type: SharesType | null }[] }>(
  participants: Participant[],
): Participant[] =>
  participants.filter(({ shares }) => {
    if (!shares) return false;
    const votingShares = getVotingShares(shares);

    return Boolean(votingShares.length);
  });

export const filterOnlyParticipatingGroups = (
  shareholding: ShareholdingGroup[],
  participants: ParticipantsGroup[],
) => {
  const filteredShareholding = shareholding.reduce<ShareholdingGroup[]>((acc, curr) => {
    const people = filterVotingParticipants(curr.people);
    const companies = filterVotingParticipants(curr.companies);

    if (![...people, ...companies].length) return acc;

    return [...acc, { ...curr, companies, people }];
  }, []);

  return filteredShareholding.filter(({ id }) =>
    participants.find((participant) => participant.id === id && participant.isChecked),
  );
};

const filterPeople = (people: ShareholderUser[], term: string) =>
  people.filter((person) => getFullName(person).toLowerCase().includes(term.toLowerCase()));

const filterCompanies = (companies: ShareholderCompany[], term: string) =>
  companies.filter(({ name }) => name.toLowerCase().includes(term.toLowerCase()));

export const filterParticipantsGroups = (participantsGroups: ShareholdingGroup[], term: string) =>
  participantsGroups.reduce<ShareholdingGroup[]>((acc, curr) => {
    const filteredCompanies = filterCompanies(curr.companies, term);
    const filteredPeople = filterPeople(curr.people, term);

    const filteredArrays = [...filteredCompanies, ...filteredPeople];

    if (filteredArrays.length !== 0) {
      return [...acc, { ...curr, companies: filteredCompanies, people: filteredPeople }];
    }

    return acc;
  }, []);

const getNewMeetingFormType = (resType: string) => {
  let type = MeetingType.Authority;
  let customMeetingType = resType;
  Object.values(MeetingType).forEach((t) => {
    if (t !== resType) return;

    type = resType;
    customMeetingType = '';
  });

  return { type, customMeetingType };
};

const getNewMeetingFormParticipants = (
  participatingGroups: MeetingParticipatingGroupsDetailsRes[],
  shareholdingGroups: ShareholdingGroup[],
) =>
  shareholdingGroups.map(({ id, name }) => {
    const checkedMeeting = participatingGroups.find((group) => group.id === id);
    if (!checkedMeeting) {
      return {
        id,
        name,
        isChecked: false,
      };
    }
    return { id, name, isChecked: true };
  });

export const mapToNewMeetingForm = (
  { type: resType, date, participatingGroups, formality, quorum, notary }: GetMeetingDetailsRes,
  shareholdingGroups: ShareholdingGroup[],
): NewMeetingForm => {
  const { type, customMeetingType } = getNewMeetingFormType(resType);
  const time = formatTime(new Date(date));
  const participants = getNewMeetingFormParticipants(participatingGroups, shareholdingGroups);

  return {
    type,
    customMeetingType,
    date: typeof date === 'string' ? date : date.toString(),
    notary: notary?.email ?? '',
    time,
    quorum,
    formality,
    participants,
  };
};

export const mapToVotingParticipantsFormGroupsFromShareholdingGroupArr = (
  participantsGroups: ShareholdingGroup[],
): VotingParticipantsFormGroups =>
  participantsGroups.reduce<VotingParticipantsFormGroups>((acc, { id, people, companies }) => {
    const usersVoters = people.reduce<UserVoters>((accInner, { id: idInner, shares }) => {
      if (!shares?.length) return accInner;

      return { ...accInner, [idInner]: { ...accInner[idInner], representative: false } };
    }, {});
    const companiesVoters = companies.reduce<CompaniesVoters>(
      (accInner, { id: idInner, representatives, shares }) => {
        if (!shares?.length) return accInner;

        return {
          ...accInner,
          [idInner]: { ...accInner[idInner], representative: representatives[0].id },
        };
      },
      {},
    );
    const group = {
      isVoting: false,
      usersVoters,
      companiesVoters,
    };

    return { ...acc, [id]: group };
  }, {});

export const mapToExcludedVotersForm = (
  participantGroups: MeetingParticipatingGroupsDetailsRes[],
  companiesVoters: CompanyVoterRes[],
  usersVoters: UserVoterRes[],
  shareholding: ShareholdingGroup[],
): ExcludedGroupsVotersForm[] =>
  participantGroups.map((participantGroup) => {
    const companiesVotersFiltered = companiesVoters.filter(
      (companyVoter) => companyVoter.group.id === participantGroup.id,
    );

    const group = shareholding.filter((item) => item.id === participantGroup.id);
    const mappedCompaniesVoters: ExcludedGroupsVotersCompanies[] = companiesVotersFiltered.map(
      (item) => {
        const fullCompany = group[0].companies.find(
          (company) => company.id === item.company.id,
        ) as ShareholderCompany;

        return {
          ...item,
          fullCompany,
        };
      },
    );

    const userVotersFiltered = usersVoters.filter(
      (userVoter) => userVoter.group.id === participantGroup.id,
    );

    const mappedUserVoters: ExcludedGroupsVotersPeople[] = userVotersFiltered.map((userVoter) => {
      const fullUser = group[0].people.find(
        (person) => person.id === userVoter.user.id,
      ) as ShareholderUser;

      return {
        ...userVoter,
        fullUser,
      };
    });

    return {
      ...participantGroup,
      companiesVoters: mappedCompaniesVoters,
      usersVoters: mappedUserVoters,
    };
  });

export const filterExcludedVoters = (
  excludedGroupsVotersForm: ExcludedGroupsVotersForm[],
  term: string,
): ExcludedGroupsVotersForm[] =>
  excludedGroupsVotersForm.reduce<ExcludedGroupsVotersForm[]>((acc, curr) => {
    const filteredCompanies = curr.companiesVoters.filter((company) =>
      company.fullCompany.name.toLocaleLowerCase().includes(term.toLowerCase()),
    );
    const filteredPeople = curr.usersVoters.filter((person) =>
      getFullName({ names: person.fullUser.names, surname: person.fullUser.surname })
        .toLowerCase()
        .includes(term.toLowerCase()),
    );

    if (filterCompanies.length !== 0 || filteredPeople.length !== 0) {
      return [...acc, { ...curr, companiesVoters: filteredCompanies, usersVoters: filteredPeople }];
    }

    return acc;
  }, []);
