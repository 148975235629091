import React from 'react';
import { Flex } from 'components/layout';
import { PageSubheader } from 'components/common';

import NameInputs from '../NameInputs';
import SurnameInputs from '../SurnameInputs';

const ClosingMeeting = () => (
  <Flex align="start" justify="space-between">
    <Flex xs={7}>
      <PageSubheader text="protocol.closing_meeting" />
    </Flex>
    <Flex xs={12} gap={3}>
      <NameInputs name="closer" />
      <SurnameInputs name="closer" />
    </Flex>
  </Flex>
);

export default ClosingMeeting;
