import React from 'react';
import { Flex } from 'components/layout';
import { TextArea } from 'components/forms';
import { PageSubheader } from 'components/common';

const Conclusions: React.FC = () => (
  <Flex align="start" justify="space-between">
    <Flex xs={7}>
      <PageSubheader text="protocol.conclusions" />
    </Flex>
    <Flex xs={12} gap={2} justify="start">
      <TextArea
        size="responsive"
        name="anyOtherBusiness"
        label="protocol.contents"
        rows={5}
        cols={60}
      />
    </Flex>
  </Flex>
);

export default Conclusions;
