import { CompanyEntity, Names, Surname } from 'app-types';

interface Person {
  names: Names;
  surname: Surname;
  email?: string;
  placeholder?: string;
}

type Company = Pick<CompanyEntity, 'name'>;

type GetFullName = (person: Person) => string;

export const getFullName: GetFullName = ({ names, surname, email, placeholder }) => {
  const [firstName, secondName] = names;
  const [firstSurname, secondSurname] = surname;

  if (!firstName || !firstSurname) {
    return placeholder ? `${placeholder}` : `${email}`;
  }
  if (firstName && !secondName && firstSurname && !secondSurname) {
    return `${firstName} ${firstSurname}`;
  }
  if (firstName && secondName && firstSurname && !secondSurname) {
    return `${firstName}, ${secondName} ${firstSurname}`;
  }
  if (firstName && !secondName && firstSurname && secondSurname) {
    return `${firstName} ${firstSurname}-${secondSurname}`;
  }

  return `${firstName}, ${secondName} ${firstSurname}-${secondSurname}`;
};

export const getName = (shareholder: Company | Person): string => {
  if ('names' in shareholder) {
    return getFullName({ ...shareholder, placeholder: shareholder?.email });
  }

  return shareholder.name;
};
