import React from 'react';
import { Text } from 'components/common';
import { Flex } from 'components/layout';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { mapToAddUserShareholderReq } from 'utils';
import { fetchShareholdingData, successNotification } from 'slices';
import { AddMemberOrSubjectData, GroupMemberFormData } from 'types';

import MemberForm from '../MemberForm';

const AddMember: React.FC = () => {
  const { hideModal, data } = useModal<undefined, AddMemberOrSubjectData>();
  const dispatch = useAppDispatch();
  const api = useApi();

  const onSubmit = async (formData: GroupMemberFormData) => {
    const { companyId, group } = data;
    const reqData = mapToAddUserShareholderReq(formData);

    await api.post(
      `/company/${companyId}/shareholding/groups/${group.id}/shareholders/user`,
      reqData,
      () => {
        dispatch(successNotification('notifications.added_member_to_group'));
        hideModal();
      },
    );
    dispatch(fetchShareholdingData(companyId));
  };

  return (
    <Flex direction="col" gap={4}>
      <Text text="modals.add_member.title" size={4} weight="bold" />
      <MemberForm
        variant="add_member"
        onSubmit={onSubmit}
        onCancel={hideModal}
        companyType={data.companyType}
      />
    </Flex>
  );
};
export default AddMember;
