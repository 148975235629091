import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { MeetingCurrentState, VotingResult } from 'app-types';
import { InlineButton } from 'components/common';
import { VotingResultsContainer } from 'components/containers';
import { Flex } from 'components/layout';
import { useAppDispatch, useAppSelector } from 'hooks';
import { prepareModal } from 'slices';
import { ModalType, VotingResultsVariant, MeetingParams } from 'types';
import { isResumptionVotingResultState, isVotingResultState, usersNotVotedObjection } from 'utils';

import VotingResultsList from './VotingResultsList';
import VotingStatus from './VotingStatus';

const getVariant = (state?: MeetingCurrentState): VotingResultsVariant => {
  if (isResumptionVotingResultState(state)) return 'resumption';
  return 'resolution';
};

const VotingResults: React.FC = () => {
  const { state, data } = useAppSelector(({ meeting }) => meeting);
  const { meetingId } = useParams<MeetingParams>();
  const dispatch = useAppDispatch();
  const variant = useMemo(() => getVariant(state), [state]);

  if (!isVotingResultState(state)) return null;

  const isReportObjectionButtonDisabled =
    state?.isResumptionTime ||
    !data?.shareholders?.length ||
    usersNotVotedObjection(data?.shareholders, state.objections).length === 0;

  const onObjectionReport = () => {
    dispatch(
      prepareModal({
        type: ModalType.ReportObjection,
        data: {
          votingId: state.votingId,
          shareholders: data!.shareholders,
          objections: state.objections,
          meetingId,
        },
        isVisible: true,
      }),
    );
  };

  return (
    <VotingResultsContainer>
      <VotingStatus status={state.result} variant={variant} />
      {state.result !== VotingResult.INTERRUPTED && (
        <>
          <VotingResultsList state={state} />
          <Flex justify="end">
            <InlineButton
              text="pages.meeting.report_objection"
              type="button"
              onClick={onObjectionReport}
              underline
              disabled={isReportObjectionButtonDisabled}
            />
          </Flex>
        </>
      )}
    </VotingResultsContainer>
  );
};

export default VotingResults;
