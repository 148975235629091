import { useCallback, useMemo } from 'react';
import { downloadFile, downloadPdf } from 'services';
import { FileEntity } from 'app-types';

import { useAppDispatch } from './redux';

interface DownloadMeetingZipPackageParams {
  meetingId: string;
  fileName: string;
}

export const useFileDownload = () => {
  const dispatch = useAppDispatch();

  const downloadMeetingFile = useCallback(
    async ({ id, name }: Pick<FileEntity, 'id' | 'name'>) =>
      downloadFile(dispatch, `/meeting/file/${id}`, name),
    [dispatch],
  );

  const downloadMeetingZipPackage = useCallback(
    async ({ meetingId, fileName }: DownloadMeetingZipPackageParams) =>
      downloadFile(dispatch, `/meeting/${meetingId}/zip-package`, fileName),
    [dispatch],
  );

  const downloadProtocolPreview = useCallback(
    async ({ fileName }: { fileName: string }) =>
      downloadPdf(dispatch, `/protocol/schema?${new URLSearchParams({ fileName })}`),
    [dispatch],
  );

  const downloadCSVTemplate = useCallback(
    async () => downloadFile(dispatch, '/company/schemas/shareholding-csv', 'csv-template'),
    [dispatch],
  );

  return useMemo(
    () => ({
      downloadCSVTemplate,
      downloadMeetingFile,
      downloadMeetingZipPackage,
      downloadProtocolPreview,
    }),
    [downloadCSVTemplate, downloadMeetingFile, downloadMeetingZipPackage, downloadProtocolPreview],
  );
};
