import React from 'react';
import { Flex } from 'components/layout';
import { PageSubheader } from 'components/common';

import NameInputs from '../NameInputs';
import SurnameInputs from '../SurnameInputs';

const Chairman = () => (
  <Flex align="start" justify="space-between">
    <Flex xs={7}>
      <PageSubheader text="protocol.chairman" />
    </Flex>
    <Flex xs={12} gap={3} justify="start">
      <NameInputs name="chairman" />
      <SurnameInputs name="chairman" />
    </Flex>
  </Flex>
);

export default Chairman;
