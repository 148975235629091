import React from 'react';
import { Button, Text } from 'components/common';
import { DeleteModalContentContainer, ModalActionButtonsContainer } from 'components/containers';
import { useModal } from 'hooks';
import { ShowVotesData } from 'types';

import VotersList from './VotersList';

const ShowVotes: React.FC = () => {
  const {
    hideModal,
    data: { variant, voters },
  } = useModal<undefined, ShowVotesData>();

  return (
    <DeleteModalContentContainer>
      <Text text={`modals.show_votes.votes_${variant}`} size={4} weight="bold" />
      <VotersList voters={voters} />
      <ModalActionButtonsContainer>
        <Button text="application.exit" onClick={hideModal} variant="secondary" />
      </ModalActionButtonsContainer>
    </DeleteModalContentContainer>
  );
};

export default ShowVotes;
