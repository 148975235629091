import React from 'react';
import { useAppSelector } from 'hooks';

import Notification from '../Notification';
import styles from './NotificationContainer.module.scss';

const Notifications = () => {
  const { notifications } = useAppSelector((state) => state);

  return (
    <div className={styles.container}>
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
};

export default Notifications;
