import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import clsx from 'clsx';
import { GetMeetingCurrentStateRes, MeetingStatus, VotingResult } from 'app-types';
import { useAppDispatch, useAppSelector, useCountdown, useMeetingOnlineStatus } from 'hooks';
import { fetchMeetingData } from 'slices';
import { MeetingParams } from 'types';
import { Icon, Text } from 'components/common';
import { Flex } from 'components/layout';
import { areVotingStateEqual } from 'utils';

import styles from './DesktopCounter.module.scss';

interface Props {
  goalDate: string | undefined | Date;
  isBetweenVoting?: boolean;
  isPaused?: boolean;
}

const DesktopCounter: React.FC<Props> = ({ goalDate, isBetweenVoting, isPaused }) => {
  const { sendOnlineStatus } = useMeetingOnlineStatus();
  const dispatch = useAppDispatch();
  const { meetingId } = useParams<MeetingParams>();
  const meeting = useAppSelector((state) => state.meeting);

  const onCountdownEnd = useCallback(async () => {
    for (let i = 0; i < 10; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const data = await dispatch(fetchMeetingData({ meetingId }));
      const payload = data.payload as GetMeetingCurrentStateRes;

      const isMeetingEnded = payload.data && payload.data.status === MeetingStatus.ENDED;
      const areStatesEqual = areVotingStateEqual(meeting.state, payload.state);
      const isResultInterrupted =
        payload.state &&
        'result' in payload.state &&
        payload.state.result === VotingResult.INTERRUPTED;

      if (isMeetingEnded || !areStatesEqual || (areStatesEqual && isResultInterrupted)) {
        break;
      }

      // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    await sendOnlineStatus(meetingId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sendOnlineStatus, meetingId]);

  const { text } = useCountdown(onCountdownEnd, goalDate, isPaused);

  return (
    <Flex direction="col">
      <Text
        text={
          isBetweenVoting ? 'pages.meeting.till_end_of_break' : 'pages.meeting.till_end_of_voting'
        }
        size={2}
      />
      <Flex gap={1} align="center" className={clsx(styles.text, { [styles.paused]: isPaused })}>
        <Icon name={isPaused ? 'pause' : 'clock'} />
        <Text text={isPaused ? '00:00' : text} shouldTranslate={false} />
      </Flex>
    </Flex>
  );
};

export default DesktopCounter;
