import { BaseShares, SharesType } from 'app-types';

export const getSharesTranslations = (type: SharesType | null): string => {
  switch (type) {
    case SharesType.NON_VOTING:
      return 'shares_types.non_voting';
    case SharesType.PREFERENTIAL:
      return 'shares_types.preferential';
    case SharesType.TREASURY:
      return 'shares_types.treasury';
    default:
      return 'shares_types.normal';
  }
};

interface SharesWithMultiplier {
  text: string;
  translationArgs?: Record<string, number>;
}

export const getSharesWithMultiplierTranslations = ({
  type,
  multiplier,
}: BaseShares): SharesWithMultiplier => {
  switch (type) {
    case SharesType.NON_VOTING:
      return { text: 'shares.non_voting' };
    case SharesType.PREFERENTIAL:
      return { text: 'shares.preferential', translationArgs: { multiplier: multiplier! } };
    case SharesType.TREASURY:
      return { text: 'shares.treasury' };
    default:
      return { text: 'shares.normal' };
  }
};
