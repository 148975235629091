import React from 'react';
import { ExcludedGroupsVotersForm } from 'types';

import GroupContainer from './GroupContainer';

interface Props {
  excludedGroupsVotersForm: ExcludedGroupsVotersForm[];
  isUserSearching: boolean;
}

const ParticipantsList: React.FC<Props> = ({ excludedGroupsVotersForm, isUserSearching }) => (
  <>
    {excludedGroupsVotersForm.map((participantsGroup) => (
      <GroupContainer
        key={participantsGroup.id}
        excludedGroup={participantsGroup}
        isContentVisible={isUserSearching && excludedGroupsVotersForm.length < 3}
      />
    ))}
  </>
);
export default ParticipantsList;
