import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserRole } from 'app-types';

interface RouterState {
  redirectedFrom: string;
  requiredRole?: UserRole;
}

const initialState: RouterState = {
  redirectedFrom: '',
};

export const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setRedirectFrom: (state, { payload }: PayloadAction<RouterState>) => payload,
  },
});

export const { setRedirectFrom } = routerSlice.actions;
