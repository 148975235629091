import React from 'react';
import { DesktopVotingResultsContainer } from 'components/containers';
import { Vote } from 'components/meeting';

import DesktopCounter from '../DesktopCounter';

interface Props {
  isMeetingPaused: boolean;
  goalDate?: string | Date;
}

const DesktopVote: React.FC<Props> = ({ isMeetingPaused, goalDate }) => (
  <DesktopVotingResultsContainer>
    <DesktopCounter goalDate={goalDate} isPaused={isMeetingPaused} />
    <Vote isMeetingPaused={isMeetingPaused} />
  </DesktopVotingResultsContainer>
);

export default DesktopVote;
