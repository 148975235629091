import { TaxxoInvoiceEntity } from '../taxxo';
import { CompanyEntity } from '../company';

export interface BaseSubscription {
  type: SubscriptionType;
  resolutionsCount?: number;
  votersCount?: number;
  isAssistanceNeeded?: boolean;
  taxxoInvoice: TaxxoInvoiceEntity;
  company: CompanyEntity;
}

export interface SubscriptionEntity extends BaseSubscription {
  id: string;
  startDate: Date;
  endDate: Date;
}

export enum SubscriptionType {
  PER_MEETING = 'PER_MEETING',
  MONTHLY_PAYMENT = 'MONTHLY_PAYMENT',
  ANNUALLY_PAYMENT = 'ANNUALLY_PAYMENT',
  PERMANENT = 'PERMANENT',
  PENDING = 'PENDING',
}
