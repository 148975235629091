import { Voivodeship, CompanyType, CreateCompanyReq } from 'app-types';

export enum AddCompanyStages {
  SelectPlan,
  NewCompany,
  Summary,
  Calculator,
  Payment,
}

export interface CompanyFormData extends Omit<CreateCompanyReq, 'type' | 'voivodeship'> {
  type?: CompanyType;
  voivodeship?: Voivodeship;
}
