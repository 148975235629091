import React from 'react';
import { VoteOption } from 'types';
import { Input } from 'components/forms';
import { VotingOptionInputListContainer } from 'components/containers';

interface Props {
  inputBaseName: string;
}

interface InputItem {
  type: VoteOption;
  label: string;
}

const inputs: InputItem[] = [
  {
    type: 'inFavour',
    label: 'application.yes',
  },
  {
    type: 'against',
    label: 'application.no',
  },
  {
    type: 'abstain',
    label: 'application.abstain',
  },
];

const VotingOptionInputList: React.FC<Props> = ({ inputBaseName }) => (
  <VotingOptionInputListContainer>
    {inputs.map(({ type, label }) => (
      <Input
        minNumber={0}
        key={`${inputBaseName}.${type}`}
        name={`${inputBaseName}.${type}`}
        label={label}
        type="number"
        size="responsive"
        placeholder="placeholders.number"
      />
    ))}
  </VotingOptionInputListContainer>
);

export default VotingOptionInputList;
