import { UserEntity } from './user-entity';

export type PhoneNumber = string;
export type IsPhoneNumberValid = boolean;

export type IsPasswordValid = boolean;

export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  User = 'User',
}

export enum UserStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
  Deleted = 'DELETED',
}

export type UserFilteredData = Omit<
  UserEntity,
  | 'password'
  | 'currentTokenId'
  | 'changePassToken'
  | 'changePassTokenValidTill'
  | 'updateToken'
  | 'shares'
  | 'companies'
  | 'refreshToken'
>;
