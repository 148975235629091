import React from 'react';

import { Text } from 'components/common';
import { ResolutionForm } from 'components/forms';
import { Flex } from 'components/layout';
import { resolutionFormValidationSchema } from 'config';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { fetchAddMeetingData, fetchMeetingData, successNotification } from 'slices';
import { AddEditResolutionFormData, AddResolutionData } from 'types';
import { getFormDataFromObj, appendFiles } from 'utils';

const AddResolution: React.FC = () => {
  const {
    hideModal,
    data: { meetingId, isMeetingInProgress },
  } = useModal<undefined, AddResolutionData>();
  const api = useApi();
  const dispatch = useAppDispatch();

  const onSubmit = async (formValues: AddEditResolutionFormData) => {
    const { pdf, ...values } = formValues;
    const formData = getFormDataFromObj(values);

    if (pdf && pdf.length > 0) {
      appendFiles(pdf, formData, 'pdf');
    }

    await api.post(`/meeting/${meetingId}/agenda-item`, formData, () => {
      dispatch(successNotification('notifications.added_resolution'));
      if (isMeetingInProgress) {
        dispatch(fetchMeetingData({ meetingId }));
      } else {
        dispatch(fetchAddMeetingData(meetingId));
      }

      hideModal();
    });
  };

  const onCancel = () => hideModal();

  return (
    <Flex direction="col" gap={2}>
      <Text text="modals.add_resolution.title" size={4} weight="bold" />
      <ResolutionForm
        onSubmit={onSubmit}
        validationSchema={resolutionFormValidationSchema}
        onCancel={onCancel}
        variant="add-resolution"
      />
    </Flex>
  );
};

export default AddResolution;
