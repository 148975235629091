import { useEffect } from 'react';
import { hideModal, prepareModal, showModal, unPrepareModal } from 'slices';
import { PrepareModalActionPayloads, UnPrepareModalActionPayload } from 'types';

import { useAppDispatch, useAppSelector } from './redux';

export const useModal = <OutputData = unknown, Data = unknown>(
  prepare?: boolean,
  modalPayload?: PrepareModalActionPayloads,
) => {
  const dispatch = useAppDispatch();
  const { outputData, data, ...modal } = useAppSelector((state) => state.modal);

  useEffect(() => {
    if (prepare && modalPayload?.type) {
      dispatch(prepareModal(modalPayload));
    }

    return () => {
      dispatch(unPrepareModal({ isVisible: false }));
    };
    //  eslint-disable-next-line
  }, [dispatch]);

  return {
    showModal: () => dispatch(showModal()),
    hideModal: () => dispatch(hideModal()),
    prepareModal: (payload: PrepareModalActionPayloads) => dispatch(prepareModal(payload)),
    unPrepareModal: (options: UnPrepareModalActionPayload) => dispatch(unPrepareModal(options)),
    outputData: outputData as OutputData,
    data: data as Data,
    ...modal,
  };
};
