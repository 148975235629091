export enum AllRoutes {
  Home = '/',
  NotFound = '/404',
  Login = '/login',
  Register = '/register',
  ActivateAccount = '/account-activation/:id',
  CompleteRegistration = '/complete-registration/:id',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password/:id',
  PasswordChanged = '/password-changed',
  LoginCyberId = '/login-cyber-id',
  Helpdesk = '/helpdesk',
  Meeting = '/meeting/:meetingId',
  Admin = '/admin',
  AddCompany = '/add-company',
  AdminSubscriptionCanceled = '/admin/subscription-canceled',
  AdminCompany = '/admin/:companyId',
  AdminCompanyArchive = '/admin/:companyId/archive',
  AdminCompanyArchiveMeetingDetails = '/admin/:companyId/archive/:meetingId',
  AdminCompanyAddMeeting = '/admin/:companyId/add-meeting',
  AdminCompanyContinueAddMeeting = '/admin/:companyId/add-meeting/:meetingId',
  AdminCompanyDashboard = '/admin/:companyId/dashboard',
  AdminCompanyDraftMeetings = '/admin/:companyId/draft-meetings',
  AdminCompanyEditCompany = '/admin/:companyId/edit-company',
  AdminCompanyGroups = '/admin/:companyId/groups',
  AdminCompanyPlannedMeetings = '/admin/:companyId/planned-meetings',
  PaymentStatus = '/payment-status',
  SuperAdminManagement = '/super-admin/management',
  AdminProtocol = '/admin/protocol/:meetingId',
  Profile = '/profile',
}

export enum BaseRoute {
  Home = '/',
  NotFound = '404',
  Login = 'login',
  Register = '/register',
  ActivateAccount = '/account-activation/:id',
  CompleteRegistration = '/complete-registration/:id',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password/:id',
  PasswordChanged = '/password-changed',
  LoginCyberId = 'login-cyber-id',
  Helpdesk = 'helpdesk',
  Meeting = 'meeting/:meetingId',
  Admin = 'admin/*',
  SuperAdmin = 'super-admin/*',
  AddCompany = 'add-company',
  PaymentStatus = 'payment-status',
  Profile = '/profile',
}

export enum AdminRoute {
  Company = ':companyId/*',
  Protocol = 'protocol/:meetingId',
  SubscriptionCanceled = 'subscription-canceled',
}

export enum AdminCompanyRoute {
  AddMeeting = 'add-meeting',
  Archive = 'archive',
  ArchiveMeetingDetails = 'archive/:meetingId',
  ContinueAddingMeeting = 'add-meeting/:meetingId',
  Dashboard = 'dashboard',
  DraftMeetings = 'draft-meetings',
  EditCompany = 'edit-company',
  Groups = 'groups',
  PlannedMeetings = 'planned-meetings',
}

export enum SuperAdminRoute {
  Management = 'management',
}

type ArgsWithCompanyIdParam = {
  path:
    | AllRoutes.ActivateAccount
    | AllRoutes.AdminCompany
    | AllRoutes.AdminCompanyAddMeeting
    | AllRoutes.AdminCompanyArchive
    | AllRoutes.AdminCompanyDashboard
    | AllRoutes.AdminCompanyDraftMeetings
    | AllRoutes.AdminCompanyEditCompany
    | AllRoutes.AdminCompanyGroups
    | AllRoutes.AdminCompanyPlannedMeetings
    | AdminCompanyRoute.AddMeeting
    | AdminCompanyRoute.Archive
    | AdminCompanyRoute.DraftMeetings
    | AdminCompanyRoute.EditCompany
    | AdminCompanyRoute.Groups
    | AdminCompanyRoute.PlannedMeetings;
  params: { companyId: string };
};
type ArgsWithUserIdParam = {
  path: AllRoutes.ActivateAccount;
  params: { id: string };
};

type ArgsWithCompanyIdAndMeetingId = {
  path:
    | AllRoutes.AdminCompanyContinueAddMeeting
    | AllRoutes.AdminCompanyArchiveMeetingDetails
    | AdminCompanyRoute.ContinueAddingMeeting
    | AdminCompanyRoute.ArchiveMeetingDetails;
  params: { companyId: string; meetingId: string };
};

type Args =
  | ArgsWithUserIdParam
  | ArgsWithCompanyIdParam
  | ArgsWithCompanyIdAndMeetingId
  | {
      path: AllRoutes.Meeting;
      params: { meetingId: string };
    }
  | {
      path: AllRoutes.AdminProtocol;
      params: { meetingId: string };
    }
  | {
      path: AllRoutes;
      params: { companyId: string };
    };

type ArgsWithParams = Extract<Args, { path: string; params: Record<string, string> }>;

export const createPath = (args: Args): string => {
  if (!Object.prototype.hasOwnProperty.call(args, 'params')) return args.path;

  // Create a path by replacing params in the route definition
  return Object.entries((args as ArgsWithParams).params).reduce(
    (previousValue: string, [param, value]) => previousValue.replace(`:${param}`, `${value}`),
    args.path,
  );
};
