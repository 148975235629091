export enum ApiResult {
  error,
  ok,
}

export enum Status {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export interface OperationStatus {
  status: Status;
  payload?: any;
}
