import { useCallback } from 'react';
import { callBlockchain } from 'services';
import {
  AddTransactionToMempoolData,
  AddTransactionToMempoolParams,
  AddTransactionToMempoolProps,
  BlockchainMethods,
  PingData,
  PingProps,
  SaveVotingResultsData,
  SaveVotingResultsParams,
  SaveVotingResultsProps,
} from 'types';
import { useAppDispatch } from './redux';

export const useBlockchain = () => {
  const dispatch = useAppDispatch();

  const ping = useCallback(
    (props?: PingProps) =>
      callBlockchain<undefined, PingData>({
        dispatch,
        method: BlockchainMethods.Ping,
        params: undefined,
        ...props,
      }),
    [dispatch],
  );

  const addTransactionToMempool = useCallback(
    (props: AddTransactionToMempoolProps) =>
      callBlockchain<AddTransactionToMempoolParams, AddTransactionToMempoolData>({
        dispatch,
        method: BlockchainMethods.AddTransactionToMempool,
        ...props,
      }),
    [dispatch],
  );

  const saveVotingResults = useCallback(
    async (props: SaveVotingResultsProps) =>
      callBlockchain<SaveVotingResultsParams[], SaveVotingResultsData>({
        dispatch,
        method: BlockchainMethods.SaveVotingResults,
        stringifyParams: true,
        ...props,
      }),
    [dispatch],
  );

  return {
    ping,
    addTransactionToMempool,
    saveVotingResults,
  };
};
