import React from 'react';
import { Dropdown } from 'components/forms';
import { Flex } from 'components/layout';
import { PageSubheader } from 'components/common';
import { NotifiedBy } from 'app-types';
import { DropdownItem } from 'types';

const notificationType: DropdownItem[] = [
  { text: 'protocol.notified_by.courier', value: NotifiedBy.Courier },
  { text: 'protocol.notified_by.email', value: NotifiedBy.Email },
  { text: 'protocol.notified_by.letter', value: NotifiedBy.Letter },
];

const Notifications = () => (
  <Flex align="start" justify="space-between">
    <Flex xs={7}>
      <PageSubheader text="protocol.notification" />
    </Flex>
    <Flex xs={12}>
      <Dropdown
        required
        size="protocol"
        name="notification"
        label="protocol.notification_select"
        options={notificationType}
      />
    </Flex>
  </Flex>
);

export default Notifications;
