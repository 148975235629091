import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ReportIssueReq } from 'app-types';
import { Button } from 'components/common';
import { ButtonsContainer } from 'components/containers';
import { Form, Input, TextArea } from 'components/forms';
import { Flex } from 'components/layout';
import { helpdeskReportIssueValidationSchema } from 'config';
import { useApi, useAppDispatch } from 'hooks';
import { successNotification } from 'slices';

import CategoryDropdown from './CategoryDropdown';
import SubcategoryDropdown from './SubcategoryDropdown';

const ReportIssueForm: React.FC = () => {
  const api = useApi();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<ReportIssueReq> = async (formValues) =>
    api.post('/helpdesk/issue', formValues, () => {
      dispatch(successNotification('notifications.reported_issue'));
    });

  return (
    <Form
      submitHandler={onSubmit}
      validationSchema={helpdeskReportIssueValidationSchema}
      resetOnSuccessSubmit
    >
      <Flex direction="col" gap={4}>
        <Flex gap={4}>
          <CategoryDropdown />
          <SubcategoryDropdown />
        </Flex>
        <TextArea name="description" label="helpdesk_issue.description" cols={65} rows={5} />
        <Flex gap={4}>
          <Input name="senderEmail" label="user.email" />
          <Input name="senderFullName" label="helpdesk_issue.sender_full_name" />
        </Flex>
        <ButtonsContainer>
          <Button text="application.send" variant="secondary" />
        </ButtonsContainer>
      </Flex>
    </Form>
  );
};

export default ReportIssueForm;
