import React from 'react';
import { ProtocolFormData } from 'types';
import { Flex } from 'components/layout';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PageSubheader, HorizontalLine } from 'components/common';

import ListItem from './ListItem';

const CompanyRepresentative: React.FC = () => {
  const { control } = useFormContext<ProtocolFormData>();
  const { fields } = useFieldArray({
    control,
    name: 'representativeRoleInCompanyList',
  });

  if (!fields.length) return null;

  return (
    <>
      <Flex align="start" justify="space-between">
        <Flex xs={7}>
          <PageSubheader text="protocol.company_representative_role" />
        </Flex>
        <Flex xs={12} gap={2} justify="start">
          {fields.map((listItem, index) => (
            <ListItem
              key={listItem.id}
              company={listItem.company}
              voter={listItem.voter}
              ordinalNumber={index + 1}
            />
          ))}
        </Flex>
      </Flex>
      <HorizontalLine />
    </>
  );
};

export default CompanyRepresentative;
