import { useState, useEffect, useMemo, useCallback } from 'react';
import { formatTimeDistance } from 'utils';
import { isAfter } from 'date-fns';

import { usePolling } from './usePolling';

type OnFinish = () => void;
type EndDateStr = string | undefined | Date;
type IsCountdownFinished = boolean | undefined;

interface Countdown {
  text: string;
  isCountdownFinished: boolean;
}

/**
 * Countdown from now to x hook.
 * @param {OnFinish} onFinish - must be memoized (useCallback)
 * @param {EndDateStr} endDateStr
 * @param {IsCountdownFinished} isCountdownPaused
 * @returns {Countdown}
 */
export const useCountdown = (
  onFinish: OnFinish,
  endDateStr: EndDateStr,
  isCountdownPaused: IsCountdownFinished,
): Countdown => {
  const endDate = useMemo(() => new Date(endDateStr ?? ''), [endDateStr]);
  const [text, setText] = useState(formatTimeDistance(new Date(), endDate));
  const [isCountdownFinished, setIsCountdownFinished] = useState(isAfter(new Date(), endDate));

  const onTick = useCallback(() => {
    const now = new Date();
    const isFinished = isAfter(now, endDate);
    setText(formatTimeDistance(now, endDate));
    setIsCountdownFinished(isFinished);
  }, [endDate]);

  usePolling(onTick, { interval: 1000, isStopped: isCountdownPaused || isCountdownFinished });

  useEffect(() => {
    if (isCountdownFinished) {
      onFinish();
    }
  }, [isCountdownFinished, onFinish]);

  return { text, isCountdownFinished };
};
