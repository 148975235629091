import React from 'react';
import { AddShareholdingGroupReq } from 'app-types';
import { Text } from 'components/common';
import { GroupForm } from 'components/forms';
import { Flex } from 'components/layout';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { fetchShareholdingData, successNotification } from 'slices';
import { EditGroupData } from 'types';

const EditGroup: React.FC = () => {
  const dispatch = useAppDispatch();
  const { hideModal, data } = useModal<undefined, EditGroupData>();
  const api = useApi();

  const onSubmit = async (formData: AddShareholdingGroupReq) => {
    const { companyId, group } = data;
    await api.patch(`/company/${companyId}/shareholding/groups/${group.id}`, formData, () => {
      dispatch(fetchShareholdingData(companyId));
      dispatch(successNotification('notifications.edited_shareholding_group'));
      hideModal();
    });
  };

  return (
    <Flex direction="col" gap={2}>
      <Text text="modals.edit_group.title" size={4} weight="bold" />
      <GroupForm
        variant="edit_group"
        onSubmit={onSubmit}
        onCancel={hideModal}
        defaultValues={{ name: data.group.name }}
      />
    </Flex>
  );
};

export default EditGroup;
