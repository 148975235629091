import { NewMeetingForm, VotingParticipantsFormGroups, ExcludedVotersForm } from 'types';
import { setHoursAndMinutes } from 'utils';
import {
  CreateDraftMeetingReq,
  SetMeetingParticipantGroupReq,
  SetUserVoterReq,
  SetCompanyVoterReq,
  UpdateAgendaReq,
  MeetingType,
  AgendaItemRes,
  MeetingVoters,
  ExcludeVotersReq,
} from 'app-types';

export const prepareDraftMeetingRequest = (data: NewMeetingForm): CreateDraftMeetingReq => {
  const participants: SetMeetingParticipantGroupReq[] = data.participants
    .filter((item) => item.isChecked)
    .map((item) => ({ groupId: item.id }));
  const formattedDate = setHoursAndMinutes(data.date, data.time);

  return {
    type: data.type === MeetingType.Authority ? data.customMeetingType : data.type,
    formality: data.formality,
    quorum: data.quorum || 0,
    date: formattedDate,
    notary: data.notary || undefined,
    participants,
  };
};

export const mapToMeetingVotersArr = (groups: VotingParticipantsFormGroups): MeetingVoters[] =>
  Object.entries(groups).reduce<MeetingVoters[]>((acc, [groupId, groupData]) => {
    const usersVoters =
      groupData.usersVoters &&
      Object.entries(groupData.usersVoters).reduce<SetUserVoterReq[]>(
        (accInner, [userId, { isVoting, representative }]) => {
          if (!isVoting) return accInner;
          if (typeof representative === 'boolean')
            return [...accInner, { userId, voterId: userId }];

          return [...accInner, { userId, voterId: representative }];
        },
        [],
      );

    const companiesVoters =
      groupData.companiesVoters &&
      Object.entries(groupData.companiesVoters).reduce<SetCompanyVoterReq[]>(
        (accInner, [companyId, { isVoting, representative }]) =>
          isVoting ? [...accInner, { companyId, voterId: representative }] : accInner,
        [],
      );

    if (![...usersVoters, ...companiesVoters].length) return acc;

    return [
      ...acc,
      { groupId, companiesVoters: companiesVoters ?? [], usersVoters: usersVoters ?? [] },
    ];
  }, []);

export const mapToUpdateAgendaReq = (agendaItemRes: AgendaItemRes[]): UpdateAgendaReq => ({
  agendaItems: agendaItemRes.map(({ id }, i) => ({ id, ordinalNumber: i + 1 })),
});

export const mapToExcludeVotersReq = (formData: ExcludedVotersForm): ExcludeVotersReq => {
  const { excludedCompaniesVoters, excludedUsersVoters } = formData;

  const prepareExcludedVoters = (data: { [key: string]: boolean }) =>
    Object.entries(data)
      .filter(([, value]) => !value)
      .map(([key]) => ({ id: key }));

  const preparedExcludedUsersVoters = prepareExcludedVoters(excludedUsersVoters);
  const preparedExcludedCompaniesVoters = prepareExcludedVoters(excludedCompaniesVoters);

  return {
    excludedUsersVoters: preparedExcludedUsersVoters,
    excludedCompaniesVoters: preparedExcludedCompaniesVoters,
  };
};
