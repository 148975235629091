import React from 'react';
import { ResolutionData } from 'app-types';
import { FoldingContainer, Text } from 'components/common';
import { AgendaItemContentContainer } from 'components/containers';
import { useAppDispatch, useAppSelector } from 'hooks';
import { prepareModal } from 'slices';
import { ModalType } from 'types';
import { isVotingState } from 'utils';

import FileList from '../FileList';
import VotingInfo from '../VotingInfo';
import Buttons from './Buttons';

interface Identifiers {
  meetingId: string;
  agendaItemId: string;
}

interface Props {
  ordinalNumber: number;
  resolutionData: ResolutionData;
  identifiers: Identifiers;
  isActive?: boolean;
  votersLeft?: number;
}

const AgendaItem: React.FC<Props> = ({
  ordinalNumber,
  resolutionData,
  identifiers,
  isActive,
  votersLeft,
}) => {
  const { meetingId, agendaItemId } = identifiers;
  const { id, title, description, files, voting, quorum } = resolutionData;
  const dispatch = useAppDispatch();
  const { state, data } = useAppSelector(({ meeting }) => meeting);

  const onEdit = () => {
    dispatch(
      prepareModal({
        type: ModalType.EditResolution,
        data: {
          meetingId,
          agendaItemId,
          resolution: resolutionData,
          isMeetingInProgress: true,
          isCurrentlyVotingOnThatResolution: isVotingState(state) && state?.resolution?.id === id,
        },
        isVisible: true,
      }),
    );
  };

  const onDelete = () => {
    dispatch(
      prepareModal({
        type: ModalType.DeleteResolution,
        data: { agendaItemId, meetingId, title, isMeetingInProgress: true },
        isVisible: true,
      }),
    );
  };

  const onEndVoting = () => {
    dispatch(
      prepareModal({
        type: ModalType.EndVoting,
        data: { votingId: voting.id, resolutionTitle: title, meetingId },
        isVisible: true,
      }),
    );
  };

  const onManagingExcludedVoters = () => {
    if (data?.company?.id) {
      dispatch(
        prepareModal({
          type: ModalType.ManageExcludedVoters,
          data: { meetingId, companyId: data.company.id, votingId: voting.id },
          isVisible: true,
        }),
      );
    }
  };

  return (
    <FoldingContainer
      index={ordinalNumber}
      text={title}
      iconName="pdf"
      iconCount={files.length}
      bgColor={isActive ? 'blue' : 'white'}
      isContentVisible={isActive}
    >
      <AgendaItemContentContainer>
        {description && <Text text={description} shouldTranslate={false} size={3} />}
        <FileList files={files} isClickable />
        <VotingInfo data={{ ...voting, quorum }} />
        <Buttons
          votingStatus={voting.status}
          onEdit={onEdit}
          onDelete={onDelete}
          onEndVoting={onEndVoting}
          onManagingExcludedVoters={onManagingExcludedVoters}
          votersLeft={votersLeft}
        />
      </AgendaItemContentContainer>
    </FoldingContainer>
  );
};

export default AgendaItem;
