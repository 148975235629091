import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CONFIG } from 'config';
import { Card } from 'components/layout';
import { Text } from 'components/common';
import { countPrecise, formatPrice } from 'utils';

import styles from './SummaryCard.module.scss';

const countTotalPrice = (
  voters: number,
  resolutions: number,
  isAssistanceNeeded: boolean,
): number => {
  const totalResolutionsPrice = resolutions * CONFIG.SUBSCRIPTION.RESOLUTION_PRICE;
  const totalVotersPrice = voters * CONFIG.SUBSCRIPTION.VOTER_PRICE;

  let totalPrice = countPrecise(totalResolutionsPrice, totalVotersPrice);

  if (isAssistanceNeeded) {
    totalPrice = countPrecise(totalPrice, CONFIG.SUBSCRIPTION.ASSISTANCE_PRICE);
  }

  return totalPrice;
};

const SummaryCard: React.FC = () => {
  const { watch } = useFormContext();
  const votersCount = watch('votersCount');
  const resolutionsCount = watch('resolutionsCount');
  const isAssistanceNeeded = watch('isAssistanceNeeded');

  const totalAmount = useMemo(
    () => countTotalPrice(votersCount, resolutionsCount, isAssistanceNeeded),
    [votersCount, resolutionsCount, isAssistanceNeeded],
  );

  return (
    <Card className={styles.card} direction="col" align="center" justify="center">
      <Text text="calculator.total_amount_title" />
      <Text
        text="calculator.total_amount"
        translationArgs={{
          totalAmount: totalAmount ? formatPrice(totalAmount) : formatPrice(0),
          currency: 'zł',
        }}
        size={13}
        color="tertiary"
        weight="bold"
      />
    </Card>
  );
};

export default SummaryCard;
