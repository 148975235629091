import { LimitedLiabilityMeetingProtocol } from './limited-liability-meeting-protocol';
import { JointStockMeetingProtocol } from './joint-stock-meeting-protocol';
import { AuthorityMeetingProtocol } from './authority-meeting-protocol';
import { SimpleJointStockCompanyProtocol } from './simple-joint-stock-meeting-protocol';

export type ProtocolSchema =
  | LimitedLiabilityMeetingProtocol
  | SimpleJointStockCompanyProtocol
  | JointStockMeetingProtocol
  | AuthorityMeetingProtocol;

export enum ProtocolSchemaFileName {
  LimitedLiability = 'Wzór protokołu zgromadzenia wspólników SP. Z O.O',
  JointStock = 'Informacje z przebiegu głosowania SA',
  SimpleJointStock = 'Wzór protokołu zgromadzenia akcjonariuszy',
  AuthorityMeeting = 'Wzór protokoł z posiedzenia organu',
}

export enum ProtocolDirName {
  GeneratedProtocol = 'WYGENEROWANY PROTOKÓŁ',
  SignedProtocol = 'PODPISANY PROTOKÓŁ',
}

export enum ProtocolSchemaValue {
  LimitedLiability = 'limited-liability-meeting-protocol-schema',
  SimpleJointStock = 'simple-joint-stock-meeting-protocol-schema',
  JointStock = 'joint-stock-meeting-protocol-schema',
  Authority = 'authority-meeting-protocol-schema',
}

export interface ProtocolData {
  schema: ProtocolSchemaValue;
  data: ProtocolSchema;
}
