import { useCallback, useMemo } from 'react';
import { callApi } from 'services';
import { FailureCb, SuccessCb } from 'types';
import { useAppDispatch } from './redux';

export const useApi = (spinnerVisible: boolean = true) => {
  const dispatch = useAppDispatch();

  const callGet = useCallback(
    <ResponseData extends unknown = undefined>(
      url: string,
      successCb?: SuccessCb<ResponseData>,
      failureCb?: FailureCb,
    ) => callApi(dispatch, 'GET', url, undefined, successCb, failureCb, spinnerVisible),
    [dispatch, spinnerVisible],
  );

  const callPost = useCallback(
    <ResponseData extends unknown = undefined, RequestData extends unknown = undefined>(
      url: string,
      requestData?: RequestData,
      successCb?: SuccessCb<ResponseData>,
      failureCb?: FailureCb,
    ) => callApi(dispatch, 'POST', url, requestData, successCb, failureCb, spinnerVisible),
    [dispatch, spinnerVisible],
  );

  const callPut = useCallback(
    <ResponseData extends unknown = undefined, RequestData extends unknown = undefined>(
      url: string,
      requestData?: RequestData,
      successCb?: SuccessCb<ResponseData>,
      failureCb?: FailureCb,
    ) => callApi(dispatch, 'PUT', url, requestData, successCb, failureCb, spinnerVisible),
    [dispatch, spinnerVisible],
  );
  const callPatch = useCallback(
    <ResponseData extends unknown = undefined, RequestData extends unknown = undefined>(
      url: string,
      requestData?: RequestData,
      successCb?: SuccessCb<ResponseData>,
      failureCb?: FailureCb,
    ) => callApi(dispatch, 'PATCH', url, requestData, successCb, failureCb, spinnerVisible),
    [dispatch, spinnerVisible],
  );

  const callDelete = useCallback(
    <ResponseData extends unknown = undefined>(
      url: string,
      successCb?: SuccessCb<ResponseData>,
      failureCb?: FailureCb,
    ) => callApi(dispatch, 'DELETE', url, undefined, successCb, failureCb, spinnerVisible),
    [dispatch, spinnerVisible],
  );

  return useMemo(
    () => ({
      get: callGet,
      post: callPost,
      put: callPut,
      patch: callPatch,
      delete: callDelete,
    }),
    [callGet, callPost, callPut, callPatch, callDelete],
  );
};
