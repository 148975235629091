import { UserEntity } from '../user';
import { RepresentativesEntity } from '../representatives';
import { ShareholderCompany } from './company';
import { SubscriptionEntity } from '../subscription';

export interface BaseCompany {
  name: string;
  type: CompanyType;
  regon: string;
  nip: string;
  krs: string;
  country: string;
  voivodeship: Voivodeship;
  district: string;
  commune: string;
  city: string;
  streetName: string;
  streetNumber: string;
  apartmentNumber?: string | null;
  zipCode: string;
}

export interface CompanyEntity extends BaseCompany {
  id: string;
  aggregatorId: string;
  initialCapital: InitialCapital;
  createdAt: Date;
  logo: CompanyLogo;
  shareholding: ShareholdingEntity;
  shares: SharesEntity[];
  owner: UserEntity;
  subscription: SubscriptionEntity;
}

export enum CompanyType {
  LIMITED_LIABILITY_COMPANY = 'SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ',
  JOINT_STOCK_COMPANY = 'SPÓŁKA AKCYJNA',
  SIMPLE_JOINT_STOCK_COMPANY = 'PROSTA SPÓŁKA AKCYJNA',
}

export enum Voivodeship {
  LowerSilesia = 'DOLNOŚLĄSKIE',
  Kuyavia_Pomerania = 'KUJAWSKO-POMORSKIE',
  Lublin = 'LUBELSKIE',
  Lubusz = 'LUBUSKIE',
  LesserPoland = 'MAŁOPOLSKIE',
  Masovia = 'MAZOWIECKIE',
  Subcarpathia = 'PODKARPACKIE',
  Pomerania = 'POMORSKIE',
  Silesia = 'ŚLĄSKIE',
  Warmia_Masuria = 'WARMIŃSKO-MAZURSKIE',
  GreaterPoland = 'WIELKOPOLSKIE',
  WestPomerania = 'ZACHODNIOPOMORSKIE',
  Podlasie = 'PODLASKIE',
  Lodz = 'ŁÓDZKIE',
  Opole = 'OPOLSKIE',
  HolyCross = 'ŚWIĘTOKRZYSKIE',
}

export interface InitialCapital {
  value: string;
  currency: string;
}

export interface CompanyLogo {
  awsPath: string;
  fileName: string;
  imgBase64: string;
}

export interface ShareholdingEntity {
  id: string;
  groups: GroupEntity[];
  representatives: RepresentativesEntity[];
}

export interface BaseGroup {
  name: string;
  people: UserEntity[];
  companies: ShareholderCompany[];
}

export interface GroupEntity extends BaseGroup {
  id: string;
}

export interface BaseShares {
  type: SharesType | null;
  multiplier?: number;
  value: number;
  isQuorumIncluded?: boolean;
}

export interface SharesEntity extends BaseShares {
  id: string;
  user: UserEntity | null;
  company: CompanyEntity | null;
  shareholding: ShareholdingEntity;
}

export enum SharesType {
  NON_VOTING = 'NON_VOTING',
  PREFERENTIAL = 'PREFERENTIAL',
  TREASURY = 'TREASURY',
}
