import React, { useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Flex } from 'components/layout';
import { RadioItem } from 'types';

import Radio from '../Radio';
import FieldErrorMessage from '../FieldErrorMessage';
import styles from './RadioGroup.module.scss';

interface Props {
  name: string;
  items: RadioItem[];
  disabled?: boolean;
  direction?: 'col' | 'row';
  gap?: number;
}

const RadioGroup: React.FC<Props> = ({ name, items, disabled, gap = 1, direction = 'col' }) => {
  const {
    formState: { errors },
  } = useFormContext();
  const id = useId();

  return (
    <Flex className={styles.wrapper} direction={direction} gap={gap}>
      {items.map(({ value, checked, label }, i) => (
        <Radio
          key={id + value}
          name={name}
          id={id + name + i}
          label={label}
          value={value}
          checked={checked}
          disabled={disabled}
        />
      ))}
      {errors && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <FieldErrorMessage message={message} />}
        />
      )}
    </Flex>
  );
};

export default RadioGroup;
