import { AgendaItemRes } from 'app-types';
import { ExcludedGroupsVotersForm } from 'types';

type VoterId = string;

interface ExcludedVotersDictionary {
  [key: VoterId]: boolean;
}

interface ExcludedVotersDefault {
  excludedCompaniesVoters: ExcludedVotersDictionary;
  excludedUsersVoters: ExcludedVotersDictionary;
}

export interface ExcludedVoterDefault {
  [key: string]: boolean;
}

export const getExcludedVoters = (
  excludedGroupsVotersForm: ExcludedGroupsVotersForm[],
  agenda: AgendaItemRes[],
  votingId: string,
): ExcludedVotersDefault => {
  const filteredVoting = agenda.filter(
    (agendaItem) => agendaItem.resolution.voting.id === votingId,
  )[0].resolution.voting;

  return excludedGroupsVotersForm.reduce<ExcludedVotersDefault>(
    (acc, item) => {
      const excludedUsersVoters = item.usersVoters.reduce(
        (accUserVoters, userVoter) => ({
          ...accUserVoters,
          [userVoter.id]: !filteredVoting.excludedUsersVoters.some(
            (excludedUsersVoter) => excludedUsersVoter.id === userVoter.id,
          ),
        }),
        {},
      );

      const excludedCompaniesVoters = item.companiesVoters.reduce(
        (accCompanyVoters, companyVoters) => ({
          ...accCompanyVoters,
          [companyVoters.id]: !filteredVoting.excludedCompaniesVoters.some(
            (excludedCompaniesVoter) => excludedCompaniesVoter.id === companyVoters.id,
          ),
        }),
        {},
      );

      return {
        ...acc,
        excludedUsersVoters: {
          ...acc.excludedUsersVoters,
          ...excludedUsersVoters,
        },
        excludedCompaniesVoters: {
          ...acc.excludedCompaniesVoters,
          ...excludedCompaniesVoters,
        },
      };
    },
    { excludedCompaniesVoters: {}, excludedUsersVoters: {} },
  );
};
