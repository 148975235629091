import React from 'react';
import { Flex } from 'components/layout';
import { PropsWithChildren } from 'types';

import styles from './VoteContainer.module.scss';

const VoteContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex className={styles.container} direction="col" gap={2}>
    {children}
  </Flex>
);

export default VoteContainer;
