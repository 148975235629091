import React, { ReactNode, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { Icon, Text } from 'components/common';

import styles from './Checkbox.module.scss';

interface Props {
  name: string;
  value?: string;
  label?: string;
  labelTranslationArgs?: Record<string, string>;
  className?: string;
  fontSize?: number;
  textUppercase?: boolean;
  disabled?: boolean;
  fontWeight?: 'thin' | 'normal' | 'bold' | 'extra-bold';
  shouldTranslate?: boolean;
  color?: 'primary' | 'secondary' | 'tertiary' | 'sixth';
  checked?: boolean;
  required?: boolean;
  customLabel?: ReactNode;
}

const Checkbox: React.FC<Props> = ({
  name,
  value,
  label,
  labelTranslationArgs,
  className,
  fontWeight,
  shouldTranslate,
  fontSize = 3,
  textUppercase,
  color,
  disabled,
  checked,
  required,
  customLabel,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const id = useId();

  return (
    <div className={clsx(styles.container, className)}>
      <input
        {...(register && register(name))}
        type="checkbox"
        className={styles.input}
        id={`${id}-${name}`}
        value={value}
        defaultChecked={checked}
        disabled={disabled}
      />
      <label htmlFor={`${id}-${name}`} className={clsx(styles.label)}>
        <div className={clsx(styles.box, { [styles.error]: errors[name] })}>
          <Icon className={styles.icon} name="checkmark" />
        </div>
        {label && (
          <Text
            size={fontSize}
            text={label}
            translationArgs={labelTranslationArgs}
            color={color}
            weight={fontWeight}
            shouldTranslate={shouldTranslate}
            uppercase={textUppercase}
          />
        )}
        {customLabel}
        {required && <span className={styles.required}>*</span>}
      </label>
    </div>
  );
};

export default Checkbox;
