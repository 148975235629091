import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Spinner, Modal } from 'components/common';
import { BaseRoute } from 'config';

const Page404 = React.lazy(() => import('views/Page404'));
const Home = React.lazy(() => import('views/Home'));
const Login = React.lazy(() => import('views/Login'));
const Register = React.lazy(() => import('views/Register'));
const ActivateAccount = React.lazy(() => import('views/ActivateAccount'));
const CompleteRegistration = React.lazy(() => import('views/CompleteRegistration'));
const ForgotPassword = React.lazy(() => import('views/ForgotPassword'));
const ResetPassword = React.lazy(() => import('views/ResetPassword'));
const PasswordChanged = React.lazy(() => import('views/PasswordChanged'));
const LoginCyberId = React.lazy(() => import('views/LoginCyberId'));
const Meeting = React.lazy(() => import('views/Meeting'));
const Profile = React.lazy(() => import('views/Profile'));
const Helpdesk = React.lazy(() => import('views/Helpdesk'));
const AddCompany = React.lazy(() => import('views/Admin/AddCompany'));
const PaymentStatus = React.lazy(() => import('views/Admin/AddCompany/Payment'));
const RequireAuth = React.lazy(() => import('../RequireAuth'));
const RequireUserAuth = React.lazy(() => import('../RequireUserAuth'));
const RequireAdminAuth = React.lazy(() => import('../RequireAdminAuth'));
const RequireSuperAdminAuth = React.lazy(() => import('../RequireSuperAdminAuth'));
const AdminRoutes = React.lazy(() => import('../AdminRoutes'));
const SuperAdminRoutes = React.lazy(() => import('../SuperAdminRoutes'));

const BaseRoutes: React.FC = () => (
  <Suspense fallback={<Spinner overlay transparent />}>
    <BrowserRouter>
      <Modal />
      <Routes>
        <Route path="*" element={<Navigate to={BaseRoute.NotFound} replace />} />
        <Route path={BaseRoute.NotFound} element={<Page404 />} />
        <Route path={BaseRoute.Home} element={<Home />} />
        <Route path={BaseRoute.Login} element={<Login />} />
        <Route path={BaseRoute.Register} element={<Register />} />
        <Route path={BaseRoute.ActivateAccount} element={<ActivateAccount />} />
        <Route path={BaseRoute.CompleteRegistration} element={<CompleteRegistration />} />
        <Route path={BaseRoute.ForgotPassword} element={<ForgotPassword />} />
        <Route path={BaseRoute.ResetPassword} element={<ResetPassword />} />
        <Route path={BaseRoute.PasswordChanged} element={<PasswordChanged />} />
        <Route path={BaseRoute.LoginCyberId} element={<LoginCyberId />} />
        <Route path={BaseRoute.Helpdesk} element={<Helpdesk />} />
        <Route
          path={BaseRoute.SuperAdmin}
          element={
            <RequireSuperAdminAuth>
              <SuperAdminRoutes />
            </RequireSuperAdminAuth>
          }
        />
        <Route
          path={BaseRoute.Meeting}
          element={
            <RequireAuth>
              <Meeting />
            </RequireAuth>
          }
        />
        <Route
          path={BaseRoute.Profile}
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path={BaseRoute.PaymentStatus}
          element={
            <RequireUserAuth>
              <PaymentStatus />
            </RequireUserAuth>
          }
        />
        <Route
          path={BaseRoute.AddCompany}
          element={
            <RequireUserAuth>
              <AddCompany />
            </RequireUserAuth>
          }
        />
        <Route
          path={BaseRoute.Admin}
          element={
            <RequireAdminAuth>
              <AdminRoutes />
            </RequireAdminAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  </Suspense>
);

export default BaseRoutes;
