import React from 'react';
import { Flex } from 'components/layout';
import { PropsWithChildren } from 'types';

const AgendaItemContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex direction="col" gap={2}>
    {children}
  </Flex>
);

export default AgendaItemContainer;
