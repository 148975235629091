import React from 'react';
import { CompanyType, CreateCompanyReq, EditCompanyReq, Voivodeship } from 'app-types';
import { Dropdown, Form, Input } from 'components/forms';
import { Flex, Row } from 'components/layout';
import { PageSubheader } from 'components/common';
import { addOrEditCompanyValidationSchema } from 'config';
import { CompanyFormVariant, DropdownItem, CompanyFormData } from 'types';

import ButtonSelector from './ButtonSelector';
import Identifiers from './Identifiers';

interface Props {
  onSubmit: (formData: CreateCompanyReq | EditCompanyReq) => void;
  variant: CompanyFormVariant;
  onGetKRSData?: () => void;
  defaultValues?: CreateCompanyReq | EditCompanyReq | CompanyFormData | {};
}

const companyTypes: DropdownItem[] = [
  { text: 'company.types.limited', value: CompanyType.LIMITED_LIABILITY_COMPANY },
  { text: 'company.types.joint_stock', value: CompanyType.JOINT_STOCK_COMPANY },
  { text: 'company.types.simple_joint_stock', value: CompanyType.SIMPLE_JOINT_STOCK_COMPANY },
];

const voivodeships: DropdownItem[] = [
  { text: 'company.voivodeships.lower_silesia', value: Voivodeship.LowerSilesia },
  { text: 'company.voivodeships.kuyavia_pomerania', value: Voivodeship.Kuyavia_Pomerania },
  { text: 'company.voivodeships.lublin', value: Voivodeship.Lublin },
  { text: 'company.voivodeships.lubusz', value: Voivodeship.Lubusz },
  { text: 'company.voivodeships.lodz', value: Voivodeship.Lodz },
  { text: 'company.voivodeships.lesser_poland', value: Voivodeship.LesserPoland },
  { text: 'company.voivodeships.masovia', value: Voivodeship.Masovia },
  { text: 'company.voivodeships.opole', value: Voivodeship.Opole },
  { text: 'company.voivodeships.podlasie', value: Voivodeship.Podlasie },
  { text: 'company.voivodeships.subcarpathia', value: Voivodeship.Subcarpathia },
  { text: 'company.voivodeships.pomerania', value: Voivodeship.Pomerania },
  { text: 'company.voivodeships.silesia', value: Voivodeship.Silesia },
  { text: 'company.voivodeships.holy_cross', value: Voivodeship.HolyCross },
  { text: 'company.voivodeships.warmia_masuria', value: Voivodeship.Warmia_Masuria },
  { text: 'company.voivodeships.greater_poland', value: Voivodeship.GreaterPoland },
  { text: 'company.voivodeships.west_pomerania', value: Voivodeship.WestPomerania },
];

const CompanyForm: React.FC<Props> = ({ defaultValues, onGetKRSData, onSubmit, variant }) => (
  <Form
    submitHandler={onSubmit}
    defaultValues={defaultValues}
    validationSchema={addOrEditCompanyValidationSchema}
  >
    <Flex direction="col" gap={4}>
      <Identifiers variant={variant} onGetKrsData={onGetKRSData} />
      <Row col gap={1}>
        <PageSubheader text="company.subject_data" />
        <Row gap={2}>
          <Input name="name" label="company.name" required />
          <Dropdown name="type" label="company.type" options={companyTypes} />
        </Row>
      </Row>
      <Row col gap={1}>
        <PageSubheader text="company.hq_address" />
        <Row gap={2}>
          <Input name="country" label="company.country" required />
          <Dropdown name="voivodeship" label="company.voivodeship" options={voivodeships} />
          <Input name="district" label="company.district" required />
          <Input name="commune" label="company.commune" required />
          <Input
            name="zipCode"
            label="company.zip_code"
            size="small"
            placeholder="placeholders.zip_code"
            required
          />
          <Input name="city" label="company.city" required />
          <Input name="streetName" label="company.street" required />
          <Input
            name="streetNumber"
            label="company.building_number"
            size="small"
            placeholder="placeholders.number"
            required
          />
          <Input
            name="apartmentNumber"
            label="company.apartment_number"
            size="small"
            placeholder="placeholders.number"
          />
        </Row>
      </Row>
      <Row col gap={1}>
        <PageSubheader text="company.capital" />
        <Row gap={2}>
          <Input name="initialCapital.value" label="company.capital_value" size="normal" required />
          <Input name="initialCapital.currency" label="company.currency" size="normal" required />
        </Row>
      </Row>
      <Row justifyFe gap={1}>
        <ButtonSelector variant={variant} />
      </Row>
    </Flex>
  </Form>
);

export default CompanyForm;
