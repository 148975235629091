import { Dispatch } from '@reduxjs/toolkit';
import {
  ApiErrorCode,
  ApiErrorMessage,
  ApiErrorResponse,
  ApiOkMessage,
  HttpMethod,
} from 'app-types';
import { CONFIG } from 'config';
import { errorNotification, clearUser, setLoading } from 'slices';
import { EmptyObject, FailureCb, SuccessCb } from 'types';
import { isApiErrorMessage, isApiErrorResponse, isApiOkMessage } from 'utils';

export const callApi = async <
  ResponseData extends unknown = undefined,
  RequestData extends unknown = undefined,
>(
  dispatch: Dispatch,
  method: HttpMethod,
  url: string,
  requestData?: RequestData,
  successCb?: SuccessCb<ResponseData>,
  failureCb?: FailureCb,
  spinnerVisible?: boolean,
): Promise<ResponseData | EmptyObject> => {
  if (spinnerVisible && method !== 'GET') dispatch(setLoading(true));

  const headers = { 'Content-Type': 'application/json' };

  const is401 = (response: Response) => response.status === 401;

  try {
    const response = await fetch(`${CONFIG.BASE_URL}${url}`, {
      method,
      headers: requestData && requestData instanceof FormData ? {} : headers,
      credentials: 'include',
      body:
        requestData && requestData instanceof FormData ? requestData : JSON.stringify(requestData),
    });

    const data: ApiErrorResponse | ApiOkMessage<ResponseData> = await response.json();

    if (spinnerVisible && method !== 'GET') dispatch(setLoading(false));

    if (isApiOkMessage(data)) {
      successCb?.(data.payload);
      return data.payload;
    }

    if (isApiErrorResponse(data)) {
      if (isApiErrorMessage(data)) {
        failureCb?.(data, response);
        dispatch(errorNotification(data.error_code));
        return {};
      }

      if (is401(response)) {
        dispatch(clearUser());
        return {};
      }
      failureCb?.(data, response);
      dispatch(errorNotification(ApiErrorCode.OtherError));
      return {};
    }

    failureCb?.(data, response);
    dispatch(errorNotification((data as ApiErrorMessage)?.error_code));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(errorNotification(ApiErrorCode.OtherError));
  }
  return {};
};
