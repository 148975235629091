import { MutableRefObject, useEffect } from 'react';

export const useOutsideClick = (
  ref: MutableRefObject<HTMLDivElement | null>,
  callback: () => void,
) => {
  useEffect(() => {
    const handleClickEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') callback();
    };

    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
        callback();
      }
    };

    document.addEventListener('keydown', handleClickEsc);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('keydown', handleClickEsc);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};
