import React from 'react';
import { Button } from 'components/common';
import { ModalActionButtonsContainer } from 'components/containers';
import { Form } from 'components/forms';
import { Flex } from 'components/layout';
import { createSplitVotesValidationSchema } from 'config';
import { SharesWithId, SplitVotesFormData } from 'types';

import SharesInputsGroup from './SharesInputsGroup';

interface Props {
  onSubmit: (formData: SplitVotesFormData) => void;
  onCancel: () => void;
  shares: SharesWithId[];
}

const SplitVotesForm: React.FC<Props> = ({ onSubmit, onCancel, shares }) => (
  <Form submitHandler={onSubmit} validationSchema={createSplitVotesValidationSchema(shares)}>
    <Flex direction="col" gap={4}>
      {shares.map((share) => (
        <SharesInputsGroup key={share.id} shares={share} />
      ))}
      <ModalActionButtonsContainer>
        <Button text="pages.meeting.make_vote" variant="secondary" />
        <Button text="application.cancel" variant="tertiary" type="button" onClick={onCancel} />
      </ModalActionButtonsContainer>
    </Flex>
  </Form>
);

export default SplitVotesForm;
