import React, { useMemo } from 'react';
import { Flex } from 'components/layout';
import { BaseSharesWithId, SharesType } from 'app-types';
import { HorizontalLine, Text } from 'components/common';
import { getSharesTranslations } from 'utils';

interface Props {
  shares: BaseSharesWithId[];
}

const SharesList: React.FC<Props> = ({ shares }) => {
  const normalShares = useMemo(
    () => shares.filter(({ type }) => type !== SharesType.PREFERENTIAL),
    [shares],
  );
  const preferentialShares = useMemo(
    () =>
      shares
        .filter(({ type }) => type === SharesType.PREFERENTIAL)
        .sort((a, b) => {
          if (a.multiplier && b.multiplier) {
            return a.multiplier - b.multiplier;
          }

          return 0;
        }),
    [shares],
  );

  return (
    <>
      {[...normalShares, ...preferentialShares].map(({ id, type, value, multiplier }, i) => (
        <React.Fragment key={id}>
          <Flex direction="col" gap={1}>
            <Flex gap={2}>
              <Text text="shares.type" size={2} color="sixth" />
              <Text text={getSharesTranslations(type)} size={2} weight="bold" />
            </Flex>
            <Flex gap={2}>
              <Text text="shares.value" size={2} color="sixth" />
              <Text text={value.toString()} shouldTranslate={false} size={2} weight="bold" />
            </Flex>
            {type === SharesType.PREFERENTIAL && (
              <Flex gap={2}>
                <Text text="shares.weight" size={2} color="sixth" />
                <Text text={`${multiplier}`} shouldTranslate={false} size={2} weight="bold" />
              </Flex>
            )}
          </Flex>
          {i !== shares.length - 1 && <HorizontalLine />}
        </React.Fragment>
      ))}
    </>
  );
};

export default SharesList;
