import { MeetingType } from 'app-types';

export const getMeetingTypeTranslation = (type: string | undefined) => {
  switch (type) {
    case MeetingType.NWZ:
      return { text: 'meeting.type.NWZ', shouldTranslate: true };
    case MeetingType.NZW:
      return { text: 'meeting.type.NZW', shouldTranslate: true };
    case MeetingType.ZWZ:
      return { text: 'meeting.type.ZWZ', shouldTranslate: true };
    case MeetingType.ZZW:
      return { text: 'meeting.type.ZZW', shouldTranslate: true };
    case undefined:
      return { text: 'meeting.type.type_not_selected', shouldTranslate: true };
    default:
      return { text: type, shouldTranslate: false };
  }
};
