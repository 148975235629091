export enum HelpdeskIssueCategory {
  LoggingIn = 'LoggingIn',
  Payment = 'Payment',
  Meeting = 'Meeting',
  TechnicalProblem = 'TechnicalProblem',
  Other = 'Other',
}

export enum TechnicalProblemSubcategory {
  CantAddShareholderToVoting = 'CantAddShareholderToVoting',
  KRSDataIsIncorrect = 'KRSDataIsIncorrect',
  CompanyAlreadyAdded = 'CompanyAlreadyAdded',
}

export interface ReportIssueReq {
  category: string;
  description: string;
  senderFullName: string;
  senderEmail: string;
}

export interface ShareVideoReq {
  email: string;
  youtubeUrl: string;
}
