import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Icon, Text } from 'components/common';
import { IconName } from 'types';
import { onKeyboardInteraction } from 'utils';

import styles from './FoldingContainer.module.scss';

interface CommonProps {
  children: React.ReactNode;
  iconName?: IconName;
  iconCount?: number | string;
  isContentVisible?: boolean;
  bgColor?: 'gray' | 'white' | 'blue';
}

interface NormalCoverProps extends CommonProps {
  index: string | number;
  text: string;
  dragAndDrop?: boolean;
}

interface CustomCoverProps extends CommonProps {
  customCover: React.ReactNode;
}

const FoldingContainer: React.FC<NormalCoverProps | CustomCoverProps> = ({
  children,
  iconName,
  iconCount,
  isContentVisible,
  bgColor = 'gray',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(isContentVisible);

  useEffect(() => {
    setIsOpen(isContentVisible);
  }, [isContentVisible]);
  const onClick = () => setIsOpen(!isOpen);

  if ('customCover' in props) {
    const { customCover } = props;
    return (
      <div className={styles.container}>
        <div
          className={clsx(styles.button, {
            [styles.active]: isOpen,
            [styles?.[`bg-color-${bgColor}`]]: bgColor,
          })}
          role="button"
          onClick={onClick}
          onKeyDown={onKeyboardInteraction(onClick)}
          tabIndex={0}
        >
          <div className={clsx(styles.col, styles.col__left)}>{customCover}</div>
          <span className={clsx(styles.col, styles.col__right)}>
            <span
              className={clsx(styles.icon, {
                [styles.blue]: iconCount !== undefined && iconCount > 0,
              })}
            >
              {iconName && <Icon name={iconName} />}
              {iconCount !== undefined && ` ${iconCount}`}
            </span>
            <Icon name="arrow-down" className={styles.arrow} />
          </span>
        </div>
        {isOpen && children}
      </div>
    );
  }
  const { index, text, dragAndDrop } = props;

  return (
    <div className={styles.container}>
      <button
        className={clsx(styles.button, {
          [styles.active]: isOpen,
          [styles?.['drag-and-drop']]: dragAndDrop,
          [styles?.[`bg-color-${bgColor}`]]: bgColor,
        })}
        type="button"
        onClick={onClick}
      >
        <span className={clsx(styles.col, styles.col__left)}>
          <Text text={`${index}`} shouldTranslate={false} size={3} weight="bold" />
          <Text text={text} shouldTranslate={false} size={3} truncate />
        </span>
        <span className={clsx(styles.col, styles.col__right)}>
          <span
            className={clsx(styles.icon, {
              [styles.blue]: iconCount !== undefined && iconCount > 0,
            })}
          >
            {iconName && <Icon name={iconName} />}
            {iconCount !== undefined && ` ${iconCount}`}
          </span>
          <Icon name="arrow-down" className={styles.arrow} />
        </span>
      </button>
      {isOpen && children}
    </div>
  );
};

export default FoldingContainer;
