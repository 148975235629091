import React from 'react';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';
import { IconName } from 'types';

import styles from './Icon.module.scss';

interface Props {
  name: IconName;
  className?: string;
}

const Icon: React.FC<Props> = ({ name, className }) => (
  <SVG src={`/assets/images/icons/${name}.svg`} className={clsx(styles.icon, className)} />
);

export default Icon;
