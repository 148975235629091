import React from 'react';
import { Form } from 'components/forms';
import { Main, Flex } from 'components/layout';
import { ProtocolSchemaFileName } from 'app-types';
import { ButtonsContainer } from 'components/containers';
import { authorityMeetingProtocolValidationSchema } from 'config';
import { PageHeader, HorizontalLine, Button } from 'components/common';
import { ProtocolFormData, AgendaContentList, ProtocolType } from 'types';

import Agenda from '../Agenda';
import Greeter from '../Greeter';
import Preview from '../Preview';
import Disscussion from '../Discussion';
import Conclusions from '../Conclusions';
import Notifications from '../Notifications';
import ProtocolNumber from '../ProtocolNumber';
import ClosingMeeting from '../ClosingMeeting';

interface Props {
  onSubmit: (data: ProtocolFormData) => Promise<void>;
  meeting: {
    companyName: string;
    type: string;
    date: string;
  };
  agendaContentList: AgendaContentList[];
}

const AuthorityMeetingProtocol: React.FC<Props> = ({ onSubmit, meeting, agendaContentList }) => (
  <Main size="medium">
    <Flex gap={4}>
      <PageHeader
        text="protocol.title.authority_meeting"
        uppercase
        args={{
          name: meeting.companyName,
          date: meeting.date,
          type: meeting.type,
        }}
      />
      <Flex gap={10}>
        <Preview fileName={ProtocolSchemaFileName.AuthorityMeeting} />
        <Form
          submitHandler={onSubmit}
          defaultValues={{ agendaContentList }}
          validationSchema={authorityMeetingProtocolValidationSchema}
        >
          <Flex direction="col" gap={6}>
            <ProtocolNumber />
            <Notifications />
            <HorizontalLine />
            <Greeter />
            <HorizontalLine />
            <Disscussion />
            <HorizontalLine />
            <Agenda protocolType={ProtocolType.AUTHORITY_MEETING} />
            <HorizontalLine />
            <Conclusions />
            <HorizontalLine />
            <ClosingMeeting />
            <ButtonsContainer>
              <Button text="protocol.generate_protocol" variant="secondary" />
            </ButtonsContainer>
          </Flex>
        </Form>
      </Flex>
    </Flex>
  </Main>
);
export default AuthorityMeetingProtocol;
