import React, { useMemo } from 'react';

import { Flex } from 'components/layout';
import { Text } from 'components/common';
import { EditResolutionData, AddEditResolutionFormData } from 'types';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { ResolutionForm } from 'components/forms';
import { resolutionFormValidationSchema } from 'config';
import { fetchAddMeetingData, fetchMeetingData, successNotification } from 'slices';
import { getFormDataFromObj, appendFiles, getRemovedFiles } from 'utils';

const EditResolution: React.FC = () => {
  const {
    hideModal,
    data: {
      agendaItemId,
      meetingId,
      resolution: {
        title,
        description,
        files,
        quorum,
        voting: { type, duration, majority, threshold },
      },
      isMeetingInProgress,
      isCurrentlyVotingOnThatResolution,
    },
  } = useModal<undefined, EditResolutionData>();

  const api = useApi();
  const dispatch = useAppDispatch();

  const defaultValues = useMemo(
    () => ({
      title,
      description: description || '',
      pdf: files,
      type,
      duration,
      quorum,
      majority,
      threshold,
    }),
    [description, duration, files, majority, quorum, threshold, title, type],
  );

  const onSubmit = async (formValues: AddEditResolutionFormData) => {
    const { pdf, ...values } = formValues;
    const formData = getFormDataFromObj(values);

    if (pdf && pdf.length > 0) {
      appendFiles(pdf, formData, 'pdf');

      const removedFiles = getRemovedFiles(pdf);
      if (removedFiles.length > 0) {
        formData.append('removedFiles', JSON.stringify(removedFiles));
      }
    }

    await api.patch(`/meeting/agenda-item/${agendaItemId}/${meetingId}`, formData, () => {
      hideModal();
      dispatch(successNotification('notifications.edited_resolution'));
      if (isMeetingInProgress) {
        dispatch(fetchMeetingData({ meetingId }));
      } else {
        dispatch(fetchAddMeetingData(meetingId));
      }
    });
  };

  return (
    <Flex direction="col" gap={2}>
      <Text text="modals.edit_resolution.title" size={4} weight="bold" />
      <ResolutionForm
        onSubmit={onSubmit}
        validationSchema={resolutionFormValidationSchema}
        defaultValues={defaultValues}
        onCancel={hideModal}
        variant="edit-resolution"
        isVotingDurationInputDisabled={isMeetingInProgress && isCurrentlyVotingOnThatResolution}
      />
    </Flex>
  );
};

export default EditResolution;
