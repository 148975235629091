import React from 'react';
import { Flex } from 'components/layout';
import { CSVFileInput } from 'components/inputs';
import { Text, Icon, Button } from 'components/common';
import { ModalActionButtonsContainer } from 'components/containers';

import DataWillBeRemoved from './DataWillBeRemoved';
import styles from './Upload.module.scss';

interface Props {
  hideModal: () => void;
  onCsvImport: (file: File) => void;
}

const Upload: React.FC<Props> = ({ hideModal, onCsvImport }) => (
  <Flex direction="col" justify="center" align="center" gap={6}>
    <Flex direction="col" align="center" gap={4}>
      <Icon name="warning" className={styles.icon} />
      <Flex justify="center">
        <DataWillBeRemoved />
        <Text text="modals.upload_csv.subtitle" size={4} align="center" />
      </Flex>
    </Flex>
    <ModalActionButtonsContainer>
      <CSVFileInput text="application.import_csv" name="import-csv" onSelect={onCsvImport} />
      <Button text="application.cancel" type="button" onClick={hideModal} variant="tertiary" />
    </ModalActionButtonsContainer>
  </Flex>
);

export default Upload;
