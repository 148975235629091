import React from 'react';
import { Flex } from 'components/layout';
import { PropsWithChildren } from 'types';

const AgendaContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex direction="col" gap={3}>
    {children}
  </Flex>
);

export default AgendaContainer;
