import React from 'react';
import { VotingShareholder } from 'app-types';
import { Text, List } from 'components/common';
import { Flex } from 'components/layout';
import { getName } from 'utils';

interface Props {
  voters: VotingShareholder[];
}

const VotersList: React.FC<Props> = ({ voters }) => {
  if (!voters.length) {
    return (
      <Flex justify="center">
        <Text text="modals.show_votes.no_votes" weight="thin" size={2} italic />
      </Flex>
    );
  }

  return (
    <List>
      {voters.map(({ id, ...voter }) => (
        <li key={id}>
          <Text text={getName(voter)} shouldTranslate={false} size={2} weight="bold" />
        </li>
      ))}
    </List>
  );
};

export default VotersList;
