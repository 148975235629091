import React, { useMemo } from 'react';
import { MeetingStatus } from 'app-types';
import { Link } from 'components/common';
import { AllRoutes, createPath } from 'config';
import { useAppSelector } from 'hooks';

const isMeetingPlannedOrEnded = (meetingStatus: MeetingStatus): boolean => {
  const desiredStatuses = [MeetingStatus.PLANNED, MeetingStatus.ENDED];

  return desiredStatuses.includes(meetingStatus);
};

const BackToPlannedMeetings: React.FC = () => {
  const {
    data: { company, status },
  } = useAppSelector(({ meeting: { data } }) => ({
    data: data!,
  }));
  const { user } = useAppSelector((state) => state);

  const isBreadcrumbVisible = useMemo(() => {
    if (!company || !user) return false;
    const isCompanyOwner = company.owner.id === user.id;

    return isCompanyOwner && isMeetingPlannedOrEnded(status);
  }, [company, user, status]);

  if (!isBreadcrumbVisible) return null;

  return (
    <Link
      text="breadcrumbs.back_to_planned_meetings"
      leftArrow
      to={createPath({
        path: AllRoutes.AdminCompanyPlannedMeetings,
        params: { companyId: company.id },
      })}
    />
  );
};

export default BackToPlannedMeetings;
