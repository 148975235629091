import React, { useMemo } from 'react';
import { Column, useTable } from 'react-table';
import clsx from 'clsx';
import { EditCompanyReq, CompaniesDiffs } from 'app-types';
import { __ } from 'services';
import { onKeyboardInteraction } from 'utils';

import styles from './CompareTable.module.scss';

interface Props {
  data: CompaniesDiffs[];
  state: [Partial<EditCompanyReq>, React.Dispatch<React.SetStateAction<Partial<EditCompanyReq>>>];
}

const getTranslation = (value: string) => {
  switch (value) {
    case 'name':
      return __('company.name');
    case 'type':
      return __('company.type');
    case 'krs':
      return __('company.krs_number');
    case 'nip':
      return __('company.nip_number');
    case 'regon':
      return __('company.regon_number');
    case 'country':
      return __('company.country');
    case 'voivodeship':
      return __('company.voivodeship');
    case 'district':
      return __('company.district');
    case 'commune':
      return __('company.commune');
    case 'zipCode':
      return __('company.zip_code');
    case 'city':
      return __('company.city');
    case 'streetName':
      return __('company.street');
    case 'streetNumber':
      return __('company.building_number');
    case 'apartmentNumber':
      return __('company.apartment_number');
    case 'initialCapital.value':
      return __('company.capital_value');
    case 'initialCapital.currency':
      return __('company.currency');
    default:
      return '';
  }
};

const CompareTable: React.FC<Props> = ({ data, state: [compareData, setCompareData] }) => {
  const columns: Column<CompaniesDiffs>[] = useMemo(
    () => [
      { Header: __('modals.edit_company.property'), accessor: 'property' },
      { Header: __('modals.edit_company.old_value'), accessor: 'dbCompany' },
      { Header: __('modals.edit_company.new_value'), accessor: 'krsCompany' },
    ],
    [],
  );

  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <table className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className={styles?.['head-cell']}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                const shouldBeClickable = cell.column.id !== 'property';
                const cellContent = !shouldBeClickable
                  ? getTranslation(cell.value)
                  : cell.render('Cell');
                const onClick = () =>
                  setCompareData((prev) => ({ ...prev, [cell.row.original.property]: cell.value }));

                return (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <td
                    {...cell.getCellProps()}
                    onClick={shouldBeClickable ? onClick : undefined}
                    onKeyPress={shouldBeClickable ? onKeyboardInteraction(onClick) : undefined}
                    role={shouldBeClickable ? 'button' : undefined}
                    className={clsx(styles.cell, {
                      [styles.active]: cell.value === compareData?.[cell.row.original.property],
                    })}
                  >
                    {cellContent}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CompareTable;
