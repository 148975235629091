import { AllowedShareholdingGroups } from '../company';
import { VotingResult } from '../voting';

export class JointStockMeetingProtocol {
  public meeting?: ProtocolMeeting;

  public company?: ProtocolCompany;

  public resolutions?: ResolutionProtocol[];

  public dataFromUser: ProtocolDataFromUser;

  public attachments: Attachment[];

  constructor(data: JointStockMeetingProtocol) {
    this.meeting = data.meeting;
    this.company = data.company;
    this.resolutions = data.resolutions;
    this.dataFromUser = data.dataFromUser;
    this.attachments = data.attachments;
  }
}

/* Meeting */

export interface ProtocolMeeting {
  name?: string;
  type?: string;
  date?: string;
  time?: string;
  capitalPercentage?: string;
  isMinimalQuorumReachedInfo?: string;
  allowedShareholdingGroups?: AllowedShareholdingGroups;
  quorum?: number;
  currentQuorum?: number;
  formality: boolean;
}

/* Attachement */

export interface Attachment {
  name: string;
}

/* Shareholders */

export interface ShareholdingsLists {
  userList: UserMember[];
  userRepresentativeList: UserRepresentative[];
  companyList: CompanyMember[];
}

/* Guests */

export type GuestList = UserDataTypeForProtocol[];

/* Company */

export interface ProtocolCompany {
  name?: string;
  krs?: string;
  address?: CompanyAddress;
}

export interface CompanyAddress {
  city?: string;
  zipCode?: string;
  street?: Street;
}

interface Street {
  name?: string;
  number?: string;
}

/* User Member */

export interface UserMember {
  id: string;
  names?: string[];
  surname?: string[];
  shares: number;
  percentageOfTotalShares?: number;
  representative?: UserRepresentative;
  sharesValue: number;
}

export interface UserRepresentative extends UserMember {
  date: string;
  representativeNames: string[];
  representativeSurname: string[];
}

/* Company Member */

export interface CompanyMember {
  company: {
    name?: string;
    nip?: string;
    krs?: string;
  };
  voter: {
    id?: string;
    names?: string[];
    surname?: string[];
    role?: string;
  };
  role: string;
  shares: number;
  sharesValue: number;
  percentageOfTotalShares?: number;
}

/* Presence List */

export type PresenceLists = {
  userList: UserMember[];
  userRepresentativeList: UserRepresentative[];
  companyList: CompanyMember[];
};

/* Voting */

export interface AssignVoting {
  type: AssignVotingType;
  result: AssignVotingResult;
}

export type AssignVotingType = 'jawnym' | 'tajnym';
export type AssignVotingResult = 'przyjęta' | 'odrzucona';

/* Objections */

export interface ObjectionsLists {
  userList: UserMember[];
  userRepresentativeList: UserRepresentative[];
  companyList: CompanyMember[];
}

/* Resolutions */

export interface ResolutionProtocol {
  id: string;
  ordinalNumber: number;
  title: string;
  objections: ObjectionsLists;
  isAnyObjections: boolean;
  description: string;
  voting: VotingResult;
  votes: Votes;
  percentageShareOfShareCapital: number;
  agendaContent: string;
  files: string[];
  quorum: number;
  isQuorumReached: number;
}

export interface Votes {
  inFavour: number;
  against: number;
  abstain: number;
  all: number;
}

export interface ProtocolDataFromUser {
  protocolNumber?: string;
  agendaRecorder?: UserDataTypeForProtocol;
  chairman?: UserDataTypeForProtocol;
  greeter?: UserDataTypeForProtocol;
  notification?: NotifiedBy;
  copies?: number;
  representativeDateList?: RepresentativeDateType[];
  representativeRoleInCompanyList?: RepresentativeRoleType[];
  agendaContentList?: AgendaContent[];
  discussions?: Discussion[];
  anyOtherBusiness?: string;
  closer?: UserDataTypeForProtocol;
  absentPerson?: UserDataTypeForProtocol[];
}

export interface RepresentativeDateType {
  userId: string;
  representativeId: string;
  date: string;
}
export interface RepresentativeRoleType {
  userId: string;
  companyId: string;
  role: string;
}

export interface Discussion {
  title: string;
  description: string;
}

export interface UserDataTypeForProtocol {
  id?: string;
  names: string[];
  surname: string[];
}

export interface AgendaContent {
  agendaItemId: string;
  content: string;
  proposingPerson?: UserDataTypeForProtocol;
}

export enum NotifiedBy {
  Letter = 'wysłanie listów poleconych',
  Email = 'wysłanie wiadomości e-mail',
  Courier = 'nadanie przesyłek pocztą kurierską',
}

export type AuthorityPresenceList = UserDataTypeForProtocol[];
