import React, { useMemo } from 'react';
import { VotingStatus } from 'app-types';
import { Button } from 'components/common';
import { ButtonsContainer } from 'components/containers';
import { useMeetingState } from 'hooks';

interface Props {
  votingStatus: VotingStatus;
  onEdit: () => void;
  onDelete: () => void;
  onEndVoting: () => void;
  onManagingExcludedVoters: () => void;
  votersLeft?: number;
}

const Buttons: React.FC<Props> = ({
  votingStatus,
  onEdit,
  onDelete,
  onEndVoting,
  onManagingExcludedVoters,
  votersLeft,
}) => {
  const {
    isResolutionVotingState,
    isMeetingInProgress,
    isUserCompanyOwner,
    isMeetingPaused,
    isBreakTime,
  } = useMeetingState();

  const isVotingInProgress = useMemo(
    () => votingStatus === VotingStatus.IN_PROGRESS,
    [votingStatus],
  );

  const isVotingNotStarted = useMemo(
    () => votingStatus === VotingStatus.NOT_STARTED,
    [votingStatus],
  );

  const areButtonsVisible = useMemo(() => {
    const hasVotingNotEnded = votingStatus !== VotingStatus.ENDED;
    const areButtonsVisibleMeetingInProgress =
      isMeetingInProgress && isVotingInProgress && isUserCompanyOwner;
    const areButtonsVisibleMeetingPaused =
      isMeetingPaused && hasVotingNotEnded && isUserCompanyOwner;

    return areButtonsVisibleMeetingInProgress || areButtonsVisibleMeetingPaused;
  }, [votingStatus, isVotingInProgress, isMeetingPaused, isMeetingInProgress, isUserCompanyOwner]);

  const isEditButtonVisible = useMemo(
    () => (isMeetingPaused && isVotingInProgress) || (isMeetingPaused && isVotingNotStarted),
    [isMeetingPaused, isVotingInProgress, isVotingNotStarted],
  );

  const isDeleteButtonVisible = useMemo(
    () => isMeetingPaused && isVotingNotStarted,
    [isMeetingPaused, isVotingNotStarted],
  );

  const isEndVotingButtonVisible = useMemo(
    () =>
      isVotingInProgress &&
      !isBreakTime &&
      !isMeetingPaused &&
      isResolutionVotingState &&
      votersLeft === 0,
    [isVotingInProgress, isMeetingPaused, isBreakTime, isResolutionVotingState, votersLeft],
  );

  if (!areButtonsVisible) return null;

  return (
    <ButtonsContainer>
      {isEditButtonVisible && (
        <Button text="application.edit" size="small" variant="tertiary" onClick={onEdit} />
      )}
      {isDeleteButtonVisible && (
        <>
          <Button text="application.delete" size="small" variant="tertiary" onClick={onDelete} />
          <Button
            text="application.manage_voters"
            size="small"
            variant="tertiary"
            onClick={onManagingExcludedVoters}
          />
        </>
      )}
      {isEndVotingButtonVisible && (
        <Button
          text="pages.meeting.end_voting"
          size="small"
          variant="tertiary"
          onClick={onEndVoting}
        />
      )}
    </ButtonsContainer>
  );
};

export default Buttons;
