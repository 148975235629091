import React from 'react';
import clsx from 'clsx';
import { MeetingStatus } from 'app-types';
import { __ } from 'services';

import styles from './StatusIndicator.module.scss';

interface Props {
  status: MeetingStatus;
}

const StatusIndicator: React.FC<Props> = ({ status }) => (
  <p className={clsx(styles?.['status-indicator'], styles?.[`color-${status}`])}>
    {__(`meeting.status.${status}`)}
  </p>
);

export default StatusIndicator;
