import React from 'react';
import { UpdateActivated, UpdateActivatedReload, UpdateAvailable } from 'react-workbox';
import { __ } from 'services';
import { onKeyboardInteraction } from 'utils';

import styles from './UpdatePopup.module.scss';

const onUpdateClick = async () => {
  const registration = await navigator.serviceWorker.getRegistration();
  if (registration && registration.waiting) {
    registration.waiting.postMessage('skipWaiting');
  }
  setTimeout(() => window.location.reload(), 1000);
};

const UpdateInfoBar = () => (
  <>
    <UpdateAvailable>
      <div
        className={styles?.['update-popup']}
        onClick={onUpdateClick}
        onKeyPress={onKeyboardInteraction(onUpdateClick)}
        role="button"
        tabIndex={0}
      >
        {__('update_info.need_update')}
      </div>
    </UpdateAvailable>
    <UpdateActivated>
      <div
        className={styles?.['update-popup']}
        onClick={onUpdateClick}
        onKeyPress={onKeyboardInteraction(onUpdateClick)}
        role="button"
        tabIndex={0}
      >
        {__('update_info.updated')}
      </div>
    </UpdateActivated>
    <UpdateActivatedReload />
  </>
);

export default UpdateInfoBar;
