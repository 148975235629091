import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, InlineButton, Text } from 'components/common';
import { Input } from 'components/forms';
import { Row } from 'components/layout';
import { GroupMemberModalVariant } from 'types';

interface Props {
  variant: GroupMemberModalVariant;
}

const getVariantData = (variant: GroupMemberModalVariant, fields: object[]) => {
  switch (variant) {
    case 'add_member':
    case 'edit_member':
      return {
        title: 'shareholder.plenipotentiary',
        buttonText: 'shareholder.add_plenipotentiary',
        showDeleteButton: true,
        showAddButton: fields.length === 0,
      };
    case 'add_subject':
    case 'edit_subject':
      return {
        title: 'shareholder.representative',
        buttonText: 'shareholder.add_representative',
        showDeleteButton: fields.length > 1,
        showAddButton: true,
      };
    default:
      return { title: '', buttonText: '', showDeleteButton: false, showAddButton: false };
  }
};

const RepresentativesGroup: React.FC<Props> = ({ variant }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'representatives' });

  const { title, buttonText, showDeleteButton, showAddButton } = getVariantData(variant, fields);

  return (
    <>
      {fields.map((field, i) => (
        <React.Fragment key={field.id}>
          <Row alignC spaceBetween>
            <Text
              text={title}
              translationArgs={{ i: fields.length > 1 ? i + 1 : '' }}
              size={3}
              weight="bold"
            />
            {showDeleteButton && (
              <Button
                text="application.delete"
                type="button"
                variant="danger"
                onClick={() => remove(i)}
              />
            )}
          </Row>
          <Input
            name={`representatives.${i}.email`}
            autoComplete="new-password"
            label="user.email"
            size="responsive"
            required
          />
        </React.Fragment>
      ))}
      {showAddButton && (
        <InlineButton
          text={buttonText}
          iconName="plus-fill"
          type="button"
          onClick={() => append({}, { shouldFocus: false })}
        />
      )}
    </>
  );
};

export default RepresentativesGroup;
