import React, { useState } from 'react';
import { IconButton } from 'components/common';
import { MobileBottomBarContainer } from 'components/containers';
import { useMeetingState } from 'hooks';

import Avatar from './Avatar';
import MobileVotingMenuContent from './MobileVotingMenuContent';
import MobileCounter from './MobileCounter';

interface Props {
  changeMenu: () => void;
}

const MobileVotingMenu: React.FC<Props> = ({ changeMenu }) => {
  const [isVotingMenuOpen, setIsVotingMenuOpen] = useState(false);

  const { isMeetingPaused, isMeetingInProgress, isBreakTime, goalDate } = useMeetingState();

  return (
    <MobileBottomBarContainer>
      <MobileCounter
        goalDate={goalDate}
        isPaused={isMeetingPaused}
        isFadedOut={!isMeetingInProgress || isBreakTime}
      />
      <IconButton
        isActive={isVotingMenuOpen}
        iconName="arrow-up"
        onClick={() => setIsVotingMenuOpen(!isVotingMenuOpen)}
      />
      <Avatar onClick={changeMenu} />
      <MobileVotingMenuContent
        isOpen={isVotingMenuOpen}
        openMenu={() => setIsVotingMenuOpen(true)}
      />
    </MobileBottomBarContainer>
  );
};

export default MobileVotingMenu;
