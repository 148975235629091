import React from 'react';
import { ExcludedGroupsVotersForm } from 'types';
import { FoldingContainer } from 'components/common';

import Content from './Content';
import CustomCover from './CustomCover';

interface Props {
  isContentVisible?: boolean;
  excludedGroup: ExcludedGroupsVotersForm;
}

const isParticipantGroupVisible = ({ companiesVoters, usersVoters }: ExcludedGroupsVotersForm) =>
  companiesVoters.length + usersVoters.length > 0;

const GroupContainer: React.FC<Props> = ({ isContentVisible, excludedGroup }) => {
  if (!isParticipantGroupVisible(excludedGroup)) return null;

  return (
    <FoldingContainer
      isContentVisible={isContentVisible}
      customCover={<CustomCover participantsGroup={excludedGroup} />}
    >
      <Content groupMembers={[...excludedGroup.usersVoters, ...excludedGroup.companiesVoters]} />
    </FoldingContainer>
  );
};

export default GroupContainer;
