import React from 'react';
import { CompanyShareholderData, UserShareholderData } from 'app-types';
import { Text, FoldingContainer } from 'components/common';
import { DesktopShareholderCardContainer } from 'components/containers';
import { SharesList } from 'components/meeting';
import { getName } from 'utils';

interface Props {
  shareholder: UserShareholderData | CompanyShareholderData;
  isActive: boolean;
}

const ShareholderCard: React.FC<Props> = ({ isActive, shareholder }) => (
  <FoldingContainer
    customCover={
      <Text text={getName(shareholder)} shouldTranslate={false} size={4} weight="bold" />
    }
    isContentVisible={isActive}
    bgColor="white"
  >
    <DesktopShareholderCardContainer multiple>
      <Text size={1} uppercase color="sixth" weight="bold" text="pages.meeting.shares_data" />
      <SharesList shares={shareholder.shares} />
    </DesktopShareholderCardContainer>
  </FoldingContainer>
);
export default ShareholderCard;
