import React from 'react';
import clsx from 'clsx';
import { UserAuthLoginRes } from 'app-types';
import { CircleContainer, CircularProgressbar } from 'components/common';
import { useAppSelector, useMeetingState } from 'hooks';
import { isVotingState, onKeyboardInteraction } from 'utils';

import styles from './Avatar.module.scss';

interface Props {
  onClick: () => void;
}

const getInitials = ({ names: [firstName], surname: [firstSurname] }: UserAuthLoginRes): string => {
  if (!firstName || !firstSurname) return 'IV';

  return `${firstName[0]}${firstSurname[0]}`.toUpperCase();
};

// TODO: will be changed FIRST LETTERS PLACEHOLDER
const Avatar: React.FC<Props> = ({ onClick }) => {
  const { user } = useAppSelector((state) => state);
  const { data, state } = useAppSelector(({ meeting }) => meeting);
  const { progressbarValue } = useMeetingState();

  if (!data) return null;

  const onAvatarClick = isVotingState(state) && state.shareholders.length ? onClick : undefined;

  return (
    <CircleContainer
      role={onAvatarClick && 'button'}
      tabIndex={0}
      onKeyDown={onAvatarClick && onKeyboardInteraction(onAvatarClick)}
      onClick={onAvatarClick}
      className={clsx(styles.container, { [styles.clickable]: !!onAvatarClick })}
    >
      <CircularProgressbar
        value={progressbarValue}
        goal={data.shareholders.length || 1}
        text={getInitials(user)}
      />
    </CircleContainer>
  );
};

export default Avatar;
