import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { __ } from 'services';
import { IconName } from 'types';
import Icon from '../Icon';
import styles from './Button.module.scss';

interface Props {
  text: string;
  onClick?: () => void;
  iconName?: IconName;
  transparent?: boolean;
  disabled?: boolean;
  to?: string;
  navLink?: boolean;
  responsive?: boolean;
  dontTranslate?: boolean;
  className?: string;
  size?: 'small' | 'normal';
  type?: 'submit' | 'button';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'cyberId' | 'action';
  target?: string;
}

const Button: React.FC<Props> = ({
  text,
  onClick,
  iconName,
  transparent,
  disabled,
  to,
  navLink,
  responsive,
  dontTranslate,
  className,
  size = 'normal',
  type = 'submit',
  variant = 'primary',
  target,
  ...rest // data-x etc
}) => {
  const formContext = useFormContext();
  const getClassNames = (isActive?: boolean) =>
    clsx(className, styles.button, {
      [styles.transparent]: transparent,
      [styles?.[variant]]: variant,
      [styles?.[size]]: size,
      [styles.responsive]: responsive,
      [styles?.['with-icon']]: iconName,
      [styles.active]: isActive,
    });

  if (to && navLink) {
    return (
      <NavLink to={to} className={({ isActive }) => getClassNames(isActive)} {...rest}>
        {iconName && (
          <span className={styles?.['icon-wrapper']}>
            <Icon name={iconName} />
          </span>
        )}
        <span className={styles.text}>{dontTranslate ? text : __(text)}</span>
      </NavLink>
    );
  }

  if (to) {
    return (
      <Link target={target} to={to} className={getClassNames()} onClick={onClick} {...rest}>
        {iconName && (
          <span className={styles?.['icon-wrapper']}>
            <Icon name={iconName} />
          </span>
        )}
        <span className={styles.text}>{dontTranslate ? text : __(text)}</span>
      </Link>
    );
  }

  return (
    <button
      disabled={formContext?.formState?.isSubmitting || disabled}
      onClick={onClick}
      type={type}
      className={getClassNames()}
      {...rest}
    >
      {iconName && (
        <span className={styles?.['icon-wrapper']}>
          <Icon name={iconName} />
        </span>
      )}
      <span className={styles.text}>{dontTranslate ? text : __(text)}</span>
    </button>
  );
};

export default Button;
