import React from 'react';
import { PropsWithChildren } from 'types';

import styles from './CenteredFullHeightContainer.module.scss';

const CenteredFullHeightContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <main className={styles.container}>{children}</main>
);

export default CenteredFullHeightContainer;
