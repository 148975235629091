import React, { useEffect, useRef } from 'react';
import { Icon, PageHeader, Text } from 'components/common';
import { MeetingHeaderContainer } from 'components/containers';
import { AdminActionButtons, StatusIndicator } from 'components/meeting';
import { infoNotification } from 'slices';
import { Flex, Row } from 'components/layout';
import { useAppSelector, useAppDispatch } from 'hooks';
import { MeetingStatus } from 'app-types';

import styles from './MeetingHeader.module.scss';
import BackToPlannedMeetings from './BackToPlannedMeetings';

interface DidMountRef {
  status: string;
  type: boolean;
}

const MeetingHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    data: { type, status, company, participantsCount },
  } = useAppSelector(({ meeting }) => ({ data: meeting.data!, state: meeting.state }));
  const didMountRef = useRef<DidMountRef>({ status, type: false });

  useEffect(() => {
    if (didMountRef.current.type) {
      if (status === MeetingStatus.PAUSED) {
        dispatch(infoNotification('notifications.paused_meeting'));
      } else if (status === MeetingStatus.IN_PROGRESS) {
        if (didMountRef.current.status === MeetingStatus.PLANNED) {
          dispatch(infoNotification('notifications.started_meeting'));
        } else {
          dispatch(infoNotification('notifications.resumed_meeting'));
        }
      }
    }
    didMountRef.current.type = true;
    didMountRef.current.status = status;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <MeetingHeaderContainer>
      <Row gap={2} noWrap spaceBetween>
        <Flex direction="col" gap={2} className={styles.header}>
          <BackToPlannedMeetings />
          <PageHeader text="pages.meeting.title" args={{ meetingName: type }} uppercase />
          <Flex gap={5} justify="space-between">
            <Flex gap={5} align="center">
              <Text text={company.name} shouldTranslate={false} color="sixth" weight="bold" />
              <Flex gap={1} align="center">
                <Icon name="user" />
                <Text
                  text="pages.meeting.participants"
                  translationArgs={{ participantsCount }}
                  color="sixth"
                  weight="bold"
                />
              </Flex>
            </Flex>
            <Flex className={styles['status-wrapper']}>
              <StatusIndicator status={status} />
            </Flex>
          </Flex>
          <AdminActionButtons variant="mobile" />
        </Flex>
        <Flex className={styles['status-container']}>
          <StatusIndicator status={status} />
        </Flex>
      </Row>
    </MeetingHeaderContainer>
  );
};

export default MeetingHeader;
