import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AuthCyberIdReq,
  AuthLoginReq,
  AuthLoginRes,
  RefreshedUserDataRes,
  UserAuthLoginRes,
  RefeshTokenRes,
} from 'app-types';
import { LoginFormData } from 'types';
import { callApi } from 'services';

import { successNotification } from '../notification-slice';

const initialState = {} as UserAuthLoginRes;

export const refreshUserData = createAsyncThunk<RefreshedUserDataRes | {}>(
  'user/refreshUserData',
  async (_u, { dispatch }) => callApi<RefreshedUserDataRes>(dispatch, 'GET', '/user/refresh'),
);

export const loginUser = createAsyncThunk<AuthLoginRes | {}, LoginFormData>(
  'user/login',
  async ({ statute, ...formData }, { dispatch }) =>
    callApi<AuthLoginRes, AuthLoginReq>(dispatch, 'POST', '/auth/login', formData, () =>
      dispatch(successNotification('notifications.successful_login')),
    ),
);

export const loginCyberIdUser = createAsyncThunk<AuthLoginRes | {}, AuthCyberIdReq>(
  'user/cyber-id-login',
  async (redirectData, { dispatch }) =>
    callApi<AuthLoginRes, AuthCyberIdReq>(dispatch, 'POST', '/cyber-id', redirectData, (res) => {
      if (res.url) {
        window.location.href = res.url;
      }
      return dispatch(successNotification('notifications.successful_login'));
    }),
);

export const logoutUser = createAsyncThunk('user/logout', async (_, { dispatch }) =>
  callApi<AuthLoginRes>(dispatch, 'GET', '/auth/logout', undefined, () =>
    dispatch(successNotification('notifications.successful_logout')),
  ),
);

export const refreshToken = createAsyncThunk<RefeshTokenRes | {}, number>(
  'user/refreshToken',
  async (count, { dispatch }) =>
    callApi(dispatch, 'GET', '/cyber-id/refresh', undefined, undefined, () => {
      if (count < 3) {
        dispatch(refreshToken(count + 1));
      }
    }),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(loginUser.fulfilled, (state, { payload }) =>
        'user' in payload ? payload.user : state,
      )
      .addCase(loginCyberIdUser.fulfilled, (state, { payload }) =>
        'user' in payload ? payload.user : state,
      )
      .addCase(logoutUser.fulfilled, () => initialState)
      .addCase(refreshUserData.fulfilled, (state, { payload }) =>
        'id' in payload ? payload : state,
      )
      .addCase(refreshToken.fulfilled, (state, { payload }) => {
        if ('expireTime' in payload) {
          state.accessExpireTime = payload.expireTime;
        }
      }),
});

export const { clearUser } = userSlice.actions;
