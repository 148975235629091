import React, { forwardRef, useId } from 'react';
import { FieldErrors } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { Icon } from 'components/common';
import { FieldErrorMessage, Label } from 'components/forms';
import { Flex } from 'components/layout';
import { __ } from 'services';

import styles from './CustomInput.module.scss';

interface Props {
  fName: string;
  errors: FieldErrors;
  value?: string;
  required?: boolean;
  label?: string;
}

const CustomInput = forwardRef<HTMLButtonElement, Props>(
  ({ fName, value, errors, required, label, ...props }, ref) => {
    const id = useId();

    return (
      <Flex direction="col" className={styles.container}>
        {label && <Label label={label} htmlFor={`${id}-${fName}`} required={required} />}
        <button {...props} className={styles.button} id={`${id}-${fName}`} type="button" ref={ref}>
          <Icon name="calendar" />
          <span className={clsx({ [styles.placeholder]: !value })}>
            {value || __('placeholders.date')}
          </span>
        </button>
        {errors && (
          <ErrorMessage
            errors={errors}
            name={fName}
            render={({ message }) => <FieldErrorMessage message={message} />}
          />
        )}
      </Flex>
    );
  },
);

export default CustomInput;
