import { setMinutes, setHours } from 'date-fns';

// time format hh:mm
export const setHoursAndMinutes = (date: string, time: string) => {
  const [hoursStr, minutesStr] = time.split(':');

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  return setMinutes(setHours(new Date(date), hours), minutes);
};
