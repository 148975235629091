import React, { useEffect, useMemo } from 'react';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { InputWithIcon } from 'components/inputs';
import { Flex } from 'components/layout';
import { TablePagination, Table } from 'components/tables';
import { useStateDebounced } from 'hooks';
import { ManagementTableItemsData } from 'types';
import { __ } from 'services';

import ActionCell from './ActionCell';
import { useManagementData } from './useManagementData';
import { getTableParams } from './utils';

const ManagementTable: React.FC = () => {
  const [searchTerm, setSearchTermDebounced] = useStateDebounced('');
  const { data, getManagementTableData } = useManagementData();
  const columns: Column<ManagementTableItemsData>[] = useMemo(
    () => [
      { Header: __('company.name'), accessor: 'name' },
      { Header: __('company.krs_number'), accessor: 'krs' },
      { Header: __('company.nip_number'), accessor: 'nip' },
      { Header: __('company.subscription.type'), accessor: 'subscription', disableSortBy: true },
      {
        Header: __('application.action'),
        accessor: 'add_permanent_subscription',
        disableSortBy: true,
        Cell: ActionCell,
      },
      {
        Header: __('application.action'),
        accessor: 'block',
        disableSortBy: true,
        Cell: ActionCell,
      },
      {
        Header: __('application.action'),
        accessor: 'delete',
        disableSortBy: true,
        Cell: ActionCell,
      },
    ],
    [],
  );

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data: data?.items ?? [],
      pageCount: data?.countTotal ?? 1,
      manualPagination: true,
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    (async function fetchManagementTableData() {
      const params = getTableParams({ sortBy, searchTerm, pageIndex });

      await getManagementTableData(params);
    })();
  }, [getManagementTableData, pageIndex, sortBy, searchTerm]);

  return (
    <Flex direction="col" gap={3}>
      <InputWithIcon
        iconName="magnifier"
        placeholder="placeholders.search"
        onChange={(e) => setSearchTermDebounced(e.target.value)}
      />
      <Table
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={page}
        prepareRow={prepareRow}
        reloadData={getManagementTableData}
        searchTerm={searchTerm}
      />
      <TablePagination
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        pageCount={data?.countTotal ?? 1}
      />
    </Flex>
  );
};
export default ManagementTable;
