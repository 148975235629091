import React, { useEffect } from 'react';
import { Alert } from 'components/common';
import { useAppDispatch } from 'hooks';
import { hideNotification } from 'slices';
import { SingleNotification } from 'types';
import { onKeyboardInteraction } from 'utils';

import styles from './Notification.module.scss';

interface Props {
  notification: SingleNotification;
}

const Notification: React.FC<Props> = ({ notification }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timeout = setTimeout(() => dispatch(hideNotification(notification.id)), 5000);

    return () => clearTimeout(timeout);
  }, [dispatch, notification.id]);

  const onClick = () => dispatch(hideNotification(notification.id));

  return (
    <div
      onClick={onClick}
      onKeyPress={onKeyboardInteraction(onClick)}
      className={styles.notification}
      role="button"
      tabIndex={0}
      aria-label="hide-notification"
    >
      <Alert type={notification.type} text={notification.text} />
    </div>
  );
};

export default Notification;
