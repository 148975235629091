import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Icon } from 'components/common';
import { IconName } from 'types';

import styles from './IconButton.module.scss';

interface Props {
  iconName: IconName;
  onClick?: () => void;
  to?: string;
  isActive?: boolean;
  className?: string;
}

const IconButton: React.FC<Props> = ({
  iconName,
  onClick,
  to,
  isActive,
  className: propsClassName,
}) => {
  const className = clsx(styles.button, propsClassName, { [styles.active]: isActive });

  if (to) {
    return (
      <Link className={className} to={to}>
        <Icon name={iconName} />
      </Link>
    );
  }

  return (
    <button className={className} onClick={onClick} type="button" aria-label="icon">
      <Icon name={iconName} />
    </button>
  );
};

export default IconButton;
