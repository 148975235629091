import React from 'react';
import { FileRes } from 'app-types';
import { Icon, Text } from 'components/common';
import { useFileDownload } from 'hooks';

import styles from './File.module.scss';

interface Props {
  data: Pick<FileRes, 'id' | 'name'>;
  isClickable?: boolean;
}

const File: React.FC<Props> = ({ data, isClickable }) => {
  const { downloadMeetingFile } = useFileDownload();
  const { name } = data;

  const onClick = async () => downloadMeetingFile(data);

  return (
    <button
      className={styles.wrapper}
      onClick={isClickable ? onClick : undefined}
      aria-label="download-meeting"
    >
      <Icon name="pdf" />
      <Text text={name} shouldTranslate={false} size={2} color="action" weight="bold" truncate />
    </button>
  );
};

export default File;
