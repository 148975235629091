import React from 'react';
import { PropsWithChildren } from 'types';
import { Flex } from 'components/layout';

import styles from './AgendaItemListContainer.module.scss';

const AgendaItemListContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <Flex className={styles.container} direction="col" gap={1} xs={12} grow={1}>
    {children}
  </Flex>
);

export default AgendaItemListContainer;
