import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Text } from 'components/common';

import styles from './Radio.module.scss';

interface Props {
  name: string;
  id: string;
  value?: string | number;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  labelTranslationArgs?: Record<string, string>;
  color?: 'primary' | 'secondary' | 'tertiary' | 'sixth';
}

const Radio: React.FC<Props> = ({
  name,
  id,
  value,
  checked,
  disabled,
  label,
  labelTranslationArgs,
  color,
}) => {
  const { register } = useFormContext();

  return (
    <div className={styles.wrapper}>
      <input
        {...register(name)}
        type="radio"
        className={styles.input}
        id={id}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label className={styles.label} htmlFor={id}>
        <span className={styles.box} />
        {label && (
          <Text size={3} text={label} translationArgs={labelTranslationArgs} color={color} />
        )}
      </label>
    </div>
  );
};

export default Radio;
