import React from 'react';
import { Flex } from 'components/layout';
import { PropsWithChildren } from 'types';

import styles from './VotingParticipantsMemberRepresentativeContainer.module.scss';

const VotingParticipantsMemberRepresentativeContainer: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Flex direction="col" gap={1} className={styles.container}>
    {children}
  </Flex>
);

export default VotingParticipantsMemberRepresentativeContainer;
