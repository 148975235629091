import React from 'react';

import { Form, Input } from 'components/forms';
import { Flex } from 'components/layout';
import { Text, Button } from 'components/common';
import { useAppDispatch, useModal, useApi } from 'hooks';
import { refreshUserData, successNotification } from 'slices';
import { AllRoutes, confirmationSmsCodeValidationSchema } from 'config';
import { useNavigate } from 'react-router';

const EnterSmsCode: React.FC = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    hideModal,
    data: { id, isLoggedIn },
  } = useModal<undefined, { id: string; isLoggedIn?: boolean }>();

  const onSubmit = (formData: { smsCode: string }) => {
    const smsReqData = {
      code: formData.smsCode,
      id,
    };

    api.post('/user/activate-user-phone', smsReqData, () => {
      dispatch(successNotification('notifications.success'));
      hideModal();
      dispatch(refreshUserData());
      if (!isLoggedIn) navigate(AllRoutes.Login);
    });
  };

  const resendSmsCode = () => {
    api.post(`/user/${isLoggedIn ? '' : 'register/'}resend-phone-activation-code`, { id }, () => {
      dispatch(successNotification('notifications.resend_sms_code'));
    });
  };

  return (
    <Flex direction="col" gap={5}>
      <Form submitHandler={onSubmit} validationSchema={confirmationSmsCodeValidationSchema}>
        <Flex direction="col" gap={4}>
          <Flex direction="col" gap={4}>
            <Flex direction="col" gap={4}>
              <Text text="pages.login.activate_account" />
              <Flex direction="col" gap={4}>
                <Text text="pages.login.smsCode" />
                <Flex justify="space-between" gap={2}>
                  <Input name="smsCode" size="responsive" maxLength={6} />
                  <Button
                    text="pages.login.send_again"
                    variant="secondary"
                    onClick={resendSmsCode}
                    type="button"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Button text="pages.login.confirm" variant="primary" />
        </Flex>
      </Form>
    </Flex>
  );
};

export default EnterSmsCode;
