import React from 'react';
import { useAppSelector } from 'hooks';
import { PropsWithChildren } from 'types';

const OnlineContent: React.FC<PropsWithChildren> = ({ children }) => {
  const isOnline = useAppSelector(({ appStatus }) => appStatus);

  if (!isOnline) return null;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default OnlineContent;
