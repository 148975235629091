import React, { useMemo } from 'react';
import { VoteOption, VotingResultItem } from 'types';
import { v4 as uuid } from 'uuid';
import { isOpenVotingState, isVotingResultState } from 'utils';
import { MeetingCurrentState, MeetingStateDuringResults } from 'app-types';

import VotingResultCard from '../VotingResultCard';

interface Props {
  state: MeetingStateDuringResults;
}

const results: VotingResultItem[] = [
  { id: uuid(), voteOption: 'inFavour', cardVariant: 'yes' },
  { id: uuid(), voteOption: 'against', cardVariant: 'no' },
  { id: uuid(), voteOption: 'abstain', cardVariant: 'abstain' },
];

const getNormalizedState = (state?: MeetingCurrentState): Record<VoteOption, number> => {
  if (isOpenVotingState(state)) {
    const { inFavour, against, abstain } = state;
    return {
      inFavour: inFavour.votesCount ?? 0,
      against: against.votesCount ?? 0,
      abstain: abstain.votesCount ?? 0,
    };
  }

  if (isVotingResultState(state)) {
    const { inFavour, against, abstain } = state;
    return { inFavour: inFavour ?? 0, against: against ?? 0, abstain: abstain ?? 0 };
  }

  return { inFavour: 0, against: 0, abstain: 0 };
};

const getMajorityOption = (state: Record<VoteOption, number>): string => {
  const sortVotingResults = (votingResults: Record<VoteOption, number>): [string, number][] =>
    Object.entries(votingResults).sort((a, b) => b[1] - a[1]);

  const [arr1, arr2] = sortVotingResults(state);

  const areResultsEqual = arr1[1] === arr2[1];
  if (areResultsEqual) return '';

  return arr1[0];
};

const VotingResultsList: React.FC<Props> = ({ state }) => {
  const normalizedState = useMemo(() => getNormalizedState(state), [state]);
  const majorityOption = useMemo(() => getMajorityOption(normalizedState), [normalizedState]);

  return (
    <>
      {results.map(({ id, voteOption, cardVariant }) => (
        <VotingResultCard
          key={id}
          variant={cardVariant}
          votesFor={normalizedState[voteOption]}
          voters={isOpenVotingState(state) ? state[voteOption].shareholders : undefined}
          totalVotes={state.votesCount}
          hasMajority={majorityOption === voteOption}
        />
      ))}
    </>
  );
};

export default VotingResultsList;
