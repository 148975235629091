import { useMemo } from 'react';
import { MeetingStatus } from 'app-types';
import {
  isResolutionVotingState,
  isResumptionVotingState,
  isResolutionVotingResultState,
  isResumptionVotingResultState,
  isVotingState,
  isVotingResultState,
} from 'utils';
import { useAppSelector } from './redux';

export const useMeetingState = () => {
  const { data, state } = useAppSelector(({ meeting }) => meeting);
  const { user } = useAppSelector((rootState) => rootState);

  const stateBooleans = useMemo(
    () => ({
      isVotingState: isVotingState(state),
      isResolutionVotingState: isResolutionVotingState(state),
      isResumptionVotingState: isResumptionVotingState(state),
      isVotingResultState: isVotingResultState(state),
      isResolutionVotingResultState: isResolutionVotingResultState(state),
      isResumptionVotingResultState: isResumptionVotingResultState(state),
      isUserVotingShareholdersArrayEmpty: isVotingState(state) && state.shareholders.length === 0,
      wasResolutionRecapitulated:
        isResolutionVotingResultState(state) && state.wasResolutionRecapitulated,
    }),
    [state],
  );

  const dataBooleans = useMemo(
    () => ({
      isUserVoting: data?.shareholders && data?.shareholders.length > 0,
      isMeetingInProgress: data?.status === MeetingStatus.IN_PROGRESS,
      isMeetingPlanned: data?.status === MeetingStatus.PLANNED,
      isMeetingPaused: data?.status === MeetingStatus.PAUSED,
      hasMeetingEnded: data?.status === MeetingStatus.ENDED,
    }),
    [data],
  );

  const sharedBooleans = useMemo(
    () => ({
      hasUserVotedWithAllShares:
        dataBooleans.isUserVoting &&
        stateBooleans.isVotingState &&
        stateBooleans.isUserVotingShareholdersArrayEmpty,
      isBreakTime: dataBooleans.isMeetingInProgress && stateBooleans.isVotingResultState,
    }),
    [
      dataBooleans.isUserVoting,
      dataBooleans.isMeetingInProgress,
      stateBooleans.isVotingState,
      stateBooleans.isVotingResultState,
      stateBooleans.isUserVotingShareholdersArrayEmpty,
    ],
  );

  const progressbarValue = useMemo(() => {
    if (!data?.shareholders.length || !isVotingState(state)) return 0;
    if (!state.shareholders.length) return data.shareholders.length;

    return data.shareholders.length - state.shareholders.length;
  }, [data?.shareholders, state]);

  const goalDate = useMemo(() => {
    if (isVotingResultState(state)) {
      return state.breakEndDate;
    }
    if (isVotingState(state)) {
      return isResumptionVotingState(state)
        ? (state.resumption.endDate as string)
        : (state.resolution?.voting?.endDate as string);
    }

    return undefined;
  }, [state]);

  const isUserCompanyOwner = useMemo(
    () => !!user?.id && data?.company.owner.id === user.id,
    [user, data?.company.owner.id],
  );

  return {
    ...stateBooleans,
    ...dataBooleans,
    ...sharedBooleans,
    isUserCompanyOwner,
    progressbarValue,
    goalDate,
  };
};
