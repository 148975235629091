import React from 'react';
import { Button, Text } from 'components/common';
import { DeleteModalContentContainer, ModalActionButtonsContainer } from 'components/containers';
import { useApi, useAppDispatch, useModal } from 'hooks';
import { DeleteResolutionData } from 'types';
import { fetchAddMeetingData, fetchMeetingData } from 'slices';

const DeleteResolution: React.FC = () => {
  const {
    hideModal,
    data: { agendaItemId, title, meetingId, isMeetingInProgress },
  } = useModal<undefined, DeleteResolutionData>();
  const api = useApi();
  const dispatch = useAppDispatch();

  const onAccept = async () =>
    api.delete(`/meeting/agenda-item/${agendaItemId}`, () => {
      if (isMeetingInProgress) {
        dispatch(fetchMeetingData({ meetingId }));
      } else {
        dispatch(fetchAddMeetingData(meetingId));
      }
      hideModal();
    });

  return (
    <DeleteModalContentContainer>
      <Text
        text="modals.delete_resolution.title"
        translationArgs={{ title }}
        size={4}
        weight="bold"
      />
      <ModalActionButtonsContainer>
        <Button text="application.yes" variant="secondary" onClick={onAccept} />
        <Button text="application.no" variant="tertiary" onClick={hideModal} />
      </ModalActionButtonsContainer>
    </DeleteModalContentContainer>
  );
};

export default DeleteResolution;
