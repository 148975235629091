import {
  BlockchainSynchronizationData,
  BlockchainSynchronizationReq,
  MeetingData,
  MeetingResultsRes,
  VotingResultRes,
} from 'app-types';
import { BlockchainFailureMessage, BlockchainResults, SaveVotingResultsParams } from 'types';

export const areVotesAlreadySent = (meetingData?: MeetingData): boolean =>
  typeof meetingData?.isBlockchainSynchronized === 'boolean'
    ? meetingData?.isBlockchainSynchronized
    : false;

const getArrOfIds = <Obj extends { id: string }>(arr: Obj[]): string[] => arr.map(({ id }) => id);

const getTxId = (id: string, blockchainResults: BlockchainResults): string => {
  if (Array.isArray(blockchainResults)) {
    return blockchainResults.find((result) => result.id === id)?.result.data.txid ?? '';
  }
  return blockchainResults.result.data.txid ?? '';
};

export const getBlockchainSynchronizationReq = ({
  votingResults,
  resumptionResults,
}: MeetingResultsRes): BlockchainSynchronizationData => ({
  synchronizedVotingIds: getArrOfIds(votingResults),
  synchronizedResumptionIds: getArrOfIds(resumptionResults),
});

export const getBlockchainSuccessSynchronizationReq = (
  { synchronizedVotingIds, synchronizedResumptionIds }: BlockchainSynchronizationData,
  blockchainResults: BlockchainResults,
): BlockchainSynchronizationReq => ({
  synchronizedVotingIds: synchronizedVotingIds.map((id) => ({
    id,
    txId: getTxId(id, blockchainResults),
  })),
  synchronizedResumptionIds: synchronizedResumptionIds.map((id) => ({
    id,
    txId: getTxId(id, blockchainResults),
  })),
});

export const getFailureCbData = (
  messages: BlockchainFailureMessage | BlockchainFailureMessage[],
  reqData: BlockchainSynchronizationData,
) => {
  const errorIdsArr = Array.isArray(messages) ? getArrOfIds(messages) : [messages.id];
  const errorFilter = (id: string) => !errorIdsArr.includes(id);

  const synchronizedVotingIds = reqData.synchronizedVotingIds.filter(errorFilter);
  const synchronizedResumptionIds = reqData.synchronizedResumptionIds.filter(errorFilter);

  const summedLength = synchronizedVotingIds.length + synchronizedResumptionIds.length;

  return { synchronizedVotingIds, synchronizedResumptionIds, summedLength };
};

const getSaveVotingResultsParamsArr = (results: VotingResultRes[]): SaveVotingResultsParams[] =>
  results.map(({ id, ...voting_data }) => ({
    id,
    voting_data: { id, ...voting_data },
  }));

export const getParams = (
  { votingResults, resumptionResults }: MeetingResultsRes,
  isBlockchainSynchronized?: boolean | BlockchainSynchronizationData,
): SaveVotingResultsParams[] => {
  const votingResultsArr = getSaveVotingResultsParamsArr(votingResults);
  const resumptionResultsArr = getSaveVotingResultsParamsArr(resumptionResults);

  if (typeof isBlockchainSynchronized === 'boolean' || isBlockchainSynchronized === undefined) {
    return [...resumptionResultsArr, ...votingResultsArr];
  }
  const { synchronizedVotingIds, synchronizedResumptionIds } = isBlockchainSynchronized;

  const filteredVotingResultsArr = votingResultsArr.filter(
    ({ id }) => !synchronizedVotingIds.includes(id),
  );
  const filteredResumptionResultsArr = resumptionResultsArr.filter(
    ({ id }) => !synchronizedResumptionIds.includes(id),
  );

  return [...filteredVotingResultsArr, ...filteredResumptionResultsArr];
};
