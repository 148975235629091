import React from 'react';
import { useParams } from 'react-router';
import { BlockchainSynchronizationReq } from 'app-types';
import { Button } from 'components/common';
import { useApi, useAppDispatch, useAppSelector, useBlockchain } from 'hooks';
import { fetchMeetingData, infoNotification, successNotification } from 'slices';
import { BlockchainFailureMessage, BlockchainResults, MeetingParams } from 'types';
import { areVotesAlreadySent } from 'utils';

import { useMeetingResultsData } from './useMeetingResultsData';
import {
  getParams,
  getBlockchainSynchronizationReq,
  getFailureCbData,
  getBlockchainSuccessSynchronizationReq,
} from './utils';

interface Props {
  className: string;
}

const SendVotingResultsToBlockchain: React.FC<Props> = ({ className }) => {
  const { saveVotingResults } = useBlockchain();
  const { meetingResultsData } = useMeetingResultsData();
  const { data } = useAppSelector(({ meeting }) => meeting);
  const { meetingId } = useParams<MeetingParams>();
  const api = useApi();
  const dispatch = useAppDispatch();

  if (areVotesAlreadySent(data)) {
    return (
      <Button
        className={className}
        text="pages.meeting.blockchain_data_already_sent"
        variant="secondary"
        disabled
      />
    );
  }

  const onBlockchainSave = async () => {
    if (!meetingResultsData) return;
    const cbReqData = getBlockchainSynchronizationReq(meetingResultsData);
    const params = getParams(meetingResultsData, data?.isBlockchainSynchronized);

    const successCb = async (blockchainResults: BlockchainResults) => {
      const successCbReqData = getBlockchainSuccessSynchronizationReq(cbReqData, blockchainResults);
      await api.patch<undefined, BlockchainSynchronizationReq>(
        `/meeting/${meetingId}/blockchain-synchronization`,
        successCbReqData,
        async () => {
          await dispatch(fetchMeetingData({ meetingId }));
          dispatch(successNotification('notifications.blockchain_data_sent'));
        },
      );
    };

    const failureCb = async (
      messages: BlockchainFailureMessage | BlockchainFailureMessage[],
      blockchainResults?: BlockchainResults,
    ) => {
      const { summedLength, ...filteredReqData } = getFailureCbData(messages, cbReqData);

      const sentVoteCount = params.length;
      const totalVotesCount =
        cbReqData.synchronizedVotingIds.length + cbReqData.synchronizedResumptionIds.length;

      const hasAnyBeenSaved = summedLength !== totalVotesCount - sentVoteCount;
      if (!summedLength || !hasAnyBeenSaved || !blockchainResults) {
        dispatch(infoNotification('blockchain_informations.nothing_saved'));
        return;
      }

      const successCbReqData = getBlockchainSuccessSynchronizationReq(
        filteredReqData,
        blockchainResults,
      );

      api.patch<undefined, BlockchainSynchronizationReq>(
        `/meeting/${meetingId}/blockchain-synchronization`,
        successCbReqData,
        async () => {
          await dispatch(fetchMeetingData({ meetingId }));
          dispatch(infoNotification('blockchain_informations.partly_saved'));
        },
      );
    };
    await saveVotingResults({ params, successCb, failureCb });
  };

  return (
    <Button
      className={className}
      text="pages.meeting.send_to_blockchain"
      variant="secondary"
      disabled={!meetingResultsData}
      onClick={onBlockchainSave}
    />
  );
};

export default SendVotingResultsToBlockchain;
