import React from 'react';
import clsx from 'clsx';
import { __ } from 'services';

import styles from './Text.module.scss';

interface Props {
  text: string;
  className?: string;
  size?: number;
  opacity?: number;
  color?: 'primary' | 'secondary' | 'tertiary' | 'sixth' | 'action' | 'success' | 'error';
  as?: 'span' | 'p';
  weight?: 'thin' | 'normal' | 'semi-bold' | 'bold' | 'extra-bold';
  align?: 'start' | 'left' | 'center' | 'right';
  italic?: boolean;
  uppercase?: boolean;
  block?: boolean;
  shouldTranslate?: boolean;
  whiteSpace?: 'break-spaces' | 'unset';
  truncate?: boolean;
  truncateVertical?: number;
  translationArgs?: Record<string, string | number>;
}

const Text: React.FC<Props> = ({
  text,
  className,
  size,
  italic,
  translationArgs,
  block,
  uppercase,
  opacity,
  whiteSpace,
  truncate,
  truncateVertical,
  align = 'start',
  color = 'first',
  weight = 'normal',
  shouldTranslate = true,
  as: Tag = 'span',
}) => (
  <Tag
    className={clsx(styles.text, className, {
      [styles?.[`size-${size}`]]: size,
      [styles?.[`color-${color}`]]: color,
      [styles?.[`opacity-${opacity}`]]: opacity,
      [styles?.[`align-${align}`]]: align,
      [styles?.[weight]]: weight,
      [styles?.[`${whiteSpace}`]]: whiteSpace,
      [styles.uppercase]: uppercase,
      [styles.block]: block,
      [styles.italic]: italic,
      [styles.truncate]: truncate,
      [styles?.[`truncate-vertical-${truncateVertical}`]]: truncateVertical,
    })}
  >
    {shouldTranslate ? __(text, translationArgs) : text}
  </Tag>
);

export default Text;
