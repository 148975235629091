import { CompanyEntity, GroupEntity } from '../company';
import { ProtocolSchema } from '../protocol';
import { AgendaEntity } from '../agenda';
import { UserVoterEntity } from '../user-voter';
import { CompanyVoterEntity } from '../company-voter';
import { UserEntity } from '../user';
import { BlockchainSynchronizationReq } from './meeting-requests';
import { OnlineShareholderEntity } from '../online-shareholder';
import { RepresentativesEntity } from '../representatives';

export interface BaseMeeting {
  date: Date;
  quorum: number;
  notary: UserEntity;
  type: string;
  formality: Formality;
  participatingGroups: GroupEntity[];
  representatives: RepresentativesEntity[];
  company: CompanyEntity;
  agenda: AgendaEntity;
}

export interface MeetingEntity extends BaseMeeting {
  id: string;
  status: MeetingStatus;
  currentQuorum: number;
  isBlockchainSynchronized: boolean;
  blockchainSynchronizations: string | BlockchainSynchronizationReq;
  usersVoters: UserVoterEntity[];
  companiesVoters: CompanyVoterEntity[];
  onlineShareholders: OnlineShareholderEntity[];
  protocolSchema: ProtocolSchema;
}

export enum MeetingStatus {
  DRAFT = 'DRAFT',
  PARTICIPANTS_NOT_INVITED = 'PARTICIPANTS_NOT_INVITED',
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  ENDED = 'ENDED',
}

export enum MeetingType {
  NZW = 'NADZWYCZAJNE ZGROMADZENIE WSPÓLNIKÓW',
  ZZW = 'ZWYCZAJNE ZGROMADZENIE WSPÓLNIKÓW',
  NWZ = 'NADZWYCZAJNE WALNE ZGROMADZENIE',
  ZWZ = 'ZWYCZAJNE WALNE ZGROMADZENIE',
  Authority = 'ORGANU',
}

export enum Formality {
  FORMAL = 'FORMAL',
  INFORMAL = 'INFORMAL',
}
