import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from 'app-types';
import { setRedirectFrom } from 'slices';

import { useAppDispatch, useAppSelector } from './redux';

export interface Paths {
  [UserRole.SuperAdmin]?: string;
  [UserRole.Admin]?: string;
  [UserRole.User]?: string;
  default: string;
}

export const useUserRedirect = (paths: Paths): void => {
  const { role, id } = useAppSelector(({ user }) => user);
  const { redirectedFrom, requiredRole } = useAppSelector(({ router }) => router);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!id) return () => {};
    if ((redirectedFrom && !requiredRole) || (redirectedFrom && role === requiredRole)) {
      navigate(redirectedFrom);

      return () => {
        dispatch(setRedirectFrom({ redirectedFrom: '' }));
      };
    }

    if (role === UserRole.SuperAdmin && paths.SuperAdmin) {
      navigate(paths.SuperAdmin, { replace: true });
    } else if (role === UserRole.Admin && paths.Admin) {
      navigate(paths.Admin, { replace: true });
    } else if (role === UserRole.User && paths.User) {
      navigate(paths.User, { replace: true });
    } else {
      navigate(paths.default, { replace: true });
    }

    return () => {
      if (redirectedFrom) {
        dispatch(setRedirectFrom({ redirectedFrom: '' }));
      }
    };
  }, [dispatch, redirectedFrom, requiredRole, id, role, paths, navigate]);
};
