import React, { useId } from 'react';
import { Button, InlineButton, Text } from 'components/common';
import { MobileBottomBarContainer } from 'components/containers';
import { Flex } from 'components/layout';
import { useAppSelector, useScrollLock } from 'hooks';

import MobileSharesMenuContent from './MobileSharesMenuContent';

interface Props {
  goBack: () => void;
}

const MobileSharesMenu: React.FC<Props> = ({ goBack }) => {
  const { state } = useAppSelector(({ meeting }) => meeting);
  const id = useId();
  useScrollLock(id);

  if (!state) {
    goBack();
    return null;
  }

  return (
    <>
      <MobileSharesMenuContent />
      <MobileBottomBarContainer id={id}>
        <Button text="breadcrumbs.back" onClick={goBack} variant="tertiary" responsive />
        <Flex align="center">
          <Text text="pages.meeting.something_is_wrong" size={2} />
          <InlineButton text="pages.meeting.report_mistake" underline />
        </Flex>
      </MobileBottomBarContainer>
    </>
  );
};

export default MobileSharesMenu;
