import React from 'react';
import { Flex } from 'components/layout';
import { HorizontalLine } from 'components/common';
import { ExcludedGroupsVotersPeople, ExcludedGroupsVotersCompanies } from 'types';

import Member from '../Member';

interface Props {
  groupMembers: (ExcludedGroupsVotersPeople | ExcludedGroupsVotersCompanies)[];
}

const Content: React.FC<Props> = ({ groupMembers }) => (
  <Flex gap={2}>
    {groupMembers.map((member, i) => (
      <React.Fragment key={member.id}>
        {!!i && <HorizontalLine />}
        <Member data={member} />
      </React.Fragment>
    ))}
  </Flex>
);

export default Content;
