import React from 'react';
import clsx from 'clsx';
import { Flex } from 'components/layout';

import styles from './Card.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  gap?: number;
  grow?: number;
  shrink?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  justify?: 'start' | 'center' | 'end' | 'space-between';
  align?: 'start' | 'center' | 'end' | 'stretch';
  direction?: 'row' | 'col';
  onClick?: () => void;
  role?: 'radio' | 'button';
}

const Card: React.FC<Props> = ({
  children,
  className,
  gap,
  grow,
  shrink,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  justify,
  align,
  direction,
  role,
  onClick,
}) => (
  <Flex
    className={clsx(styles.card, className)}
    gap={gap}
    grow={grow}
    shrink={shrink}
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    xxl={xxl}
    justify={justify}
    align={align}
    direction={direction}
    role={role}
    onClick={onClick}
  >
    {children}
  </Flex>
);

export default Card;
