import React from 'react';
import { Button } from 'components/common';
import { AllRoutes } from 'config';
import { CompanyFormVariant } from 'types';

interface Props {
  variant: CompanyFormVariant;
}

const ButtonSelector: React.FC<Props> = ({ variant }) => {
  switch (variant) {
    case 'add-company':
      return <Button text="pages.new_company.button2" variant="secondary" />;
    case 'edit-company':
      return (
        <>
          <Button text="application.cancel" variant="tertiary" to={AllRoutes.Admin} />
          <Button text="application.save_changes" variant="secondary" />
        </>
      );
    default:
      return null;
  }
};

export default ButtonSelector;
