import React from 'react';
import { GroupModalVariant } from 'types';

import AddGroup from './AddGroup';
import EditGroup from './EditGroup';

interface Props {
  variant: GroupModalVariant;
}

const Group: React.FC<Props> = ({ variant }) => {
  switch (variant) {
    case 'add_group':
      return <AddGroup />;
    case 'edit_group':
      return <EditGroup />;
    default:
      return null;
  }
};

export default Group;
