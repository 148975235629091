import React, { useEffect } from 'react';
import clsx from 'clsx';
import Menu from 'components/common/Menu';

import styles from './Main.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  bodyBackgroundColor?: 'white' | 'gray';
  size?: 'small' | 'medium' | 'large';
}

const Main: React.FC<Props> = ({
  children,
  className,
  bodyBackgroundColor = 'white',
  size = 'large',
}) => {
  useEffect(() => {
    document.body.classList.add(`bg-${bodyBackgroundColor}`);

    return () => {
      document.body.classList.remove(`bg-${bodyBackgroundColor}`);
    };
  }, [bodyBackgroundColor]);

  return (
    <main
      className={clsx(className, styles.main, {
        [styles?.[size]]: size,
      })}
    >
      <Menu />
      {children}
    </main>
  );
};

export default Main;
