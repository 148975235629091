import React from 'react';
import { Button } from 'components/common';
import { Form, RadioGroup } from 'components/forms';
import { Flex } from 'components/layout';
import { voteValidationSchema } from 'config';
import { VoteFormData, VoteOption } from 'types';

interface Props {
  submitHandler: (formValues: VoteFormData) => void;
  isFormDisabled: boolean;
}

interface RadioItem {
  value: VoteOption;
  label: string;
  isChecked?: boolean;
}

const radioItems: RadioItem[] = [
  {
    value: 'inFavour',
    label: 'application.yes',
  },
  {
    value: 'against',
    label: 'application.no',
  },
  {
    value: 'abstain',
    label: 'application.abstain',
  },
];

const VoteForm: React.FC<Props> = ({ submitHandler, isFormDisabled }) => (
  <Form submitHandler={submitHandler} validationSchema={voteValidationSchema}>
    <Flex direction="col" gap={6}>
      <RadioGroup name="voteOption" items={radioItems} gap={2} disabled={isFormDisabled} />
      <Button
        text="pages.meeting.make_vote"
        variant="secondary"
        responsive
        disabled={isFormDisabled}
      />
    </Flex>
  </Form>
);

export default VoteForm;
