import React from 'react';
import { UseTableInstanceProps } from 'react-table';
import { TableContainer } from 'components/containers';

import styles from './Table.module.scss';

interface Props<Data extends object, Params extends object> {
  headerGroups: UseTableInstanceProps<Data>['headerGroups'];
  getTableBodyProps: UseTableInstanceProps<Data>['getTableBodyProps'];
  rows: UseTableInstanceProps<Data>['rows'];
  prepareRow: UseTableInstanceProps<Data>['prepareRow'];
  reloadData?: (params: Params) => void;
  searchTerm?: string;
}

/**
 * This component is ready for react-table implementation
 */

const Table = <Data extends object, Params extends object>({
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  reloadData,
  searchTerm,
}: Props<Data, Params>) => (
  <TableContainer>
    <table className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell', { reloadData, searchTerm })}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  </TableContainer>
);

export default Table;
