import React, { useEffect, useMemo } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import { Text } from 'components/common';
import { SharesInputsGroupContainer } from 'components/containers';
import { Flex } from 'components/layout';
import { SharesWithId } from 'types';
import { getSharesWithMultiplierTranslations } from 'utils';

import VotingOptionInputList from '../VotingOptionInputList';
import VotesUsed from './VotesUsed';
import FieldErrorMessage from '../../FieldErrorMessage';

interface Props {
  shares: SharesWithId;
}

const SharesInputsGroup: React.FC<Props> = ({ shares }) => {
  const {
    watch,
    trigger,
    formState: { isSubmitted, errors },
  } = useFormContext();
  const { text, translationArgs } = useMemo(
    () => getSharesWithMultiplierTranslations(shares),
    [shares],
  );

  useEffect(() => {
    const { unsubscribe } = watch((data, info) => {
      if (!info.name?.includes(shares.id)) return;
      if (info.type === 'change' && isSubmitted) {
        trigger(shares.id);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [trigger, watch, isSubmitted, shares.id]);

  return (
    <SharesInputsGroupContainer>
      <Flex direction="col">
        <Text text={text} translationArgs={translationArgs} weight="bold" size={3} />
        <VotesUsed shares={shares} />
      </Flex>
      <VotingOptionInputList inputBaseName={shares.id} />
      {errors && (
        <ErrorMessage
          errors={errors}
          name={shares.id}
          render={({ message }) => <FieldErrorMessage message={message} />}
        />
      )}
    </SharesInputsGroupContainer>
  );
};

export default SharesInputsGroup;
