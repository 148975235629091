import React from 'react';
import {
  AddResolution,
  DataChanged,
  DeleteResolution,
  EditResolution,
  EndVoting,
  Group,
  GroupMember,
  ManageResumption,
  Report,
  ShowVotes,
  SplitVotes,
  UploadCSV,
  MeetingClosed,
  ShareYoutubeVideo,
  ManageExcludedVoters,
} from 'components/modals';
import { ModalType } from 'types';
import EnterSmsCode from 'components/modals/EnterSmsCode';

interface Props {
  content: ModalType | null;
}

const ModalSelector: React.FC<Props> = ({ content }) => {
  switch (content) {
    case ModalType.DataChanged:
      return <DataChanged />;
    case ModalType.AddGroup:
      return <Group variant="add_group" />;
    case ModalType.EditGroup:
      return <Group variant="edit_group" />;
    case ModalType.AddSubject:
      return <GroupMember variant="add_subject" />;
    case ModalType.AddMember:
      return <GroupMember variant="add_member" />;
    case ModalType.EditGroupSubject:
      return <GroupMember variant="edit_subject" />;
    case ModalType.EditGroupMember:
      return <GroupMember variant="edit_member" />;
    case ModalType.DeleteFromGroup:
      return <GroupMember variant="delete_member" />;
    case ModalType.DeleteResolution:
      return <DeleteResolution />;
    case ModalType.AddResolution:
      return <AddResolution />;
    case ModalType.EditResolution:
      return <EditResolution />;
    case ModalType.EndVoting:
      return <EndVoting />;
    case ModalType.SplitVotes:
      return <SplitVotes />;
    case ModalType.ShowVotes:
      return <ShowVotes />;
    case ModalType.ReportChange:
      return <Report variant="change" />;
    case ModalType.ReportObjection:
      return <Report variant="objection" />;
    case ModalType.ManageResumption:
      return <ManageResumption />;
    case ModalType.UploadCSV:
      return <UploadCSV />;
    case ModalType.MeetingClosed:
      return <MeetingClosed />;
    case ModalType.ShareYoutubeVideo:
      return <ShareYoutubeVideo />;
    case ModalType.ManageExcludedVoters:
      return <ManageExcludedVoters />;
    case ModalType.EnterSmsCode:
      return <EnterSmsCode />;
    default:
      return null;
  }
};

export default ModalSelector;
