import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ModalState, PrepareModalActionPayloads, UnPrepareModalActionPayload } from 'types';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const initialState: ModalState = {
  isVisible: false,
  isClosable: true,
  isPrepared: false,
  isLoading: false,
  type: null,
  data: null,
  outputData: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    prepareModal: (state, { payload }: PayloadAction<PrepareModalActionPayloads>) => {
      if (payload.isVisible) {
        state.isVisible = payload.isVisible;
        disableBodyScroll(document.getElementById('modal-content')!);
      }
      state.isPrepared = true;
      state.type = payload.type;
      state.data = payload.data;
    },
    unPrepareModal: (state, { payload }: PayloadAction<UnPrepareModalActionPayload>) => {
      state.isVisible = payload?.isVisible ?? state.isVisible;
      state.isPrepared = false;
      state.type = null;
      state.data = null;
      state.outputData = null;
    },
    showModal: (state) => {
      disableBodyScroll(document.getElementById('modal-content')!);
      state.isVisible = true;
    },
    hideModal: (state) => {
      clearAllBodyScrollLocks();
      state.isVisible = false;
      state.isClosable = true;
    },
    setModalLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setOutputData: (state: Draft<ModalState>, { payload }: PayloadAction<unknown>) => {
      state.outputData = payload;
    },
  },
});

export const { prepareModal, unPrepareModal, showModal, hideModal, setOutputData } =
  modalSlice.actions;
