import { CONFIG } from 'config';
import { Dispatch } from '@reduxjs/toolkit';
import { ApiErrorCode } from 'app-types';
import { errorNotification, setLoading } from 'slices';

const is400 = (response: Response) => response.status === 400;

export const downloadPdf = async (dispatch: Dispatch, url: string) => {
  try {
    dispatch(setLoading(true));
    const response = await fetch(`${CONFIG.BASE_URL}${url}`, {
      method: 'GET',
      headers: { Accept: 'application/octet-stream' },
      credentials: 'include',
    });

    if (!response.ok || is400(response)) {
      dispatch(errorNotification(ApiErrorCode.CouldNotDownloadFile));
      return;
    }

    const file = new Blob([await response.blob()], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    const pdfWindow = window.open();
    if (pdfWindow) {
      pdfWindow.location.href = fileURL;
    }
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(errorNotification(ApiErrorCode.CouldNotDownloadFile));
  }
};
