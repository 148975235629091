import React, { useId, useState } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { Flex } from 'components/layout';
import { IconButton } from 'components/common';
import { ErrorMessage } from '@hookform/error-message';

import { __ } from 'services';
import FieldErrorMessage from '../FieldErrorMessage';
import Label from '../Label';
import styles from './Input.module.scss';

interface Props {
  name: string;
  className?: string;
  label?: string;
  labelTranslationArgs?: Record<string, string>;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  size?: 'small' | 'semi-small' | 'normal' | 'semi-big' | 'big' | 'responsive' | 'protocol';
  type?: 'number' | 'text' | 'password';
  minNumber?: number;
  maxNumber?: number;
  autoComplete?: 'off' | 'new-password';
  errorNowrap?: boolean;
  maxLength?: number;
}

const Input: React.FC<Props> = ({
  name,
  label,
  labelTranslationArgs,
  required,
  className,
  disabled = false,
  size = 'big',
  placeholder = 'placeholders.provide_data',
  type = 'text',
  minNumber,
  maxNumber,
  autoComplete,
  errorNowrap,
  maxLength,
}) => {
  const { register, getFieldState, formState } = useFormContext();
  const { error } = getFieldState(name, formState);
  const id = useId();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div
      className={clsx(className, styles.field, {
        [styles.error]: error,
        [styles?.[size]]: size,
      })}
    >
      {label && (
        <Label
          htmlFor={`${id}-${name}`}
          required={required}
          label={label}
          translationArgs={labelTranslationArgs}
        />
      )}
      <Flex direction="row" align="center" gap={1}>
        <input
          {...(register && register(name))}
          type={isPasswordVisible ? 'text' : type}
          id={`${id}-${name}`}
          name={name}
          placeholder={placeholder ? __(placeholder) : ''}
          aria-invalid={!!error}
          disabled={disabled}
          min={minNumber}
          max={maxNumber}
          autoComplete={autoComplete}
          maxLength={maxLength}
        />
        {type === 'password' && (
          <IconButton
            isActive={isPasswordVisible}
            iconName={isPasswordVisible ? 'eye-slash' : 'eye'}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        )}
      </Flex>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => (
            <FieldErrorMessage errorNowrap={errorNowrap} message={message} />
          )}
        />
      )}
    </div>
  );
};

export default Input;
