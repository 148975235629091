import { AWSError, S3 } from 'aws-sdk';
import { PromiseResult } from 'aws-sdk/lib/request';
import { Readable } from 'stream';
import { VotingEntity } from '../voting';
import { CompanyEntity } from '../company';
import { MeetingEntity } from '../meeting';

export type BaseS3Object = Omit<S3.PutObjectRequest, 'Body'>;
export type AwsGetFileRes = PromiseResult<S3.GetObjectOutput, AWSError>;

export interface CreateS3ObjectData {
  fileName: string;
  originalname: string;
  fileType: FileType;
  meeting?: MeetingEntity;
  voting?: VotingEntity;
  company?: CompanyEntity;
  name?: string;
  stream?: Readable;
}

export enum FileType {
  RESOLUTION = 'RESOLUTION',
  ZIP = 'ZIP',
  SCHEMA = 'SCHEMA',
  LOGO = 'LOGO',
  AUTHORIZATION = 'AUTHORIZATION',
  SIGNED_PROTOCOL = 'SIGNED_PROTOCOL',
  UNSIGNED_PROTOCOL = 'UNSIGNED_PROTOCOL',
}

export interface BaseDir {
  mainDir: string;
  fileName: string;
}
