import React from 'react';
import { DndProvider, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Flex } from 'components/layout';
import { PropsWithChildren } from 'types';

const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: { enableMouseEvents: true },
      preview: true,
      transition: TouchTransition,
    },
  ],
};

const AgendaItemsContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <DndProvider options={HTML5toTouch}>
    <Flex direction="col" gap={1}>
      {children}
    </Flex>
  </DndProvider>
);

export default AgendaItemsContainer;
