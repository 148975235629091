import React from 'react';
import { PropsWithChildren } from 'types';

import styles from './DesktopSideColumnContainer.module.scss';

const DesktopSideColumnContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <aside className={styles?.['side-menu']}>{children}</aside>
);

export default DesktopSideColumnContainer;
