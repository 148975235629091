interface RetryFetchOptions extends RequestInit {
  onRetry?: () => void;
  retries?: number;
  delayInMs?: number;
}

export class TimeoutError extends Error {}

const wait = async (delay: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

export const retryFetch = async <T>(
  url: string,
  options: RetryFetchOptions = {
    retries: 5,
    delayInMs: 1000,
  },
): Promise<T> => {
  const { onRetry, retries = 5, delayInMs = 1000, ...fetchOptions } = options;
  if (retries === 0) throw new TimeoutError();

  try {
    const response = await fetch(url, fetchOptions);
    return (await response?.json()) as T;
  } catch (e) {
    if (retries > 1) {
      onRetry?.();
      await wait(delayInMs);
    }
    return retryFetch(url, { onRetry, retries: retries - 1, delayInMs, ...fetchOptions });
  }
};
