import React from 'react';
import { Button } from 'components/common';
import { ModalActionButtonsContainer } from 'components/containers';
import { Form, TimeInput } from 'components/forms';
import { Flex } from 'components/layout';
import { manageResumptionValidationSchema } from 'config';
import { ManageResumptionFormData } from 'types';

interface Props {
  onSubmit: (formValues: ManageResumptionFormData) => void;
  onCancel: () => void;
}

const ManageResumptionForm: React.FC<Props> = ({ onSubmit, onCancel }) => (
  <Form submitHandler={onSubmit} validationSchema={manageResumptionValidationSchema}>
    <Flex direction="col" gap={2}>
      <TimeInput label="resolution.duration.time" name="duration" />
      <ModalActionButtonsContainer>
        <Button text="modals.manage_resumption.title" variant="secondary" />
        <Button text="application.cancel" variant="tertiary" onClick={onCancel} type="button" />
      </ModalActionButtonsContainer>
    </Flex>
  </Form>
);

export default ManageResumptionForm;
