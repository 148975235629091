import React from 'react';
import { DesktopVotingResultsContainer } from 'components/containers';
import { VotingResults } from 'components/meeting';

import DesktopCounter from '../DesktopCounter';

interface Props {
  goalDate: string | undefined | Date;
}

const DesktopVotingResults: React.FC<Props> = ({ goalDate }) => (
  <DesktopVotingResultsContainer>
    <DesktopCounter goalDate={goalDate} isBetweenVoting />
    <VotingResults />
  </DesktopVotingResultsContainer>
);

export default DesktopVotingResults;
