import React, { useEffect, useState } from 'react';

import { Form } from 'components/forms';
import { Flex } from 'components/layout';
import { ExcludeVotersReq } from 'app-types';
import { InputWithIcon } from 'components/inputs';
import { ParticipantsList } from 'components/meeting';
import { ButtonsContainer } from 'components/containers';
import { Text, PageHeader, Button } from 'components/common';
import { mapToExcludeVotersReq, getExcludedVoters } from 'utils';
import { ManageExcludedVotersData, ExcludedVotersForm } from 'types';
import { useAppDispatch, useModal, useApi, useAppSelector } from 'hooks';
import {
  setVotingId,
  successNotification,
  fetchAddMeetingData,
  fetchShareholdingData,
  setAddMeetingFilteredExcludedVoters,
} from 'slices';

const ManageExcludedVoters: React.FC = () => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const [term, setTerm] = useState('');
  const { data } = useAppSelector(({ meeting }) => meeting);
  const { agenda } = useAppSelector(({ dashboard }) => dashboard.addMeeting);
  const { excludedGroupsVotersForm, votingParticipants } = useAppSelector(
    ({ dashboard }) => dashboard.addMeeting,
  );
  const {
    hideModal,
    data: { meetingId, companyId, votingId },
  } = useModal<undefined, ManageExcludedVotersData>();

  useEffect(() => {
    (async function fetchAddMeetingBaseData() {
      await dispatch(fetchShareholdingData(companyId));
      await dispatch(fetchAddMeetingData(meetingId));

      dispatch(setVotingId(votingId));
    })();
  }, [meetingId, dispatch, companyId, votingId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => dispatch(setAddMeetingFilteredExcludedVoters(term)), 250);

    return () => clearTimeout(timeoutId);
  }, [term, dispatch]);

  const onExcludedVotersSubmit = async (formData: ExcludedVotersForm) => {
    const reqData = mapToExcludeVotersReq(formData);

    await api.put<undefined, ExcludeVotersReq>(
      `/meeting/${meetingId}/voting/${votingId}/excluded-voters`,
      reqData,
      async () => {
        await dispatch(fetchAddMeetingData(meetingId!));
        dispatch(successNotification('notifications.edited_excluded_voters'));
      },
    );
    hideModal();
  };

  if (!votingParticipants || !excludedGroupsVotersForm?.view || !excludedGroupsVotersForm?.votingId)
    return null;

  return (
    <Flex direction="col" gap={4}>
      <Flex direction="col">
        <PageHeader
          text="pages.excluded_voters.title"
          args={{ companyName: data?.company?.name ?? '' }}
          uppercase
        />
        <Text text="pages.excluded_voters.description" size={3} />
      </Flex>
      <InputWithIcon
        value={term}
        onChange={(e) => setTerm(e.target.value)}
        iconName="magnifier"
        placeholder="placeholders.search"
      />
      <Form
        submitHandler={onExcludedVotersSubmit}
        defaultValues={getExcludedVoters(
          excludedGroupsVotersForm?.form ?? [],
          agenda,
          excludedGroupsVotersForm?.votingId,
        )}
      >
        <Flex direction="col" gap={2}>
          <ParticipantsList
            excludedGroupsVotersForm={excludedGroupsVotersForm.view}
            isUserSearching={Boolean(term)}
          />
          <Flex justify="end" gap={2}>
            <ButtonsContainer>
              <Button
                text="application.cancel"
                type="button"
                onClick={hideModal}
                variant="tertiary"
              />
              <Button text="application.save" />
            </ButtonsContainer>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
};

export default ManageExcludedVoters;
