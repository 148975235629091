import React from 'react';
import { PropsWithChildren } from 'types';

import styles from './MeetingContentContainer.module.scss';

const MeetingContentContainer: React.FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

export default MeetingContentContainer;
