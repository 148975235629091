import React from 'react';
import { Dropdown } from 'components/forms';
import { DropdownItem } from 'types';
import { HelpdeskIssueCategory } from 'app-types';

const dropdownItems: DropdownItem[] = [
  {
    text: 'helpdesk_issue.categories.logging_in',
    value: HelpdeskIssueCategory.LoggingIn,
  },
  {
    text: 'helpdesk_issue.categories.payment',
    value: HelpdeskIssueCategory.Payment,
  },
  {
    text: 'helpdesk_issue.categories.meeting',
    value: HelpdeskIssueCategory.Meeting,
  },
  {
    text: 'helpdesk_issue.categories.technical_problem',
    value: HelpdeskIssueCategory.TechnicalProblem,
  },
  {
    text: 'helpdesk_issue.categories.other',
    value: HelpdeskIssueCategory.Other,
  },
];

const CategoryDropdown: React.FC = () => (
  <Dropdown name="category" options={dropdownItems} label="helpdesk_issue.category" size="big" />
);

export default CategoryDropdown;
